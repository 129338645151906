import {useEffect, useState} from "react";
import restService from "./rest.service";
import {apiClientPrivate} from "../api/apiClient";

const settingService = {
	useSetting,
	getSetting,
	setSetting,
}

function setSetting( settingName, value ) {
	return new Promise( (resolve, reject) => {

		const params = {
			settingName: settingName,
			value: value,
		}

		apiClientPrivate.post( `/api/setting/setSetting`, params)
			.then( r => restService.handleServerResponseAxios(r) )
			.then( values => {
				resolve( values );
			} )
			.catch( reject );
	})
}

function getSetting( settingNames, signal ) {
	return new Promise( (resolve, reject) => {

		const params = {
			settingNames: JSON.stringify( settingNames )
		}

		apiClientPrivate.get( `/api/setting/getSettings?` + new URLSearchParams( params ), {
			signal: signal
		} )
			.then( r => restService.handleServerResponseAxios(r) )
			.then( values => {
				resolve( values );
			} )
			.catch( reject );
	})
}

function useSetting( settingNames ) {
	const [values, setValues] = useState( {} );
	const [_settingNames] = useState(settingNames); //workaround for solving of an infinite loop in useEffect(..., [settingNames])

	useEffect(() => {
		const controller = new AbortController();

		getSetting( _settingNames, controller.signal )
			.then( values => setValues(values) )
			.catch( error => restService.handleServerErrorsAxios(error, controller.signal) )

		return function cleanup() {
			controller.abort();
		}
	}, [_settingNames]);

	return values;
}

export default settingService
