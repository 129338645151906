import React from 'react';
import {useTranslation} from "react-i18next";
import Index from "../form/Index";
import {FormElements} from "./FormElements";
import bootstrapTableService from "../_components/bootstrapTable.service";
import ShowDamage from "./ShowDamage";
import {CreateEditDamage} from "./CreateEditDamage";
import {Link} from "react-router-dom";
import restService from "../_services/rest.service";

function Damage({ match }) {
	const { t } = useTranslation();

	const columns = [
		{
			dataField: 'id',
			text: t('damage.id'),
			sortField: 'id',
			sortType: 'Long',
			sort: true,
			headerStyle: (column, colIndex) => {
				return { width: '5em' };
			},
		},
		{
			dataField: 'lastChangedOn',
			sort: true,
			hidden: true
		},
		{
			dataField: 'policyNumber',
			text: t('damage.policyNumber'),
			sortField: 'policyVersion.policyNumber_sort',
			sort: true,
			formatter: (cellContent, row) => <Link to={ restService.getTo( row.policyVersion, 'show' ) } target = "_blank" rel = "noopener noreferrer" >
				{ cellContent }
			</Link>,
			headerStyle: (column, colIndex) => {
				return { width: '8em' };
			},
		},
		{
			dataField: 'policyHolder',
			text: t('damage.policyHolder'),
			sortField: 'policyVersion.policyHolder.label_sort',
			formatter: (cellContent, row) => <Link to={ restService.getTo( cellContent, 'show' ) } target = "_blank" rel = "noopener noreferrer" >
				{ cellContent.label }
			</Link>,
			sort: true
		},
		{
			dataField: 'dateOfDamage',
			text: t( 'damage.dateOfDamage' ),
			sortField: 'dateOfDamage',
			formatter: (cellContent, row) => bootstrapTableService.formatterDate(cellContent),
			sort: true,
			headerStyle: (column, colIndex) => {
				return { width: '10em' };
			},
		},
		{
			dataField: 'damageEvent.label',
			text: t( 'damage.damageEvent' ),
			classes: (cell, row, rowIndex, colIndex) => 'overflow-hidden',
			sortField: 'damageEvent.eventName_sort',
			sort: true
		},
		{
			dataField: 'damageType.label',
			text: t( 'damage.damageType' ),
			sortField: 'damageType.name_sort',
			sort: true,
			headerStyle: (column, colIndex) => {
				return { width: '8em' };
			},

		},
		{
			dataField: 'lastChangedOn',
			text: t( 'default.lastChangedOn' ),
			sort: true,
			formatter: (cellContent, row) => {
				if ( cellContent ) {
					return bootstrapTableService.formatterDateTime( cellContent )
				}
			},
			headerStyle: (column, colIndex) => {
				return { width: '10em' };
			},
		},
		{
			dataField: 'resolved',
			text: t( 'damage.resolved' ),
			sort: true,
			formatter: bootstrapTableService.formatterBoolean,
			align: 'center',
			headerStyle: (column, colIndex) => {
				return { width: '10em', textAlign: 'center'};
			},
		},
		// {
		// 	dataField: 'accounted',
		// 	text: t( 'damage.accounted' ),
		// 	formatter: bootstrapTableService.formatterBoolean,
		// }
	];

	return (
		<React.StrictMode>
			<Index
				path={match.path}
				domainName={ 'damage' }
				columns={ columns }
				toString={ (object) => object.dateOfDamage }
				formElements={ <FormElements/> }
				sortField={'lastChangedOn'}
				sortOrder={'desc'}
				allowEdits={ (row) => {
					let result = false
					if ( row && row.id ) {
						result = !row.resolved ? `/api/damage/${ row.id }/edit#GET` : false
					}
					else {
						result = `/api/damage/create`
					}
					return result
				} }
				allowShow={ (row) => {
					if ( row ) {
						return `/api/damage/${row.id}#GET`
					}
					else {
						return '/api/damage/create'
					}
				}}
				showElement={<ShowDamage/>}
				createElement={<CreateEditDamage/>}
				editElement={<CreateEditDamage/>}
				allowDeletions={ (row) => {
					let result = false

					if ( row && row.id ) {
						result = !row.resolved ?  `/api/damage/${row.id}#DELETE` : false
					}

					return result
				}}
			/>
		</React.StrictMode>
	);
}

export { Damage };
