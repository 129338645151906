import restService from "./rest.service";
import {useEffect, useState} from "react";
import {apiClientPrivate} from "../api/apiClient";

const serviceService = {
    usePoliciesVersionsWithIncompleteRV,
    reindexAll,
};

function usePoliciesVersionsWithIncompleteRV( params ) {
    const [ policiesVersionsWithIncompleteRV, setPoliciesVersionsWithIncompleteRV ] = useState([])

    useEffect( () => {
        const controller = new AbortController();

        apiClientPrivate.get(`/api/service/showPoliciesWithMissingRV?` + new URLSearchParams( params ), {
            signal: controller.signal
        })
            .then( response => restService.handleServerResponseAxios( response ) )
            .then( json => {
                setPoliciesVersionsWithIncompleteRV(json.items);
            } )
            .catch( (error) => {
                restService.handleServerErrorsAxios( error, controller.signal );
            } );

        return function cleanup() {
            controller.abort()
        }
    }, []);

    return policiesVersionsWithIncompleteRV;
}

function reindexAll() {
    return new Promise((resolve, reject) => {
        const controller = new AbortController();
        apiClientPrivate.get( `/api/service/reindexAll`, {
            signal: controller.signal,
        } )
            .then( ( response ) => {
                return restService.handleServerResponseAxios( response )
            } )
            .then( successJSON => resolve( successJSON ) )
            .catch( error => reject( error, controller.signal ) );
    })
}

export default serviceService;
