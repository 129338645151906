import React, {useCallback} from "react";
import {Settlements} from "../settlement/Settlements";
import restService from "../_services/rest.service";
import {apiClientPrivate} from "../api/apiClient";

function PreviewPolicyVersionParticipationSettlements( {policyVersion}) {
	const getData = useCallback((page, sizePerPage, sort, searchText, searchOptions, signal) => {
		return new Promise( (resolve, reject) => {
			const params = {
				policyVersionId: policyVersion.id,
				page: page,
				sizePerPage: sizePerPage,
				sort: sort,
				searchText: searchText,
				searchOptions: searchOptions,
			}
			apiClientPrivate.get( `/api/settlement/getParticipationSettlements?${ new URLSearchParams( params ) }`, {
				signal: signal
			} )
				.then( r => restService.handleServerResponseAxios(r) )
				.then( json => {
					//debugger;
					resolve( {totalSize: json.count, data: json.data } );
				} )
				.catch( reject )
		})
	}, [policyVersion] );

	return (
		<>
			<div className={'mt-3'}>
				{(policyVersion && policyVersion.id) ? <Settlements getData={getData} hiddenFields={['policyNumber']}/> : null}
			</div>
		</>

	)
}

export default PreviewPolicyVersionParticipationSettlements
