import {originalWarn} from "../index";
import React, {useEffect, useState} from "react";
import restService from "./rest.service";
import {Trans} from "react-i18next";
import { Partner } from '../partner/Index';
import { Country } from '../country/Index';
import { County } from "../county/Index";
import { Zip } from "../zip/Index";
import { Avb } from "../avb/Index";
import { AvbDescription } from "../avbDescription/Index";
import { Salutation } from '../salutation/Index';
import {ConfiguratorEdit} from "../configurator/Editor";
import Home from "../Home";
import {Setting} from "../setting/Index";
import {BuildingType} from "../buildingType/Index";
import {BuildingClass} from "../buildingClass/Index";
import {ConstructionType} from "../constructionType/Index";
import {FireExtinguisher} from "../fireExtinguischer/Index";
import {Building} from "../building/Index";
import {RoofType} from "../roofType/Index";
import {HeatingType} from "../heatingType/Index";
import {Policy} from "../policy/Index";
import {IndividualText} from "../individualText/Index";
import {StandardText} from "../standardText/Index";
import {ProductType} from "../productType/Index";
import {Product} from "../product/Index";
import {Tariff} from "../tariff/Index";
import {Branch} from "../branch/Index";
import {PolicyCancellationReason} from "../policyCancellationReason/Index";
import {PartnerConsultantScope} from "../partnerConsultantScope/Index";
import {PolicyTargetConditionIndex} from "../policyTargetCondition/Index";
import PolicyVersionSettlementBatch from "../policyVersionSettlementBatch/Index";
import CommissionSettlementBatch from "../commissionSettlementBatch/Index"
import OtherLeadershipDiscountSettlementBatch from "../otherLeadershipDiscountSettlementBatch/Index"
import ParticipationSettlementBatch from "../participationSettlementBatch/Index"
import ProfitParticipationSettlementBatch from "../profitParticipationSettlementBatch/Index"
import OtherLeadershipCheck from "../otherLeadershipCheck/Index"
import PoliciesExport from "../reports/PoliciesExport";
import InventoryExport from "../reports/InventoryExport";
import DamagePayments from "../reports/DamagePayments";
import InterimBillingDamages from "../reports/InterimBillingDamages"
import InvoicedAppversPart from "../reports/InvoicedAppversPart"
import BillingForPartners from "../reports/BillingForPartners"
import {ReportMessage} from "../reportMessage/Index"
import PoliciesVersionsWithIncompleteRV from "../service/PoliciesVersionsWithIncompleteRV"
import CommissionsExport from "../reports/CommissionsExport";
import i18n from "i18next";
import {
	faAddressCard,
	faBuilding,
	faDiagramProject,
	faArrowUpAZ,
	faFolderClosed,
	faSliders,
	faUmbrella,
	faPeopleGroup,
	faRectangleList,
	faScrewdriverWrench,
	faUsers,
	faHouseChimneyCrack,
	faBurst,
	faMoneyBillWave, faBuildingColumns, faFileInvoice,
	faFileLines, faBook, faFileExport, faListCheck, faMoneyBillTransfer, faLanguage,
	faSuitcaseMedical, faRotate, faUsersRectangle, faBullhorn
} from "@fortawesome/free-solid-svg-icons"
import ConfiguratorNodeOrderList from "../configuratorNodeOrder/ConfiguratorNodeOrderList";
import PreviewSettlement from "../settlement/PreviewSettlement";
import {User} from "../user/Index";
import {Damage} from "../damage/Index";
import {DamageType} from "../damageType/Index";
import {DamageEvent} from "../damageEvent/Index";
import {ElementalDamageCause} from "../elementalDamageCause/Index";
import ShowDamageDetail from "../damageDetail/ShowDamageDetail";
import Changelog from "../youtrack/Changelog";
import {BankToCustomerNotification} from "../bankToCustomerNotification/Index";
import {Settlements} from "../settlement/Settlements";
import ShowBankPayment from "../bankPayment/ShowBankPayment";
import Reports from "../reports/Reports";
import DebtorsList from "../reports/DebtorsList";
import DamageRendement from "../reports/DamageRendement";
import FeePerYear from "../reports/FeePerYear";
import ExpiringPolicyVersions from "../reports/ExpiringPolicyVersions";
import ShowDamagePaymentInfo from "../damagePaymentInfo/ShowDamagePaymentInfo";
import ShowDamagePaymentInfoDetail from "../damagePaymentInfoDetail/ShowDamagePaymentInfoDetail";
import CreateEditDamagePaymentInfo from "../damagePaymentInfo/CreateEditDamagePaymentInfo";
import CreateEditCommission from "../commission/CreateEditCommission";
import ProfitParticipationSummary from "../reports/ProfitParticipationSummary"
import {ManualPayment} from "../manualPayment/Index";
import ShowSimilarToBankPayment from "../bankPayment/ShowSimilarToBankPayment";
import CreateManualPayment from "../manualPayment/CreateManualPayment";
import {InnerPredicateEnum, SecuredType} from "../_enum/enum";
import moment from "moment/moment";
import ReindexAll from "../service/ReindexAll";
import {RoleGroup} from "../roleGroup/Index";
import {AclUserGroup} from "../aclUserGroup/Index";
import {securityService} from "./security.service";
import {AccountingExport} from "../accountingExport/Index";
import MergeDomains from "../mergeDomains/MergeDomains";
import AnnouncementEdit from "../_components/AnnouncementEdit";
import logoAppvers from "../images/logo_appvers.svg";
import Tickets from "../notification/Tickets";
import PlainMessages from "../notification/PlainMessages";
import UserProfile from "../user/UserProfile";
import {apiClientPrivate} from "../api/apiClient";
import store from "../store";
import Claim from "../settlement/Claim";
import SettlementsContextProvider from "../settlement/SettlementsContextProvider";

export * from './alert.service';

const appService = {
	isMobile,
	compareObjectsByValue,
	sortArrayOfObjects,
	nullSafeGet,
	fixFileName,
	groupBy,
	capitalize,
	serverLogger,
	intersect,
	useRoleNodeTree,
	useClassSimpleNameAndIdFromRoute,
	getRoutes,
	removeDiacritics,
	useAllowedRoutes,
	forEachReactChildren,
	useMergeable,
	notify,
	useTitle,
	getSearchOptions,
}

function useTitle(title) {
	useEffect(() => {
		const prevTitle = document.title
		if ( title ) {
			document.title = title
		}
		return () => {
			document.title = prevTitle
		}
	})
}

function forEachReactChildren(children, callback) {
	const childrenArray = React.Children.toArray(children)
	for( const elementIdx in childrenArray ) {
		const element = childrenArray[elementIdx]
		if ( React.isValidElement(element) ) {
			const updatedElement = callback(element)
			const _element = updatedElement ? updatedElement : element
			const modifiedChildren = React.Children.toArray(_element.props.children);
			childrenArray[elementIdx] = React.cloneElement( _element, {
				children: modifiedChildren.map( child => forEachReactChildren( child, callback ) )
			} );
		}
	}
	return childrenArray
}

function getSearchOptions( searchParams ) {
	let searchOptions = null
	if ( searchParams ) {
		let queryStrings = []
		for (const p of searchParams) {
			let queryString = { field: p[0] }
			let isPredicate = false
			Object.values( InnerPredicateEnum ).forEach( predicate => {
				const match = p[1].match( new RegExp( `${predicate}\\((.*)\\)` ) )
				if ( match && match.length === 2 ) {
					queryString['text'] = match[1]
					queryString['innerPredicate'] = predicate
					isPredicate = true
				}
			} )
			if ( !isPredicate ) {
				queryString['text'] = p[1]
			}
			queryStrings.push( queryString )
		}
		searchOptions = {
			queryStrings: queryStrings, targetGroup: "AllSettlements"
		}
	}
	return searchOptions
}

function getRoutes() {
	function SettlementsComponent( {routerProps, title} ) {
		appService.useTitle( title )

		const searchOptions = getSearchOptions( new URLSearchParams(routerProps.location.search) )

		return <Settlements {...routerProps} hiddenFields={['paymentState']} searchOptions={searchOptions}/>
	}

	//!!!!!!!!!!!IMPORTANT!!!!!!!!!!!
	//Always add "path" value to Grails application.groovy -> appvers.reactRoutes config variable
	//direct url links will not work properly otherwise
	const routes = [
		{
			path: "/",
			exact: true,
			component: (routerProps) => <Home {...routerProps} />,
		},
		{
			path: "/tickets",
			component: (routerProps) => <Tickets {...routerProps} />,
		},
		{
			path: "/plainMessages",
			component: (routerProps) => <PlainMessages {...routerProps} />,
		},
		{
			path: "/merge",
			component: (routerProps) => <MergeDomains {...routerProps} />,
		},
		{
			path: "/commission/edit/:id?",
			exact: true,
			component: (routerProps) => {
				return <CreateEditCommission id={ parseInt(routerProps.match.params.id) } />
			},
		},
		{
			path: "/commission/create/:policyVersionId?",
			exact: true,
			component: (routerProps) => {
				return <CreateEditCommission policyVersionId={ parseInt(routerProps.match.params.policyVersionId) } />
			},
		},
		{
			path: "/settlement/show/:id?",
			exact: true,
			component: (routerProps) => {
				return <PreviewSettlement id={ parseInt(routerProps.match.params.id) } />
			},
		},
		{
			path: "/bankPayment/show/:id?",
			exact: true,
			component: (routerProps) => {
				return <ShowBankPayment id={ parseInt(routerProps.match.params.id) } />
			},
		},
		{
			path: "/bankPayment/showSimilar/:id?",
			exact: true,
			component: (routerProps) => {
				return <ShowSimilarToBankPayment id={ parseInt(routerProps.match.params.id) } />
			},
		},
		{
			path: "/damageDetail/show/:id?",
			exact: true,
			component: (routerProps) => {
				return <ShowDamageDetail id={ parseInt(routerProps.match.params.id) } />
			},
		},
		{
			path: "/damagePaymentInfo/show/:id?",
			exact: true,
			component: (routerProps) => {
				return <ShowDamagePaymentInfo id={ parseInt(routerProps.match.params.id) } />
			},
		},
		{
			path: "/damagePaymentInfoDetail/show/:id?",
			exact: true,
			component: (routerProps) => {
				return <ShowDamagePaymentInfoDetail id={ parseInt(routerProps.match.params.id) } />
			},
		},
		{
			path: "/damagePaymentInfo/edit/:id?",
			exact: true,
			component: (routerProps) => {
				return <CreateEditDamagePaymentInfo id={ parseInt(routerProps.match.params.id) } />
			},
		},
		{
			path: "/damage/:damageId/damagePaymentInfo/create",
			exact: true,
			component: (routerProps) => {
				return <CreateEditDamagePaymentInfo damageId={ parseInt(routerProps.match.params.damageId) } />
			},
		},
		{
			path: "/partner/:domainName?",
			link: "/partner",
			sidebar: () => i18n.t("menuBar.partners.label"),
			icon: faPeopleGroup,
			component: (routerProps) => <Partner {...routerProps} />,
			hasAccess: '/api/partner#GET' ,
		},
		{
			path: "/policyVersion",
			sidebar: () => i18n.t("menuBar.policies.label"),
			icon: faUmbrella,
			component: (routerProps) => <Policy {...routerProps} />,
			hasAccess: '/api/policyVersion#GET' ,
		},
		{
			path: "/damage",
			sidebar: () => i18n.t("menuBar.damages.label"),
			icon: faHouseChimneyCrack,
			component: (routerProps) => <Damage {...routerProps} />,
			hasAccess: '/api/damage#GET' ,
		},
		{
			sidebar: () => i18n.t("menuBar.billing.label"),
			icon: faMoneyBillWave,
			routes: [
				{
					path: "/invoicePolicyVersion",
					sidebar: () => i18n.t("menuBar.invoices.label"),
					icon: faFileInvoice,
					component: (routerProps) => <SettlementsComponent routerProps={routerProps} title={i18n.t("menuBar.invoices.label")}/>,
					abbrev: () => i18n.t("menuBar.invoices.abbrev.label"),
					hasAccess: '/api/settlement#GET' ,
				},
				{
					path: `/claim`,
					component: (routerProps) => <SettlementsContextProvider><Claim routerProps={routerProps} /></SettlementsContextProvider>,
					hasAccess: '/api/settlement#GET' ,
				},
				{
					path: `/claim?paymentState=${i18n.t('appvers.enums.PaymentState.UNPAID')}&dueDate=LESS_THAN(${moment().format("D.M.YYYY")})`,
					sidebar: () => i18n.t("menuBar.claims.label"),
					icon: faFileInvoice,
					abbrev: () => i18n.t("menuBar.claims.abbrev.label"),
					hasAccess: '/api/settlement#GET' ,
				},
				{
					sidebar: () => i18n.t( "menuBar.settlementBatch.label" ),
					icon: faMoneyBillWave,
					routes: [
						{
							path: "/policyVersionSettlementBatch",
							sidebar: () => i18n.t( "menuBar.policyVersionSettlementBatch.label" ),
							component: ( routerProps ) => <PolicyVersionSettlementBatch { ...routerProps }/>,
							abbrev: () => i18n.t( "menuBar.policyVersionSettlementBatch.abbrev.label" ),
							hasAccess: '/api/policyVersionSettlementBatch#GET' ,
						},
						{
							path: "/commissionSettlementBatch",
							sidebar: () => i18n.t("menuBar.commissionSettlementBatch.label"),
							component: (routerProps) => <CommissionSettlementBatch {...routerProps}/>,
							abbrev: () => i18n.t("menuBar.commissionSettlementBatch.abbrev.label"),
							hasAccess: '/api/commissionSettlementBatch#GET' ,
						},
						{
							path: "/participationSettlementBatch",
							sidebar: () => i18n.t("menuBar.participationSettlementBatch.label"),
							component: (routerProps) => <ParticipationSettlementBatch {...routerProps}/>,
							abbrev: () => i18n.t("menuBar.participationSettlementBatch.abbrev.label"),
							hasAccess: '/api/participationSettlementBatch#GET' ,
						},
						{
							path: "/otherLeadershipDiscountSettlementBatch",
							sidebar: () => i18n.t("menuBar.otherLeadershipDiscountSettlementBatch.label"),
							component: (routerProps) => <OtherLeadershipDiscountSettlementBatch {...routerProps}/>,
							abbrev: () => i18n.t("menuBar.otherLeadershipDiscountSettlementBatch.abbrev.label"),
							hasAccess: '/api/otherLeadershipDiscountSettlementBatch#GET' ,
						},
						{
							path: "/profitParticipationSettlementBatch",
							sidebar: () => i18n.t("menuBar.profitParticipationSettlementBatch.label"),
							component: (routerProps) => <ProfitParticipationSettlementBatch {...routerProps}/>,
							abbrev: () => i18n.t("menuBar.profitParticipationSettlementBatch.abbrev.label"),
							hasAccess: '/api/profitParticipationSettlementBatch#GET' ,
						},

					]
				},
				{
					path: "/bankToCustomerNotification",
					sidebar: () => i18n.t("menuBar.bankToCustomerNotification.label"),
					icon: faBuildingColumns,
					component: (routerProps) => <BankToCustomerNotification {...routerProps} />,
					abbrev: () => i18n.t("menuBar.bankToCustomerNotification.abbrev.label"),
					hasAccess: '/api/bankToCustomerNotification#GET' ,
				},
				{
					path: "/manualPayment/bankPaymentCorrection/:id?/:invoiceId?",
					exact: true,
					component: (routerProps) => {
						return <CreateManualPayment bankPaymentId={ parseInt(routerProps.match.params.id) } invoiceId={routerProps.match.params.invoiceId ? parseInt(routerProps.match.params.invoiceId) : undefined} />
					},
					hasAccess: '/api/manualPayment#GET',
				},
				{
					path: "/manualPayment",
					sidebar: () => <Trans
						i18nKey={'menuBar.manualPayment.label'}
						components={{ ul: <ul/>, li: <li/> }}/>,
					icon: faMoneyBillTransfer,
					component: (routerProps) => <ManualPayment {...routerProps} />,
					hasAccess: '/api/manualPayment#GET' ,
				},
				{
					path: "/otherLeadershipCheck",
					sidebar: () => i18n.t("menuBar.otherLeadershipCheck.label"),
					icon: faListCheck,
					component: (routerProps) => <OtherLeadershipCheck {...routerProps} />,
					abbrev: () => i18n.t("menuBar.otherLeadershipCheck.abbrev.label"),
					hasAccess: '/api/otherLeadershipCheck#GET' ,
				},
			]
		},
		{
			path: "/reports",
			sidebar: () => i18n.t("menuBar.reports.label"),
			icon: faFileLines,
			component: (routerProps) => <Reports {...routerProps} />,
			hasAccess: 'ROLE_REPORTS',
		},
		{
			path: "/debtorsList",
			component: (routerProps) => <DebtorsList {...routerProps} />
		},
		{
			path: "/damageRendement",
			component: (routerProps) => <DamageRendement {...routerProps} />
		},
		{
			path: "/feePerYear",
			component: (routerProps) => <FeePerYear {...routerProps} />
		},
		{
			path: "/expiringPolicyVersions",
			component: (routerProps) => <ExpiringPolicyVersions {...routerProps} />
		},
		{
			path: "/profitParticipationSummary",
			component: (routerProps) => <ProfitParticipationSummary {...routerProps} />
		},
		{
			path: "/policiesExport",
			component: (routerProps) => <PoliciesExport {...routerProps} />
		},
		{
			path: "/inventoryExport",
			component: (routerProps) => <InventoryExport {...routerProps} />
		},
		{
			path: "/damagePayments",
			component: (routerProps) => <DamagePayments {...routerProps} />
		},
		{
			path: "/interimBillingDamages",
			component: (routerProps) => <InterimBillingDamages {...routerProps} />
		},
		{
			path: "/invoicedAppversPart",
			component: (routerProps) => <InvoicedAppversPart {...routerProps} />
		},
		{
			path: "/commissionsExport",
			component: (routerProps) => <CommissionsExport {...routerProps} />
		},
		{
			path: "/billingForPartners",
			component: (routerProps) => <BillingForPartners {...routerProps} />
		},
		{
			sidebar: () => i18n.t("menuBar.accounting.label"),
			icon: faBook,
			routes: [
				{
					path: "/accountingExport",
					sidebar: () => i18n.t("menuBar.accountingExport.label"),
					icon: faFileExport,
					component: (routerProps) => <AccountingExport {...routerProps} />,
					hasAccess: '/api/accountingExport#POST',
				}
			]
		},
		{
			sidebar: () => i18n.t("menuBar.baseData.label"),
			icon: faFolderClosed,
			routes: [
				{
					sidebar: () => i18n.t("menuBar.persons.and.addresses.label"),
					icon: faAddressCard,
					routes: [
						{
							path: "/salutation",
							sidebar: () => i18n.t("menuBar.salutations.label"),
							component: (routerProps) => <Salutation {...routerProps} />,
							abbrev: () => i18n.t("menuBar.salutations.abbrev.label"),
							hasAccess: '/api/salutation#POST'
						},
						{
							path: "/country",
							sidebar: () => i18n.t("menuBar.countries.label"),
							component: (routerProps) => <Country {...routerProps} />,
							abbrev: () => i18n.t("menuBar.countries.abbrev.label"),
							hasAccess: '/api/country#POST'
						},
						{
							path: "/county",
							sidebar: () => i18n.t("menuBar.counties.label"),
							component: (routerProps) => <County {...routerProps} />,
							abbrev: () => i18n.t("menuBar.counties.abbrev.label"),
							hasAccess: '/api/county#POST'
						},
						{
							path: "/zip",
							sidebar: () => i18n.t("menuBar.zips.label"),
							component: (routerProps) => <Zip {...routerProps} />,
							abbrev: () => i18n.t("menuBar.zips.abbrev.label"),
							hasAccess: '/api/zip#POST'
						},
						{
							path: "/partnerConsultantScope",
							sidebar: () => i18n.t("menuBar.partnerConsultantScope.label"),
							component: (routerProps) => <PartnerConsultantScope {...routerProps} />,
							abbrev: () => i18n.t("menuBar.partnerConsultantScope.abbrev.label"),
							hasAccess: '/api/partnerConsultantScope#POST'
						},
					]
				},
				{
					sidebar: () => i18n.t("menuBar.object.label"),
					icon: faBuilding,
					routes: [
						{
							path: "/building",
							icon: faBuilding,
							sidebar: () => i18n.t("menuBar.buildings.label"),
							component: (routerProps) => <Building {...routerProps} />,
							abbrev: () => i18n.t("menuBar.buildings.abbrev.label"),
							hasAccess: '/api/building#POST'
						},
						{
							path: "/buildingType",
							sidebar: () => i18n.t("menuBar.buildingType.label"),
							component: (routerProps) => <BuildingType {...routerProps} />,
							abbrev: () => i18n.t("menuBar.buildingType.abbrev.label"),
							hasAccess: '/api/buildingType#POST'
						},
						{
							path: "/buildingClass",
							sidebar: () => i18n.t("menuBar.buildingClass.label"),
							component: (routerProps) => <BuildingClass {...routerProps} />,
							abbrev: () => i18n.t("menuBar.buildingClass.abbrev.label"),
							hasAccess: '/api/buildingClass#POST'
						},
						{
							path: "/constructionType",
							sidebar: () => i18n.t("menuBar.constructionType.label"),
							component: (routerProps) => <ConstructionType {...routerProps} />,
							abbrev: () => i18n.t("menuBar.constructionType.abbrev.label"),
							hasAccess: '/api/constructionType#POST'
						},
						{
							path: "/heatingType",
							sidebar: () => i18n.t("menuBar.heatingType.label"),
							component: (routerProps) => <HeatingType {...routerProps} />,
							abbrev: () => i18n.t("menuBar.heatingType.abbrev.label"),
							hasAccess: '/api/heatingType#POST'
						},
						{
							path: "/roofType",
							sidebar: () => i18n.t("menuBar.roofType.label"),
							component: (routerProps) => <RoofType {...routerProps} />,
							abbrev: () => i18n.t("menuBar.roofType.abbrev.label"),
							hasAccess: '/api/roofType#POST'
						},
						{
							path: "/fireExtinguisher",
							sidebar: () => i18n.t("menuBar.fireExtinguisher.label"),
							component: (routerProps) => <FireExtinguisher {...routerProps} />,
							abbrev: () => i18n.t("menuBar.fireExtinguisher.abbrev.label"),
							hasAccess: '/api/fireExtinguisher#POST'
						},
					]
				},
				{
					sidebar: () => i18n.t("menuBar.policies.label"),
					icon: faRectangleList,
					routes: [
						{
							path: "/avbDescription",
							sidebar: () => i18n.t("menuBar.avbDescriptions.label"),
							component: (routerProps) => <AvbDescription {...routerProps} />,
							abbrev: () => i18n.t("menuBar.avbDescriptions.abbrev.label"),
							hasAccess: '/api/avbDescription#POST'
						},
						{
							path: "/avb",
							sidebar: () => i18n.t("menuBar.avbs.label"),
							component: (routerProps) => <Avb {...routerProps} />,
							abbrev: () => i18n.t("menuBar.avbs.abbrev.label"),
							hasAccess: '/api/avb#POST'
						},
						{
							path: "/individualText",
							sidebar: () => i18n.t("menuBar.individualTexts.label"),
							component: (routerProps) => <IndividualText {...routerProps} />,
							abbrev: () => i18n.t("menuBar.individualTexts.abbrev.label"),
							hasAccess: '/api/individualText#POST'
						},
						{
							path: "/standardText",
							sidebar: () => i18n.t("menuBar.standardTexts.label"),
							component: (routerProps) => <StandardText {...routerProps} />,
							abbrev: () => i18n.t("menuBar.standardTexts.abbrev.label"),
							hasAccess: '/api/standardText#POST'
						},
						{
							path: "/policyCancellationReason",
							sidebar: () => i18n.t("menuBar.policyCancellationReason.label"),
							component: (routerProps) => <PolicyCancellationReason {...routerProps} />,
							abbrev: () => i18n.t("menuBar.policyCancellationReason.abbrev.label"),
							hasAccess: '/api/policyCancellationReason#POST'
						},
					]
				},
				{
					sidebar: () => i18n.t("menuBar.damages.label"),
					icon: faBurst,
					routes: [
						{
							path: "/damageType",
							sidebar: () => i18n.t("menuBar.damageType.label"),
							component: (routerProps) => <DamageType {...routerProps} />,
							abbrev: () => i18n.t("menuBar.damageType.abbrev.label"),
							hasAccess: '/api/damageType#POST'
						},
						{
							path: "/damageEvent",
							sidebar: () => i18n.t("menuBar.damageEvent.label"),
							component: (routerProps) => <DamageEvent {...routerProps} />,
							abbrev: () => i18n.t("menuBar.damageEvent.abbrev.label"),
							hasAccess: '/api/damageEvent#POST'
						},
						{
							path: "/edCause",
							sidebar: () => i18n.t("menuBar.elementalDamageCause.label"),
							component: (routerProps) => <ElementalDamageCause {...routerProps} />,
							abbrev: () => i18n.t("menuBar.elementalDamageCause.abbrev.label"),
							hasAccess: '/api/elementalDamageCause#POST'
						},
					]
				},
				{
					sidebar: () => i18n.t("menuBar.insuranceProduct.label"),
					icon: faDiagramProject,
					routes: [
						{
							path: "/configurator-editor",
							sidebar: () => i18n.t("menuBar.configuratorEditor.label"),
							component: (routerProps) => <ConfiguratorEdit {...routerProps} />,
							abbrev: () => i18n.t("menuBar.configuratorEditor.abbrev.label"),
							hasAccess: '/api/configuratorNode#POST'
						},
						{
							path: "/policyTargetCondition",
							sidebar: () => i18n.t("menuBar.policyTargetCondition.label"),
							component: (routerProps) => <PolicyTargetConditionIndex {...routerProps} />,
							abbrev: () => i18n.t("menuBar.policyTargetCondition.abbrev.label"),
							hasAccess: '/api/policyTargetCondition#POST'
						},
						{
							path: "/branch",
							sidebar: () => i18n.t("menuBar.branch.label"),
							component: (routerProps) => <Branch {...routerProps} />,
							abbrev: () => i18n.t("menuBar.branch.abbrev.label"),
							hasAccess: '/api/branch#POST'
						},
						{
							path: "/product",
							sidebar: () => i18n.t("menuBar.products.label"),
							component: (routerProps) => <Product {...routerProps} />,
							abbrev: () => i18n.t("menuBar.products.abbrev.label"),
							hasAccess: '/api/product#POST'
						},
						{
							path: "/productType",
							sidebar: () => i18n.t("menuBar.productTypes.label"),
							component: (routerProps) => <ProductType {...routerProps} />,
							abbrev: () => i18n.t("menuBar.productTypes.abbrev.label"),
							hasAccess: '/api/productType#POST'
						},
						{
							path: "/tariff",
							sidebar: () => i18n.t("menuBar.tariffs.label"),
							component: (routerProps) => <Tariff {...routerProps} />,
							abbrev: () => i18n.t("menuBar.standardTexts.abbrev.label"),
							hasAccess: '/api/tariff#POST'
						},
					]
				},
			]
		},
		{
			sidebar: () => i18n.t("menuBar.settingEditor.label"),
			icon: faScrewdriverWrench,
			routes: [
				{
					path: "/setting",
					sidebar: () => i18n.t( "menuBar.defaultValues.label" ),
					icon: faSliders,
					component: ( routerProps ) => <Setting { ...routerProps } />,
					hasAccess: '/api/setting#POST'
				},
				{
					path: "/configuratorNodeOrder",
					sidebar: () => i18n.t( "menuBar.configuratorNodeOrderList.label" ),
					icon: faArrowUpAZ,
					component: ( routerProps ) => <ConfiguratorNodeOrderList { ...routerProps } />,
					hasAccess: '/api/configuratorNode/getConfiguratorNodeOrders#GET'
				},
				{
					path: "/user/profile",
					exact: true,
					component: ( routerProps ) => <UserProfile { ...routerProps } />,
					hasAccess: '/api/user/profile#POST'
				},
				{
					path: "/user",
					sidebar: () => i18n.t( "menuBar.users.label" ),
					icon: faUsers,
					component: ( routerProps ) => <User { ...routerProps } />,
					hasAccess: '/api/user#GET'
				},
				{
					path: "/roleGroup",
					sidebar: () => i18n.t( "menuBar.roleGroup.label" ),
					icon: faUsers,
					component: ( routerProps ) => <RoleGroup { ...routerProps } />,
					hasAccess: '/api/roleGroup#GET'
				},
				{
					path: "/aclUserGroup",
					sidebar: () => i18n.t( "menuBar.aclUserGroup.label" ),
					icon: faUsersRectangle,
					component: ( routerProps ) => <AclUserGroup { ...routerProps } />,
					hasAccess: '/api/aclUserGroup#GET'
				},
				{
					path: "/reportMessage",
					sidebar: () => i18n.t( "menuBar.reportMessage.label" ),
					icon: faLanguage,
					component: ( routerProps ) => <ReportMessage { ...routerProps } />,
					hasAccess: '/api/reportMessage#GET'
				},
				{
					sidebar: () => i18n.t("menuBar.service.label"),
					icon: faSuitcaseMedical,
					routes: [
						{
							path: "/policiesVersionsWithIncompleteRV",
							sidebar: () => i18n.t( "menuBar.policiesVersionsWithIncompleteRV.label" ),
							icon: faUmbrella,
							component: ( routerProps ) => <PoliciesVersionsWithIncompleteRV { ...routerProps } />,
							hasAccess: '/api/service/showPoliciesWithMissingRV#GET',
						},
						{
							path: "/reindexAll",
							sidebar: () => i18n.t( "menuBar.reindexAll.label" ),
							icon: faRotate,
							component: ( routerProps ) => <ReindexAll { ...routerProps } />,
							hasAccess: '/api/service/reindexAll#GET',
						},
						{
							path: "/announcement",
							sidebar: () => i18n.t( "menuBar.announcement.label" ),
							icon: faBullhorn,
							component: ( routerProps ) => <AnnouncementEdit { ...routerProps } />,
							hasAccess: '/api/setting/setSetting#POST',
						},
					]
				},
			]
		},
		{
			path: "/changelog",
			component: ( routerProps ) => <Changelog { ...routerProps } />
		},
		/*!!!!!!!!!!!IMPORTANT!!!!!!!!!!!*/
		/*Always add "path" value to Grails application.groovy -> appvers.reactRoutes config variable*/
		/*direct url links will not work properly otherwise*/
	]

	let key = 0
	const getNextKey = () => {
		return ++key
	}

	const addKeys = (_routes) => {
		_routes.forEach( route => {
			route.key = getNextKey()
			if ( route.routes ) {
				addKeys( route.routes )
			}
		})
	}

	addKeys( routes )

	return routes
}

function useAllowedRoutes() {
	const [routes, setRoutes] = useState( [] )

	useEffect( () => {
		const promisedRoutes = []
		const promises = []

		function fetchAccess(routes) {
			routes.forEach( (route) => {
				if ( route.hasAccess ) {
					promises.push( securityService.isGranted( route.hasAccess, SecuredType.ANY ) )
				}
				else {
					promises.push( Promise.resolve( true ) )
				}

				promisedRoutes.push( route )

				if ( route.routes ) {
					fetchAccess( route.routes )
				}
			})
		}

		const allRoutes = getRoutes()
		fetchAccess( allRoutes )

		Promise.all(promises)
			.then( (results) => {
				//fill the result to hasAccess for each route
				results.forEach( (result, index) => {
					promisedRoutes[index].hasAccess = result
				})

				//remove all routes that user has no access to or the user has no access to any of the subroutes
				function removeForbiddenRoutes(routes) {
					return routes.map( ( route ) => {
						let result = null

						if ( route.routes ) {
							const subRoutes = removeForbiddenRoutes( route.routes )
							if ( subRoutes.length > 0 ) {
								route.routes = subRoutes
								result = route
							}
						} else {
							if ( route.hasAccess ) {
								result = route
							}
						}

						return result
					} ).filter( ( route ) => route !== null )
				}

				const allowedRoutes = removeForbiddenRoutes( allRoutes )

				//shorten the menu for cases, where there is just one subroute
				const result = allowedRoutes.map( (route) => {
					let result = route
					while ( result.routes && result.routes.length === 1 ) {
						result = result.routes[0]
					}
					return result
				})

				setRoutes(result)
			})


	}, [] );

	return routes
}

function groupBy(array,func) {
	return array.reduce((a,b,i)=>((a[func(b,i,array)]||=[]).push(b),a),{}); // eslint-disable-line
}

function intersect(array1, array2) {
	if ( array1 && array2 ) {
		return array1.filter(value => array2.includes(value));
	}
	else {
		return []
	}
}

function capitalize(word) {
	return word.charAt(0).toUpperCase()	+ word.slice(1);
}

function isMobile()
{
	return "ontouchstart" in window;
}

function fixFileName(fileName) {
	const umlautMap = {
		'\u00dc': 'UE',
		'\u00c4': 'AE',
		'\u00d6': 'OE',
		'\u00fc': 'ue',
		'\u00e4': 'ae',
		'\u00f6': 'oe',
		'\u00df': 'ss',
	}

	function replaceUmlaute(str) {
		return str
			.replace(/[\u00dc|\u00c4|\u00d6][a-z]/g, (a) => {
				const big = umlautMap[a.slice(0, 1)];
				return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1);
			})
			.replace(new RegExp('['+Object.keys(umlautMap).join('|')+']',"g"),
				(a) => umlautMap[a]
			);
	}

	const deAccentedFileName = replaceUmlaute(fileName).normalize("NFD").replace(/[\u0300-\u036f]/g, "")
	return deAccentedFileName.replace(/([^a-z0-9\-\s.]+)/gi, '');
}

function compareObjectsByValue(obj1, obj2) {
	if ( !obj1 || !obj2 ) {
		if ( !obj1 && !obj2 ) {
			return true
		}
		else {
			return false
		}
	}

	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);

	if ( keys1.length !== keys2.length ) {
		return false
	}

	const existsKeyWhereObjectsDiffer = keys1.some( (key) => {
		return !( obj2.hasOwnProperty( key ) && obj1[key] === obj2[key] )
	});

	return !existsKeyWhereObjectsDiffer;
}

function sortArrayOfObjects(array, crit) {
	const cmp = (a,b) => {
		const getValue = (obj, prop) => {
			const props = prop.split('.');
			let val = obj;
			props.forEach( (p) => {
				val = val[p];
			})
			return val;
		}

		const compare = (a,b,order) => {
			console.assert(order === 'asc' || order === 'desc', `Incorrect order criteria '${order}'. Only 'asc' or 'desc' allowed.`)
			const revertOrder = (order==='desc'?-1:1);
			if (a < b) {
				return -1*revertOrder;
			}
			if (a > b) {
				return 1*revertOrder;
			}
			return 0;
		}

		let result = 0;
		for( const critIdx in crit ) {
			const c = crit[critIdx].split(' ');
			console.assert( c.length === 1 || c.length === 2, `Incorrect sort criteria '${crit[critIdx]}'` );
			const cProp = c[0];
			const cOrder = c.length === 2 ? c[1] : 'asc';
			result = compare(getValue(a, cProp), getValue(b, cProp), cOrder);
			if ( result ) {
				break;
			}
		};
		return result
	}

	array.sort(cmp);
}

function nullSafeGet(object, property) {
	const props = property.split('.');
	let result = object;
	let i = 0;
	while(result && i < props.length) {
		result = result[props[i++]];
	}
	return result;
}

function serverLogger(level, message) {
	let auth = store.getState().auth;
	if ( auth && !auth.access_token) {
		return
	}
	const controller = new AbortController();

	if ( !['error', 'warn', 'info'].includes(level) ) {
		originalWarn(`serverLogger() called with unknown level '${level}'.`)
	}

	const body = {
		level: level,
		message: message,
	}
	apiClientPrivate.post( `/api/logger`, body, {
		signal: controller.signal,
	} )
		.then()
		.catch( error => {
			console.log(`serverLogger(): ${error}`)
		} );
}

function useMergeable( domainName ) {
	const [ isMergeable, setIsMergeable ] = useState( false )

	useEffect( () => {
		if ( domainName ) {
			const controller = new AbortController()

			const params = {
				domainName: domainName,
			}

			apiClientPrivate.get( `/api/application/isMergeable?` + new URLSearchParams( params ), {
				signal: controller.signal
			} )
				.then( response => restService.handleServerResponseAxios( response ) )
				.then( json => {
					setIsMergeable( json.isMergeable )
				} )
				.catch( ( error ) => {
					restService.handleServerErrorsAxios( error, controller.signal );
				} );

			return function cleanup() {
				controller.abort()
			}
		}
	}, [domainName]);

	return isMergeable;
}

function useClassSimpleNameAndIdFromRoute( domainName, id ) {
	const [ classSimpleName, setClassSimpleName ] = useState( undefined )

	useEffect( () => {
		if ( domainName ) {
			const controller = new AbortController()

			const params = {
				domainName: domainName,
				id: id,
			}

			apiClientPrivate.get( `/api/application/getClassSimpleName?` + new URLSearchParams( params ), {
				signal: controller.signal
			} )
				.then( response => restService.handleServerResponseAxios( response ) )
				.then( json => {
					setClassSimpleName( json.classSimpleName )
				} )
				.catch( ( error ) => {
					restService.handleServerErrorsAxios( error, controller.signal );
				} );

			return function cleanup() {
				controller.abort()
			}
		}
	}, [domainName, id]);

	return classSimpleName;
}

function useRoleNodeTree( authorities ) {
	const [ roleNodeTree, setRoleNodeTree ] = useState([])

	useEffect( () => {
		console.log('useRoleNodeTree() called')
		const plainAuthorities = authorities && authorities.map( (it) => it.authority )
		const controller = new AbortController();
		apiClientPrivate.get(`/api/application/getRoleNodeTree`, {
			signal: controller.signal
		})
			.then( response => restService.handleServerResponseAxios( response ) )
			.then( json => {
				const updateRoleNodeSelected = (node, authorities, parentIsSelected = false) => {
					node.selected = plainAuthorities.includes( node.authority ) || parentIsSelected
					node.children.forEach( (child) => {
						child.parentAuthority = node.authority; //I had child.parent = node here, but it caused a circular reference in JSON.stringify, that I need to create deep copy in reducer
						updateRoleNodeSelected(child, authorities, node.selected)
					} )
				}
				json.roots.forEach( (node) => {
					updateRoleNodeSelected(node, authorities || [])
				})
				setRoleNodeTree(json);
			} )
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
			} );

		return function cleanup() {
			controller.abort()
		}
	}, [authorities]);

	return roleNodeTree;
}

function removeDiacritics(str) {
	return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

function notify(title, text, href) {
	if (!window.Notification) {
		console.log('Browser does not support notifications.');
	} else {
		const notify = () => {
			const removeHtml = (html) => {
				const doc = new DOMParser().parseFromString(html, 'text/html');
				return doc.body.textContent || "";
			}
			const notification = new Notification(removeHtml(title), {
				body: removeHtml(text),
				icon: logoAppvers,
			});

			if ( href ) {
				notification.onclick = ( event ) => {
					event.preventDefault(); // prevent the browser from focusing the Notification's tab
					window.open( href, "_blank" );
				};
			}
		}

		// check if permission is already granted
		if (Notification.permission === 'granted') {
			// show notification here
			notify()
		} else {
			// request permission from user
			Notification.requestPermission().then(function (p) {
				if (p === 'granted') {
					// show notification here
					notify()
				} else {
					console.log('User blocked notifications.');
				}
			}).catch(function (err) {
				console.error(err);
			});
		}
	}
}

export default appService
