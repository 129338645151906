import React, {useState} from 'react';
import {Alert as BootstrapAlert, Button, Card, Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import logoAppvers from "./images/logo_appvers_with_name.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {securityService} from "./_services/security.service";
import {Alert} from "./_components";
import FormControl from "./_components/FormControl";
import {FormProvider, useForm} from "react-hook-form";
import Trans from "./_components/Trans";
import i18n from "i18next";


function AccountActivation({username} ) {
    const { t } = useTranslation()
    const [activationLInkSent, setActivationLinkSent] = useState(false)
    const useFormObject = useForm();

    const handleSubmit = (data) => {
        securityService.sendActivationLink( data.username, i18n.language ).then( () => {
            setActivationLinkSent(true)
        })
    }

    const renderNavigation = () => {
        return (
            <Row className={"mb-3 mt-3"}>
                <Col>
                    <p className={"text-end"}>
                        <Link to={"/"} className={"float-end"}>{t('login.backToLogin.label')}</Link>
                    </p>
                </Col>
            </Row>
        )
    }

    const renderSuccess = () => {
        return (
            <Container>
                <Row>
                    <Col>
                        <BootstrapAlert variant="success">
                            <center>{t('activationLink.sent')}</center>
                        </BootstrapAlert>
                    </Col>
                </Row>
            </Container>
        )
    }

    if ( activationLInkSent ) {
        return renderSuccess()
    }
    else {
        return (
            <Col sm="7" md="5" lg="4" xl="3">
                <Card className="my-5">
                    <Card.Body>
                        <div className="text-center">
                            <img src={logoAppvers} alt={"logo"} className="my-3"/>
                        </div>

                        <Alert/>
                        <BootstrapAlert variant="info">
                            <Trans i18nKey={'login.activation.info'}/>
                        </BootstrapAlert>
                        <FormProvider {...useFormObject}>
                            <Form className="login-form" onSubmit={useFormObject.handleSubmit(handleSubmit)}>
                                <InputGroup>
                                    <InputGroup.Text><FontAwesomeIcon
                                        icon={faUser}/></InputGroup.Text>
                                    <FormControl name={'username'}
                                                 value={username}
                                                 rules={{
                                                     required: true
                                                 }}
                                                 placeholder={t('login.username.placeholder')}
                                                 autoComplete="off"/>
                                </InputGroup>
                                {renderNavigation()}
                                <Button className="px-5 mb-4 w-100" variant="success"
                                        type="submit">{t('login.account.send.activation.label')}</Button>
                            </Form>
                        </FormProvider>
                    </Card.Body>
                </Card>
            </Col>
        )
    }
}

export default AccountActivation
