import {ConfiguratorNode} from "./ConfiguratorNode";
import React from "react";

function ChildrenNodesElements( {childrenData, deleteChildCallback, addChildCallback, parentLoeschfuenferRelevant, parentEsRelevant} ) {

    return (
        <div>
        { childrenData && childrenData.map( ( configuratorNode, index ) => {
            return <ConfiguratorNode key={ configuratorNode.id } className={ configuratorNode.className }
                                     dbId={ configuratorNode.id } isLeaf={ configuratorNode.isLeaf }
                                     configuratorNodeDomainHolders={ configuratorNode.configuratorNodeDomainHolders }
                                     onDelete={ deleteChildCallback } onAddSibling={addChildCallback} isPartOfTariffName={ configuratorNode.isPartOfTariffName }
                                     displayPartOfNameWarning={ configuratorNode.displayPartOfNameWarning }
                                     parentLoeschfuenferRelevant={ parentLoeschfuenferRelevant }
                                     parentEsRelevant={ parentEsRelevant } showEdit={configuratorNode.showEdit}
                                     active={ configuratorNode.active } configuratorNodeAccessList={configuratorNode.configuratorNodeAccessList} />
        } ) }
    </div> )
}

export {ChildrenNodesElements}
