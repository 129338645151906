import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretRight, faBars } from '@fortawesome/free-solid-svg-icons';
import { configuratorService } from "../_services/configurator.service";
import appService, {alertService} from "../_services";
import {Alert, Badge, Button, Col, Dropdown as BootstrapDropdown, Form, Modal, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import ConfiguratorNodeEditor from "./ConfiguratorNodeEditor";
import ConfiguratorNodeTypeDialog from "./ConfiguratorNodeTypeDialog";
import {ChildrenNodesElements} from "./ChildrenNodesElements";
import {ConfiguratorNodeContent} from "./ConfiguratorNodeContent";
import {ConfiguratorEditContext} from "./contexts";
import {Dropdown} from "../_components/Dropdown"
import restService from "../_services/rest.service";
import Trans from "../_components/Trans";
import {ConfiguratorNodeAccessType} from "../_enum/enum";
import websocketService from "../_services/websocket.service";

function ConfiguratorNode(props) {
	const { t } = useTranslation();
	const [expanded, setExpanded] = useState(props.expanded);
	const [childrenData, setChildrenData] = useState(undefined);
	const [childrenFetched, setChildrenFetched] = useState(false);
	const [isLeaf, setIsLeaf] = useState(props.isLeaf)
	const [showTypeDialog, setShowTypeDialog] = useState(false);
	const [broker, setBroker] = useState(false);
	const [brokers, setBrokers] = useState([]);
	const [showSelectBrokerDialog, setShowSelectBrokerDialog] = useState(false);
	const [showEdit, setShowEdit] = useState(props.showEdit);
	const [editErrors] = useState({});
	const [isNewNode, setIsNewNode] = useState(undefined);
	const [showDelete, setShowDelete] = useState(false);
	const [editMode, setEditMode] = useState(undefined);
	const [mouseOver, setMouseOver] = useState(false);
	const [availableOptions, setAvailableOptions] = useState([]);
	const [showNoChildAllowedDialog, setShowNoChildAllowedDialog] = useState(false);
	const [isPartOfTariffName, setIsPartOfTariffName] = useState(props.isPartOfTariffName);
	const {showConfiguratorNodeId} = useContext(ConfiguratorEditContext)

	const expandConfiguratorNodeDomainHolders = (holders) => {
		return holders.map((configuratorNodeDomainHolder) => {
			if ( 'configuratorNodeDomainAsJsonString' in configuratorNodeDomainHolder ) {
				let domain = JSON.parse( configuratorNodeDomainHolder.configuratorNodeDomainAsJsonString );
				if ( domain.contentType === "json" ) {
					domain.content = JSON.parse( domain.content )
				}
				delete configuratorNodeDomainHolder.configuratorNodeDomainAsJsonString;
				configuratorNodeDomainHolder.domain = domain;
			}
			configuratorNodeDomainHolder.name = "configuratorNodeDomainHolder-" + configuratorNodeDomainHolder.id;
			return configuratorNodeDomainHolder;
		});
	};

	const [configuratorNodeDomainHolders, setConfiguratorNodeDomainHolders] = useState(expandConfiguratorNodeDomainHolders([...props.configuratorNodeDomainHolders]));
	const NON_TARGET = 'nonTarget';

	const esRelevant = useMemo( () => {
		let result = false;
		if ( props.parentEsRelevant ) {
			result = true;
		}
		else if ( undefined !== configuratorNodeDomainHolders ) {
			for( let i = 0; i< configuratorNodeDomainHolders.length; i++ ) {
				const holder = configuratorNodeDomainHolders[i];
				if (holder.domain.className === 'product' && holder.domain.content.esRelevant) {
					result = true;
					break;
				}
			}
		}
		return result;
	}, [configuratorNodeDomainHolders, props.parentEsRelevant])

	const loeschfuenferRelevant = useMemo( () => {
		let result = false;
		if ( props.parentLoeschfuenferRelevant ) {
			result = true;
		}
		else if ( undefined !== configuratorNodeDomainHolders ) {
			for( let i = 0; i< configuratorNodeDomainHolders.length; i++ ) {
				const holder = configuratorNodeDomainHolders[i];
				if (holder.domain.className === 'productType' && holder.domain.content.loeschfuenferRelevant) {
					result = true;
					break;
				}
			}
		}
		return result;
	}, [configuratorNodeDomainHolders, props.parentLoeschfuenferRelevant])

	const getContent = () => {
		let configuratorNodeId
		const content = configuratorNodeDomainHolders.map( ( configuratorNodeDomainHolder ) => {
			configuratorNodeId = configuratorNodeDomainHolder.configuratorNodeId
			return <ConfiguratorNodeContent key={configuratorNodeDomainHolder.id} configuratorNodeDomainHolder={configuratorNodeDomainHolder} isPartOfTariffName={isPartOfTariffName} displayPartOfNameWarning={props.displayPartOfNameWarning} active={props.active}/>;
		} );

		return (
			<div className={"configurator-item-name"}>
				{showConfiguratorNodeId && <span><Badge variant="primary">{configuratorNodeId}</Badge>:</span>}
				{content}
			</div>
		)
	}

	const refreshChildrenData = useCallback(() => {
		configuratorService.getConfiguratorNodesData(props.dbId).then( nodes => {
			setChildrenFetched( true );
			setChildrenData( nodes );
		}).catch( errMsg => {
			alertService.error( errMsg );
		} );
	}, [props.dbId])

	const afterUpdateConfiguratorNodeCallback = useCallback( (changes) => {
		refreshChildrenData()
	}, [refreshChildrenData]);
	const ids = useMemo( () => childrenData && childrenData.map( (cn) => cn.id ), [childrenData] );
	websocketService.useAfterUpdateSubscription('configuratorNode', ids, afterUpdateConfiguratorNodeCallback);

	const setNodeExpanded = useCallback((value) => {
		if ( !isLeaf ) {
			if ( !childrenFetched ) {
				refreshChildrenData()
			}
			setExpanded( value );
		}
	}, [isLeaf, childrenFetched, refreshChildrenData])

	const handleOnClick = () => {
		setNodeExpanded(!expanded);
	}

	useEffect(() => {
		if(!isLeaf && expanded) {
			setNodeExpanded(true)
		}
		setIsPartOfTariffName( isPartOfTariffName );
	}, [isLeaf, expanded, setNodeExpanded, isPartOfTariffName]);

	const getCaret = () => {
		let caretContent;
		if ( isLeaf ) {
			caretContent = <>&nbsp;</>
		}
		else {
			caretContent = <FontAwesomeIcon icon={ expanded ? faCaretDown : faCaretRight }/>
		}

		return ( <div className={ "node-caret pt-2 pe-1" }>{caretContent}</div> );
	}

	const handleCloseSelectBrokerDialog = (event) => {
		if(event) {
			event.stopPropagation();
		}
		setShowSelectBrokerDialog(false);
	}

	const handleCreateBrokerTariff = (event) => {
		if(event) {
			event.stopPropagation();
		}
		handleCloseSelectBrokerDialog()

		configuratorService.createBrokerTariff(props.dbId, broker)
			.then( (newNode) => {
                props.onAddSibling(newNode, props.dbId, true);
			})
	}

	const handleCancelEditNode = (event) => {
		if(event) {
			event.stopPropagation();
		}
		setShowEdit(false);
	}

	const handleCloseDeleteNode = (event) => {
		if ( event ) {
			event.stopPropagation();
		}
		setShowDelete(false);
	}

	const handleSubmitNode = ( data ) => {
		if ( isNewNode ) {
			data.parentId = props.dbId;
		}
		else {
			data.id = props.dbId;
		}
		configuratorService.saveConfiguratorNode(data)
			.then((result) => {
				setShowEdit(false);
				if ( isNewNode ) {
					setIsLeaf( false );
					setChildrenFetched(false);
					setExpanded(true);
				}
				else {
					const holders = expandConfiguratorNodeDomainHolders(result.configuratorNodeDomainHolders);
					setConfiguratorNodeDomainHolders(holders);
					setIsPartOfTariffName(result.isPartOfTariffName);
				}
			})
			.catch(error => {
				alertService.error( error, {id: 'editorErrors', autoClose: false} );
			})
	}

	const deleteChild = (childId) => {
		let newChildren = childrenData.filter( child => child.id !== childId )
		setChildrenData( newChildren )
		if( newChildren.length === 0 ) {
			setIsLeaf(true);
		}
	}

    const addChild = useCallback( (child, addAfterDbId, showEdit) => {
		const index = childrenData.findIndex( (child) => child.id === addAfterDbId ) + 1

		child.showEdit = showEdit

        const newChildrenData = [
            ...childrenData.slice(0, index),
            child,
            ...childrenData.slice(index)
        ];
        setChildrenData( newChildrenData )
        setIsLeaf(false);
    }, [childrenData] )

	const deleteConfigurationNodeDomain = (dbId, className) => {
		try {
			const data = {
				id: dbId,
				className: className,
			}
			configuratorService.deleteConfiguratorNode(data).then( () => {
				props.onDelete(props.dbId);
				alertService.success(t("default.deleted", {what: t(data.className + '.label')}), { keepAfterRouteChange: true });
			})
				.catch( (e) => {
					alertService.error(t("default.delete.error", {errorMessage: e.message}), { keepAfterRouteChange: true });
				})
		}
		catch (e) {
			alertService.error(t("default.delete.error", {errorMessage: e.message}), { keepAfterRouteChange: true });
		}
	}

	const handleOpenEdit = (event) => {
		event.stopPropagation();
		setIsNewNode(false);
		setShowEdit(true);
	}

	const handleCreateChild = (event) => {
		event.stopPropagation();
		getAvailableOptions().then( ( _availableOptions ) => {
			setAvailableOptions( _availableOptions );
			if ( _availableOptions.length === 0 ) {
				setShowNoChildAllowedDialog(true);
			} else if ( _availableOptions.length === 1 ) {
				handleSubmitTypeDialog( _availableOptions[0].id );
			} else {
				setShowTypeDialog( true );
			}
		} )
	}

	const handleChooseBroker = (event) => {
		event.stopPropagation();
		const hsNamedCriteriaBroker = {
				namedRestriction:
					{
						queryName: "broker",
						params: {}
					}
			}
		restService.getDomainInstancesList('partner', 1, 10000, undefined, undefined, hsNamedCriteriaBroker).then( (result) => {
			setBrokers(result.data);
			setShowSelectBrokerDialog( true );
		})
	}

	const getAvailableOptions = () => {
		return new Promise ( (resolve, reject) => {
			configuratorService.getAllowedChildrenClasses( props.dbId ).then( (allowedChildrenClasses) => {
				let availableOptions = [];
				let nonTargetClassIsAvailable = false;

				for ( let cls in allowedChildrenClasses ) {
					if ( allowedChildrenClasses[cls].isTarget ) {
						availableOptions.push( {
							id: cls,
							label: cls + '.label'
						} );
					} else {
						nonTargetClassIsAvailable = true;
					}
				}

				if ( nonTargetClassIsAvailable ) {
					availableOptions.push( {
						id: NON_TARGET,
						label: NON_TARGET + ".label"
					} );
				}
				resolve(availableOptions);
			}).catch( (error) => reject(error) );
		});
}

const handleOpenDelete = (event) => {
	event.stopPropagation()
	setShowDelete(true)
}

const getConfiguratorNodeEditor = () => {
	let cnProps = {
		loeschfuenferRelevant: loeschfuenferRelevant,
		esRelevant: esRelevant,
		showEdit: showEdit,
		isNewNode: isNewNode,
		handleSubmit: handleSubmitNode,
		handleCancelEdit: handleCancelEditNode,
		dbId: props.dbId,
		isPartOfTariffName: isPartOfTariffName,
		isTariff: isTariff(),
		errors: editErrors,
		configuratorNodeAccessList: props.configuratorNodeAccessList,
	}

	if ( isNewNode ) {
		if ( isTariff() ) {
			cnProps.optionsDomainName = editMode;
			cnProps.configuratorNodeDomainHolders = [
				{
					domain: {
						className: editMode,
						content: {
							tariffs: []
						}
					}
				}
			];
		} else {
			cnProps.optionsDomainName = '';
			cnProps.configuratorNodeDomainHolders = [];
		}
	}
	else {
		cnProps.optionsDomainName = props.className;
		cnProps.configuratorNodeDomainHolders = configuratorNodeDomainHolders;
	}
	return <ConfiguratorNodeEditor {...cnProps}    />
}

const getEditor = () => {
	if (showEdit) {
		return getConfiguratorNodeEditor();
	}
}

const isTariff = () => {
	if ( isNewNode ) {
		return editMode !== NON_TARGET
	}
	else {
		const configuratorTargetPrefix = "configuratorTarget"
		return props.className.substring( 0, configuratorTargetPrefix.length ) === configuratorTargetPrefix;
	}
}

const handleCancelTypeDialog = (event) => {
	if(event) {
		event.stopPropagation();
	}
	setShowTypeDialog(false);
}

const handleSubmitTypeDialog = (result) => {
	setShowTypeDialog(false);
	setEditMode(result);
	setIsNewNode(true);
	setShowEdit(true);
}

const getTypeDialog = () => {
	if (showTypeDialog) {
		return <ConfiguratorNodeTypeDialog
			show={showTypeDialog}
			availableOptions={availableOptions}
			handleCancel={handleCancelTypeDialog}
			handleSubmit={handleSubmitTypeDialog}
			defaultValue={availableOptions[0].id}
		/>;
	}
}

const getHamburgerMenu = () => {
	if (mouseOver || appService.isMobile()) {
		return <BootstrapDropdown>
			<BootstrapDropdown.Toggle variant="light">
				<FontAwesomeIcon icon={ faBars }/>
			</BootstrapDropdown.Toggle>

			<BootstrapDropdown.Menu>
				<BootstrapDropdown.Item onClick={ handleCreateChild }>{ t( 'default.addChild' ) }</BootstrapDropdown.Item>
				{ ['configuratorTargetBase', 'configuratorTargetAdditionalProducts', 'configuratorTargetDependentProduct'].includes(props.className) &&
					<BootstrapDropdown.Item onClick={ handleChooseBroker }>{ t( 'default.createBrokerTariff' ) }</BootstrapDropdown.Item>
				}
				<BootstrapDropdown.Item onClick={ handleOpenEdit }>{ t( 'default.update' ) }</BootstrapDropdown.Item>
				<BootstrapDropdown.Item className={'text-danger'} onClick={ handleOpenDelete }>{ t( 'default.delete' ) }</BootstrapDropdown.Item>
			</BootstrapDropdown.Menu>
		</BootstrapDropdown>
	}
	else {
		return undefined;
	}
}

const handleConfiguratorItemMouseEnter = () => {
	setMouseOver(true);
}

const handleConfiguratorItemMouseLeave = () => {
	setMouseOver(false);
}

const getNoChildAllowedDialog = () => {
	return (
		<Modal
			show={showNoChildAllowedDialog}
			onHide={() => setShowNoChildAllowedDialog(false)}
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Body>
				<p>{t('configuratorNode.noChildAllowed.message')}</p>
			</Modal.Body>

			<Modal.Footer>
				<Button variant="primary" onClick={() => setShowNoChildAllowedDialog(false)}>{t('default.close')}</Button>
			</Modal.Footer>
		</Modal>
	)
}



return (
	<>
		{showNoChildAllowedDialog && getNoChildAllowedDialog()}
		{showTypeDialog && getTypeDialog()}
		{showEdit && getEditor()}

		{/*Select broker for a new broker tariff*/}
		<Modal show={showSelectBrokerDialog} onHide={handleCloseSelectBrokerDialog} animation={false}>
			<Modal.Header closeButton>
				<Modal.Title>{ t('selectBrokerDialog.title') }</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={handleCreateBrokerTariff}>
					<Row>
						<Col>
							<Alert variant={"info"}>
								<Trans i18nKey={'selectBrokerDialog.body'} values={{tariff: configuratorNodeDomainHolders[0].domain.content.text}}/>
							</Alert>
						</Col>
					</Row>
					<Row>
						<Col>
							<Dropdown
								name={"broker"}
								nullable={true}
								options={brokers}
								keyPropName={'id'}
								valuePropName={'label'}
								required={true}
								onChange={(event) => {
									setBroker(event.target.value);
								}}
							/>
						</Col>
					</Row>
					<Row className={"mt-3"}>
						<Col>
							<Button variant="primary" type={"submit"}>
								{ t('default.create') }
							</Button>
							<Button className={"ms-1"} variant="secondary" onClick={handleCloseSelectBrokerDialog}>
								{ t('default.cancel') }
							</Button>
                        </Col>
                    </Row>
				</Form>
            </Modal.Body>
		</Modal>


		{/*Delete confirm modal*/}
		<Modal show={showDelete} onHide={handleCloseDeleteNode} animation={false}>
			<Modal.Header closeButton>
				<Modal.Title>Delete configurator node</Modal.Title>
			</Modal.Header>
			{/*todo: translate*/}
			<Modal.Body>Do you want to delete node "<span className={"content-to-delete"}>{getContent()}</span>"?</Modal.Body>
			<Modal.Footer>
				<Button variant="danger" onClick={(row) => {
					handleCloseDeleteNode();
					deleteConfigurationNodeDomain(props.dbId, props.className);
				}}>
					{ t('default.delete') }
				</Button>
				<Button variant="secondary" onClick={handleCloseDeleteNode}>
					{ t('default.cancel') }
				</Button>
			</Modal.Footer>
		</Modal>

		<div id={ props.dbId } className={ "configurator-item d-flex" }
		     onMouseEnter={ handleConfiguratorItemMouseEnter } onMouseLeave={ handleConfiguratorItemMouseLeave }>
			{ getCaret() }
			<a href="#home" className={ "active node-content" } onClick={ handleOnClick }>{ getContent() }</a>
			<span>{ props.configuratorNodeAccessList.map( ( access ) => {
				return <Badge className={ "ms-1" } key={ access.user.label }
				              bg={ access.type.name === ConfiguratorNodeAccessType.ALLOWED ? 'success' : 'danger' }>{ access.user.label }</Badge>
			} ) }</span>
			{ getHamburgerMenu() }
		</div>
		<div className={ "ps-4 " + ( expanded === false ? "hidden" : "" ) }>
			<ChildrenNodesElements childrenData={ childrenData } deleteChildCallback={ deleteChild }
			                       addChildCallback={ addChild } parentLoeschfuenferRelevant={ loeschfuenferRelevant }
			                       parentEsRelevant={esRelevant} />
		</div>
	</>
);
}

ConfiguratorNode.defaultProps = {
	expanded: false,
	isLeaf: false
};

export { ConfiguratorNode };
