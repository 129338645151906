import React, {useEffect, useState} from 'react';
import {securityService} from "../_services/security.service";
import appService from "../_services";

function DisplayIfAnyChildPropertyPresent({children}) {
    const [propertyNames, setPropertyNames] = useState([])
    const classSimpleNameOfAllowedContext = securityService.useClassSimpleNameOfAllowedContext()
    const hasReadAccessToProperties = securityService.useAccessToProperties( propertyNames )

    useEffect( () => {
        let _propertyNames = []

        appService.forEachReactChildren( children, (element) => {
            if ( element.props.__TYPE === 'Allowed' ) {
                if ( element.props.propertyName ) {
                    _propertyNames.push( `${classSimpleNameOfAllowedContext}.${element.props.propertyName}` )
                }
            }
        } )

        setPropertyNames( _propertyNames )
    }, [children, classSimpleNameOfAllowedContext])

	return (
        <>
            {/*<p>hasReadAccessToProperties: {JSON.stringify(hasReadAccessToProperties)}</p>*/}
            {
                hasReadAccessToProperties.ready && Object.values( hasReadAccessToProperties.value ).some( (value) => value.read )
                ?
                    <>
                        {children}
                    </>
                :
                    null
            }
        </>
    )
}

export default DisplayIfAnyChildPropertyPresent