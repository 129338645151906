import React, {useCallback, useEffect, useState} from 'react';
import ShowBankPayment from "./ShowBankPayment";
import {ShowSimilarInvoices} from "../invoice/ShowSimilarInvoices";
import {Button} from "react-bootstrap";
import {t} from "i18next";
import {useHistory} from "react-router-dom";

function ShowSimilarToBankPayment( {id} ) {
    const history = useHistory();
    const [invoiceId, setInvoiceId] = useState(null);
    const [editPairedInvoice, setEditPairedInvoice] = useState(true);
    const [editPairedInvoiceObject, setEditPairedInvoiceObject] = useState(null );

    useEffect(() => {
        setEditPairedInvoiceObject( {isEditing: editPairedInvoice, setEditing: setEditPairedInvoice} )
    }, [editPairedInvoice, setEditPairedInvoice])

    const handleCancel = () => {
        history.push(`/bankPayment/show/${id}`)
    }

    const onPairWithInvoice = useCallback((invoiceId) => {
        setEditPairedInvoice(true)
        setInvoiceId(invoiceId)
    },  [setEditPairedInvoice, setInvoiceId])

    return (
        <>
            <ShowBankPayment id={id} editPairedInvoice={ editPairedInvoiceObject } newInvoiceId={invoiceId}/>
            <Button variant={"secondary"} className={'mb-2'} size={'sm'} type="button" onClick={handleCancel}>{t('default.cancel')}</Button>
            <ShowSimilarInvoices bankPaymentId={id} onPairWithInvoice={onPairWithInvoice}/>
        </>
    )
}

export default ShowSimilarToBankPayment
