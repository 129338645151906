import React, {useContext} from 'react';
import {AllowedContext} from "../form/form.service";

/**
 * @param classSimpleName
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
function AllowedContextPropertyName( {propertyName, children}) {
	const allowedContext = useContext( AllowedContext )

	return (
        <AllowedContext.Provider value={ { ...allowedContext, propertyName: propertyName } }>
            { children }
        </AllowedContext.Provider>
	)
}

export default AllowedContextPropertyName