import React, {useCallback, useEffect, useMemo, useState} from 'react';
import restService from "../_services/rest.service";
import ShowField from "../form/ShowField";
import Envers from "../_components/Envers";
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import {RestAsyncSelectControl} from "../_components/RestAsyncSelectControl";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import FormControl from "../_components/FormControl";

function CreateEditCommission( {id, policyVersionId}) {
	const [commission, setCommission] = useState({});
	const [policyVersion, setPolicyVersion] = useState({});
	const {t} = useTranslation();
	const useFormObject = useForm();

	useEffect( () => {
		if ( id ) {
			restService.getDomainInstance('commission', id)
				.then((instance) => {
						setCommission( instance );
						setPolicyVersion( instance.policyVersion );
					});
		}
		if ( policyVersionId ) {
			restService.getDomainInstance('policyVersion', policyVersionId, null, null, "select")
				.then((instance) => setPolicyVersion(instance));
		}
	}, [id, policyVersionId])

	const settlementSearchOptions = useMemo( () => {
		return { queryStrings: [{field: 'policyVersion.id', text: `${policyVersion && policyVersion.id}`}] };
	}, [policyVersion] );

	const handleCancel = () => {
		window.close();
	}

	const handleSubmit = useCallback( (data) => {
		if ( data.id ) {
			restService.updateDomainInstance( 'commission', data.id, data )
				.then( () => {
					window.close();
				})
				.catch( (error, signal ) => {
					restService.handleServerErrorsAxios( error, signal );
				});
		}
		else {
			restService.saveDomainInstance( 'commission', data )
				.then( () => {
					window.close();
				} )
				.catch( ( error, signal ) => {
					restService.handleServerErrorsAxios( error, signal );
				} );
		}
	}, []);

	const hsNamedCriteriaPartnerConsultantScopeOrBroker = useMemo( () => {
		return {
			namedRestriction:
				{
					queryName: "namedCriteriaPartnerConsultantScopeOrBroker",
					params: {}
				}
		}
	}, [])

	return (
		<FormProvider {...useFormObject}>
			<Form onSubmit={useFormObject.handleSubmit(handleSubmit)}>
				<Envers domain={commission}/>
				<FormControl type={"number"} name={'id'} hidden={true} value={commission.id}/>
				<FormControl type={"number"} name={'version'} hidden={true} value={commission.version}/>
				<FormControl type={"text"} name={'policyVersion'} hidden={true} value={policyVersion}/>
				<ShowField label={'commission.policyVersion'} object={policyVersion} property={'label'} type={'text'}/>

				<Row className={"mb-3"}>
					<Form.Group as={Col} md="12" controlId="groupPartner">
						<Form.Label>{t('commission.partner')}</Form.Label>
						<RestAsyncSelectControl
							domainName={"partner"}
							sort={"fullName_sort"}
							value={commission.partner ? commission.partner.id : null}
							label={commission.partner ? commission.partner.label : null}
							name={'partner'}
							searchOptions={hsNamedCriteriaPartnerConsultantScopeOrBroker}
							rules={{
								required: true
							}}
						/>
					</Form.Group>
				</Row>

				<Row className={"mb-3"}>
					<Form.Group as={Col} md={3} xs={6}  controlId="groupPercent">
						<Form.Label>{t('commission.percent')}</Form.Label>
						<InputGroup>
							<FormControl
								name={ "percent" }
								type={ "number" }
								value={ commission.percent }
								validationMessages={{
									range: t('default.validationMessage.range', {from:"0", to:"100"}),
								}}
								rules={{
									validate: {
										range: (v) => 0<=v && v<=100
									}
								}}
							/>
							<InputGroup.Text>&#37;</InputGroup.Text>
						</InputGroup>
					</Form.Group>
					<Form.Group as={Col} md={3} xs={6}  controlId="groupAmount">
						<Form.Label>{t('commission.amount')}</Form.Label>
						<FormControl
							name={ "amount" }
							type={ "number" }
							value={ commission.amount }
							rules={{
								required: true
							}}
						/>
					</Form.Group>
				</Row>

				<Row className={"mb-3"}>
					<Form.Group as={Col} md={3} xs={6}  controlId="groupYear">
						<Form.Label>{t('commission.year')}</Form.Label>
						<FormControl
							name={ "year" }
							type={ "number" }
							value={ commission.year }
							rules={{
								required: true
							}}
						/>
					</Form.Group>
					<Form.Group as={Col} md={3} xs={6}  controlId="groupCommissionDate">
						<Form.Label>{t('commission.commissionDate')}</Form.Label>
						<FormControl
							name={ "commissionDate" }
							type={ "date" }
							value={ commission.commissionDate }
							rules={{
								required: true
							}}
						/>
					</Form.Group>
				</Row>

				<Row className={"mb-3"}>
					<Form.Label>{t('commission.settlementNumber')}</Form.Label>
					<RestAsyncSelectControl
						domainName={"settlement"}
						sort={"number"}
						value={commission.settlement ? commission.settlement.id : null}
						label={commission.settlement ? commission.settlement.label : null}
						name={'settlement'}
						searchOptions={ settlementSearchOptions }
					/>
				</Row>

				<Row className={"mb-3"}>
					<Form.Group controlId="memo">
						<Form.Label>{t("commission.memo")}</Form.Label>
						<FormControl
							name={"memo"}
							type={"text"}
							as="textarea" rows={10}
							value={ commission.memo }
						/>
					</Form.Group>
				</Row>

				<Form.Group>
					<Button type="submit" className="btn btn-primary">{t('default.save')}</Button>&nbsp;
					<Button className="btn btn-secondary" onClick={handleCancel}>{t('default.cancel')}</Button>
				</Form.Group>
			</Form>
		</FormProvider>
	)
}

export default CreateEditCommission
