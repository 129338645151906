import React from 'react';
import {CloseButton, Toast} from "react-bootstrap";
import textService from "../_services/text.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import NotificationCommand from "./NotificationCommand";
import DynamicText from "../_components/DynamicText";
import Snooze from "./Snooze";
import {PlainMessageState} from "../_enum/enum";

function PlainMessage( {message, onUpdateMessage}) {
	const getBg = () => {
		if ( message.snoozedUntil ) {
			return 'secondary'
		}
		else {
			if ( message.state === PlainMessageState.UNREAD ) {
				return "info"
			}
		}
		return ""
	}


	return (
		<Toast bg={getBg()}>
			<Toast.Header closeButton={false}>
				<span className={"w-100"}>
					<span className={`${ message.state === PlainMessageState.UNREAD ? "fw-bold" : "" } float-start`}>{ message.title }</span>

					<small className={ 'float-end' }>
						{ textService.formatDateTime(Date.parse(message.createdOn),{dateStyle: 'medium', timeStyle: 'short' }) }
						<Snooze notification={message} onUpdate={onUpdateMessage} className={'align-top'}/>
						{ message.state === PlainMessageState.UNREAD ? <CloseButton onClick={ () => onUpdateMessage(message.id, {version: message.version, state: PlainMessageState.READ})}/> : <FontAwesomeIcon icon={faEnvelope} size={"lg"} className={"ms-3 cursor-pointer"} onClick={ () => onUpdateMessage(message.id, {version: message.version, state: PlainMessageState.UNREAD})} /> }
					</small>
				</span>
			</Toast.Header>
			<Toast.Body>
				<DynamicText htmlContent = {message.text} />
				{ message.commands &&
					<div className={ "mt-3 mb-3 float-end"}>
						{message.commands.map( (command, index) => <NotificationCommand key={index} command={command}/> )}
					</div>
				}
			</Toast.Body>
		</Toast>
	)
}

export default PlainMessage