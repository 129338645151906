import React from 'react';
import restService from "../_services/rest.service";
import EditableTable from "../form/EditableTable";
import {useTranslation} from "react-i18next";
import {ConfiguratorNodeAccessType, getEnumType} from "../_enum/enum";
import DefaultSpinner from "../_components/DefaultSpinner";

function ConfiguratorNodeAccess({configuratorNodeAccessList, onChange}) {
    const [users,,,,loaded] = restService.useDomainInstancesList("user", undefined, undefined, 'username')
	const {t} = useTranslation()

	const columns = [
		{id: "user", label: t('configuratorNodeAccess.user.label'), input: {tag: "select", values: users, required: true, nullable:true }, formatter: (row) => row.user ? row.user.label : ''},
		{id: "type", label: t('configuratorNodeAccess.type.label'), input: {tag: "select", values: Object.values(ConfiguratorNodeAccessType).map( ( type) => {
			return {
				...getEnumType('appvers.configurator.ConfiguratorNodeAccessType', type),
				id: type,
				label: t('appvers.enums.ConfiguratorNodeAccessType.' + type)
			}}), required: true, nullable:true }, formatter: (row) => row.type},
	]

	return (
		loaded ?
			<EditableTable
				name="configuratorNodeAccess"
				columns={ columns }
				data={ configuratorNodeAccessList }
				onChange={ onChange }
			/>
			:
			<DefaultSpinner loading={!loaded}/>
	)
}

export default ConfiguratorNodeAccess