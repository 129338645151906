import restService from "./rest.service";
import React, {useState, useEffect, useCallback} from "react";
import {Link} from "react-router-dom";
import i18n from "i18next";
import websocketService from "./websocket.service";
import {TicketPriority, TicketState} from "../_enum/enum";
import {policyService} from "./policy.service";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint} from "@fortawesome/free-solid-svg-icons";
import {apiClientPrivate} from "../api/apiClient";


const notificationService = {
	getUnreadPlainMessagesCount,
	getNotificationCommandElement,
	getNotificationCommandHref,
	usePlainNotificationsChanged,
	useUnreadPlainMessagesCount,
	useTodoTicketsCount,
	getTicketBg,
	getTicketStyle,
};

function usePlainNotificationsChanged( classSimpleName, callback ) {
	websocketService.useWebSocketSubscription(`/user/queue/notificationsChanged/${classSimpleName}`, callback )
}

function useTodoTicketsCount() {
	const [todoTicketsCount, setTodoTicketsCount] = useState(undefined);

	const resetTodoTicketsCount = useCallback( () => {
		getTodoTicketsCount()
			.then( (result) => {
				setTodoTicketsCount(result)
			});
	}, [setTodoTicketsCount]);

	usePlainNotificationsChanged( 'ticket', resetTodoTicketsCount );

	useEffect( () => {
		resetTodoTicketsCount()
	}, [resetTodoTicketsCount]);

	return todoTicketsCount;
}

function useUnreadPlainMessagesCount() {
	const [unreadPlainMessagesCount, setUnreadPlainMessagesCount] = useState(undefined);

	const resetUnreadCount = useCallback( () => {
		getUnreadPlainMessagesCount()
			.then(setUnreadPlainMessagesCount);
	}, [setUnreadPlainMessagesCount]);

	usePlainNotificationsChanged( 'plainMessage', resetUnreadCount );

	useEffect( () => {
		resetUnreadCount()
	}, [resetUnreadCount]);

	return unreadPlainMessagesCount;
}

/**
 * Get the number of unread plain messages.
 * @returns {Promise<unknown>}
 */
function getUnreadPlainMessagesCount() {
	return new Promise( ( resolve, reject ) => {
		const controller = new AbortController();
		apiClientPrivate.get( `/api/plainMessage/getUnreadPlainMessagesCount`, {
			signal: controller.signal,
		} )
			.then( response => restService.handleServerResponseAxios( response ) )
			.then( json => {
				resolve( json.unreadCount );
			} )
			.catch( reject )
	} );
}

function getTodoTicketsCount() {
	return new Promise( ( resolve, reject ) => {
		const controller = new AbortController();
		apiClientPrivate.get( `/api/ticket/getTodoTicketsCount`, {
			signal: controller.signal,
		} )
			.then( response => restService.handleServerResponseAxios( response ) )
			.then( json => {
				resolve( json );
			} )
			.catch( reject )
	} );
}

function getNotificationCommandElement(command) {
	switch( command.classSimpleName ) {
		case 'showDomainListCommand':
			return (
				<Link to={ getNotificationCommandHref(command) } className="btn btn-primary me-1" target={"_blank"} rel={"noopener noreferrer"}>
					{ i18n.t(command.labelKey) }
				</Link>
			)
		case 'showDomainCommand':
			return (
				<Link to={ restService.getTo(command.domain, 'show') } className="btn btn-primary me-1" target={"_blank"} rel={"noopener noreferrer"}>
					{ i18n.t(command.labelKey) }
				</Link>
			)
		case 'printPolicyCommand':
			return (
				<Button className="btn btn-primary me-1" onClick={ () => policyService.print(command.id) }>
					<FontAwesomeIcon icon={faPrint}/> { i18n.t(command.labelKey) }
				</Button>
			)
		case 'printSettlementCommand':
			return (
				<Button className="btn btn-primary me-1" onClick={ () => policyService.printSettlement( command.id ).then( () => { /*invoicePolicyVersion printed*/} )}>
					<FontAwesomeIcon icon={faPrint}/> { i18n.t(command.labelKey) }
				</Button>
			)
		default:
			throw new Error(`Unsupported command: ${command.classSimpleName}`)
	}
}

function getNotificationCommandHref(command) {
	switch( command.classSimpleName ) {
		case 'showDomainListCommand':
			return `${restService.getLink(command.domain)}?searchText=${command.searchText}`
		case 'showDomainCommand':
			return `${restService.getTo(command.domain, 'show')}`
		default:
			throw new Error(`Unsupported command: ${command.classSimpleName}`)
	}
}

function getTicketStyle( ticket ) {
	const bg = getTicketBg( ticket.priority, ticket.state )

	let bodyBg = ''
	if ( ticket.state === TicketState.CLOSED ) {
		bodyBg = 'bg-light'
	}
	else if ( ticket.snoozedUntil ) {
		bodyBg = 'bg-secondary'
	}

	const textStyle = ticket.state === TicketState.CLOSED ? 'text-muted' : ''

	return {bg: bg, bodyBg: bodyBg, textStyle: textStyle}
}

function getTicketBg( priority, state ) {
	let result = ''
	if ( state === TicketState.CLOSED ) {
		result = 'light'
	}
	else {
		switch ( priority ) {
			case TicketPriority.LOW:
				result = 'info'
				break
			case TicketPriority.NORMAL:
				result = 'info'
				break
			case TicketPriority.HIGH:
				result = 'warning'
				break
			case TicketPriority.IMMEDIATE:
				result = 'danger'
				break
			default:
				console.warn( 'Unknown priority: ' + priority )
		}
	}

	return result
}
export default notificationService;
