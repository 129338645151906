import React from 'react';
import AppMenu from "./AppMenu";
import 'react-quill/dist/quill.snow.css';

const Home = () => {
	return (
		<>
			<AppMenu/>
		</>
	);
};

export default Home;




