import restService from "./rest.service";
import {fileService} from "./file.service";
import {useEffect, useState} from "react";
import {apiClientPrivate} from "../api/apiClient";

export const accountingService = {
	downloadAccountingExportFile,
	useAccountingDocuments,
	getAccountingDocuments
}

function downloadAccountingExportFile(id, type) {
	const controller = new AbortController();
	const params = {
		id: id,
		type: type,
	}
	apiClientPrivate.post( `/api/accountingExport/downloadAccountingExportFile`, params,
		{
			signal: controller.signal,
			responseType: 'blob',
		} )
		.then( fileService.saveAttachmentWithContentDispositionFileName )
		.catch( (error) => {
			restService.handleServerErrorsAxios( error, controller.signal );
		} );
}

function useAccountingDocuments(ownerClassName, ownerId) {
	const [ accountingDocuments, setAccountingDocuments ] = useState([]);
	const [ accountingDocumentsLoaded, setAccountingDocumentsLoaded ] = useState(false);

	useEffect(() => {
		const controller = new AbortController();

		if ( ownerClassName && ownerId ) {
			setAccountingDocumentsLoaded( false );
			getAccountingDocuments(ownerClassName, ownerId, controller.signal)
				.then( (result) => {
					setAccountingDocuments( result )
					setAccountingDocumentsLoaded( true );
				})
				.catch( error => restService.handleServerErrorsAxios( error, controller.signal ) )
		}

		return function cleanup() {
			controller.abort();
		}
	}, [ownerClassName, ownerId]);

	return { accountingDocuments: accountingDocuments, setAccountingDocuments: setAccountingDocuments, accountingDocumentsLoaded: accountingDocumentsLoaded };
}

function getAccountingDocuments(ownerClassName, ownerId, signal) {
	return new Promise( (resolve, reject) => {

		const params = {
			ownerClassName: ownerClassName,
			ownerId: ownerId,
		}

		apiClientPrivate.get( `/api/accountingExport/getAccountingDocuments?` + new URLSearchParams( params ), {
			signal: signal
		} )
			.then((response) => restService.handleServerResponseAxios(response))
			.then( result => {
				resolve( result.data );
			} )
			.catch( reject );
	})
}