let showLoadingFunction = null;
let hideLoadingFunction = null;

export const setLoadingFunctions = (showLoading, hideLoading) => {
    showLoadingFunction = showLoading;
    hideLoadingFunction = hideLoading;
};

export const showLoading = () => {
    if (showLoadingFunction) showLoadingFunction();
};

export const hideLoading = () => {
    if (hideLoadingFunction) hideLoadingFunction();
};
