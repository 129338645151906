import React, {useCallback, useEffect, useMemo, useState} from 'react';
import restService from "../_services/rest.service";
import {FormProvider, useForm} from "react-hook-form";
import {Button, Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt, faSquareCheck, faSquareXmark} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

function Editable( {children, onSave, editFormElement, isEditable, editingControl} ) {
    const [editing, setEditing] = useState(false );
    const useFormObject = useForm();

    useEffect(() => {
        if ( editingControl && editingControl.isEditing !== undefined ) {
            setEditing( editingControl.isEditing )
        }
    }, [editingControl]);

    const _setEditing = useCallback(( value ) => {
        if ( editingControl && editingControl.setEditing ) {
            editingControl.setEditing( value )
        }
        else {
            setEditing( value )
        }
    }, [editingControl, setEditing])

    const handleOnEdit = useCallback( (event) => {
        event.preventDefault();
        event.stopPropagation();
        _setEditing(true);
    }, [_setEditing]);

    const handleEditingSubmit = useCallback( (data, event) => {
        event.preventDefault();
        event.stopPropagation();
        const promise = onSave(data)

        if ( typeof promise !== 'object' || promise.constructor.name !== "Promise" ) {
            console.warn('onSave must return a promise.')
        }
        else {
            promise
                .then( () => _setEditing( false ) )
                .catch( ( error ) => restService.handleServerErrorsAxios( error ) )
        }
    }, [_setEditing, onSave]);

    const handleCancelEditing = useCallback( (event) => {
        event.preventDefault();
        event.stopPropagation();
        _setEditing(false)
    }, [_setEditing]);

    const element = useMemo(() => {
        if ( editing ) {
            return (
                <FormProvider {...useFormObject}>
                    <Form onSubmit={useFormObject.handleSubmit(handleEditingSubmit)} >
                        <div style={{display: "table", width: "100%"}}>
                            <div style={{display: "table-cell"}}>
                                { editFormElement }
                            </div>
                            <div style={{display: "table-cell", textAlign: "right", width: "80px", verticalAlign: "bottom", paddingBottom:"5px"}} >
                                <Button type={"submit"} className={"ms-1"} style={{right: "0px"}} variant={ "primary" } size={ "sm" }>
                                    <FontAwesomeIcon icon={ faSquareCheck }/>
                                </Button>
                                <Button type={"button"} className={"ms-1"} style={{right: "0px"}} variant={ "secondary" } size={ "sm" } onClick={handleCancelEditing}>
                                    <FontAwesomeIcon icon={ faSquareXmark }/>
                                </Button>
                            </div>
                        </div>
                    </Form>
                </FormProvider>
            )
        }
        else {
            return ( isEditable )
                ?
                    <div style={ { display: "table", width: "100%" } }>
                        <div style={ { display: "table-cell" } }>
                            { children }
                        </div>
                        <div style={ {
                            display: "table-cell",
                            textAlign: "right",
                            width: "40px",
                            verticalAlign: "bottom",
                            paddingBottom: "5px"
                        } }>
                            <Button type={ "button" } variant={ "primary" } size={ "sm" } style={ { right: "0px" } }
                                    className={ "ms-2" } onClick={ handleOnEdit }>
                                <FontAwesomeIcon icon={ faPencilAlt }/>
                            </Button>
                        </div>
                    </div>
                :
                    children
        }
    }, [editing, children, handleEditingSubmit, handleOnEdit, handleCancelEditing, editFormElement, useFormObject, isEditable] )

    return <>
            {/*<p>accessToProperty():{ JSON.stringify(accessToProperty) }, allowedContextPropertyName={allowedContextPropertyName}, classSimpleName={classSimpleName}</p>*/}
            {element}
        </>
}

Editable.propTypes = {
    editFormElement: PropTypes.element.isRequired,
    onSave: PropTypes.func.isRequired,
    isEditable: PropTypes.bool.isRequired,
}

export default Editable
