import React from 'react';

function PlainValue( {value, hidden, className} ) {
    if ( hidden ) {
        return <></>
    }
    else {
        let _value;
        if ( value === null || value === undefined || value === '' ) {
            _value = <>&nbsp;</>
        } else {
            _value = value
        }
        return ( <div className={ `border-bottom white-space-pre-wrap fw-bold ${className}` } >{ _value }</div> )
    }
}

PlainValue.defaultProps = {
    hidden: false
}
export default PlainValue