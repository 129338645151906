import React, {useCallback, useMemo} from 'react';
import {Button, ButtonGroup, Card, Col, Dropdown, Row, Tab, Tabs} from "react-bootstrap";
import appService from "../_services";
import restService from "../_services/rest.service";
import ShowField from "../form/ShowField";
import {useTranslation} from "react-i18next";
import ShowDamageDamageDetails from "./ShowDamageDamageDetails";
import {Link} from "react-router-dom";
import {ChonkyFileBrowser} from "../_components/ChonkyFileBrowser";
import ShowDamagePaymentsInfos from "./ShowDamagePaymentsInfos";
import ShowDamagePaymentInvoices from "./ShowDamagePaymentInvoices";
import {damageService} from "../_services/damage.service";
import websocketService from "../_services/websocket.service";
import {domainTableService} from "../_services/domainTable.service";
import Envers from "../_components/Envers";
import {securityService} from "../_services/security.service";
import {SecuredType} from "../_enum/enum";
import Secured from "../_components/Secured";
import AllowedContextWrapper from "../_components/AllowedContextWrapper";

function ShowDamage(props) {
    const [damage, setDamage] = restService.useDomainInstance( 'damage', props.id, false );
    const { t } = useTranslation();
    const hasAccessToAttachments = securityService.useGranted( '/api/damageAttachment/index#GET' )
    const urls = useMemo( () => [
        '/api/creditNoteDamagePaymentNotification/index',
        '/api/invoiceDamagePaymentInvolvedInsurance/index',
        '/api/invoiceDamagePaymentPolicyHolder/index'
    ], [])
    const hasAccessToDamagePaymentInvoices = securityService.useGranted( urls, SecuredType.ANY ) // when updating here, update also list of classes in SearchResourceGroupTypeEnum::DamageSettlement

    appService.useTitle( damage ? damage.label : t('damage.label') )

    const linkToPolicyVersion = useMemo( () => {
        return restService.getTo( damage.policyVersion, 'show' )
    },[ damage.policyVersion ])

    const handlePrintDamageStatement = useCallback( () => {
        damageService.printDamageStatement( damage.id );
    }, [damage])

    const handlePrintDamageDossier = useCallback( () => {
        damageService.printDamageDossier( damage.id );
    }, [damage])

    const handleDownloadInvolvedInsuranceInvoices = useCallback( () => {
        damageService.downloadInvolvedInsuranceInvoices( damage.id );
    }, [damage])

    const printButtons = useMemo( () => {
        let buttons = [];
        const addButton = (label, onClick) => {
            buttons.push( {label: label, onClick: onClick} );
        }

        if ( damage.resolved ) {
            addButton(t('damage.printDamageStatement'), handlePrintDamageStatement);

            if ( damage.existDamageParticipatoinInvolvedInsuranceInvoice ) {
                addButton( t('damage.downloadInvolvedInsuranceInvoices'), handleDownloadInvolvedInsuranceInvoices );
            }
        }
        addButton(t('damage.printDamageDossier'), handlePrintDamageDossier);

        if ( buttons.length === 1 ) {
            return <Button className={ "float-end" } variant={"primary"} onClick={buttons[0].onClick}>{buttons[0].label}</Button>
        }
        else if ( buttons.length > 1 ) {
            return (
                <Dropdown as={ ButtonGroup } size="sm" className={ "float-end" }>
                    <Button variant={"primary"} onClick={buttons[0].onClick}>{buttons[0].label}</Button>

                    <Dropdown.Toggle split variant="primary" id="dropdown-split-basic"/>

                    <Dropdown.Menu>
                        { buttons.slice(1).map( (button, index) => {
                            return <Dropdown.Item key={index} onClick = {button.onClick}>{button.label}</Dropdown.Item>
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            )
        }
    }, [damage.resolved, handlePrintDamageDossier, handlePrintDamageStatement, t, damage.existDamageParticipatoinInvolvedInsuranceInvoice, handleDownloadInvolvedInsuranceInvoices])

    const showBaseData = () => {
        return (
            <Card className='mb-2'>
                <Card.Header><h4>{appService.nullSafeGet( damage, 'id')}</h4></Card.Header>
                <Card.Body>
                    <Envers domain={damage}/>
                    <Row>
                        <Col>
                            {printButtons}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ShowField label={'damage.dateOfDamage'} object={damage} property={'dateOfDamage'} type={'date'}/>
                        </Col>
                        <Col>
                            <ShowField label={'damage.description'} object={damage} property={'description'} type={'text'}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ShowField label={'damage.policyNumber'} object={damage} property={'policyNumber'} type={'text'} link={{to: linkToPolicyVersion, newTab:true}}/>
                        </Col>
                        <Col>
                            <ShowField label={'damage.policyHolder'} object={damage} property={'policyHolder.label'} type={'text'} link={{domain: 'policyHolder', action:'show', newTab:true}}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ShowField label={'damage.damageType'} object={damage} property={'damageType.label'} type={'text'} />
                        </Col>
                        <Col>
                            <ShowField label={'damage.damageEvent'} object={damage} property={'damageEvent.label'} type={'text'}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ShowField label={'damage.settlementCostPercent'} object={damage} property={'settlementCostPercent'} type={'text'} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ShowField label={'damage.note'} object={damage} property={'note'} type={'text'}/>
                        </Col>
                        <Col>
                            <ShowField label={'damage.resolved'} object={damage} property={'resolved'} type={'boolean'}/>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        )
    }

    const refreshDamage = useCallback( (controller) => {
        const signal = controller && controller.signal;
        restService.getDomainInstance( 'damage', props.id, signal )
            .then( (result) => {
                setDamage(result);
                setTimeout( () => domainTableService.refresh("DamageSettlements"), 100 );
            } )
            .catch( error => restService.handleServerErrorsAxios( error, signal ) )
    }, [props.id, setDamage])

    const afterUpdateDamageCallback = useCallback( (changes) => {
        if ( changes.payload.updatedProperties.includes( 'resolved' ) ) {
            refreshDamage();
        }
    }, [refreshDamage]);

    const ids = useMemo( () => [parseInt(props.id)], [props.id] );
    websocketService.useAfterUpdateSubscription('damage', ids, afterUpdateDamageCallback);

    const showAttachmentsData = () => {
        if ( undefined === damage || !damage.attachments) return;
        return (
            <Card className={"mb-2"}>
                <Card.Header>{t('default.files')}</Card.Header>
                <Card.Body>
                    <Row>
                        <ChonkyFileBrowser holderType={'damage'} holderId={damage.id}/>
                    </Row>
                </Card.Body>
            </Card>
        )
    }

    const handleDamageResolved = useCallback( () => {
        damageService.markDamageAsResolved( damage.id )
    }, [damage.id])

    const handleRevertDamageResolve = useCallback( () => {
        damageService.revertDamageResolve( damage.id )
    }, [damage.id])

    const handlePartlyResolveDamage = useCallback( () => {
        damageService.partlyResolveDamage( damage.id ).then( refreshDamage )
    }, [damage.id, refreshDamage])

    return (
        <>
            {showBaseData()}
            <Row>
                <Col>
                    <Secured granted={`/api/damage/${ damage.id }/edit#GET`}>
                        {!damage.resolved && <Link to={ `/damage/edit/${props.id}` } className="btn btn-sm btn-primary mb-2 me-2">{t('default.edit')}</Link>}
                        {damage.isResolvable && <Button className={"btn-sm mb-2 me-2"} variant={"warning"} onClick={handleDamageResolved}>{t('damage.markAsResolved')}</Button>}
                        {damage.resolved && <Button className={"btn-sm mb-2 me-2"} variant={"danger"} onClick={handleRevertDamageResolve}>{t('damage.revertDamageResolve')}</Button>}
                        {damage.paymentInfoToBeProcessedExists && <Button className={"btn-sm mb-2 me-2"} variant={"warning"} onClick={handlePartlyResolveDamage}>{t('damage.partlyResolveDamage')}</Button>}

                    </Secured>
                </Col>
            </Row>
            <Tabs defaultActiveKey="base" id="damage-tabs">
                <Tab eventKey="base" title={t('damage.tab.details')}>
                    <div className={"border-left border-right border-bottom rounded-bottom p-3"}>
                        <ShowDamageDamageDetails damage={damage}/>
                    </div>
                </Tab>
                <Tab eventKey="paymentsInfo" title={t('damage.tab.payments')} mountOnEnter={true}>
                    <ShowDamagePaymentsInfos damage={damage}/>
                </Tab>
                { hasAccessToAttachments.ready && hasAccessToAttachments.value &&
                    <Tab eventKey="attachments" title={t('damage.tab.attachments')} mountOnEnter={true}>
                        <AllowedContextWrapper allowEdits={()=> '/api/damageAttachment/update#PUT'}>
                                {showAttachmentsData()}
                        </AllowedContextWrapper>
                    </Tab>
                }
                { hasAccessToDamagePaymentInvoices.ready && hasAccessToDamagePaymentInvoices.value &&
                    <Tab eventKey="invoices" title={t('damage.tab.invoices')} mountOnEnter={true}>
                        <ShowDamagePaymentInvoices damage={damage}/>
                    </Tab>
                }
            </Tabs>
        </>
    )
}

export default ShowDamage
