import React from 'react';
import {useTranslation} from "react-i18next";
import Index from "../form/Index";
import {CreatePolicy} from "./CreatePolicy";
import PolicyVersionList from "./PolicyVersionList";
import {PolicyVersionStatus, SecuredType} from "../_enum/enum";
import PreviewPolicyVersion from "./PreviewPolicyVersion";
import {policyService} from "../_services/policy.service";
import {Link} from "react-router-dom";
import {faCirclePlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import restService from "../_services/rest.service";
import LogoAppvers from "../_components/LogoAppvers";
import Secured from "../_components/Secured";
import {apiClientPrivate} from "../api/apiClient";

function Policy({ match }) {
	const { t } = useTranslation();

	const columns = [
		{
			dataField: 'id',
			text: t( 'default.id' ),
			hidden: true
		},
		{
			dataField: 'policyVersionNumber',
			text: t( 'policyVersion.policyNumber.label' ),
			sort: true,
			sortField: 'lastPolicyVersionNumber_sort',
			headerStyle: (column, colIndex) => {
				return { width: '120px' };
			},
		},
		{
			dataField: 'state',
			text: t( 'policyVersion.state.label' ),
			formatter: (value) => {
				return t('appvers.enums.PolicyVersionStatus.' + value);
			},
			headerStyle: (column, colIndex) => {
				return { width: '120px' };
			},
			classes: (cell, row, rowIndex, colIndex) => {
				return policyService.getPolicyStateClass(row.state);
			}
		},
		{
			dataField: 'policyHolder',
			sort: true,
			sortField: 'lastPolicyHolder_sort',
			text: t( 'policyVersion.policyHolder.label' ),
			formatter: (cellContent, row) => <Link to={ restService.getTo( cellContent, 'show' ) } target = "_blank" rel = "noopener noreferrer" >
				{ cellContent.label }
			</Link>,
		},
		{
			dataField: 'policyType',
			sortField: 'lastPolicyType_sort',
			text: t( 'policy.policyType.label' ),
			sort: true
		},
		{
			dataField: 'consultantOrBroker',
			text: t( 'default.consultantOrBroker' ),
			formatter: (cellContent, row) => {
				if ( row.partnerConsultantScope ) {
					return <span><LogoAppvers greyscale={true} width={'12px'}/> {row.partnerConsultantScope.label}</span>;
				}
				else {
					return row.broker && row.broker.label;
				}
			},
			sort: false //transient field
		}
	];

	const expandRow = {
		renderer: row => (
			<PolicyVersionList policyId={row.policyId} excludePolicyVersionId={row.id}/>
		)
	};

	const getIndexData = ( page, sizePerPage, sort, searchText, searchOptions, signal ) => {
		const queryParams = new URLSearchParams(window.location.search)
		const partnerId = queryParams.get("partnerId")
		let _searchOptions = { ...searchOptions }
		if ( partnerId ) {
			let queryStrings = _searchOptions.queryStrings = _searchOptions.queryStrings || []
			queryStrings.push({field: 'lastPolicyHolderId', text: `${partnerId}`})
		}
		restService.addSearchTextProperties( 'policy', _searchOptions )
		const params = {
			page: page,
			sizePerPage: sizePerPage,
			searchText: searchText ? searchText : '',
			searchOptions: _searchOptions ? JSON.stringify(_searchOptions) : '',
		}
		if ( sort ) {
			params.sort = sort
		}

		return new Promise( (resolve, reject) => {
			apiClientPrivate.get( `/api/policy/getPolicyIndex?` + new URLSearchParams( params ), {
				signal: signal
			} )
				.then( r => restService.handleServerResponseAxios(r) )
				.then( json => resolve( {totalSize: json.count, data: json.data } ) )
				.catch( error => reject(error) )
		})
	}

	return (
		<Index
			path={match.path}
			domainName={ 'policyVersion' }
			columns={ columns }
			toString={ ['id', 'policyHolder.label'] }
			allowEdits={ (row) => {
				let result = false
				if ( row && row.id ) {
					result = PolicyVersionStatus.isInProcess( row.state ) ? `/api/policyVersion/${ row.id }/edit#GET` : false
				}
				else {
					result = `/api/policyVersion/create`
				}
				return result
			} }
			allowDeletions={ (row) => PolicyVersionStatus.isInProcess( row.state, true ) ? `/api/policyVersion/${row.id}#DELETE` : false }
			allowShow={ (row) => {
				let result = false
				if ( row && row.id ) {
					result = `/api/policyVersion/${ row.id }#GET`
				}
				else {
					result = `/api/policyVersion/create`
				}
				return result
			} }
			addButtons={
				<Secured granted={'/api/policyVersion/create'}>
					<Link to={ `policyVersion/create` } className="btn btn-sm btn-success">{ t( 'default.addWhat', { what: t( 'default.offer' ) } ) }</Link>
				</Secured>
			}
			createElement={<CreatePolicy/>}
			editElement={<CreatePolicy/>}
			showElement={<PreviewPolicyVersion/>}
			sortField={'lastChangedBy'}
			sortOrder={'desc'}
			expandRow={expandRow}
			rowStyle={ { cursor: 'pointer', fontSize:'90%' } }
			getIndexData={ getIndexData }
			additionalButtons={ (row) => {
				if ( !PolicyVersionStatus.isInProcess( row.state ) ) {
					return (
						<Secured granted={[`/api/policyVersion/${row.id}/edit#GET`, '/api/policyVersion/create']} type={SecuredType.ALL}>
							<Link to={ `/policyVersion/create/${row.policyId}` } className="btn btn-sm btn-success ml-1">{ <FontAwesomeIcon icon={ faCirclePlus }/> }</Link>
						</Secured>
					)
				}
				else {
					return undefined;
				}
			} }
		/>
	);
}

export { Policy };
