import {Alert, Button, Card, Col, Form, Row} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useAuthContext} from "./security/AuthContext";
import {FormProvider, useForm} from "react-hook-form";
import TwoFactorRegistrationFormFields from "./user/TwoFactorRegistrationFormFields";
import logoAppvers from "./images/logo_appvers_with_name.svg";
import {Alert as AppversAlert} from "./_components";
import {securityService} from "./_services/security.service";
import {alertService} from "./_services";
import restService from "./_services/rest.service";


function TwoFactorSetup() {
    const {login, logout, twoFactorSetupRequired} = useAuthContext()
    const { t } = useTranslation()
    const history = useHistory()
    const methods = useForm()

    useEffect(() => {
        if ( !twoFactorSetupRequired ) {
            history.push('/')
        }
    }, [twoFactorSetupRequired, history]);

    const handleFormSubmit = (data) => {
        securityService.setupTwoFactor(data)
            .then((auth) => {
                login(auth, true);
                alertService.success( t( 'login.twoFactorSetup.done' ), { keepAfterRouteChange: true } );
                // history.push('/')
            })
            .catch( (error) =>{
                    restService.handleServerErrorsAxios(error)
                }
            )
    }

    return (
        <FormProvider {...methods}>
            <Col sm="10" md="6" lg="7" xl="5">
                <Form onSubmit={methods.handleSubmit(handleFormSubmit)}>
                    <Card className={"mb-3"}>
                        <Card.Body className="text-center">
                            <div className="text-center">
                                <img src={logoAppvers} alt={"logo"} className="my-3"/>
                            </div>
                            <AppversAlert/>
                            <Alert variant="info">
                                {t('login.twoFactorSetupRequired.info')}
                            </Alert>
                            <TwoFactorRegistrationFormFields/>
                            <Form.Group className={"mt-4"}>
                                    <Button type="submit" className="btn btn-primary">{t('default.save')}</Button>&nbsp;
                                    <Button className="btn btn-secondary" onClick={() => logout()}>{t('default.cancel')}</Button>
                            </Form.Group>
                        </Card.Body>
                    </Card>
                </Form>
            </Col>
        </FormProvider>
    )
}

export default TwoFactorSetup
