import React, {useMemo} from 'react';
import {Form} from "react-bootstrap";
import {Options} from "./Options";
import {dropdownService} from "../_services/dropdown.service";

function Dropdown( { name, defaultValue, value, className, optionsDomainName, keyPropName, valuePropName, nullable, required, onChange, options, size, readOnly, dropdownRef, namedCriteria, sortBy, disabled, isInvalid, optionClassName, templateSuffix } ) {
	const selectedOption = useMemo( () => {
		if ( options ) {
			return options.find( ( option ) => option[keyPropName] == value ) // eslint-disable-line
		}
	}, [value, options, keyPropName])

	return (
		<>
		{/*<p>Value: '{value===undefined ? 'undefined' : (value===null ? 'null' : value)}'</p>*/}
		{/*<p>selectedOption: '{selectedOption===undefined ? 'undefined' : (selectedOption===null ? 'null' : JSON.stringify(selectedOption))}'</p>*/}
		<Form.Control
			name={ name }
			as="select"
			defaultValue={ defaultValue }
			value = {value}
			required={required}
			size={size}
			onChange={onChange}
			readOnly={readOnly}
			className={className + ' ' + dropdownService.getOptionClassName(optionClassName, selectedOption)}
			ref={dropdownRef}
			disabled={disabled}
			isInvalid={isInvalid}
		>
			<Options
				nullable={ nullable }
				defaultValue={ defaultValue?defaultValue:value }
				options={ options }
				domainName ={ optionsDomainName }
				keyPropName ={ keyPropName }
				valuePropName ={ valuePropName }
				namedCriteria = { namedCriteria }
				sortBy = { sortBy }
				optionClassName = { optionClassName }
				templateSuffix = { templateSuffix }
			/>
		</Form.Control>
		</>
	)
}

Dropdown.defaultProps = {
	required: false
};

export { Dropdown };
