import restService from "./rest.service";
import appService from "./index";
import {saveAs} from "file-saver";
import {apiClientPrivate} from "../api/apiClient";

export const fileService = {
	downloadAttachment,
	attachmentsInfo,
	thumbnail,
	saveAttachmentsInfo,
	saveAttachmentWithContentDispositionFileName,
	uploadAttachment
}

function downloadAttachment(id) {
	return new Promise( (resolve, reject) => {
		const controller = new AbortController()
		apiClientPrivate.get(`/api/files/download/${ id }`, {
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( response => resolve( response.data ) )
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
				reject();
			} );
	})
};

function attachmentsInfo(type, id) {
	return new Promise( (resolve, reject) => {
		const controller = new AbortController()
		apiClientPrivate.get( `/api/files/attachmentsInfo/${type}/${ id }`, {
			signal: controller.signal
		} )
			.then( ( response ) => restService.handleServerResponseAxios( response ) )
			.then( data => resolve(data) )
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
				reject();
			} );
	})
};

function thumbnail(url, id) {
	return new Promise( (resolve, reject) => {
		const controller = new AbortController()
		apiClientPrivate.get( `${url}/${ id }`, {
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( response => resolve ( response.data ) )
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
				reject();
			} );
	})
};

function saveAttachmentsInfo( type, id, data ) {
	return new Promise( (resolve, reject) => {
		const controller = new AbortController()
		apiClientPrivate.put( `/api/files/saveAttachmentsInfo/${type}/${ id }`, data, {
			signal: controller.signal,
			headers: {
				'Content-Type': 'text/plain;charset=UTF-8' //needed for the server to automatically bind command object, maybe a bug in Spring
			}
		} )
			.then( ( response ) => restService.handleServerResponseAxios( response ) )
			.then( data => resolve(data) )
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
				reject();
			} );
	})
}

function saveAttachmentWithContentDispositionFileName( response) {
	return new Promise( (resolve, reject) => {
		try {
			restService.handleServerResponseAxios( response )
				.then( ( content ) => {
					const contentDisposition = response.headers.get( "content-disposition" );
					const filename = contentDisposition.split( 'filename=' )[1]
					if ( !(content instanceof Blob) ) {
						content = new Blob([content], {type: "text/plain"});
					}

					saveAs( content, appService.fixFileName( filename ) );
					resolve();
				} )
				.catch( reject )
		}
		catch(error) {
			reject(error);
		}
	});
}

/**
 * Uploads an attachment to the server.
 *
 * @param {File} file - The file to be uploaded.
 * @param {function} onUploadProgress - The callback function to handle the upload progress.
 * @param {AbortController} controller - The controller to manage the upload process and allow for cancellation.
 * @return {Promise} The promise representing the result of the upload operation.
 */
function uploadAttachment( file, onUploadProgress, controller ) {
	let formData = new FormData()
	formData.append("file", file)
	return apiClientPrivate.post( "/api/files/upload", formData, {
		headers: {
			"Content-Type": "multipart/form-data"
		},
		signal: controller.signal,
		onUploadProgress
	} )
}
