import React from 'react';
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import FormControl from "../_components/FormControl";
import {FormProvider, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import reportService from "../_services/report.service";
import SwitchButtonControl from "../_components/SwitchButtonControl";
import appService from "../_services";

function DamagePayments() {
    const {t} = useTranslation();
    const useFormObject = useForm();

    appService.useTitle( t('reports.damagePayments.title') )

    const onSubmit = (data) => {
        reportService.printDamagePayments( data.dateFrom, data.dateTo, data.groupData )
    }

	return (
        <>
            <h2>{t('reports.damagePayments.title')}</h2>

            <FormProvider {...useFormObject}>
                <Form onSubmit={useFormObject.handleSubmit(onSubmit)}>
                    <Card>
                        <Card.Header>
                            {t('default.filter')}
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Form.Group className="col-md-6" controlId="dateFrom">
                                    <Form.Label>{t('damagePayments.dateFrom.label')}</Form.Label>
                                    <FormControl
                                        name={"dateFrom"}
                                        type={"date"}
                                    />
                                </Form.Group>
                                <Form.Group className="col-md-6" controlId="dateTo">
                                    <Form.Label>{t('damagePayments.dateTo.label')}</Form.Label>
                                    <FormControl
                                        name={"dateTo"}
                                        type={"date"}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className={"mt-3"}>
                                <Col md={2}>
                                    <SwitchButtonControl
                                        offlabel={t("damagePayments.groupData.offlabel")}
                                        onlabel={t("damagePayments.groupData.onlabel")}
                                        name={"groupData"}
                                    />
                                </Col>
                            </Row>
                            <Row className={"mt-3"}>
                                <Col md={2}>
                                    <Button type={"submit"}>{ t('default.export') }</Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Form>
            </FormProvider>
        </>
	)
}

export default DamagePayments