import React, {useContext} from 'react';
import { RoleNodeTreeDispatchContext } from './RoleNodeTreeContext.js';
import {securityService} from "../../_services/security.service";
import {AllowedForEnum} from "../../form/form.service";
import RoleNodeTreeLevel from "./RoleNodeTreeLevel";

function RoleNode( { roleNode } ) {
	const dispatch = useContext(RoleNodeTreeDispatchContext);
	const isDomainEditable = securityService.useAllowedFor( AllowedForEnum.EDIT )

	const handleOnClick = () => {
			if (roleNode.selected) {
				dispatch({type: 'unselect', authority: roleNode.authority});
			} else {
				dispatch({type: 'select', authority: roleNode.authority});
		}
	}

	return (
		<div>
			<div style={{pointerEvents: `${!(isDomainEditable.ready && isDomainEditable.value) ? 'none' : 'inherit'}`}}>
				<div className={ `border ps-2 mb-2 cursor-pointer ${roleNode.selected ? 'bg-success text-white' : ''}` } onClick={ handleOnClick }>
					<div>
						{ roleNode.label }
					</div>
					<div className={"fw-lighter ms-3"}>
						<small>{ roleNode.description }</small>
					</div>
				</div>
			</div>
			<div className={ "ms-4" }>
				<RoleNodeTreeLevel nodes={roleNode.children}/>
			</div>
		</div>
	)
}

export default RoleNode
