import React, {useEffect, useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {t} from "i18next";
import DynamicText from "../_components/DynamicText";
import restService from "../_services/rest.service";
import {useFormContext} from "react-hook-form";
import DefaultSpinner from "../_components/DefaultSpinner";
import FormControl from "../_components/FormControl";

export function TwoFactorRegistrationFormFields() {
    const [twoFactorRegistrationData, setTwoFactorRegistrationData] = useState(null)
    const {register} = useFormContext()


    useEffect(() => {
        async function fetchTwoFactorRegistrationCode() {
            const json = await restService.fetchTwoFactorRegistrationCode()
            setTwoFactorRegistrationData(json)
        }

        fetchTwoFactorRegistrationCode()
    }, [setTwoFactorRegistrationData])

    if (twoFactorRegistrationData) {
        return (
            <>
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4>{t('twoFactorAuthentication.headline')}</h4>
                            <div className="text-center">
                                <div className="d-inline-block text-start">
                                    <DynamicText htmlContent={t('twoFactorAuthentication.description')}/>
                                </div>
                            </div>
                            <h4>{t('twoFactorAuthentication.qrCode')}</h4>
                            <img alt={"key"} src={twoFactorRegistrationData.authUrl}/>
                            <h4>{t('twoFactorAuthentication.registrationCode')}</h4>
                            <p>{twoFactorRegistrationData.key}</p>
                            <input type={"hidden"}
                                   {...register("totpSecret", {
                                       value: twoFactorRegistrationData.key
                                   })}
                            />
                            <h4>{t('twoFactorRegistrationData.verify')}</h4>
                            <p>{t('twoFactorRegistrationData.verifyDescription')}</p>
                        </Col>
                    </Row>
                    <Row>
                        <div className="d-flex justify-content-center">
                            <Form.Group as={Col} md={4} xs={4} controlId="formGroupVerify">
                                <FormControl
                                    name={"totpConfirmation"}
                                    type={"text"}
                                    rules={{
                                        required: true
                                    }}
                                    placeholder={t('twoFactorRegistrationData.verify.code')}
                                    autoComplete={"off"}
                                />
                            </Form.Group>
                        </div>
                    </Row>
                </div>
                </>
                )
                } else {
                           return (
                           <DefaultSpinner loading={true} />
        )
    }
    }

    export default TwoFactorRegistrationFormFields;
