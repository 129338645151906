import React, {useMemo} from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import FormControl from "../_components/FormControl";
import {RestAsyncSelectControl} from "../_components/RestAsyncSelectControl";
import {FormElements as DamageEventFormElements} from "../damageEvent/FormElements";
import {PolicyVersionStatus} from "../_enum/enum";

function FormElements( {domainInstance, isAddMode, defaultValues} ) {
	const { t } = useTranslation();

	const searchOptionsNotInProcess = useMemo( () => {
		return { queryStrings: PolicyVersionStatus.isNotInProcessQueryString() }
	}, [])

	return (
		<>
			<Card className={"mb-2"}>
				<Card.Header>{t('damage.card.info')}</Card.Header>
				<Card.Body>
					<Row className={"mb-3"}>
						<Form.Group as={Col} md="4" controlId="formGroupDamageDate">
							<Form.Label>{t('damage.dateOfDamage')}</Form.Label>
							<FormControl
								name={"dateOfDamage"}
								type={"date"}
								value={domainInstance.dateOfDamage}
								rules={{
									required: true
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} md="8" controlId="formGroupDamageDescription">
							<Form.Label>{t('damage.description')}</Form.Label>
							<FormControl
								name={"description"}
								type={"text"}
								value={domainInstance.description || (defaultValues && defaultValues.name)}
							/>
						</Form.Group>
					</Row>
					<Row className={"mb-3"}>
						<Form.Group as={Col} md="4" controlId="formGroupDamagePolicyNumber">
							<Form.Label>{t('damage.policyNumber')}</Form.Label>
							<RestAsyncSelectControl
								domainName={"policyVersion"}
								sort={"[Long]selectionSort"}
								value={domainInstance && domainInstance.policyVersion && domainInstance.policyVersion.id}
								label={domainInstance && domainInstance.policyVersion && domainInstance.policyVersion.label}
								searchOptions={ searchOptionsNotInProcess }
								name={'policyVersion'}
								// onChange={handlePolicyholderOnChange}
								rules={{
									required: true
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} md="8" controlId="formGroupDamageDamageEvent">
							<Form.Label>{t('damage.damageEvent')}</Form.Label>
							<RestAsyncSelectControl
								noSelection={{label: t('damage.damageEvent.noSelection'), id: ""}}
								placeholder={t('damage.damageEvent.noSelection')}
								domainName={"damageEvent"}
								sort={"eventDateTime desc"}
								value={ domainInstance && domainInstance.damageEvent && domainInstance.damageEvent.id }
								label={ domainInstance && domainInstance.damageEvent && domainInstance.damageEvent.label }
								name={'damageEvent'}
								createable={{formElements: <DamageEventFormElements/>, target:'eventName'}}
							/>
						</Form.Group>
					</Row>
					<Row className={"mb-3"}>
						<Form.Group as={Col} md="4" controlId="formGroupDamageType">
							<Form.Label>{t('damage.damageType')}</Form.Label>
							<RestAsyncSelectControl
								domainName={"damageType"}
								sort={"edCause.description_sort"}
								value={ domainInstance && domainInstance.damageType && domainInstance.damageType.id }
								label={ domainInstance && domainInstance.damageType && domainInstance.damageType.label }
								name={'damageType'}
								rules={{
									required: true
								}}
							/>
						</Form.Group>
					</Row>
					<Row className={"mb-3"}>
						<Form.Group as={Col} controlId="formGroupDamageNote">
							<Form.Label>{t('damage.description')}</Form.Label>
							<FormControl
								name={"note"}
								type={"text"}
								as="textarea" rows={10}
								value={domainInstance.note || (defaultValues && defaultValues.note)}
							/>
						</Form.Group>
					</Row>
				</Card.Body>
			</Card>
		</>
	);
}

export { FormElements };
