import React, {useMemo} from 'react';
import ObligatoryElementTargets from "./ObligatoryElementTargets";
import {useTranslation} from "react-i18next";

function ObligatoryElementCondition( {element, addConfiguratorTarget, building}) {
    const {t} = useTranslation()

    return useMemo( () => {
        if ( element.unfulfilled !== undefined ) {
            return (
                <>
                    { element.unfulfilled.length > 1 && <span> { t(`policyTargetCondition.operator.${element.operator.name}.tooltip`) } </span> }
                    <ul className={ "mb-0" }>
                        { element.unfulfilled.map( ( unfulfilled ) => {
                            return <li><ObligatoryElementCondition element={ unfulfilled } addConfiguratorTarget={addConfiguratorTarget} building={building}/></li>
                        } ) }
                    </ul>
                </>
            )
        } else {
            return <ObligatoryElementTargets key={element.id} element={ element } addConfiguratorTarget={addConfiguratorTarget} building={building}/>
        }
    }, [element, t, addConfiguratorTarget, building] )
}

export default ObligatoryElementCondition