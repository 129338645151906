import React, {useMemo} from 'react';
import PolicyTargetCondition from "./PolicyTargetCondition";
import restService from "../_services/rest.service";
import {FormProvider, useForm} from "react-hook-form";
import {Button, Form} from "react-bootstrap";
import SubmitButton from "../_components/SubmitButton";
import {useTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";

function EditPolicyTargetCondition( { routeParams, history } ) {
	const useFormObject = useForm();
	const { t } = useTranslation();
	const id = useMemo( () => routeParams && routeParams.id, [routeParams]);
	const [policyTargetCondition] = restService.useDomainInstance( 'policyTargetCondition', id, true );

	const onSubmit = (data) => {
		if ( id ) {
			restService.updateDomainInstance( 'policyTargetCondition', id, data ).then( () => {
				goBack();
			} )
		}
		else {
			restService.saveDomainInstance( 'policyTargetCondition', data ).then( () => {
				goBack();
			} )
		}
	}

	function goBack() {
		if ( history.length === 1 ) {
			window.close();
		}
		else {
			history.goBack();
		}
	}

	const handleCancel = () => {
		window.history.back();
	}

	return (
		<FormProvider {...useFormObject}>
			<Form onSubmit={useFormObject.handleSubmit(onSubmit)}>
				{ Object.keys(policyTargetCondition).length !== 0 && <PolicyTargetCondition policyTargetCondition={policyTargetCondition} /> }
				<Form.Group>
					<SubmitButton isSubmitting={useFormObject.formState.isSubmitting} type="submit" className="btn btn-primary">{t('default.save')}</SubmitButton>&nbsp;
					<Button className="btn btn-secondary" onClick={handleCancel}>{t('default.cancel')}</Button>
				</Form.Group>
			</Form>
		</FormProvider>
	)
}

export default withRouter( EditPolicyTargetCondition )