import React, {useEffect, useMemo, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus} from "@fortawesome/free-solid-svg-icons";
import {Button, Col, Row} from "react-bootstrap";
import {RestAsyncSelectControl} from "../_components/RestAsyncSelectControl";
import FormControl from "../_components/FormControl";
import SwitchButtonControl from "../_components/SwitchButtonControl";
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";
import {PolicyTargetConditionOperator} from "../_enum/enum";

function PolicyTargetConditionConfiguratorTarget( { policyTargetConditionConfiguratorTarget, namePrefix, removeMe, operator } ) {
	const {t} = useTranslation()
	const { setValue} = useFormContext();
	const [optional, setOptional] = useState( policyTargetConditionConfiguratorTarget.optional )
	const noObsoleteTargets = useMemo( () => {
		return {
			queryName: "notObsolete",
			params: {}
		}
	}, [])

	useEffect( () => {
		setValue( `${ namePrefix }optional`, optional )
	}, [optional, namePrefix, setValue] );

	return (
		<>
			<FormControl type={ "text" } name={ `${ namePrefix }id` } hidden={ true }
		                           value={ policyTargetConditionConfiguratorTarget.id }/>
			<FormControl type={ "text" } name={ `${ namePrefix }version` } hidden={ true }
			             value={ policyTargetConditionConfiguratorTarget.version }/>
			<FormControl type={ "text" } name={ `${ namePrefix }deleted` } hidden={ true }
			             value={ policyTargetConditionConfiguratorTarget.deleted }/>
		{ !policyTargetConditionConfiguratorTarget.deleted && <Row className={"mb-1"}>
	        <Col md={ 12 }>
		        <div style={ { display: "table", width: "100%" } }>
			        <div style={ { display: "table-cell" } }>
				        <RestAsyncSelectControl
					        name={ `${ namePrefix }configuratorTarget` }
					        domainName={ "configuratorTarget" }
					        value={ policyTargetConditionConfiguratorTarget && policyTargetConditionConfiguratorTarget.configuratorTarget && policyTargetConditionConfiguratorTarget.configuratorTarget.id }
					        label={ policyTargetConditionConfiguratorTarget && policyTargetConditionConfiguratorTarget.configuratorTarget && policyTargetConditionConfiguratorTarget.configuratorTarget.label }
					        sort={ "label" }
					        required={ true }
					        namedCriteria={ noObsoleteTargets }
				        />
			        </div>
			        <div style={ {
				        display: "table-cell",
				        textAlign: "right",
				        width: "150px",
				        verticalAlign: "bottom",
				        paddingBottom: "5px",
				        paddingLeft: "10px"
			        } }>
				        {operator === PolicyTargetConditionOperator.AND &&
				        <SwitchButtonControl
					        value={policyTargetConditionConfiguratorTarget.optional}
					        name={`${ namePrefix }optional_switchbutton`}
							offlabel={t("policyTargetConditionConfiguratorTarget.optional.offlabel")}
					        onlabel={t("policyTargetConditionConfiguratorTarget.optional.onlabel")}
					        offstyle={"danger"}
					        onstyle={"warning"}
					        onChange={ (value) => {
								setOptional(value)
					        }}
				        />
				        }
			        </div>
			        <div style={ {
				        display: "table-cell",
				        textAlign: "right",
				        width: "50px",
				        verticalAlign: "bottom",
				        paddingBottom: "5px"
			        } }>
				        <Button className={ "float-end ms-2" } size={ "xs" } variant={ "danger" }
				                onClick={ removeMe }><FontAwesomeIcon icon={ faMinus }/></Button>
			        </div>
		        </div>
	        </Col>
		</Row> }
		</>
	)
}

export default PolicyTargetConditionConfiguratorTarget