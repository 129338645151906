import React, {useContext, useMemo, useState} from 'react';
import {Button, ButtonGroup, Col, Dropdown, Row} from "react-bootstrap";
import DropdownItemWithOptions from "../_components/DropdownItemWithOptions";
import {Settlements} from "./Settlements";
import appService from "../_services";
import {useTranslation} from "react-i18next";
import {invoiceService} from "../_services/invoice.service";
import {SettlementsContext} from "./SettlementsContext";

function Claim() {
    const {t} = useTranslation();
    const settlementsContext = useContext( SettlementsContext );
    const settlementsState = useMemo( () => settlementsContext && settlementsContext.settlementsState, [settlementsContext] )
    const dispatchSettlementsState = useMemo( () => settlementsContext && settlementsContext.dispatchSettlementsState, [settlementsContext] )

    appService.useTitle( t("menuBar.claims.label") )

    const [searchText, setSearchText] = useState()

    const searchOptions = useMemo( () => {
        return appService.getSearchOptions( new URLSearchParams( window.location.search ) )
    }, [])

    const hiddenFields = useMemo( () => {
        return ['paymentState']
    }, [] )

    const handlePrintSettlements = ( withLogo, ppstamp ) => {
        invoiceService.printSettlements( searchText, searchOptions, undefined, withLogo, ppstamp, { selection: settlementsState.selection, allSelected: settlementsState.allSelected } )
            .then( (result) => {
                alert(`settlementsSize: ${result.settlementsSize}` )
            })
    }

    return (
        <>
            <Row className={"mt-3"}>
                <Col lg={6} md={9} sm={12}>
                    <Dropdown as={ButtonGroup}>
                        <Button variant={"primary"} onClick={handlePrintSettlements}> {t('settlements.printSelected.label') } </Button>
                        <Dropdown.Toggle split variant={"primary"} />
                        <Dropdown.Menu>
                            <DropdownItemWithOptions
                                label={ t('settlements.printSelected.label') }
                                options={[{name:'withLogo', checked:true, control: 'switchbutton'}, {name:'ppstamp', checked:true, control: 'switchbutton'}]}
                                onClick={ (values) => {
                                    handlePrintSettlements( values.withLogo, values.ppstamp );
                                }}
                            />
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>

            <Settlements showSelection={true} hiddenFields={ hiddenFields } searchOptions={ searchOptions }/>
        </>
    )
}

export default Claim