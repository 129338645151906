import React from 'react';
import Envers from "../_components/Envers";
import {SettlementCancelReason} from "../_enum/enum";
import {Alert, Row} from "react-bootstrap";
import ShowField from "../form/ShowField";
import {useTranslation} from "react-i18next";

function FormElementsSettlement({settlement, onRefreshSettlement}) {
	const {t} = useTranslation();

	return (
		<>
            <Envers domain={settlement}/>
            { settlement && settlement.cancelledWhy && settlement.cancelledWhy !== SettlementCancelReason.NOT_CANCELLED &&
                <Alert variant={"danger"}>
                    <h3>{t('default.cancelled')}</h3>
                    <Row>
                        <ShowField label={'settlement.cancelledWhy'} object={settlement} property={'cancelledWhy'} type={'appvers.enums.SettlementCancelReason'} size={4}/>
                        <ShowField label={'settlement.cancelledBy'} object={settlement} property={'cancelledBy'} type={'text'} size={4}/>
                        <ShowField label={'settlement.cancelledOn'} object={settlement} property={'cancelledOn'} type={'text'} size={4}/>
                    </Row>
                    <Row>
                        <ShowField label={'settlement.cancelledNote'} object={settlement} property={'cancelledNote'} type={'text'} size={4}/>
                    </Row>
                </Alert>
            }
            <Row>
                <ShowField
                    label={'settlement.partner'}
                    object={settlement}
                    property={'partner.label'}
                    type={'text'}
                    link={{domain: 'partner', action: 'show', newTab:true}}
                />
            </Row>
			{ settlement.settlementBatch &&
				<Row>
					<ShowField
						label={'settlementBatch.label'}
						object={settlement}
						property={'settlementBatch.label'}
						type={'text'}
						link={{domain: 'settlementBatch', action: 'show', newTab:true}}
					/>
				</Row>
			}
            <Row>
                <ShowField label={ 'settlement.total' } object={ settlement } property={ 'total' } type={ 'number' } size={ 6 } />
            </Row>
        </>
	)
}

export default FormElementsSettlement