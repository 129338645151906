import React, {useCallback, useEffect, useMemo, useReducer, useState} from 'react';
import {Alert, Card, Col, Form, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import FormControl from "../_components/FormControl";
import SwitchButtonControl from "../_components/SwitchButtonControl";
import Attachments from "../_components/Attachments";
import FileUploader from "../_components/FileUploader";
import {getEnumType, UserAttachmentType} from "../_enum/enum";
import appService from "../_services";
import DefaultSpinner from "../_components/DefaultSpinner";
import {RestAsyncSelectControl} from "../_components/RestAsyncSelectControl";
import {securityService} from "../_services/security.service";
import {AllowedForEnum} from "../form/form.service";
import RoleNodeTree from "../_components/RoleNodeTree/RoleNodeTree";
import {RoleNodeTreeDispatchContext} from "../_components/RoleNodeTree/RoleNodeTreeContext";
import {roleNodeTreeReducer} from "../_components/RoleNodeTree/roleNodeTreeReducer";
import EditableTableControl from "../form/EditableTableControl";
import restService from "../_services/rest.service";
import FormCheckControl from "../_components/FormCheckControl";
import {messageBoxService} from "../_services/messageBox.service";
import DynamicText from "../_components/DynamicText";
import {MessageBoxButtons} from "../_components/MessageBox";

function FormElements({domainInstance, isAddMode}) {
	const { t } = useTranslation();
	const [attachmentsData, setAttachmentsData] = useState([])
	const [fileChanges, setFileChanges] = useState({});
	const initialRoleNodeTree = appService.useRoleNodeTree(domainInstance.authorities)
	const isDomainEditable = securityService.useAllowedFor( AllowedForEnum.EDIT )
	const [twoFactorEnabled, setTwoFactorEnabled] = useState()
	const [sendInvitation, setSendInvitation] = useState(false)
	const [twoFactorRequired, setTwoFactorRequired] = useState()
	const [setPassword, setSetPassword] = useState(false)
	const [roleNodeTree, dispatch] = useReducer(
		roleNodeTreeReducer,
		initialRoleNodeTree
	);
	const [aclUserGroups] = restService.useDomainInstancesList('aclUserGroup', 1, 10000, undefined, undefined, undefined, undefined, "select")

	const isNew = domainInstance.id === null

	const aclUserGroupUniqueValidator = (( value, data, rowIndex ) => {
		if ( data[rowIndex].deleted ) return
		//check uniqueness
		if ( data.find( (item, index) => {
			if ( index !== rowIndex && item.aclUserGroup.id === value.id && !item.deleted) {
				return true
			}
			return false
		})) {
			return t('userAclUserGroup.unique.message')
		}
	})

	useEffect( () => {
		dispatch({type: 'init', roleNodeTree: initialRoleNodeTree})
	}, [initialRoleNodeTree])

	useEffect( () => {
		const getAttachmentsData = () => {
			if ( domainInstance && domainInstance.attachments ) {
				return domainInstance.attachments.map((attachmentsData) => {
					return attachmentsData
				})
			}
			else {
				return []
			}
		}
		if ( domainInstance ) {
			setTwoFactorEnabled(domainInstance.twoFactorEnabled)
			setTwoFactorRequired(domainInstance.twoFactorRequired)
		}
		setAttachmentsData( getAttachmentsData() );
	},[domainInstance]);

	const aclUserGroupsColumns = [
		{
			id: "aclUserGroup",
			label: '' ,
			input: {
				tag: "typeahead",
				id: "aclUserGroup",
				options: aclUserGroups,
				required: true
			},
			validator: aclUserGroupUniqueValidator,
			formatter: (row) => row.aclUserGroup ? row.aclUserGroup.label : ''
		},
	]


	const filesCount = useMemo( ()=> {
		return attachmentsData.length - Object.values(fileChanges).filter( (fc) => {
			return fc.action === 'delete'
		}).length
	}, [attachmentsData, fileChanges])

	const onFileChange = useCallback( (_fileChanges) => {
		setFileChanges(_fileChanges)
	}, [setFileChanges])

	 const renderRoleTree = () => {
		return (
			<Card className={"mb-3"}>
				 <Card.Header>
					 {t('user.roleNodeTree')}
				 </Card.Header>
				 <Card.Body>
					 { ( roleNodeTree.roots && roleNodeTree.roots.length > 0) ?
						 <>
							 <FormControl type={"text"} name={'roleNodeTree'} hidden={true} value={ roleNodeTree }/>
							 <RoleNodeTreeDispatchContext.Provider value={dispatch}>
								 <RoleNodeTree nodes={roleNodeTree.roots} showRoleGroupSelection={true} />
							 </RoleNodeTreeDispatchContext.Provider>
						 </>
						 :
						 <DefaultSpinner loading={true}/>
					 }
				 </Card.Body>
			 </Card>
		)
	 }

	 const renderAclUserGroups = () => {
		return (
			<Card className={"mb-3"}>
				<Card.Header>{t('user.userAclUserGroups')}</Card.Header>
				<Card.Body>
					<Row>
						<EditableTableControl
							name={"userAclUserGroups"}
							columns={aclUserGroupsColumns}
							data={domainInstance.userAclUserGroups}
						/>
					</Row>
				</Card.Body>
			</Card>
		)
	 }

	const onInvitationChange = useCallback((e) => {
		if ( e.target.checked ) {
			setSendInvitation(true)
			domainInstance.enabled = false
		}
		else {
			setSendInvitation(false)
			domainInstance.enabled = true
		}
	}, [domainInstance])


	const renderTwoFactorControl = () => {
		if ( domainInstance.twoFactorEnabled ) {
			return (
				<Col key={"switch"}>
					<Form.Group as={Col} controlId="formGroupTwoFactorEnabled">
						<FormCheckControl
							type="switch"
							name="twoFactorEnabled"
							label={t('user.twoFactorEnabled.label')}
							value={twoFactorEnabled}
							onChange={(e) => {
								if ( domainInstance?.twoFactorEnabled ) {
									if ( !e.target.checked ) {
										messageBoxService.display(
											<DynamicText htmlContent={t('user.twoFactorAuthentication.disable.content')}/>,
											<strong>{t('twoFactorAuthentication.disable.title')}</strong>,
											[MessageBoxButtons.YES, MessageBoxButtons.NO], {headerClassName: 'bg-danger text-white h5'})
											.then((button) => {
												if (button === MessageBoxButtons.YES) {
													setTwoFactorEnabled(false)
												} else {
													setTwoFactorEnabled(true)
												}
											})
									}
									else {
										setTwoFactorEnabled(true)
									}
								}
							}}
						/>
					</Form.Group>
				</Col>
			)
		}
		else {
			return (
				<Col key={"message"}>
					<Alert variant={"warning"}>{t('user.twoFactorDisabled.info')}</Alert>
				</Col>
			)
		}
	 }

	const renderTwoFactorAuthenticationOptions = () => {
		return (
			<Card className={"mb-3"}>
				<Card.Header>{t('user.twoFactorAuthenticationGroup')}</Card.Header>
				<Card.Body>
					<Row>
						{renderTwoFactorControl()}
					</Row>
					<Row>
						<Col md={3}>
							<Form.Group as={Col} controlId="formGroupTwoFactorRequired">
								<FormCheckControl
									type="switch"
									name="twoFactorRequired"
									label={t('user.twoFactorRequired.label')}
									value={twoFactorRequired}
									onChange={(e) => {
										setTwoFactorRequired(e.target.checked)
									}}
								/>
							</Form.Group>
						</Col>
					</Row>
					{twoFactorRequired &&
					<Row className={"mt-3"}>
						<Col>
							 <Alert variant={"success"}>{t('user.twoFactorRequired.info')}</Alert>
						</Col>
					</Row>
					}
				</Card.Body>
			</Card>
		)
	}

	const renderPasswordInputs = () => {
		let controls = []

		const showPasswordInput = ( isNew && !sendInvitation ) || ( !isNew && setPassword )

		const passwordInput =
				<FormControl
					autoComplete={"new-password"}
					name={"password"}
					type={"password"}
					value= {domainInstance.password}
					rules={{
						required: true
					}}
					className={"mt-2"}
					shouldUnregister={true}
					placeholder={t('user.new.password.placeholder')}
				/>

		if ( isNew ) {
			controls.push(
				<FormCheckControl
					type="switch"
					name="sendInvitation"
					label={t('user.sendInvitation.label')}
					value={sendInvitation}
					onChange={onInvitationChange}
				/>
			)
		}
		else {
			controls.push(
				<FormCheckControl
					type="switch"
					name="setPassword"
					label={t('user.setPassword.label')}
					value={setPassword}
					onChange={onSetPasswordChange}
				/>
			)
		}

		return (
			<>
				<Col>
					{controls}
				</Col>
				{showPasswordInput && <Col className={'col-12'}>
					{passwordInput}
				</Col>}
				{!showPasswordInput && sendInvitation && <Col className={'col-12 mt-2'}>
					<Alert variant={"info"}>{t('user.sendInvitation.info')}</Alert>
				</Col>}
			</>
		)
	}

	const onSetPasswordChange = (e) => {
		setSetPassword(e.target.checked)
	}

	return (
		<>
			<Card className="mb-3">
				<Card.Header>{t('user.card.common')}</Card.Header>
				<Card.Body>
					<Row>
						<Form.Group controlId="formGroupUserUsername">
							<Form.Label>{t('user.username.label')}</Form.Label>
							<FormControl
								name={"username"}
								type={"text"}
								value= {domainInstance.username}
								rules={{
									required: true
								}}
								autoComplete={"off"}
							/>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group controlId="formGroupUserEmail">
							<Form.Label>{t('user.email.label')}</Form.Label>
							<FormControl
								name={"email"}
								type={"text"}
								value= {domainInstance.email}
								rules={{
									required: sendInvitation
								}}
								autoComplete={"off"}
							/>
						</Form.Group>
					</Row>
					<Row className={"mt-3"}>
						<Form.Group controlId="formGroupUserName">
							<Form.Label>{t('user.nameOfUser.label')}</Form.Label>
							<FormControl
								name={"nameOfUser"}
								type={"text"}
								value= {domainInstance.nameOfUser}
								autoComplete={"off"}
								rules={{
									required: true
								}}
							/>
						</Form.Group>
					</Row>
					<Row className={"mt-3"}>
						<Form.Group controlId="formGroupPartner">
							<Form.Label>{t('user.partner.label')}</Form.Label>
							<RestAsyncSelectControl
								domainName={"partner"}
								sort={"fullName_sort"}
								value={domainInstance.partner ? domainInstance.partner.id : null}
								label={domainInstance.partner ? domainInstance.partner.label : null}
								name={'partner'}
							/>
						</Form.Group>
					</Row>
					<Row className={"mt-3"}>
						<Col md={3}>
							<SwitchButtonControl
								value={domainInstance.enabled}
								offlabel={t("user.enabled.offlabel")}
								onlabel={t("user.enabled.onlabel")}
								onstyle={'success'}
								offstyle={'danger'}
								name={"enabled"}
								disabled={sendInvitation}
							/>
						</Col>
						<Col md={3}>
							<SwitchButtonControl
								value={domainInstance.accountExpired}
								offlabel={t("user.accountExpired.offlabel")}
								onlabel={t("user.accountExpired.onlabel")}
								onstyle={'danger'}
								offstyle={'success'}
								name={"accountExpired"}
							/>
						</Col>
						<Col md={3}>
							<SwitchButtonControl
								value={domainInstance.accountLocked}
								offlabel={t("user.accountLocked.offlabel")}
								onlabel={t("user.accountLocked.onlabel")}
								onstyle={'danger'}
								offstyle={'success'}
								name={"accountLocked"}
							/>
						</Col>
						<Col md={3}>
							<SwitchButtonControl
								value={domainInstance.passwordExpired}
								offlabel={t("user.passwordExpired.offlabel")}
								onlabel={t("user.passwordExpired.onlabel")}
								onstyle={'warning'}
								name={"passwordExpired"}
							/>
						</Col>
					</Row>
				</Card.Body>
			</Card>
			<Card className="mb-3">
				<Card.Header>{t('user.passwordGroup')}</Card.Header>
				<Card.Body>
					<Row>
						{renderPasswordInputs()}
					</Row>
				</Card.Body>
			</Card>
			{renderTwoFactorAuthenticationOptions()}
			{renderAclUserGroups()}
			{renderRoleTree()}
			<Card className={"mb-3"}>
				<Card.Header>
					{t('user.signature')}
				</Card.Header>
				<Card.Body>
					<Row className={"ps-3 pe-3"}>
						<Attachments attachments={attachmentsData} changesPropertyName={'fileChanges'} showImages={true} onFileChange={onFileChange} readOnly={isDomainEditable.ready && !isDomainEditable.value}/>
					</Row>
					{ filesCount === 0 && isDomainEditable.ready && isDomainEditable.value &&
						<Row>
							<Form.Group as={ Col } md="12" controlId="groupFileChanges">
								<Form.Label>{ t( 'uploadFiles.label' ) }</Form.Label>
								<FileUploader changesPropertyName={ 'fileChanges' } maxFiles={1} additionalFileProperties={{userAttachmentType: getEnumType( "appvers.enums.UserAttachmentType", UserAttachmentType.SIGNATURE )}}/>
							</Form.Group>
						</Row>
					}
				</Card.Body>
			</Card>
		</>
	);
}

export { FormElements };
