import React, {useState} from 'react';
import {Alert, Button, Card, Col, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import logoAppvers from "./images/logo_appvers_with_name.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";
import {securityService} from "./_services/security.service";
import {Alert as AppversAlert} from "./_components";
import i18n from "i18next";

function PasswordRecoveryRequest() {
    const { t } = useTranslation()
    const [requestSent, setRequestSent] = useState(false)

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if ( form.checkValidity() === true ) {
            const formData = new FormData(event.target)
            const data = Object.fromEntries(formData.entries())
            securityService.passwordRecoveryRequest( data.username, i18n.language).then( () => {
                setRequestSent(true)
            })
        }
    }

    if ( requestSent ) {
        return <Alert variant="info">
            <center><Trans i18nKey={'login.passwordResetRequest.success'}/></center>
        </Alert>
    }
    else {
        return (
            <Col sm="7" md="5" lg="4" xl="3">
                <Card className="">
                    <Card.Body>
                        <div className="text-center">
                            <img src={logoAppvers} alt={"logo"} className="my-3"/>
                        </div>
                        <AppversAlert/>
                        <Alert variant="info">
                            {t('login.passwordResetRequest.info')}
                        </Alert>

                        <Form className="login-form" onSubmit={handleSubmit}>
                            <InputGroup className={"mb-3"}>
                                <InputGroup.Text><FontAwesomeIcon icon={faUser}/></InputGroup.Text>
                                <FormControl type='text' name='username' required placeholder={t('login.username.label')}
                                             autoComplete="on"/>
                            </InputGroup>
                            <Row className={"mb-3"}>
                                <Col>
                                    <p className={"text-end"}>
                                        <Link to={"/"} className={"float-end"}>{t('login.backToLogin.label')}</Link>
                                    </p>
                                </Col>
                            </Row>


                            <Button className="px-5 mb-4 w-100" variant="success"
                                    type="submit">{t('login.passwordReset.label')}</Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        )
    }
}

export default PasswordRecoveryRequest
