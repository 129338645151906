import React, {useRef} from 'react';
import PropTypes from "prop-types";
import DomainTable from "../_components/DomainTable";
import bootstrapTableService from "../_components/bootstrapTable.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import restService from "../_services/rest.service";

function Damages( {namedCriteria, searchOptions, hiddenFields}) {
    const {t} = useTranslation();
    const domainTableRef = useRef()

    const columns = [
        {
            dataField: 'id',
            text: t('damage.id'),
        },
        {
            dataField: 'policyNumber',
            text: t('damage.policyNumber'),
        },
        {
            dataField: 'policyHolder',
            text: t('damage.policyHolder'),
            formatter: (cellContent, row) => <Link to={ restService.getTo( cellContent, 'show' ) } target = "_blank" rel = "noopener noreferrer" >
                { cellContent.label }
            </Link>,
        },
        {
            dataField: 'dateOfDamage',
            text: t('damage.dateOfDamage'),
        },
        {
            dataField: 'damageEvent.label',
            text: t('damage.damageEvent'),
        },
        {
            dataField: 'damageType.label',
            text: t('damage.damageType'),
        },
        {
            dataField: 'resolved',
            text: t('damage.resolved'),
            formatter: bootstrapTableService.formatterBoolean,
        },
        // {
        //     dataField: 'accounted',
        //     text: t('damage.accounted'),
        //     formatter: bootstrapTableService.formatterBoolean,
        // },
        {
            dataField: '',
            text: '',
            sort: false,
            classes: (cell, row, rowIndex, colIndex) => 'text-end',
            formatter: (cellContent, row) => {
                return (
                    <>
                        <Link className={`btn btn-sm btn-primary ms-1`} to={`/damage/show/${row.id}`} target={"_blank"} rel={"noopener noreferrer"}>
                            <FontAwesomeIcon icon={faEye}/>
                        </Link>
                    </>
                )
            },
            headerStyle: (column, colIndex) => {
                return { width: '5%' };
            },
        }
    ]

    return (
        <>
            <DomainTable
                ref={domainTableRef}
                domainName={'damage'}
                columns={columns}
                searchOptions={searchOptions}
                // rowClasses={rowClasses}
                sortField={'dateOfDamage'}
                sortOrder={'desc'}
            />
        </>
    )
}


Damages.defaultProps = {
    hiddenFields: []
};

Damages.propTypes = {
    namedCriteria: PropTypes.object,
    hiddenFields: PropTypes.array
};

export { Damages };
