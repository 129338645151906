import React, {useEffect, useState} from 'react';
import {Card, Col, Form, InputGroup, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import FormControl from "../_components/FormControl";
import {RestAsyncSelectControl} from "../_components/RestAsyncSelectControl";
import {FormElements as ZipFormElements} from "../zip/FormElements";
import {GoogleMapLink} from "../_components/GoogleMapLink";
import Acl from "../_components/Acl";
import {securityService} from "../_services/security.service";
import {AllowedForEnum} from "../form/form.service";
import Allowed from "../_components/Allowed";
import DisplayIfAnyChildPropertyPresent from "../form/DisplayIfAnyChildPropertyPresent";
import MergeHint from "../_components/MergeHint";
import MergeHints from "../_components/MergeHints";
import AclMerge from "../_components/AclMerge";

function FormElements({domainInstance, defaultValues, mergeDomainInstance}) {
	const { t } = useTranslation();
	const [street, setStreet] = useState(domainInstance.street || (defaultValues && defaultValues.street));
	const [zipLabel, setZipLabel] = useState(domainInstance && domainInstance.zip && domainInstance.zip.label);
	const isDomainEditable = securityService.useAllowedFor( AllowedForEnum.EDIT )
	const isMerging = mergeDomainInstance && Object.keys(mergeDomainInstance).length > 0

	useEffect( () => {
		setStreet(domainInstance.street || (defaultValues && defaultValues.street));
		setZipLabel(domainInstance && domainInstance.zip && domainInstance.zip.label);
	}, [domainInstance, defaultValues])

	return (
		<>
			<MergeHints domain1={domainInstance} domain2={mergeDomainInstance}>
				<Card className={"mb-2"}>
					<Card.Body>
						<Allowed allowedFor={AllowedForEnum.SHOW} propertyName={'description'} >
							<Card className={"mb-2"}>
								<Card.Header>{t('building.description.label')}</Card.Header>
								<Card.Body>
									<Row>
										<Form.Label>{t('building.description.label')}<MergeHint name={"description"}/></Form.Label>
										<Form.Group as={Col} md="12" controlId="formGroupDescription">
											<FormControl
												name={"description"}
												type={"text"}
												value= {domainInstance.description}
												rules={{
													required: true
												}}
												placeholder={t('building.description.placeholder')}
											/>
										</Form.Group>
									</Row>
								</Card.Body>
							</Card>
						</Allowed>
						<DisplayIfAnyChildPropertyPresent>
							<Card className={"mb-2"}>
								<Card.Header>{t('building.card.address.header')}</Card.Header>
								<Card.Body>
									<Row>
										<Allowed allowedFor={AllowedForEnum.SHOW} propertyName={'street'} >
											<Form.Group as={Col} md="6" controlId="formGroupStreet">
												<Form.Label>{t('building.street.label')} <GoogleMapLink street={street} zipLabel={zipLabel}/> <MergeHint name={"street"}/></Form.Label>
												<FormControl
													name={"street"}
													type={"text"}
													value= {street}
													rules={{
														required: true
													}}
													onChange={ (event) => setStreet(event.target.value) }
												/>
											</Form.Group>
										</Allowed>
										<Allowed allowedFor={AllowedForEnum.SHOW} propertyName={'zip'} >
											<Form.Group as={Col} md="6" controlId="groupZip">
												<Form.Label>{t('building.zip.label')} <MergeHint name={"zip"} labelProperty={'label'}/></Form.Label>
												<RestAsyncSelectControl
													domainName={"zip"}
													sort={"place_sort"}
													value={domainInstance && domainInstance.zip && domainInstance.zip.id}
													label={domainInstance && domainInstance.zip && domainInstance.zip.label}
													name={'zip'}
													createable={{formElements: <ZipFormElements/>}}
													rules={{
														required: true
													}}
													onChange={ (event) => setZipLabel(event.target.value ? event.target.value.label : '') }
												/>
											</Form.Group>
										</Allowed>
									</Row>
								</Card.Body>
							</Card>
						</DisplayIfAnyChildPropertyPresent>
						<DisplayIfAnyChildPropertyPresent>
							<Card className={"mb-2"}>
								<Card.Header>{t('building.card.properties.header')}</Card.Header>
								<Card.Body>
									<DisplayIfAnyChildPropertyPresent>
										<Row className={"mb-3"}>
											<Allowed allowedFor={AllowedForEnum.SHOW} propertyName={'buildingType'} >
												<Form.Group as={Col} md="4" controlId="groupBuildingType">
													<Form.Label>{t('building.buildingType.label')}<MergeHint name={"buildingType"} labelProperty={'label'}/></Form.Label>
													<RestAsyncSelectControl
														domainName={"buildingType"}
														sort={"name_sort"}
														value={domainInstance && domainInstance.buildingType && domainInstance.buildingType.id}
														label={domainInstance && domainInstance.buildingType && domainInstance.buildingType.label}
														name={'buildingType'}
														rules={{
															required: true
														}}
													/>
												</Form.Group>
											</Allowed>
											<Allowed allowedFor={AllowedForEnum.SHOW} propertyName={'buildingClass'} >
												<Form.Group as={Col} md="4" controlId="groupBuildingClass">
													<Form.Label>{t('building.buildingClass.label')}<MergeHint name={"buildingClass"} labelProperty={'label'}/></Form.Label>
													<RestAsyncSelectControl
														domainName={"buildingClass"}
														sort={"name_sort"}
														value={domainInstance && domainInstance.buildingClass && domainInstance.buildingClass.id}
														label={domainInstance && domainInstance.buildingClass && domainInstance.buildingClass.label}
														name={'buildingClass'}
														rules={{
															required: true
														}}
													/>
												</Form.Group>
											</Allowed>
											<Allowed allowedFor={AllowedForEnum.SHOW} propertyName={'constructionType'} >
												<Form.Group as={Col} md="4" controlId="groupConstructionType">
													<Form.Label>{t('building.constructionType.label')}<MergeHint name={"constructionType"} labelProperty={'label'}/></Form.Label>
													<RestAsyncSelectControl
														domainName={"constructionType"}
														sort={"name_sort"}
														value={domainInstance && domainInstance.constructionType && domainInstance.constructionType.id}
														label={domainInstance && domainInstance.constructionType && domainInstance.constructionType.label}
														name={'constructionType'}
														rules={{
															required: true
														}}
													/>
												</Form.Group>
											</Allowed>
										</Row>
									</DisplayIfAnyChildPropertyPresent>
									<DisplayIfAnyChildPropertyPresent>
										<Row className={"mb-3"}>
											<Allowed allowedFor={AllowedForEnum.SHOW} propertyName={'constructionType'} >
												<Form.Group as={Col} md="4" controlId="groupFireExtinguisher">
													<Form.Label>{t('building.fireExtinguisher.label')}<MergeHint name={"fireExtinguisher"} labelProperty={'label'}/></Form.Label>
													<RestAsyncSelectControl
														domainName={"fireExtinguisher"}
														sort={"name_sort"}
														value={domainInstance && domainInstance.fireExtinguisher && domainInstance.fireExtinguisher.id}
														label={domainInstance && domainInstance.fireExtinguisher && domainInstance.fireExtinguisher.label}
														name={'fireExtinguisher'}
														rules={{
															required: true
														}}
													/>
												</Form.Group>
											</Allowed>
											<Allowed allowedFor={AllowedForEnum.SHOW} propertyName={'heatingType'} >
												<Form.Group as={Col} md="4" controlId="groupHeatingType">
													<Form.Label>{t('building.heatingType.label')}<MergeHint name={"heatingType"} labelProperty={'label'}/></Form.Label>
													<RestAsyncSelectControl
														domainName={"heatingType"}
														sort={"name_sort"}
														value={domainInstance && domainInstance.heatingType && domainInstance.heatingType.id}
														label={domainInstance && domainInstance.heatingType && domainInstance.heatingType.label}
														name={'heatingType'}
														rules={{
															required: true
														}}
													/>
												</Form.Group>
											</Allowed>
											<Allowed allowedFor={AllowedForEnum.SHOW} propertyName={'roofType'} >
												<Form.Group as={Col} md="4" controlId="groupRoofType">
													<Form.Label>{t('building.roofType.label')}<MergeHint name={"roofType"} labelProperty={'label'}/></Form.Label>
													<RestAsyncSelectControl
														domainName={"roofType"}
														sort={"name_sort"}
														value={domainInstance && domainInstance.roofType && domainInstance.roofType.id}
														label={domainInstance && domainInstance.roofType && domainInstance.roofType.label}
														name={'roofType'}
														rules={{
															required: true
														}}
													/>
												</Form.Group>
											</Allowed>
										</Row>
									</DisplayIfAnyChildPropertyPresent>
									<DisplayIfAnyChildPropertyPresent>
										<Row>
											<Allowed allowedFor={AllowedForEnum.SHOW} propertyName={'branch'} >
												<Form.Group as={Col} md="4" controlId="groupBranch">
													<Form.Label>{t('building.branch.label')}<MergeHint name={"branch"} labelProperty={'label'}/></Form.Label>
													<RestAsyncSelectControl
														domainName={"branch"}
														sort={"name_sort"}
														value={domainInstance && domainInstance.branch && domainInstance.branch.id}
														label={domainInstance && domainInstance.branch && domainInstance.branch.label}
														name={'branch'}
														noSelection={ true }
													/>
												</Form.Group>
											</Allowed>
											<Allowed allowedFor={AllowedForEnum.SHOW} propertyName={'cubicCapacity'} >
												<Form.Group as={Col} md="4" controlId="formCubicCapacity">
													<Form.Label>{t('building.cubicCapacity.label')}<MergeHint name={"cubicCapacity"}/></Form.Label>
													<InputGroup>
														<FormControl
															name={"cubicCapacity"}
															type={"number"}
															value= {domainInstance.cubicCapacity}
														/>
														<InputGroup.Text>&#13221;</InputGroup.Text>
													</InputGroup>
												</Form.Group>
											</Allowed>
										</Row>
									</DisplayIfAnyChildPropertyPresent>
								</Card.Body>
							</Card>
						</DisplayIfAnyChildPropertyPresent>
					</Card.Body>
				</Card>
				{ isMerging ?
					<AclMerge domainInstance1={ domainInstance } domainInstance2={ mergeDomainInstance } editable={ isDomainEditable.ready && isDomainEditable.value }/>
					:
					<Acl domainInstance={ domainInstance } editable={ isDomainEditable.ready && isDomainEditable.value }/>
				}
			</MergeHints>
		</>
	);
}


// FireExtinguisher fireExtinguisher
// Long cubicCapacity
// Boolean flatRoof

export { FormElements };
