import {useCallback, useEffect, useMemo, useState} from "react";
import restService from "./rest.service";
import moment from "moment";
import settingService from "./setting.service";
import appService from "./index";
import {PolicyVersionStatus} from "../_enum/enum";
import {fileService} from "./file.service";
import {messageBoxService} from "./messageBox.service";
import i18n from "i18next";
import {MessageBoxButtons} from "../_components/MessageBox";
import {configuratorService} from "./configurator.service";
import {apiClientPrivate} from "../api/apiClient";

export const policyService = {
	usePolicyOffer,
	print,
	printSettlement,
	cancelSettlement,
	round20: round20,
	getPolicyStateClass,
	activateDeactivate,
	setPolicyVersionToBeActivated,
	revertPolicyVersionActivation,
	revertPolicyVersionDeactivation,
	useLastSettlement,
	useValidToCalculated,
	assistanceFormHelvetia,
	assistanceSosCardHelvetia,
	getTotalFeePerYear,
	useOtherLeadershipChecks,
	saveOrUpdateOtherLeadershipCheck,
	printExpiringPolicyVersions,
	checkMandatoryText,
	policiesExport,
	billingForPartners,
	inventoryExport,
	invoicedAppversPart,
	deletePolicyVersion,
	exportCommissions,
}

function useValidToCalculated( validFrom, contractValidityPeriod, dueMonth ) {
	const [validToCalculated, setValidToCalculated] = useState(undefined);

	useEffect( () => {
		const controller = new AbortController();
		const params = {
			validFrom: validFrom,
			contractValidityPeriod: contractValidityPeriod,
			dueMonth: dueMonth,
		}
		apiClientPrivate.get( `/api/policy/getValidToCalculated?${new URLSearchParams( params )}`, {
			signal: controller.signal,
		} )
			.then( response => restService.handleServerResponseAxios( response ) )
			.then( json => {
				setValidToCalculated(json.validToCalculated);
			} )
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
			} );

		return function cleanup() {
			controller.abort()
		}
	}, [validFrom, contractValidityPeriod, dueMonth])

	return validToCalculated
}

function useLastSettlement( policyVersionId ) {
	const [ lastSettlement, setLastSettlement ] = useState({})

	useEffect( () => {
		const controller = new AbortController();
		const params = {
			policyVersionId: policyVersionId
		}
		apiClientPrivate.get( `/api/policy/getLastSettlement?${new URLSearchParams( params )}`, {
			signal: controller.signal,
		} )
			.then( response => restService.handleServerResponseAxios( response ) )
			.then( json => {
				setLastSettlement(json);
			} )
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
			} );

		return function cleanup() {
			controller.abort()
		}
	}, [policyVersionId]);

	return lastSettlement;
}

function deletePolicyVersion( id ) {
	return new Promise( (resolve, reject) => {
		const controller = new AbortController();
		apiClientPrivate.get( `/api/policy/deletePolicyVersion/${id}`, {
			signal: controller.signal,
		} )
			.then( response => restService.handleServerResponseAxios( response ) )
			.then( () => {
				resolve();
			} )
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
				reject();
			} );
	});
}

function setPolicyVersionToBeActivated( policyVersionId, fileChanges ) {
	return new Promise( (resolve, reject) => {
		const data = {
			policyVersionId: policyVersionId,
			fileChanges: fileChanges
		}
		const controller = new AbortController();
		apiClientPrivate.put(`/api/policy/setPolicyVersionToBeActivated`, data, {
			signal: controller.signal
		} )
			.then( response => restService.handleServerResponseAxios( response ) )
			.then( json => {
				resolve(json);
			} )
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
				reject();
			} );
	});
}


function activateDeactivate( action, params ) {
	return new Promise( (resolve, reject) => {
		const controller = new AbortController();
		apiClientPrivate.get( `/api/policy/${ action }PolicyVersion?${new URLSearchParams( params )}`, {
			signal: controller.signal,
		} )
			.then( response => restService.handleServerResponseAxios( response ) )
			.then( json => {
				resolve(json);
			} )
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
				reject();
			} );
	});
}

function revertPolicyVersionActivation( policyVersionId ) {
	return new Promise( (resolve, reject) => {
		const controller = new AbortController();
		apiClientPrivate.get( `/api/policy/revertPolicyVersionActivation?${new URLSearchParams( { policyVersionId: policyVersionId } )}`, {
			signal: controller.signal,
		} )
			.then( response => restService.handleServerResponseAxios( response ) )
			.then( json => {
				resolve(json);
			} )
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
				reject();
			} );
	});
}

function revertPolicyVersionDeactivation( policyVersionId ) {
	return new Promise( (resolve, reject) => {
		const controller = new AbortController();
		apiClientPrivate.get( `/api/policy/revertPolicyVersionDeactivation?${new URLSearchParams( { policyVersionId: policyVersionId } )}`, {
			signal: controller.signal,
		} )
			.then( response => restService.handleServerResponseAxios( response ) )
			.then( json => {
				resolve(json);
			} )
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
				reject();
			} );
	});
}

function print(id, withLogo = true, hangingFileTitle = false, year = moment().year()) {
	return new Promise( (resolve, reject) => {
		const controller = new AbortController();

		const params = {
			withLogo: withLogo,
			hangingFileTitle: hangingFileTitle,
			year: year,
		}

		apiClientPrivate.get( `/api/policy/report/${ id }?` + new URLSearchParams( params ), {
			responseType: 'blob',
			signal: controller.signal,
		} )
			.then( (response) => {
				console.log("then")
				fileService.saveAttachmentWithContentDispositionFileName(response)
					.then(() => {
						resolve()
					})
			} )
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
				reject();
			} );
	});
}

function cancelSettlement(id, settlementCancelReason, settlementCancelNote) {
	return new Promise( (resolve, reject) => {
		const controller = new AbortController()
		const params = {
			id: id,
			settlementCancelReason: settlementCancelReason,
			settlementCancelNote: settlementCancelNote
		}
		apiClientPrivate.post( `/api/policy/cancelSettlement`, params, {
			signal: controller.signal,
		} )
			.then( response => restService.handleServerResponseAxios( response ) )
			.then( result => {
				resolve(result);
			} )
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
				reject();
			} );
	});
}

function printSettlement(id, withLogo = true) {
	return new Promise( (resolve, reject) => {
		const controller = new AbortController()
		apiClientPrivate.get( `/api/policy/printSettlement/${ id }?withLogo=${withLogo}`, {
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( (response) => {
				fileService.saveAttachmentWithContentDispositionFileName(response)
					.then(resolve)
			} )
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
				reject();
			} );
	});
}

function useDefaultBroker() {
	const [ defaultBroker, setDefaultBroker ] = useState(undefined);

	useEffect( () => {
		const controller = new AbortController();
		apiClientPrivate.get( `/api/policy/getCurrentBroker`, {
			signal: controller.signal,
		} )
			.then( response => restService.handleServerResponseAxios( response ) )
			.then( json => {
				setDefaultBroker(json);
			} )
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
			} );

		return function cleanup() {
			controller.abort()
		}
	}, []);

	return defaultBroker;
}

function useDefaultPartnerConsultantScope() {
	const [ defaultPartnerConsultantScope, setDefaultPartnerConsultantScope ] = useState(undefined);

	useEffect( () => {
		const controller = new AbortController();
		apiClientPrivate.get( `/api/policy/getCurrentPartnerConsultantScope`, {
			signal: controller.signal,
		} )
			.then( response => restService.handleServerResponseAxios( response ) )
			.then( json => {
				setDefaultPartnerConsultantScope(json);
			} )
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
			} );

		return function cleanup() {
			controller.abort()
		}
	}, []);

	return defaultPartnerConsultantScope;
}

function usePolicyOffer(id, policyId, partnerId) {
	const { defaultContractValidityPeriod, defaultNewPolicyOfferValidityMonths, defaultReinsurancePartnerId } = settingService.useSetting(['defaultContractValidityPeriod', 'defaultNewPolicyOfferValidityMonths', 'defaultReinsurancePartnerId'])
	const [ defaultReinsurancePartner ] = restService.useDomainInstance('partner', defaultReinsurancePartnerId, false, 'select');
	const [ defaultPartner ] = restService.useDomainInstance('partner', partnerId, false, 'select');
	const defaultPartnerConsultantScope = useDefaultPartnerConsultantScope();
	const defaultBroker = useDefaultBroker();

	const getMaxId = function () {
		let maxId
		if ( this.targets && this.targets.length > 0 ) {
			maxId = Math.max.apply( Math, this.targets.map( function ( o ) {
				return o.key;
			} ) );
		} else {
			maxId = 0;
		}
		return maxId;
	}

	const sameBuilding = (t1, t2) => {
		const getBuildingId = (t) => t.building ? parseInt(t.building.id) : undefined;
		return getBuildingId(t1) === getBuildingId(t2);
	}

	const configuratorTargetBrokerCorrespondsWithPolicyVersionBroker = (configuratorTargetBroker, policyVersionBroker, brokerOrConsultant) => {
		const configuratorTargetBrokerIsSet = !!(configuratorTargetBroker && configuratorTargetBroker.id);
		const policyVersionBrokerIsSet = !!(policyVersionBroker && policyVersionBroker.id) && brokerOrConsultant === 'BROKER';

		return !configuratorTargetBrokerIsSet ||
			( policyVersionBrokerIsSet && configuratorTargetBroker.id === policyVersionBroker.id )
	}

	const getParentTarget = useCallback( (targets, dependentTarget) => {
		if ( dependentTarget.dependsOn && targets ) {
			return targets.find( ( parentTarget ) => {
				return (
					( parentTarget.action !== 'delete' && dependentTarget.dependsOn.id === parentTarget.targetId)
						||
					( parentTarget.action !== 'delete' && parentTarget.broker && dependentTarget.dependsOn.id === parentTarget.parentConfiguratorTarget.id )
				)
			} )

		}
		else {
			return null;
		}
	}, [])

	const getDependentTargets = useCallback( (targets, parentTarget) => {
		return targets.filter( (dependentTarget) => {
			return ( dependentTarget.action !== 'delete'
				&& dependentTarget.dependsOn
				&& dependentTarget.dependsOn.id === parentTarget.targetId )
			||
				( dependentTarget.action !== 'delete'
					&& dependentTarget.broker
					&& dependentTarget.parentConfiguratorTarget.dependsOn
					&& dependentTarget.parentConfiguratorTarget.dependsOn.id === parentTarget.targetId )
		} )
	}, [])

	const getCorrespondingTargets = useCallback( (targets, target, amount) => {
		const getSimilarity = (t) => {
			if ( t ) {
				return [...t.jsPathToRoot].reverse().find( ( node ) => node.isPartOfTariffName );
			}
			else {
				return undefined;
			}
		};
		const targetSimilarity = getSimilarity(target);
		if (targets) {
			return targets.filter( ( t ) => {
				const similarity1 = getSimilarity( t );
				return t.action !== 'delete'
					&& t.key !== target.key
					&& appService.compareObjectsByValue( similarity1, targetSimilarity )
					&& ( target.key !== t.key )
					&& ( t.amount !== amount )
					&& sameBuilding( target, t )
					&& ( t.targetClassName === 'configuratorTargetBase'
						||
						t.targetClassName === 'configuratorTargetBaseBroker'
						||
						( (t.targetClassName === 'configuratorTargetDependentProduct' || t.targetClassName === 'configuratorTargetDependentProductBroker') && t.dependsOn.id === target.targetId )
						||
						( (t.targetClassName === 'configuratorTargetDependentProduct' || t.targetClassName === 'configuratorTargetDependentProductBroker') && target.parentConfiguratorTarget && t.dependsOn.id === target.parentConfiguratorTarget.id )
					)
			} )
		}
		else {
			return [];
		}
	}, []);

	const addConfiguratorTargets = useCallback( ( newTargets, handleUpdate, values ) => {
		const detailValues = values['detail']
		const addProductConfiguratorValues = values['addProductConfigurator']
		const lastTouchesValues = values['lastTouches']

		newTargets.forEach( ( target) => {

			let lastIndividualTextOrdering = 0
			target.individualTexts = [];
			target.configuratorTargetIndividualTexts.forEach( (ctit) => {
				target.individualTexts.push( {
					"action": "create",
					"individualText": { "id": ctit.individualText.id, "label": ctit.individualText.label, "classSimpleName":"individualText" },
					"ordering": ++lastIndividualTextOrdering
				} );
			} )
		})
		const allTargets = detailValues.targets.concat( newTargets );

		newTargets.forEach( ( target) => {
			let recommendedAmount = undefined

			switch(target.targetClassName) {
				case 'configuratorTargetBase':
				case 'configuratorTargetBaseBroker':
					const correspondingTargets = getCorrespondingTargets(allTargets, target, 0).sort((a,b) => {
						//base targets should have priority before dependent targets when amount is predefined
						let aOrder = 0;
						let bOrder = 0;
						if (a.targetClassName === 'configuratorTargetBase' || a.targetClassName === 'configuratorTargetBaseBroker') {
							aOrder = -1
						}
						if (b.targetClassName === 'configuratorTargetBase' || b.targetClassName === 'configuratorTargetBaseBroker') {
							bOrder = -1
						}
						return ( aOrder < bOrder ) ? -1 : ( ( bOrder < aOrder ) ? 1 : 0 )
					})
					const correspondingTarget = correspondingTargets[0]
					if ( correspondingTarget && correspondingTarget.type === target.type ) {
						if (correspondingTarget.targetClassName === 'configuratorTargetDependentProduct' || correspondingTarget.targetClassName === 'configuratorTargetDependentProductBroker') {
							recommendedAmount = Math.round(correspondingTarget.amount / correspondingTarget.amountDependencyFactor)
						} else {
							recommendedAmount = correspondingTarget.amount;
						}
					}
					break;
				case 'configuratorTargetDependentProduct':
				case 'configuratorTargetDependentProductBroker':
					const parentTarget = getParentTarget(allTargets, target);
					if ( parentTarget ) {
						recommendedAmount = Math.round(parentTarget.amount * target.amountDependencyFactor / target.roundAmountToMultiple) * target.roundAmountToMultiple
					}
					break;
				default:
				//skip
			}

			//If there is just one available amount for this target, we predefine it.
			let onlyAmount = undefined
			if ( target.availableInsuranceAmount.intervals && target.availableInsuranceAmount.intervals.length === 1 && target.availableInsuranceAmount.intervals[0].from === target.availableInsuranceAmount.intervals[0].to ) {
				onlyAmount = target.availableInsuranceAmount.intervals[0].from
			}

			if ( onlyAmount ) {
				target.amount = onlyAmount
			}
			else if ( recommendedAmount ) {
				target.amount = recommendedAmount
			}
		})

		handleUpdate( 'detail', { ...detailValues, ...{targets: allTargets} } );
		if ( addProductConfiguratorValues ) {
			handleUpdate( 'addProductConfigurator', { ...addProductConfiguratorValues, targets: [] } ); //clear the temporary targets
		}

		let standardTexts = [...(lastTouchesValues.standardTexts || [])];
		let lastStandardTextOrdering = standardTexts.length;
		newTargets.forEach( ( target) => {
			target.configuratorTargetStandardTexts.forEach( (ctst) => {

				if ( ctst.standardText.active && !standardTexts.filter( (standardText) => standardText.action !== 'delete' ).some( (st) => parseInt(st.standardText.id) === parseInt(ctst.standardText.id) ) ) {
					standardTexts.push( {
						"action": "create",
						"standardText": { "id": `${ ctst.standardText.id }`, "label": ctst.standardText.label },
						"ordering": ++lastStandardTextOrdering
					} );
				}
			})
		});
		handleUpdate( 'lastTouches', {...lastTouchesValues, standardTexts: standardTexts} );

		if ( newTargets && newTargets.length > 0 && newTargets.some( (t) => !t.amount ) ) {
			configuratorService.expandAndScrollToConfiguratorHeader(newTargets[0].key);
		}
	}, [getCorrespondingTargets, getParentTarget])

	const defaultPolicyOffer = useMemo( () => {
		//we want to return only fully loaded values. Otherwise we return null.
		if ( defaultReinsurancePartner && defaultContractValidityPeriod && defaultNewPolicyOfferValidityMonths && defaultPartnerConsultantScope !== undefined && defaultBroker !== undefined && (!partnerId || ( defaultPartner && defaultPartner.id ) )) {
			return {
				policy: {
					policyHolder: ( defaultPartner && defaultPartner.id ) ? defaultPartner : undefined,
					insured: ( defaultPartner && defaultPartner.id ) ? defaultPartner : undefined,
					invoiceTo: ( defaultPartner && defaultPartner.id ) ? defaultPartner : undefined,
					validFrom: moment().format( 'YYYY-MM-DD' ),
					offerValidTo: moment().add( defaultNewPolicyOfferValidityMonths, 'month' ).format( 'YYYY-MM-DD' ),
					contractValidityPeriod: defaultContractValidityPeriod,
					isFirstPolicyVersion: true,
					partnerConsultantScope: defaultPartnerConsultantScope,
					broker: defaultBroker,
				},
				detail: {
					targets: [],
					getMaxId: getMaxId,
					getCorrespondingTargets: getCorrespondingTargets,
					getDependentTargets: getDependentTargets,
					getParentTarget: getParentTarget,
					addConfiguratorTargets: addConfiguratorTargets,
					configuratorTargetBrokerCorrespondsWithPolicyVersionBroker: configuratorTargetBrokerCorrespondsWithPolicyVersionBroker,
				},
				addProductBuilding: {},
				addProductConfigurator: {},
				addProductTariffs: {},
				lastTouches: {
					reinsuranceCompany: { id: defaultReinsurancePartner.id, label: defaultReinsurancePartner.label },
				},
			}
		}
		else {
			return null;
		}
	}, [defaultNewPolicyOfferValidityMonths, defaultContractValidityPeriod, defaultReinsurancePartner, getCorrespondingTargets, getDependentTargets, getParentTarget, defaultPartnerConsultantScope, addConfiguratorTargets, defaultBroker, defaultPartner, partnerId]);

	const [ policyOffer, setPolicyOffer ] = useState( null );

	useEffect( () => {
		const controller = new AbortController();
		if( id || policyId ) {
			const params = {}
			var action
			if ( id ) {
				params.id = id
				action = 'editPolicyOffer'
			}
			else if ( policyId ) {
				params.policyId = policyId
				action = 'createPolicyOffer'
			}
			apiClientPrivate.get( `/api/policy/${action}?` + new URLSearchParams( params ), {
				signal: controller.signal
			} )
				.then( ( response ) => ( restService.handleServerResponseAxios( response ) ) )
				.then( ( json ) => {
					json.detail.getMaxId = getMaxId
					json.detail.getCorrespondingTargets = getCorrespondingTargets
					json.detail.getDependentTargets = getDependentTargets
					json.detail.getParentTarget = getParentTarget
					json.detail.addConfiguratorTargets = addConfiguratorTargets
					json.detail.configuratorTargetBrokerCorrespondsWithPolicyVersionBroker = configuratorTargetBrokerCorrespondsWithPolicyVersionBroker
					setPolicyOffer( json )
					if ( !json.lastTouches.reinsuranceCompany ) {
						json.lastTouches.reinsuranceCompany = { id: defaultReinsurancePartner.id, label: defaultReinsurancePartner.label }
					}
				} )
				.catch( (error) => restService.handleServerErrorsAxios( error, controller.signal ) );
		}
		else {
			setPolicyOffer( defaultPolicyOffer );
		}

		return function cleanup() {
			controller.abort()
		}
	}, [id, policyId, defaultPolicyOffer, getCorrespondingTargets, getDependentTargets, getParentTarget, defaultReinsurancePartner, addConfiguratorTargets])

	return policyOffer;
}

function round20( number) {
	let sgn = Math.sign(number)
	if ( sgn === 0 ) {
		sgn = 1
	}
	const factor = (sgn * 20)
	return Math.round(number * factor) / factor
}

function getPolicyStateClass(state) {
	switch ( state ) {
		case PolicyVersionStatus.IN_PROCESS:
			return "text-primary"
		case PolicyVersionStatus.ACTIVE:
			return "text-success"
		case PolicyVersionStatus.TO_BE_ACTIVATED:
			return "bg-warning"
		default:
			return "text-danger"
	}
}

function assistanceFormHelvetia(policyVersionId, cancellation = false) {
	return new Promise( (resolve, reject) => {
		const controller = new AbortController()
		const params = {
			policyVersionId: policyVersionId,
			cancellation: cancellation,
		}
		apiClientPrivate.get( `/api/policyVersion/assistanceFormHelvetia?` + new URLSearchParams( params ), {
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( fileService.saveAttachmentWithContentDispositionFileName )
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
				reject();
			} );
	});
}

function assistanceSosCardHelvetia(policyVersionId) {
	return new Promise( (resolve, reject) => {
		const controller = new AbortController()
		apiClientPrivate.get( `/api/policyVersion/assistanceSosCardHelvetia/${ policyVersionId }`, {
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( fileService.saveAttachmentWithContentDispositionFileName )
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
				reject();
			} );
	});
}

function getTotalFeePerYear( years ) {
	return new Promise( (resolve, reject) => {
		const controller = new AbortController()
		const params = {
			years: JSON.stringify(years),
		}
		apiClientPrivate.get( `/api/policy/getTotalFeePerYear?${new URLSearchParams( params )}`, {
			signal: controller.signal
		} )
			.then( ( response ) => ( restService.handleServerResponseAxios( response ) ) )
			.then( ( json ) => {
				resolve(json);
			})
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
				reject();
			} );
	});
}

function printExpiringPolicyVersions(dateFrom, dateTo, partnerConsultantScopes, brokers) {
	return new Promise( (resolve, reject) => {
		const controller = new AbortController()
		const params = {
			dateFrom: dateFrom,
			dateTo: dateTo,
			partnerConsultantScopes: JSON.stringify(partnerConsultantScopes),
			brokers: JSON.stringify(brokers),
		}
		apiClientPrivate.get( `/api/policy/printExpiringPolicyVersions/?` + new URLSearchParams( params ), {
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( fileService.saveAttachmentWithContentDispositionFileName )
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
				reject();
			} );
	});
}

function exportCommissions(dateFrom, dateTo, partners) {
	const controller = new AbortController()
	const params = {
		dateFrom: dateFrom,
		dateTo: dateTo,
		partners: JSON.stringify(partners),
	}
	apiClientPrivate.get( `/api/policy/exportCommissions/?` + new URLSearchParams( params ), {
		responseType: 'blob',
		signal: controller.signal
	} )
		.then( fileService.saveAttachmentWithContentDispositionFileName )
		.catch( (error) => {
			restService.handleServerErrorsAxios( error, controller.signal );
		} );
}

function policiesExport(validFrom, validTo, dataValidOn, products) {
	const controller = new AbortController()
	const params = {
		validFrom: validFrom,
		validTo: validTo,
		dataValidOn: dataValidOn,
		products: JSON.stringify(products),
	}
	apiClientPrivate.get( `/api/policy/policiesExport/?` + new URLSearchParams( params ), {
		responseType: 'blob',
		signal: controller.signal
	} )
		.then( fileService.saveAttachmentWithContentDispositionFileName )
		.catch( (error) => {
			restService.handleServerErrorsAxios( error, controller.signal );
		} );
}

function billingForPartners(validFrom, validTo, dataValidOn, courtage, administrationFee, report) {
	const controller = new AbortController()
	const params = {
		validFrom: validFrom,
		validTo: validTo,
		dataValidOn: dataValidOn,
		courtage: courtage,
		administrationFee: administrationFee,
		report: report,
	}
	apiClientPrivate.get( `/api/policy/billingForPartners/?` + new URLSearchParams( params ), {
		responseType: 'blob',
		signal: controller.signal
	} )
		.then( fileService.saveAttachmentWithContentDispositionFileName )
		.catch( (error) => {
			restService.handleServerErrorsAxios( error, controller.signal );
		} );
}


function invoicedAppversPart(dateFrom, dateTo) {
	const controller = new AbortController()
	const params = {
		dateFrom: dateFrom,
		dateTo: dateTo,
	}
	apiClientPrivate.get( `/api/policy/invoicedAppversPart/?` + new URLSearchParams( params ), {
		responseType: 'blob',
		signal: controller.signal
	} )
		.then( fileService.saveAttachmentWithContentDispositionFileName )
		.catch( (error) => {
			restService.handleServerErrorsAxios( error, controller.signal );
		} );
}


function inventoryExport(dataValidOn) {
	const controller = new AbortController()
	const params = {
		dataValidOn: dataValidOn,
	}
	apiClientPrivate.get( `/api/policy/inventoryExport/?` + new URLSearchParams( params ), {
		responseType: 'blob',
		signal: controller.signal
	} )
		.then( fileService.saveAttachmentWithContentDispositionFileName )
		.catch( (error) => {
			restService.handleServerErrorsAxios( error, controller.signal );
		} );
}

/**
 * @param textId
 * @param currentTexts
 * @param targets
 * @param textClass
 * @returns if the text is mandatory, then product name of a product is returned, where the text is mandatory
 */
function checkMandatoryText(textId, currentTexts, targets, textClass) {
	for( const target of targets.filter( (t) => t.action !== 'delete' ) ) {
		for ( const ctst of target[`configuratorTarget${appService.capitalize(textClass)}s`] ) {
			if ( ctst.mandatory && parseInt(ctst[textClass].id) === parseInt( textId ) ) {
				const sameTexts = currentTexts.filter( (st) => !st.deleted && typeof(st[textClass]) === 'object' && parseInt(st[textClass].id) === parseInt(textId) ).length
				if ( sameTexts === 1 ) {
					messageBoxService.display(
						i18n.t( 'createPolicy.mandatoryText.cant.delete.message', { product: target.text } ),
						i18n.t( 'default.mandatoryText' ),
						[MessageBoxButtons.OK]
					);
					return false;
				}
			}
		}
	}

	return true;
}

function useOtherLeadershipChecks(year) {
	const [rows, setRows] = useState([]);

	useEffect( () => {
		const controller = new AbortController()
		const params = {
			year: year,
		}
		apiClientPrivate.get( `/api/policy/getOtherLeadershipChecks?${ new URLSearchParams( params ) }`, {
			signal: controller.signal
		} )
			.then( ( response ) => ( restService.handleServerResponseAxios( response ) ) )
			.then( ( json ) => {
				setRows( json );
			} )
			.catch( ( error ) => {
				setRows( [] );
				restService.handleServerErrorsAxios( error, controller.signal );
			} );
	}, [year])

	return [rows, setRows];
}

function saveOrUpdateOtherLeadershipCheck(year, policyVersionId, data) {
	return new Promise( (resolve, reject) => {
		const controller = new AbortController()
		const params = {
			year: year,
			policyVersionId: policyVersionId,
			data: JSON.stringify(data),
		}
		apiClientPrivate.get( `/api/policy/saveOrUpdateOtherLeadershipCheck?${new URLSearchParams( params )}`, {
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( ( response ) => ( restService.handleServerResponseAxios( response ) ) )
			.then( ( json ) => {
				resolve(json);
			})
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
				reject();
			} );
	});
}
