import {useEffect, useState} from "react";
import restService from "./rest.service";
import {fileService} from "./file.service";
import {apiClientPrivate} from "../api/apiClient";

export const partnerService = {
	useBrokerInterestRates,
	useBrokerInterestRateNames,
	useBankAccounts,
	getBankAccounts,
	printEnvelopeC5,
	printVoucherC5,
}

function useBrokerInterestRates(partnerId, validOn) {
	const [ brokerInterestRates, setBrokerInterestRates ] = useState([])

	useEffect( () => {
		const controller = new AbortController();
		if( partnerId && validOn ) {
			const params = {
				partnerId: partnerId,
				validOn: validOn
			}
			apiClientPrivate.get( `/api/partner/getBrokerInterestRates?` + new URLSearchParams( params ), {
				signal: controller.signal
			} )
				.then( ( response ) => ( restService.handleServerResponseAxios( response ) ) )
				.then( ( json ) => setBrokerInterestRates( json ) )
				.catch( (error) => restService.handleServerErrorsAxios( error, controller.signal ) );
		}

		return function cleanup() {
			controller.abort()
		}
	}, [partnerId, validOn])

	return brokerInterestRates;
}

function useBrokerInterestRateNames() {
	const [ brokerInterestRateNames, setBrokerInterestRateNames ] = useState([])

	useEffect( () => {
		const controller = new AbortController();
		apiClientPrivate.get( `/api/partner/getBrokerInterestRateNames`, {
			signal: controller.signal
		} )
			.then( ( response ) => ( restService.handleServerResponseAxios( response ) ) )
			.then( ( json ) => setBrokerInterestRateNames( json ) )
			.catch( (error) => restService.handleServerErrorsAxios( error, controller.signal ) );

		return function cleanup() {
			controller.abort()
		}
	}, [])

	return brokerInterestRateNames;
}

function useBankAccounts(partnerId) {
	const [ bankAccounts, setBankAccounts ] = useState([])

	useEffect( () => {
		const controller = new AbortController();

		getBankAccounts(partnerId, controller.signal)
			.then( (bankAccounts) => setBankAccounts(bankAccounts) )
			.catch( (error) => restService.handleServerErrorsAxios( error, controller.signal ) );

		return function cleanup() {
			controller.abort()
		}
	}, [partnerId])

	return [bankAccounts, setBankAccounts];
}

function getBankAccounts(partnerId, signal) {
	return new Promise( (resolve, reject) => {

		apiClientPrivate.get( `/api/partner/getBankAccounts/${partnerId}`, {
			signal: signal
		})
			.then( ( response ) => ( restService.handleServerResponseAxios( response ) ) )
			.then( ( json ) => {
				const accounts = []
				for ( let nr=1; nr<=2; nr++) {
					if ( json[`iban${nr}`] !== '' ) {
						console.log( json[`iban${nr}`] )
						accounts.push( {
							id: json[`iban${nr}`],
							label: json[`iban${nr}name`] ? json[`iban${nr}name`] + ' (' + json[`iban${nr}`] + ')' : json[`iban${nr}`]
						} )
					}
				}
				resolve(accounts);
			} )
			.catch( reject );
	})
}

function printEnvelopeC5( partnerId, additionalText ) {
	return new Promise( (resolve, reject) => {
		const controller = new AbortController()
		const params = {}
		if (additionalText) {
			params.additionalText = additionalText
		}
		apiClientPrivate.get( `/api/partner/printEnvelopeC5/${partnerId}?` + new URLSearchParams( params ), {
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( fileService.saveAttachmentWithContentDispositionFileName )
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
				reject();
			} );
	});
}

function printVoucherC5( partnerId ) {
	return new Promise( (resolve, reject) => {
		const controller = new AbortController()
		const params = {}
		apiClientPrivate.get( `/api/partner/printVoucherC5/${partnerId}?` + new URLSearchParams( params ), {
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( fileService.saveAttachmentWithContentDispositionFileName )
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
				reject();
			} );
	});
}
