import React, {useEffect} from 'react';
import {messagePanelService} from "../_services/message.panel.service";
import PlainUserMessage from "./_userMessage/PlainUserMessage";
import NewSettlementUserMessage from "./_userMessage/NewSettlementUserMessage";
import websocketService from "../_services/websocket.service";
import ProgressUserMessage from "./_userMessage/ProgressUserMessage";

function UserMessagesListener() {
	const [userMessage] = websocketService.useWebSocketSubscriptionData(`/user/queue/all`, {})

	useEffect( () => {
		const getUserMessageElement = () => {
			switch( userMessage.reactElementName ) {
				case 'plainUserMessage':
					return <PlainUserMessage {...userMessage}/>
				case 'newSettlementUserMessage':
					return <NewSettlementUserMessage {...userMessage}/>
				case 'progressUserMessage':
					return <ProgressUserMessage {...userMessage}/>
				default:
					return undefined
			}
		}

		if ( typeof userMessage === 'object' && Object.keys(userMessage).length > 0 ) {
			const userMessageElement = getUserMessageElement()

			if ( userMessageElement ) {
				const msg = {
					uuid: userMessage.uuid,
					header: userMessage.header,
					message: userMessageElement,
					variant: userMessage.variant
				}

				messagePanelService.update( [msg], undefined )
			}
		}
	}, [userMessage])

	return null
}

export default UserMessagesListener
