import React, {useEffect} from 'react';
import {Card, Col, Row} from "react-bootstrap";
import appService from "../_services";
import ShowField from "../form/ShowField";
import restService from "../_services/rest.service";
import {useTranslation} from "react-i18next";
import FormattedText from "../_components/FormatedText";
import {RestAsyncSelectControl} from "../_components/RestAsyncSelectControl";
import {invoiceService} from "../_services/invoice.service";
import {useHistory} from "react-router-dom";

function ShowBankPayment( {editPairedInvoice, newInvoiceId, ...props} ) {
    const [bankPayment, setBankPayment] = restService.useDomainInstance( 'bankPayment', props.id, false );
    const [newInvoice] = restService.useDomainInstance( 'invoice', newInvoiceId, false );
    const history = useHistory()
    const { t } = useTranslation();

    appService.useTitle(  t('bankPayment.label') + ' ' + ( bankPayment && bankPayment.debtor ) );

    const handleInvoiceChange = (invoice) => {
        return new Promise( (resolve, reject) => {
            restService.updateDomainInstance('bankPayment', bankPayment.id, { invoice: invoice.id })
                .then( (domain) => {
                    setBankPayment( domain )
                    if ( undefined !== editPairedInvoice ) {
                        history.push(`/bankToCustomerNotification/show/${bankPayment.bankToCustomerNotification.id}`)
                    }
                    resolve();
                })
                .catch( (error) => reject(error) )
        })
    }

    useEffect(() => {
        if (bankPayment && bankPayment.id && newInvoice && newInvoice.id && ( !bankPayment.invoice || bankPayment.invoice.id !== newInvoice.id) ) {
            setBankPayment({...bankPayment, invoice: newInvoice})
        }
    }, [newInvoice, bankPayment, setBankPayment]);

    return (
        <Card className='mb-2'>
            <Card.Header><h4>{t('bankPayment.label') + ' ' + appService.nullSafeGet( bankPayment, 'id')}</h4></Card.Header>
            <Card.Body>
                <Row>
                    <Col>
                        <ShowField label={'payment.amountDate'} object={bankPayment} property={'amountDate'} type={'date'}/>
                    </Col>
                    <Col>
                        <ShowField label={'payment.refNr'} object={bankPayment} property={'refNr'} type={'text'}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ShowField label={'payment.amount'} object={bankPayment} property={'amount'} type={'text'}/>
                    </Col>
                    <Col>
                        <ShowField label={'bankPayment.invoice.total'} object={bankPayment} property={'invoice.total'} type={'text'}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ShowField label={'bankPayment.debtor'} object={bankPayment} property={'debtor'}>
                            <FormattedText text={bankPayment.debtor}/>
                        </ShowField>
                    </Col>
                    <Col>
                        <ShowField label={'bankPayment.ultimateDebtor'} object={bankPayment} property={'ultimateDebtor'}>
                            <FormattedText text={bankPayment.ultimateDebtor}/>
                        </ShowField>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ShowField label={'bankPayment.debtorIBAN'} object={bankPayment} property={'debtorIBAN'} type={'text'}/>
                    </Col>
                    <Col>
                        <ShowField label={'bankToCustomerNotification.label'} object={bankPayment} property={'bankToCustomerNotification.id'} type={'text'}
                                   link={bankPayment.bankToCustomerNotification && {to: `/bankToCustomerNotification/show/${bankPayment.bankToCustomerNotification.id}`}}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ShowField
                            label={'payment.invoice'}
                            object={bankPayment}
                            property={'invoice.invoiceNr'}
                            value={bankPayment && bankPayment.invoice ? invoiceService.showInvoiceLabel(bankPayment.invoice) : ''}
                            type={'text'}
                            link={bankPayment.invoice && {to: `/settlement/show/${bankPayment.invoice.id}`}}
                            editable={{
                                editFormElement:
                                    <RestAsyncSelectControl
                                        noSelection={{label: t('bankPayment.no.paired.invoice'), id: ""}}
                                        placeholder={t('bankPayment.no.paired.invoice')}
                                        domainName={"invoice"}
                                        value={bankPayment && bankPayment.invoice}
                                        name={'invoice'}
                                        onGetOptionLabel={invoiceService.showInvoiceLabel}
                                        rules={{
                                            required: true
                                        }}
                                    />,
                                onSave: (formData) => handleInvoiceChange(formData.invoice),
                                isEditable: true,
                                editingControl: editPairedInvoice
                            }}
                        />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default ShowBankPayment
