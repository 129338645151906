import {useCallback, useDebugValue, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux"
import {apiClient} from "../api/apiClient";
import qs from "qs";
import restService from "../_services/rest.service";
import {useIdleTimer} from "react-idle-timer";
import {useConfig} from "../_services/useConfig";
import {cacheService} from "../_services/cache.service";
import {jwtDecode} from "jwt-decode";

const useAuth = () => {
    const { config: { inactivityTimeout } } = useConfig();
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch()
    const tabId = useSelector(state => state.tabId);
    //To do not try to recover auth if the user has logged out
    const loggedOut = useSelector(state => state.auth.loggedOut ?? false);

    const setAuth = (data) => {
        dispatch({type: 'auth/setAuth', payload: data});
    }
    const clearAuth = useCallback(() => {
        dispatch({type: 'auth/clearAuth'});
    }, [dispatch])

    const loggedIn = useRef()
    const twoFactorRequired = useRef()
    const twoFactorMandatory = useRef()
    const twoFactorEnabled = useRef()

    const setLoggedInAndTwoFactorRequired = useCallback(( authData )   => {
        if ( authData?.username ) {
            const token =  jwtDecode(authData.access_token)
            twoFactorMandatory.current = token?.twoFactorMandatory
            twoFactorEnabled.current = token?.twoFactorEnabled
            if ( authData?.roles?.includes('ROLE_PRE_AUTH') ) {
                loggedIn.current = false
                twoFactorRequired.current = true
            }
            else {
                loggedIn.current = true
                twoFactorRequired.current = false
            }
        }
        else {
            loggedIn.current = false
            twoFactorRequired.current = false
            twoFactorEnabled.current = false
            twoFactorMandatory.current = false
        }
    }, [ loggedIn, twoFactorRequired, twoFactorEnabled, twoFactorMandatory])

    setLoggedInAndTwoFactorRequired(auth)

    const onMessage = (payload) => {
        if ( payload.type === "restore" ) {
            window.location.reload()
        }
        if ( payload.type === "logout" ) {
            clearAuth()
        }
    }

    const onIdle = () => {
        if ( isLeader() ) {
            logout();
            console.log("You have been logged out after " + inactivityTimeout + " seconds of inactivity.");
        }
    }

    const {
        getTabId,
        isLeader,
        message,
    } = useIdleTimer({
        onIdle,
        onMessage,
        timeout: inactivityTimeout * 1000,
        crossTab: true,
        leaderElection: true,
        syncTimers: 200,
        disabled: !loggedIn.current
    })

    const logout = useCallback((onAfterLogout) => {
        apiClient.get('/api/user/logout', {
            withCredentials: true
        })
            .then((response) => {
                if ( response.status === 200 ) {
                    message({type: 'logout'})
                    clearAuth()
                    if ( onAfterLogout ) {
                        onAfterLogout()
                    }
                }
            })
            .catch((error) => restService.handleServerErrorsAxios(error))
    }, [clearAuth, message])

    const login = useCallback((authData, notifyOthers) => {
        setLoggedInAndTwoFactorRequired(authData)
        if ( notifyOthers && loggedIn.current) {
            message({type: "restore"})
        }
        cacheService.clearCache();
        dispatch({type: 'auth/setAuth', payload: authData});
    }, [dispatch, message, loggedIn, setLoggedInAndTwoFactorRequired])

    //try to recover auth by using the refresh token
    useEffect(() => {
        if ( !auth.access_token && tabId && loggedOut === false ) {
            const getAuth = async () => {
                try {
                    const response = await apiClient.post('/oauth/access_token',
                        qs.stringify({
                            grant_type: 'refresh_token',
                        }),
                        {
                            withCredentials: true,
                        }
                    );
                    login( response.data )
                } catch (error) {
                    //do nothing
                }
            }
            getAuth();
        }
    }, [auth, loggedOut, dispatch, tabId, message, login]);

    useEffect(() => {
        dispatch({type: 'tabId/setTabId', payload: getTabId()});
    }, [dispatch, getTabId]);

    useDebugValue(auth, loggedIn.current ? "Logged In" : "Logged Out")

    const getLoggedIn = useCallback(() => {
        return loggedIn.current
    }, [loggedIn])

    const  getTwoFactorRequired = useCallback(() => {
        return twoFactorRequired.current
    }, [twoFactorRequired])

    const getTwoFactorMandatory = useCallback(() => {
        return twoFactorMandatory.current
    }, [twoFactorMandatory])

    const getTwoFactorEnabled = useCallback(() => {
        return twoFactorEnabled.current
    }, [twoFactorEnabled])

    return {
        auth,
        setAuth,
        clearAuth,
        loggedIn: getLoggedIn(),
        twoFactorRequired: getTwoFactorRequired(),
        twoFactorMandatory:  getTwoFactorMandatory(),
        twoFactorEnabled:  getTwoFactorEnabled(),
        twoFactorSetupRequired: getTwoFactorRequired() && !getTwoFactorEnabled(),
        logout,
        message,
        login
    };
}

export default useAuth;
