import React, {useMemo} from 'react';
import {Button, Form} from 'react-bootstrap'
import appService, { alertService } from '../_services'
import {useTranslation} from "react-i18next";
import {Link, withRouter} from 'react-router-dom';
import restService from "../_services/rest.service";
import {FormProvider, useForm} from "react-hook-form";
import PropTypes from "prop-types";
import {AllowedForEnum, formService} from "./form.service";
import SubmitButton from "../_components/SubmitButton";
import FormControl from "../_components/FormControl";
import {securityService} from "../_services/security.service";
import Secured from "../_components/Secured";
import DefaultSpinner from "../_components/DefaultSpinner";

function AddEditForm( { domainName, instanceToString, history, children, beforeSubmit, afterCreate, afterUpdate, routeParams } ) {
	const { t } = useTranslation();
	const _domainName = useMemo( () => ((routeParams && routeParams.domainName) ? routeParams.domainName : domainName), [routeParams, domainName] );
	const id = useMemo( () => routeParams && routeParams.id, [routeParams]);
	const [domainInstance] = restService.useDomainInstance( _domainName, id, true );
	const useFormObject = useForm();
	const isAddMode = !id;
	const allowedForEdit = securityService.useAllowedFor( AllowedForEnum.EDIT )

	let title = useMemo( () => {
		let result = domainInstance && ( domainInstance.label ? domainInstance.label : instanceToString(domainInstance) )
		if ( !result ) {
			result = t( `${ _domainName }.label` )
		}
		return result;
	}, [domainInstance, _domainName, instanceToString, t] )

	appService.useTitle(title);

	function goBack() {
		if ( history.length === 1 ) {
			window.close();
		}
		else {
			history.goBack();
		}
	}

	function onSubmit(data) {
		const flattenData = formService.flattenSubmitData(data);
		let overrides = {data: flattenData};

		if ( beforeSubmit ) {
			overrides = { overrides, ...beforeSubmit({...overrides}) };
		}

		if ( isAddMode ) {
			return create( _domainName, overrides.data );
		} else {
			return update( _domainName, id, overrides.data );
		}
	}

	function create(domainName, data) {
		return new Promise( (resolve, reject) => {
			restService.saveDomainInstance( domainName, data )
				.then( (domain) => {
					alertService.success( t( 'default.created', { what: instanceToString( data ) } ), { keepAfterRouteChange: true } );
					if ( afterCreate ) {
						afterCreate(domain)
							.then( (result) => {
								if ( result ) {
									//redirect made in afterUpdate
									resolve();
								}
								else {
									goBack();
									resolve();
								}
							})
					}
					else {
						goBack();
						resolve();
					}
				} )
				.catch( ( error, signal ) => {
					console.log(error);
					restService.handleServerErrorsAxios( error, signal );
					resolve(); //if finishes formState.isSubmitting
				} );
		})
	}

	function update(domainName, id, data) {
		return new Promise( (resolve, reject) => {
		restService.updateDomainInstance( domainName, id, data )
			.then( (domain) => {
				alertService.success( t( 'default.updated', { what: instanceToString( data ) } ), { keepAfterRouteChange: true } );
				if ( afterUpdate ) {
					afterUpdate(domain)
						.then( (result) => {
							if ( result ) {
								//redirect made in afterUpdate
								resolve();
							}
							else {
								goBack();
								resolve();
							}
						})
				}
				else {
					goBack();
					resolve();
				}
			})
			.catch( (error, signal ) => {
				console.log(error);
				restService.handleServerErrorsAxios( error, signal )
				resolve(); //if finishes formState.isSubmitting
			} );
		})
	}

	const childrenProps = useMemo( () => {
		return {
			domainInstance: domainInstance,
			isAddMode: isAddMode
		}
	}, [domainInstance, isAddMode])

	const handleCancel = () => {
		goBack();
	}

	return (
		<div>
			<FormProvider {...useFormObject}>
				<Form onSubmit={useFormObject.handleSubmit(onSubmit)}>
					<FormControl
						hidden={true}
						name="id"
						type={"number"}
						value={domainInstance && domainInstance.id}
					/>
                    <FormControl
                        hidden={true}
                        name="version"
                        type={"number"}
						value={domainInstance && domainInstance.version}
                    />
					{React.cloneElement(children, {...childrenProps})}
					<Form.Group>
						{ allowedForEdit.ready ?
							( allowedForEdit.value ?
								<><SubmitButton isSubmitting={useFormObject.formState.isSubmitting} type="submit" className="btn btn-primary">{t('default.save')}</SubmitButton>&nbsp;</>
								:
								(domainInstance && domainInstance.id && domainInstance.classSimpleName &&
									<Secured granted={`/api/${domainInstance.classSimpleName}/${domainInstance.id}/edit#GET`}>
										<Link to={ restService.getTo( domainInstance, 'edit' ) } className="btn btn-primary me-1">{ t('default.edit') }</Link>
									</Secured>
								)
							)
							:
							<DefaultSpinner loading={true} />
						}
						<Button className="btn btn-secondary" onClick={handleCancel}>{t('default.cancel')}</Button>
					</Form.Group>
				</Form>
			</FormProvider>
		</div>
	);
}

AddEditForm.propTypes = {
	domainName: PropTypes.string.isRequired,
	instanceToString: PropTypes.func,
	history: PropTypes.object.isRequired,
	children: PropTypes.any.isRequired,
	beforeSubmit: PropTypes.func
};


export default withRouter(AddEditForm);
