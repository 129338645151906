import PropTypes from "prop-types";
import {AllowedForEnum} from "../form/form.service";
import {securityService} from "../_services/security.service";
import {useMemo} from "react";
import AllowedContextPropertyName from "./AllowedContextPropertyName";
import {NotAllowedGenerateType} from "../_enum/enum";

function Allowed( {children, allowedFor, propertyName, type} ) {
	const classSimpleNameOfAllowedContext = securityService.useClassSimpleNameOfAllowedContext()
	const hasAccessToProperty = securityService.useAccessToProperty( `${classSimpleNameOfAllowedContext}.${propertyName}` )

	const allowed = useMemo( () => {
		if ( hasAccessToProperty.ready ) {
			switch ( allowedFor ) {
				case AllowedForEnum.SHOW:
					return hasAccessToProperty.read
				case AllowedForEnum.EDIT:
					return hasAccessToProperty.write
				case AllowedForEnum.DELETE:
					return hasAccessToProperty.delete
				default:
					return false
			}
		}
	}, [allowedFor, hasAccessToProperty.ready, hasAccessToProperty.read, hasAccessToProperty.write, hasAccessToProperty.delete])

	const getContent = () => {
		return <AllowedContextPropertyName propertyName={propertyName}>
			{children}
		</AllowedContextPropertyName>
	}

	return <>
		{/*<p> allowedFor: {allowedFor}, classSimpleNameOfAllowedContext: { classSimpleNameOfAllowedContext }, propertyName: { propertyName },*/}
		{/*	hasAccessToProperty: { JSON.stringify(hasAccessToProperty) }, allowed: {JSON.stringify(allowed)} </p>*/}

		{allowed
			?
				getContent() // if allowed, then display the content
			:
			type === NotAllowedGenerateType.GENERATE_BUT_HIDE
				?
					<div className={ "d-none" }>
						{
							getContent() /* if not allowed, then generate the content, but hide it. This is useful, when
							you need to generate the content in order submit it, but you don't want to display it.
							*/
						}
					</div>
				:
				null

		}
	</>
}

Allowed.propTypes = {
	allowedFor: PropTypes.oneOf( Object.values( AllowedForEnum ) ).isRequired,
	__TYPE: PropTypes.string,
	type: PropTypes.oneOf( Object.values( NotAllowedGenerateType ) )
};

Allowed.defaultProps = {
	__TYPE: 'Allowed',
	type: NotAllowedGenerateType.GENERATE_NOTHING
};

export default Allowed
