import React, {useCallback, useMemo} from 'react';
import {useTranslation} from "react-i18next";
import Index from "../form/Index";
import {FormElements} from "./FormElements";
import {Link, useHistory} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUmbrella, faIndustry, faUser} from "@fortawesome/free-solid-svg-icons";
import {Badge} from "react-bootstrap";
import Secured from "../_components/Secured";
import {messageBoxService} from "../_services/messageBox.service";
import i18n from "i18next";
import {MessageBoxButtons} from "../_components/MessageBox";

function Partner({ match }) {
	const { t } = useTranslation();
	const history = useHistory();

	const columns = useMemo( () => {
		return [{
					dataField: 'id',
					text: t( 'partner.id' ),
					hidden: true
				},
				{
					dataField: 'fullName',
					text: t( 'partner.name' ),
					sortField: 'fullName_sort',
					sort: true,
					formatter: (cell, row) => {
						let icon;
						switch(row.className) {
							case 'person':
								icon = <FontAwesomeIcon className={'text-secondary'} icon={faUser}/>;
								break;
							case 'company':
								icon = <FontAwesomeIcon icon={faIndustry}/>;
								break;
							default:
								throw new Error(`Unknown className ${row.className}`);
						}
						return (<>
						<span style={{display: 'inline-block', width:'25px'}}>
							{icon}
						</span>
							{row.fullName}
							{ row.tags && <div>
								{ row.tags.map( tag => <Badge className={"me-1"} key={tag.id} bg="primary">{ tag.partnerTag.name }</Badge> ) }
							</div>
							}
						</>);
					},
				}, {
				dataField: 'zipLabel',
				text: t( 'partner.zipLabel' ),
				sortField: 'zip.label',
				sort: true
			}, {
				dataField: 'streetFullName',
				text: t( 'partner.streetFullName' ),
			}, {
				dataField: 'consultantOrBroker',
				text: t( 'default.consultantOrBroker' ),
				formatter: ( cell, row ) => {
					return row.partnerConsultantsAndBrokers.map( cob => cob.label ).join(', ');
				}
			}
		]
	}, [t])

	const getAddButtons = useCallback(() => {
		return (
			<>
				<Secured granted={'/api/person/create'}>
					<Link to={ `partner/person/create` } className="btn btn-sm btn-success mb-2">{ t( 'default.addWhat', { what: t( `person.label` ) } ) }</Link>&nbsp;
				</Secured>
				<Secured granted={'/api/company/create'}>
					<Link to={ `partner/company/create` } className="btn btn-sm btn-success mb-2">{ t( 'default.addWhat', { what: t( `company.label` ) } ) }</Link>
				</Secured>
			</>
		)
	}, [t] );

	const getEditUrlParams = useCallback( (row) => {
		return `/${row.className}`;
	}, [])

	const getAdditionalButtons = useCallback((row) => {
		return <Link to={ `policyVersion?partnerId=${row.id}` } className="btn btn-sm btn-primary ms-1" target={"_blank"} rel={"noopener noreferrer"}>
			<FontAwesomeIcon icon={faUmbrella}/>
		</Link>
	}, [] );

	return (
		<>
			<Index
				path={match.path}
				domainName={ 'partner' }
				columns={ columns }
				toString={ ['name', 'surname'] }
				formElements={ <FormElements/> }
				sortField={'fullName'}
				allowEdits={ (row) => {
					if ( row && row.id ) {
						return `/api/partner/${row.id}/edit#GET`
					}
					else {
						if ( row && row.classSimpleName === 'person' ) {
							return '/api/person/create'
						}
						else if ( row && row.classSimpleName === 'company' ) {
							return '/api/company/create'
						}
					}
				}}
				allowDeletions={ (row) => `/api/partner/${row.id}#DELETE` }
				allowShow={ (row) => {
					if ( row ) {
						return `/api/partner/${row.id}#GET`
					}
					else {
						return '/api/partner/create'
					}
				} }
				addButtons={getAddButtons()}
				getEditUrlParams={getEditUrlParams}
				additionalButtons={getAdditionalButtons}
				afterCreate={ (domain) => {
					return new Promise( (resolve , reject) => {
						messageBoxService.display( i18n.t( 'partner.afterCreate.question.content', {name: domain.label} ), i18n.t( 'partner.afterCreate.question.title' ), [MessageBoxButtons.YES, MessageBoxButtons.NO] )
						.then( ( button ) => {
							if ( button === MessageBoxButtons.YES ) {
								history.push( `/policyVersion/create?partnerId=${domain.id}` )
								resolve(true)
							}
							else {
								resolve(false)
							}
						})
					})
				}}
			/>
		</>
	);
}

export { Partner };
