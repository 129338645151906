import React, {useMemo, useState} from 'react';
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import FormControl from "../_components/FormControl";
import {useForm, FormProvider} from "react-hook-form";
import {RestAsyncSelectControl} from "../_components/RestAsyncSelectControl";
import restService from "../_services/rest.service";
import settingService from "../_services/setting.service";
import ToggleButtonGroupControl from "../_components/ToggleButtonGroupControl";
import {SettlementType} from "../_enum/enum";
import {SelectControl} from "../_components/SelectControl";
import reportService from "../_services/report.service";
import appService from "../_services";

function DebtorsList() {
	const {t} = useTranslation();
	const useFormObject = useForm();
	const {appenzellerVersicherungPartnerId} = settingService.useSetting(['appenzellerVersicherungPartnerId']);
	const [appenzellerVersicherung] = restService.useDomainInstance("partner", appenzellerVersicherungPartnerId, false, "select")
	const defaultParticipation = useMemo( () => {
		return [appenzellerVersicherung];
	}, [appenzellerVersicherung])
	const allSettlementTypes = useMemo( () => Object.values(SettlementType).map((item) => ({id:item, label: t('appvers.enums.SettlementType.' + item)})), [t]);
	const [ settlementTypes, setSettlementTypes ] = useState(allSettlementTypes);

	appService.useTitle( t('reports.debtorsList.title') )

	const onSubmit = (data) => {
		reportService.debtorsList( data )
	}

	const insurancesHsNamedRestriction = useMemo( () => {
		return { namedRestriction:
				{
					queryName: "insurance",
					params: {}
				}
		}
	}, [])

	const policyVersionSettlementTypes = useMemo( () => ['INVOICE_PERIODIC', 'INVOICE_PRO_RATA', 'CREDIT_NOTE_POLICY_VERSION'], [])

	const isPolicyVersionSettlementSelected = useMemo( () => {
		const intersection = appService.intersect( settlementTypes && settlementTypes.map( (it) => it.id ), policyVersionSettlementTypes )
		return intersection.length > 0;
	}, [ settlementTypes, policyVersionSettlementTypes ] );

	/*
	•	Fakturiert am (von-bis)
	•	Land (CH, FL)
	•	Partner
	•	Beteiligte Gesellschaften
	•	Kanton
	•	Status bezahlt (offen, bezahlt, alle)
	•	Rechnungstyp (pro Rata, periodisch, Gutschrift)
	 */
	return (
		<>
			<h2>{t('reports.debtorsList.title')}</h2>

			<FormProvider {...useFormObject}>
				<Form onSubmit={useFormObject.handleSubmit(onSubmit)}>
					<Card>
						<Card.Header>
							{t('default.filter')}
						</Card.Header>
						<Card.Body>
							<Row>
								<Form.Group className="col-md-6" controlId="dueDateFrom">
									<Form.Label>{t('debtorsList.dueDateFrom.label')}</Form.Label>
									<FormControl
										name={"dueDateFrom"}
										type={"date"}
										required={true}
									/>
								</Form.Group>
								<Form.Group className="col-md-6" controlId="dueDateTo">
									<Form.Label>{t('debtorsList.dueDateTo.label')}</Form.Label>
									<FormControl
										name={"dueDateTo"}
										type={"date"}
										required={true}
									/>
								</Form.Group>
							</Row>
							<Row className={"mt-3"}>
								<Form.Group className="col-md-12" controlId="invoiceTos">
									<Form.Label>{t('debtorsList.invoiceTo.label')}</Form.Label>
									<RestAsyncSelectControl
										name={"invoiceTos"}
										domainName={"partner"}
										sort={"fullName_sort"}
										isMulti={true}
									/>
								</Form.Group>
							</Row>
							<Row className={"mt-3"}>
								<Form.Group className="col-md-12" controlId="countries">
									<Form.Label>{t('debtorsList.country.label')}</Form.Label>
									<RestAsyncSelectControl
										name={"countries"}
										domainName={"country"}
										sort={"name_sort"}
										isMulti={true}
									/>
								</Form.Group>
							</Row>
							<Row className={"mt-3"}>
								<Form.Group controlId="paid">
									<Form.Label>{t('debtorsList.paymentState.label')}</Form.Label>
									<div>
									<ToggleButtonGroupControl
										name={"paid"}
										options={[
											{value: "all", label: t("default.all")},
											{value: "paid", label: t("debtorsList.paid.label")},
											{value: "unpaid", label: t("debtorsList.unpaid.label")},
										]}
										value={"all"}
									/>
									</div>
								</Form.Group>
							</Row>
							<Row className={"mt-3"}>
								<Form.Group controlId="settlementTypes">
									<Form.Label>{t('debtorsList.settlementType.label')}</Form.Label>
									<div>
									<SelectControl
										name={"settlementTypes"}
										options={ allSettlementTypes }
										isMulti={true}
										value={ settlementTypes }
										onChange={ (value) => setSettlementTypes(value) }
									/>
									</div>
								</Form.Group>
							</Row>

							{ isPolicyVersionSettlementSelected && <Row className={"mt-3"}>
								<Form.Group className="col-md-12" controlId="participations">
									<Form.Label>{t(
										'debtorsList.participation.label',
										{settlementTypes: policyVersionSettlementTypes.map( (st) => t(`appvers.enums.SettlementType.${st}`) ).join(', ')}
									)}</Form.Label>
									<RestAsyncSelectControl
										name={"participations"}
										domainName={"partner"}
										sort={"fullName_sort"}
										isMulti={true}
										value={ defaultParticipation }
										searchOptions={ insurancesHsNamedRestriction }
									/>
								</Form.Group>
							</Row> }
						</Card.Body>
					</Card>
					<Row className={"mt-3"}>
						<Col md={2}>
							<Button type={"submit"}>{ t('default.export') }</Button>
						</Col>
					</Row>
				</Form>
			</FormProvider>
		</>
	)
}

export default DebtorsList