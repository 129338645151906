import React from 'react';
import RoleNodeTreeLevel from "./RoleNodeTreeLevel";
import RoleGroupSelection from "./RoleGroupSelection";

function RoleNodeTree( { nodes, showRoleGroupSelection = false } ) {

    return (
        <div>
            {showRoleGroupSelection && <RoleGroupSelection nodes={nodes}/>}
            <RoleNodeTreeLevel nodes={nodes}/>
        </div>
    )
}

export default RoleNodeTree
