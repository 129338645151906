import React, {useMemo, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faFilePdf, faPencilAlt, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import DomainTable from "../_components/DomainTable";
import PropTypes from "prop-types";
import bootstrapTableService from "../_components/bootstrapTable.service";
import AddButtons from "../form/AddButtons";
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";
import {damageService} from "../_services/damage.service";
import restService from "../_services/rest.service";
import {alertService} from "../_services";
import DeleteConfirmDialog from "../_components/DeleteConfirmDialog";
import textService from "../_services/text.service";

function DamagePaymentInfos({damage, match, namedCriteria, hiddenFields}) {
    const {t} = useTranslation();
    const searchOptions = useMemo( () => ( {queryStrings: [{field: 'damage.id', text: `${damage.id}`}]} ), [damage.id] );

    const domainTableRef = useRef()

    const handleDelete = () => {
        try {
            let row = rowToBeDeleted;
            restService.deleteDomainInstance( 'damagePaymentInfo', row.id )
                .then( () => {
                    alertService.success(t("default.deleted", {what: t('damagePaymentInfo.label')}), { keepAfterRouteChange: true });
                    domainTableRef.current.onRowDeleted(row)
                })
                .catch( (error, signal) => {
                    restService.handleServerErrorsAxios(error, signal);
                })

        }
        catch (e) {
            alertService.error(e);
        }
    }

    const [rowToBeDeleted, setRowToBeDeleted] = useState({});
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const handleCloseDeleteConfirm = () => setShowDeleteConfirm(false);

    const handlePrintDamagePaymentInfo = (id) => {
        damageService.printDamagePaymentInfo(id);
    }

    const columns = useMemo(() => {
        const _columns = [
            {
                dataField: 'id',
                text: t('damagePaymentInfo.id'),
                hidden: true,
            },
            {
                dataField: 'partner.label',
                text: t('damagePaymentInfo.partner'),
            },
            {
                dataField: 'amount',
                classes: (cell, row, rowIndex, colIndex) => 'text-end',
                text: t('damagePaymentInfo.amount'),
                sortField: 'amount',
                formatter: (cellContent, row) => bootstrapTableService.formatterAmount(cellContent),
                sort: true
            },
            {
                dataField: 'paymentInfoDate',
                text: t( 'damagePaymentInfo.paymentInfoDate' ),
                formatter: (cellContent, row) => bootstrapTableService.formatterDate(cellContent),
                sort: true
            },
            {
                dataField: 'booked',
                classes: (cell, row, rowIndex, colIndex) => 'text-center',
                text: t( 'damagePaymentInfo.booked' ),
                formatter: bootstrapTableService.formatterBoolean,
            },
            {
                dataField: '',
                text: '',
                sort: false,
                classes: (cell, row, rowIndex, colIndex) => 'text-end',
                formatter: (cellContent, row) => {
                    return (
                        <>
                            { row.booked &&
                                <Button variant={"primary"} size={"sm"} onClick={ () => handlePrintDamagePaymentInfo(row.id) }>
                                    <FontAwesomeIcon icon={faFilePdf}/>
                                </Button>
                            }
                            <Link className={`btn btn-sm btn-primary ms-1`} to={`/damagePaymentInfo/show/${row.id}`} >
                                <FontAwesomeIcon icon={faEye}/>
                            </Link>
                            { !row.booked &&
                                <Link className={`btn btn-sm btn-warning me-1`} to={`/damagePaymentInfo/edit/${row.id}`} >
                                    <FontAwesomeIcon icon={faPencilAlt}/>
                                </Link>
                            }
                            { !row.booked && <Button
                                    variant={'danger'}
                                    size={'sm'}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        setRowToBeDeleted(row);
                                        setShowDeleteConfirm(true);
                                    }}>
                                    <FontAwesomeIcon icon={faTrashAlt}/>
                                </Button>
                            }
                        </>
                    )
                },
                headerStyle: (column, colIndex) => {
                    return { width: '10%' };
                },
            }
        ];

        return _columns.filter( (column) => !hiddenFields.includes( column.dataField ) );
    }, [hiddenFields, t])

    return (
        <>
            {!damage.resolved && <AddButtons path={`/damage/${damage.id}/damagePaymentInfo`} domainName={'damagePaymentInfo'} label={t( 'default.registerWhat', { what: t('damagePaymentInfo.label') } )}/>}
            <DomainTable
                ref={domainTableRef}
                domainName={'damagePaymentInfo'}
                columns={columns}
                namedCriteria={namedCriteria}
                searchOptions={searchOptions}
            />
            <DeleteConfirmDialog
                show={showDeleteConfirm}
                onHide={handleCloseDeleteConfirm}
                bodyText={t('deleteConfirm.body', {name: textService.objectToString(rowToBeDeleted, 'damagePaymentInfo', (object) => object.amount)})}
                onDelete={handleDelete}
            />
        </>
    )
}


DamagePaymentInfos.defaultProps = {
    hiddenFields: []
};

DamagePaymentInfos.propTypes = {
    namedCriteria: PropTypes.object,
    hiddenFields: PropTypes.array
};

export default DamagePaymentInfos
