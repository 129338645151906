import React, {useState, useCallback, useMemo} from 'react';
import {Button, Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {ConfiguratorForm} from "./ConfiguratorForm";
import restService from "../_services/rest.service";
import {FormProvider, useForm} from "react-hook-form";
import {apiClientPrivate} from "../api/apiClient";

function CreatePolicyAddProductConfigurator(props) {
	const useFormObject = useForm();
	const { t } = useTranslation();
	const stepValues = useMemo( () => ( props.values[props.stepName] ), [props.values, props.stepName] );
	const policyValues = useMemo( () => ( props.values['policy'] ), [props.values])
	const [values, setValues] = useState(stepValues.values);
	const stepBuildingValues = useMemo( () => (props.values['addProductBuilding']), [props.values] );
	const buildingId = useMemo( () => ( stepBuildingValues && stepBuildingValues.building ) ? stepBuildingValues.building.id : undefined, [stepBuildingValues] );
	const validFrom = useMemo( () => ( policyValues.validFrom ), [policyValues] );
	const [building] = restService.useDomainInstance('building', buildingId);
	const brokerId = useMemo( () => {
		if ( policyValues.broker && policyValues.brokerOrConsultant==='BROKER') {
			return policyValues.broker.id
		}
		else {
			return undefined;
		}
	}, [policyValues] );
	const [isWorking, setIsWorking] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const configuratorDefaultValues = useMemo( () => {
		return {
			buildingType: building ? building.buildingType : undefined,
			buildingClass: building ? building.buildingClass : undefined,
			constructionType: building ? building.constructionType : undefined,
			fireExtinguisher: building ? building.fireExtinguisher : undefined,
			heatingType: building ? building.heatingType : undefined,
			roofType: building ? building.roofType : undefined
		}
	}, [building])

	const handleClickPrevious = () => {
		props.previousStep();
	}

	const onSubmit = (data) => {
		//TODO: Here should be either address or building information which can identify County
		setIsSubmitting(true);
		let buildingId = stepBuildingValues.building && stepBuildingValues.building.id
		let countyId
		if ( buildingId ) {
			countyId = stepBuildingValues.building.countyId
		}
		// else if ( stepBuildingValues.address. ) {
		//
		// }
		const params = {
			values: JSON.stringify(values),
			validFrom: validFrom,
			buildingId: buildingId,
			countyId: countyId,
			brokerId: brokerId,
		}

		apiClientPrivate.get(`/api/configurator/getAvailableTargets?` + new URLSearchParams( params ) )
			.then( r => restService.handleServerResponseAxios(r) )
			.then( json => {
				props.handleUpdate(props.stepName, { targets: json.configuratorTargets.map( (target) => { target.targetId = target.id; return target }), values: values } );
				props.nextStep();
				setIsSubmitting(false);
			} )
			.catch( error => {
				restService.handleServerErrorsAxios( error );
				setIsSubmitting(false);
			} );
	}

	const handleOnChange = useCallback( (values) => {
		setValues(values)
	}, [setValues] )

	return (
		<FormProvider {...useFormObject}>
			<Form onSubmit={useFormObject.handleSubmit(onSubmit)}>
				<ConfiguratorForm onChange={ handleOnChange } defaultValues={configuratorDefaultValues} buildingId={buildingId} brokerId={brokerId} validFrom={validFrom} setIsWorking={setIsWorking}/>
				<div className="create-policy-footer p-3">
					<Button className='btn btn-secondary me-1'
					        onClick={ handleClickPrevious }>{ t( 'default.previous' ) }</Button>
					<Button type={ "submit" } disabled={ isWorking || isSubmitting }
					        className='btn btn-default'>{ t( 'default.next' ) }</Button>
				</div>
			</Form>
		</FormProvider>
	);
}

export { CreatePolicyAddProductConfigurator };
