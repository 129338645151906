import React, {useEffect} from 'react';
import {Form, FormControl, Button, Card, InputGroup, Row, Col} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import logoAppvers from './images/logo_appvers_with_name.svg';
import {Alert} from "./_components";
import {faKey, faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, useHistory} from "react-router-dom";
import {securityService} from "./_services/security.service";
import restService from "./_services/rest.service";
import {alertService} from "./_services";
import {useAuthContext} from "./security/AuthContext";

const Login = () => {
	const { t } = useTranslation()
	const {twoFactorRequired, login, twoFactorEnabled, twoFactorMandatory} = useAuthContext();
	const history = useHistory()

	useEffect(() => {
		if ( twoFactorMandatory && !twoFactorEnabled ) {
			history.push('/setupOtp')
		}
		else if ( twoFactorRequired ) {
			history.push('/validateOtp')
		}
	}, [twoFactorRequired, twoFactorEnabled, twoFactorMandatory, history])

	function handleSubmit(event) {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;
		if ( form.checkValidity() === true ) {
			const formData = new FormData(event.target)
			const data = Object.fromEntries(formData.entries())
			securityService.authenticateUser( data ).then( auth => {
				login(auth, true);
			}).catch( error => {
				if ( error.response && error.response.status === 401 ) {
					alertService.error(t("login.error.unauthorized"))
				}
				else {
					restService.handleServerErrorsAxios(error);
				}
			})
		}
	}

	return (
		<Col sm="7" md="5" lg="4" xl="3">
			<Card className="my-5">
				<Card.Body>
					<div className="text-center">
						<img src={logoAppvers} alt={"logo"} className="my-3"/>
					</div>

					<Alert />
				<Form className="login-form" onSubmit={handleSubmit}>
					<InputGroup className="mb-3">
						<InputGroup.Text><FontAwesomeIcon icon={faUser}/></InputGroup.Text>
						<FormControl type='text' name='username' required placeholder={t('login.username.label')} autoComplete="on" />
					</InputGroup>

					<InputGroup className={"mb-2"}>
						<InputGroup.Text><FontAwesomeIcon icon={faKey}/></InputGroup.Text>
						<FormControl type='password' name='password' required placeholder={t('login.password.label')} autoComplete="off"/>
					</InputGroup>
					<Row className={"mb-3"}>
						<Col>
							<p className={"text-end"}>
								<Link to={"/passwordRecoveryRequest"} className={"float-end"}>{t('login.forgotPassword.label')}</Link>
							</p>
						</Col>
					</Row>

					<Button className="px-5 mb-4 w-100" variant="success" type="submit">{t('login.login.label')}</Button>
				</Form>
				</Card.Body>
			</Card>
		</Col>
	);
};

export default Login;

