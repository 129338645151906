import React, {useMemo} from 'react';
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {Alert} from "../_components";
import {FormProvider, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {RestAsyncSelectControl} from "../_components/RestAsyncSelectControl";
import {TicketPriority, TicketState} from "../_enum/enum";
import DropdownControl from "../_components/DropdownControl";
import AllowedContextWrapper from "../_components/AllowedContextWrapper";
import FormControl from "../_components/FormControl";
import ReactQuillControl from "../_components/ReactQuillControl";
import Acl from "../_components/Acl";
import {apiClientPrivate} from "../api/apiClient";
import restService from "../_services/rest.service";

function TicketEditor({ticket, showForm, onSubmit, onCancel}) {
    const {t} = useTranslation();
    const useFormObject = useForm();
    const allTicketStates = useMemo( () => Object.values(TicketState).map((item) => ({value:item, label: t('appvers.enums.TicketState.' + item)})), [t]);
    const allPriorities = useMemo( () => Object.values(TicketPriority).map((item) => ({value:item, label: t('appvers.enums.TicketPriority.' + item)})), [t]);

    const handleSubmit = () => {
        useFormObject.handleSubmit(onSubmit)();
    }

    const ticketUsers = useMemo( () => {
        return {
            namedRestriction:
                {
                    queryName: "ticketUsers",
                    params: {}
                }
        }
    }, [])

    const getTicketableUsers = (page, PAGE_SIZE, sort) => {
        const params = {
            page: page,
            pageSize: PAGE_SIZE,
            sort: sort,
        }
        return new Promise( (resolve, reject) => {
            apiClientPrivate.get( `/api/ticket/getTicketableUsers?${new URLSearchParams( params )}` )
                .then( r => restService.handleServerResponseAxios( r ) )
                .then( json => {
                    resolve( {totalSize: json.count, data: json.data } );
                } )
                .catch( reject )
        })
    }

    const getModal = () => {
        return (
            <Modal
                show={showForm}
                animation={false}
                onHide={onCancel}
                centered
                backdrop="static"
            >
                <Modal.Header>
                    <Modal.Title>{t('ticket.label')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/*{JSON.stringify(ticket)}*/}
                    {/*{JSON.stringify(allTicketStates)}*/}
                    {/*{JSON.stringify(allPriorities)}*/}
                    <Alert id={'addEditPopupFormAlert'} />
                    <AllowedContextWrapper domainName={'ticket'} id={ticket.id} allowShow={true} allowEdits={true} allowDeletions={false}>
                        <FormProvider {...useFormObject}>
                        <Form>
                            <FormControl
                                hidden
                                name="id"
                                value={ticket.id}
                                type={"number"}
                            />
                            <FormControl
                                hidden
                                name="version"
                                value={ticket.version}
                                type={"number"}
                            />
                            <FormControl
                                hidden
                                name="from"
                                value={ticket.from}
                                type={"text"}
                            />
                            <Row>
                                <Form.Group as={Col} md="12" controlId="formGroupTitle">
                                    <Form.Label>{t('ticket.title')}</Form.Label>
                                    <FormControl
                                        className={"fw-bold"}
                                        name={"title"}
                                        type={"text"}
                                        value={ticket.title}
                                        rules={{
                                            required: true
                                        }}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className={"mt-3"}>
                                <Form.Group as={Col} md="12" controlId="formGroupResponsible">
                                    <Form.Label>{t('ticket.responsible')}</Form.Label>
                                    <RestAsyncSelectControl
                                        getData={getTicketableUsers}
                                        sort={"nameOfUser_sort"}
                                        searchOptions={ticketUsers}
                                        value={ticket.responsible && ticket.responsible.id}
                                        label={ticket.responsible && ticket.responsible.label}
                                        name={'responsible'}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className={"mt-3"}>
                                <Form.Group as={ Col } md="12" controlId="formGroupState">
                                    <Form.Label>{ t( 'ticket.state' ) }</Form.Label>
                                    <DropdownControl
                                        name={ 'state' }
                                        options={ allTicketStates }
                                        keyPropName={ 'value' }
                                        valuePropName={ 'label' }
                                        value={ ticket.state }
                                        sortBy={ null }
                                    />
                                </Form.Group>
                            </Row>
                            <Row className={"mt-3"}>
                                <Form.Group as={ Col } md="12" controlId="formGroupDueDate">
                                    <Form.Label>{ t( 'ticket.dueDate' ) }</Form.Label>
                                    <FormControl
                                        name="dueDate"
                                        type="date"
                                        value={ ticket.dueDate }
                                        rules={{
                                            required: true
                                        }}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className={"mt-3"}>
                                <Form.Group as={ Col } md="12" controlId="formGroupPriority">
                                    <Form.Label>{ t( 'ticket.priority' ) }</Form.Label>
                                    <DropdownControl
                                        name={ 'priority' }
                                        options={ allPriorities }
                                        keyPropName={ 'value' }
                                        valuePropName={ 'label' }
                                        value={ ticket.priority }
                                        sortBy={ null }
                                    />
                                </Form.Group>
                            </Row>
                            <Row className={"mt-3"}>
                                <Form.Group as={Col} md="12" controlId="formGroupText">
                                    <Form.Label>{t('ticket.text')}</Form.Label>
                                    <ReactQuillControl
                                        name={"text"}
                                        value= {ticket.text}
                                        rules={{
                                            required: true
                                        }}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className={"mt-3"}>
                                <Col>
                                    <Acl domainInstance={ticket} editable={false} />
                                </Col>
                            </Row>
                        </Form>
                    </FormProvider>
                    </AllowedContextWrapper>
                </Modal.Body>
                <Modal.Footer>
                    <Form.Group>
                        <Button type="button" variant={"primary"} onClick={handleSubmit}>{t('default.save')}</Button>
                        <Button type="button" className={"ms-2"} variant={"secondary"} onClick={onCancel}>{t('default.cancel')}</Button>
                    </Form.Group>
                </Modal.Footer>
            </Modal>
        )
    }

	return showForm ? getModal() : <></>
}

export default TicketEditor