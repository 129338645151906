import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Card, ToastContainer, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import restService from "../_services/rest.service";
import notificationService from "../_services/notification.service";
import PlainMessage from "./PlainMessage";
import Pagination from "../_components/Pagination";
import {useTranslation} from "react-i18next";
import appService from "../_services";

function PlainMessages() {
	const [page, setPage] = useState(1);
	const [sizePerPage] = useState(10);
	const [read, setRead] = useState(false);
	const [messages, setMessages] = useState([])
	const [totalSize, setTotalSize] = useState(0)
	const unreadCount = notificationService.useUnreadPlainMessagesCount();
	const [snoozed, setSnoozed] = useState(false);

	const {t} = useTranslation();

	document.title = t( 'messages.header' )

	const messagesCriteria = useMemo( () => {
		return {
			queryName: "plainMessages",
			params: {direction: 'inbox', read: read, snoozed: snoozed}
		}
	}, [read, snoozed])


	const reloadMessages = useCallback( (signal, page, sizePerPage, messagesCriteria ) => {
		restService.getDomainInstancesList( "plainMessage", page, sizePerPage, 'createdOn desc', '', undefined, messagesCriteria, undefined, signal )
			.then( ( result ) => {
				setTotalSize( result.totalSize );
				setMessages( result.data );
			} )
			.catch( ( error ) => {
				restService.handleServerErrorsAxios( error, signal )
			});
	}, [] )

	useEffect( () => {
		const controller = new AbortController();

		reloadMessages( controller.signal, page, sizePerPage, messagesCriteria )

		return function cleanup() {
			controller.abort()
		}
	}, [ page, sizePerPage, messagesCriteria, reloadMessages ] )

	const handleMessageChanged = useCallback( (changedMessages) => {
		if ( 0 <= changedMessages.length ) {

			let _messages = [...messages]

			for ( const changedMessage of changedMessages ) {
				const index = _messages.findIndex( ( m ) => m.id === changedMessage.notification.id )
				if ( index !== -1 ) {
					_messages[index] = changedMessage.notification
				} else {
					if ( ( changedMessage.isNew || changedMessage.isUnsnoozed ) && page === 1 ) {
						if ( _messages.length === sizePerPage ) {
							_messages.pop()
						}
						_messages = [changedMessage.notification, ..._messages]
					}
				}

				if ( changedMessage.isNew || changedMessage.isUnsnoozed ) {
					const notificationCommandsHrefs = changedMessage.commands && changedMessage.commands.map( ( command ) => notificationService.getNotificationCommandHref( command ) ).filter( ( href) => !!href )
					const href = notificationCommandsHrefs && notificationCommandsHrefs.length > 0 ? notificationCommandsHrefs[0] : undefined;

					appService.notify( changedMessage.notification.title, changedMessage.notification.text, href )
				}
			}

			setMessages( _messages )
		}
	}, [page, sizePerPage, messages, setMessages] )

	notificationService.usePlainNotificationsChanged('plainMessage', handleMessageChanged);

	const header = useMemo( () => {

		const getText = () => {
			if ( unreadCount > 0 ) {
				return t('messages.unread.exists', {unreadCount: unreadCount})
			} else {
				return t('messages.unread.notExists')
			}
		}

		return (
			<>
				<span className={ "w-100" }>
					<span className={ 'float-start' }>{ getText() }</span>
					<small className={ 'float-end' }>
						<Pagination totalElements={totalSize} pageSize={sizePerPage} onPageChange={setPage} size={"sm"} className={'float-start'}/>
					    <ToggleButtonGroup type="checkbox" size={"sm"} className={'float-end ms-2'} name={"test"}>
				          <ToggleButton
					          id="toggle-plainMessages-unreadOnly"
					          type="checkbox"
					          variant="outline-primary"
					          checked={ read }
					          value="1"
					          onChange={(e) => setRead(e.currentTarget.checked)}
				          >
					        {t('messages.read')}
					      </ToggleButton>
						    <ToggleButton
							    id="toggle-plainMessages-snoozedOnly"
							    type="checkbox"
							    variant="outline-primary"
							    checked={ snoozed }
							    value="2"
							    onChange={(e) => setSnoozed(e.currentTarget.checked)}
						    >
					        {t('messages.snoozed')}
					      </ToggleButton>
					    </ToggleButtonGroup>
					</small>
				</span>
			</> )
	}, [unreadCount, read, sizePerPage, t, snoozed, totalSize, setPage] )

	const handleUpdateMessage = useCallback( ( id, values ) => {
		const replaceMessage = ( message ) => {
			const index = messages.findIndex( ( m ) => m.id === id )
			messages.splice( index, 1, message ); //replace the message
			setMessages( [...messages] )
		}

		const index = messages.findIndex( (m) => m.id === id )
		if ( index !== -1 ) {
			const message = { ...messages[index], values }
			replaceMessage( message )

			restService.updateDomainInstance( 'plainMessage', message.id, values )
				.then( (message) => {
					replaceMessage( message )
				} )
		}

	}, [messages, setMessages])

	const plainMessagesElements = useMemo( () => {
		return messages.map( (message) => <PlainMessage key={message.id} message={message} onUpdateMessage={handleUpdateMessage}/> )
	}, [messages, handleUpdateMessage])

	return (
        <Card>
            <Card.Header>
	            { header }
			</Card.Header>
            <Card.Body>
	            <ToastContainer className="d-flex flex-wrap gap-3 position-relative w-100" style={{zIndex:1000}}> {/*zIndex set because of ToastContainer in MessagePanel.jsx*/}
	                {plainMessagesElements}
	            </ToastContainer>
            </Card.Body>
        </Card>
	)
}

export default PlainMessages