import React, {useEffect} from 'react';
import AppRouter from './AppRouter';
import {Container, Row, Col} from 'react-bootstrap';
import Spinner from "./_components/Spinner";
import restService from "./_services/rest.service";
import {useConfig} from "./_services/useConfig";
import {WebsocketProvider} from "./_components/WebsocketProvider";
import MessagePanel from "./_components/MessagePanel";
import UserMessagesListener from "./_components/UserMessagesListener";
import MessageBox from "./_components/MessageBox";
import {useTranslation} from "react-i18next";
import LoginRouter from "./LoginRouter";
import {useAuthContext} from "./security/AuthContext";

function App() {
    const {loggedIn} = useAuthContext()
    const { setConfig } = useConfig();
    const {i18n} = useTranslation();

    useEffect(()=> {
        if ( loggedIn ) {
            const controller = new AbortController();

            if ( i18n.resolvedLanguage === 'de' && i18n.language !== 'de-CH' ) {
                i18n.changeLanguage( 'de-CH' )
            }
            else if ( i18n.resolvedLanguage === 'en' && i18n.language !== 'en-CH' ) {
                i18n.changeLanguage( 'en-CH' )
            }

            restService.getApplicationConfig(
                controller,
                (configData) => {
                    setConfig( configData);
                }
            );
            return function cleanup() {
                controller.abort();
            }
        }
    }, [setConfig, loggedIn, i18n])

    const contentForRoute = () => {
        if ( loggedIn ) {
            return (
                <Col className="d-flex page-content flex-column">
                    <WebsocketProvider>
                        <MessagePanel/>
                        <UserMessagesListener/>
                        <MessageBox/>
                        <AppRouter />
                    </WebsocketProvider>
                </Col>
            )
        } else {
            return (
                <Col className="d-flex justify-content-center align-items-center bg-light">
                    <LoginRouter/>
                </Col>
            )
        }
    };

    return (
        <Container fluid>
            <Row>
                {contentForRoute()}
            </Row>
            <Spinner/>
        </Container>
    );
}

export default App;
