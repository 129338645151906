export function roleNodeTreeReducer(roleNodeTree, action) {
    switch (action.type) {
        case 'select':
            const selectNode = (node) => {
                node.selected = true;
                node.children.forEach( (child) => {
                    selectNode( child );
                } )
            }

            const node = findNode( roleNodeTree, action.authority )
            selectNode( node );

            const processAllChildrenSelected = ( node) => {
                if ( node.children.every( (child) => child.selected ) ) {
                    node.selected = true;
                    if ( node.parentAuthority ) {
                        processAllChildrenSelected( findNode( roleNodeTree, node.parentAuthority ) )
                    }
                }
            }

            const parent = findNode( roleNodeTree, node.parentAuthority );
            if ( parent ) {
                processAllChildrenSelected( parent );
            }

            return JSON.parse(JSON.stringify(roleNodeTree))
        case 'unselect':

            const unselectParents = (node) => {
                let parent = findNode( roleNodeTree, node.parentAuthority );
                while ( parent ) {
                    if ( parent.selected ) {
                        parent.selected = false;
                        parent = findNode( roleNodeTree, parent.parentAuthority );
                    }
                    else {
                        parent = null
                    }
                }
            }

            const unselectChildren = (node) => {
                node.children.forEach( (child) => {
                    child.selected = false;
                    unselectChildren( child );
                } )
            }

            const unselectNode = (node) => {
                node.selected = false;
                unselectParents( node );
                unselectChildren( node );
            }

            unselectNode( findNode( roleNodeTree, action.authority ) )

            return JSON.parse(JSON.stringify(roleNodeTree))
        case 'init':
            return {...action.roleNodeTree};
        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}

export function findNode(roleNodeTree, authority) {
    const stack = [...roleNodeTree.roots];

    while(stack.length) {
        const node = stack.pop();
        if(node.authority === authority) {
            return node;
        }

        stack.push(...node.children);
    }

    return null;
};
