import React from 'react'
import {useTranslation} from "react-i18next";
import {policyService} from "../../_services/policy.service";
import {Button} from "react-bootstrap";
import SettlementPrintedMessageListener from "../../settlement/SettlementPrintedMessageListener";
import {messagePanelService} from "../../_services/message.panel.service";
import {Link} from "react-router-dom";

function NewSettlementUserMessage(params) {
	const {t} = useTranslation();

	const handleOnClick = () => {
		policyService.printSettlement( params.settlementId )
			.then( () => {
				messagePanelService.update( undefined, [params.uuid] );
			} )
	}

	return (
		<>
			<SettlementPrintedMessageListener settlementId={params.settlementId}/>
			<p>{params.message}</p>
			{ params.settlementClassName && !params.warningMessage &&
				<Button variant={'primary'} onClick={handleOnClick}>{t('default.showDocument', {document: t(`${params.settlementClassName}.label`)})}</Button>
			}
			{ params.warningMessage &&
				<>
					<strong><p className={"text-danger mt-2"}>{params.warningMessage}</p></strong>
					<Link className={`btn btn-primary ms-1`} to={`/settlement/show/${params.settlementId}`} target={"_blank"} rel={"noopener noreferrer"}>{t('default.showDocument', {document: t(`${params.settlementClassName}.label`)})}</Link>
				</>
			}
		</>
	)
}

export default NewSettlementUserMessage