import React, {useCallback, useContext, useState} from "react"
import {DEFAULT_CONFIG} from "../config";

export const ConfigContext = React.createContext({config: DEFAULT_CONFIG, setConfig: () => {}} )

export function useConfig() {
    return useContext(ConfigContext)
}

export default function ConfigContextProvider( {children} ) {
    const [configState, setConfigState] = useState(DEFAULT_CONFIG)

    const setConfig = useCallback((configData) => {
        setConfigState( (prevState) => {
            return {
                ...prevState,
                ...configData
            }
        })
    }, [setConfigState])

    return (
        <ConfigContext.Provider value={{config: configState, setConfig: setConfig}}>
            {children}
        </ConfigContext.Provider>
    )
}
