import React, {useMemo} from 'react';
import {Card} from "react-bootstrap";
import serviceService from "../_services/service.service";
import {useTranslation} from "react-i18next";

function ActivateCheckMissingRV( {policyVersionId} ) {
    const {t} = useTranslation()

    const params = useMemo( () => {
        return {
            filter: 'id',
            policyVersionId: policyVersionId,
        }
    }, [policyVersionId])

    const missingRV = serviceService.usePoliciesVersionsWithIncompleteRV( params )

	return (

        missingRV && missingRV.length > 0 && <Card className={ "mt-3" }>
            <Card.Header className="bg-danger">{t('activateCheckMissingRV.title')}</Card.Header>
            <Card.Body>
                <Card.Text>
                    {t('activateCheckMissingRV.description', {missing: missingRV.map( item => item.missing ).join(', ')})}
                </Card.Text>
            </Card.Body>
        </Card>
	)
}

export default ActivateCheckMissingRV