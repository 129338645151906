import React, {useCallback, useMemo} from 'react';
import {accountingService} from "../_services/accounting.service";
import AccountingDocument from "./AccountingDocument";
import {Alert} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import websocketService from "../_services/websocket.service";
import restService from "../_services/rest.service";

function PreviewAccounting( {accountableObject} ) {
	const {accountingDocuments, accountingDocumentsLoaded, setAccountingDocuments} = accountingService.useAccountingDocuments(accountableObject && accountableObject.className, accountableObject && accountableObject.id)
	const {t} = useTranslation()

	const accountableObjectId = accountableObject && accountableObject.id;
	const accountableObjectSimpleName = useMemo( () => {
		const str = accountableObject && accountableObject.className && accountableObject.className.match(/appvers\.(.*)/)[1];
		if ( str ) {
			return str.charAt(0).toLowerCase() + str.slice(1);
		}
	}, [accountableObject])

	const refreshAccountingDocuments = useCallback( () => {
		accountingService.getAccountingDocuments( accountableObject && accountableObject.className, accountableObjectId, undefined )
			.then( (result) => setAccountingDocuments(result) )
			.catch( error => restService.handleServerErrorsAxios( error, undefined ) )
	}, [accountableObjectId, setAccountingDocuments, accountableObject])

	const afterUpdateAccountableObjectCallback = useCallback( (changes) => {
		if ( changes.payload.updatedProperties.includes( 'booked' ) ) {
			refreshAccountingDocuments();
		}
	}, [refreshAccountingDocuments]);
	const ids = useMemo( () => [parseInt(accountableObjectId)], [accountableObjectId] );
	websocketService.useAfterUpdateSubscription(accountableObjectSimpleName, ids, afterUpdateAccountableObjectCallback);


	const content = useMemo( () => {
		let result
		if ( accountableObject.booked ) {
			if ( accountingDocumentsLoaded ) {
				if ( accountingDocuments.length === 0 ) {
					result = <Alert className={ "m-3" }
					                variant={ "primary" }>{ t( `notAccountedReason.${accountableObject.notAccountedReason}` ) }</Alert>;
				} else {
					result = accountingDocuments.map( ( accountingDocument, index ) => <AccountingDocument
						key={ accountingDocument.id } accountingDocument={ accountingDocument }/> );
				}
			}
			else {
				result = <p>Loading...</p>
			}
		}
		else {
			result = <Alert className={ "m-3" }
			                variant={ "primary" }>{ t( 'previewAccounting.notYetBooked.message' ) }</Alert>;
		}
		return result;
	}, [accountingDocuments, accountingDocumentsLoaded, accountableObject.booked, accountableObject.notAccountedReason, t]);

	return (
		content
	)
}

export default PreviewAccounting
