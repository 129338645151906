import React from 'react';
import {useTranslation} from "react-i18next";
import {EditReportMessage} from "./EditReportMessage";
import Index from "../form/Index";
import restService from "../_services/rest.service";
import {apiClientPrivate} from "../api/apiClient";

function ReportMessage({ match }) {
	const { t } = useTranslation();

	const columns = [{
		dataField: 'id',
		text: t('reportMessage.id.label'),
		hidden: true
	}, {
		dataField: 'code',
		text: t('reportMessage.code.label'),
		sort: true
	}, {
		dataField: 'message_de',
		text: 'Deutsch',
		sort: true
	}, {
		dataField: 'message_en',
		text: 'English',
		sort: true
	}
	];

	const getIndexData = ( page, sizePerPage, sort, searchText, searchOptions, signal ) => {
		let _searchOptions = { ...searchOptions }

		restService.addSearchTextProperties( 'reportMessage', _searchOptions )
		const params = {
			page: page,
			sizePerPage: sizePerPage,
			searchText: searchText ? searchText : '',
			searchOptions: _searchOptions ? JSON.stringify(_searchOptions) : '',
		}
		if ( sort ) {
			params.sort = sort
		}

		return new Promise( (resolve, reject) => {
			apiClientPrivate.get( `/api/reportMessage/getReportMessageIndex?` + new URLSearchParams( params ), {
				signal: signal
			} )
				.then( r => restService.handleServerResponseAxios(r) )
				.then( json => resolve( {totalSize: json.count, data: json.data } ) )
				.catch( error => reject(error) )
		})
	}

	return (
		<Index
			path={match.path}
			columns={columns}
			toString={ (object) => object.description }
			editElement={<EditReportMessage/>}
			sortField={'code'}
			allowEdits={true}
			allowDeletions={false}
			allowShow={false}
			addButtons={<></>}
			getIndexData={ getIndexData }
			domainName={ 'reportMessage' }
			pagination={false}
		/>
	);
}

export { ReportMessage };
