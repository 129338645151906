import {createSlice} from "@reduxjs/toolkit";
import moment from "moment/moment";

const authSlice = createSlice({
    name: 'auth',
    initialState: {},
    reducers: {
        setAuth: (state, action) => {
            let auth = action.payload;
            if( auth && auth.expires_in ) {
                auth.expireAt = moment().add(auth.expires_in, 'second').format();
            }
            return auth
        },
        clearAuth: (state) => {
            return {loggedOut: true}
        }
    }
})

export const { setAuth, clearAuth } = authSlice.actions

export default authSlice.reducer
