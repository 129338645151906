import React, {useContext} from 'react';
import {AllowedContext} from "../form/form.service";

/**
 * Overrides AllowedForEnum.* values in allowedContext.
 * @param allowedFor
 * @param value AllowedForEnum
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
function AllowedContextAllowedFor({allowedFor, value, children}) {
	const allowedContext = useContext( AllowedContext )

	return (
        <AllowedContext.Provider value={ { ...allowedContext, [allowedFor]: value } }>
            { children }
        </AllowedContext.Provider>
	)
}

export default AllowedContextAllowedFor