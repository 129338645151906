import { configureStore } from '@reduxjs/toolkit'
import tourReducer from "./tour/tourSlice"
import bankToCustomerNotificationReducer from "./bankToCustomerNotification/bankToCustomerNotificationSlice"
import authReducer from "./security/authSlice"
import {combineReducers} from "redux";
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import tabReducer from "./security/tabSlice";
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from 'redux-persist'

const reducers = combineReducers({
	tour: tourReducer,
	bankToCustomerNotification: bankToCustomerNotificationReducer,
	auth: authReducer,
	tabId: tabReducer
});

const persistConfig = {
	key: 'root',
	storage: storage,
	stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
	blacklist: ['auth', 'tour', 'bankToCustomerNotification', 'tabId']
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
})

export default store
export const persistor = persistStore(store);
