import {useEffect, useState} from "react";
import restService from "../_services/rest.service";
import {apiClientPrivate} from "../api/apiClient";

function usePolicyTypes( ) {
	const [policyTypes, setPolicyTypes] = useState( [] );

	useEffect(() => {
		const controller = new AbortController()
		apiClientPrivate.get( `/api/configurator/getPolicyTypes`, {
			signal: controller.signal
		} )
			.then( r => restService.handleServerResponseAxios(r) )
			.then( data => {
				setPolicyTypes( data.policyTypes );
			} )
			.catch( error =>  restService.handleServerErrorsAxios( error, controller.signal ) );
		return function cleanup() {
			controller.abort();
		}
	}, []);

	return [policyTypes];
}

export default usePolicyTypes
