import React, {useMemo} from 'react';
import {useTranslation} from "react-i18next";
import AddConfiguratorTargetButton from "./AddConfiguratorTargetButton";

function ObligatoryElementTargets({element, addConfiguratorTarget, building}) {
    const {t} = useTranslation()

    const firstElementId = element.configuratorTargets && element.configuratorTargets.length === 1 && element.configuratorTargets[0].id
    const firstElementLabel = element.configuratorTargets && element.configuratorTargets.length === 1 && element.configuratorTargets[0].label
    const firstElementOptional = element.configuratorTargets && element.configuratorTargets.length === 1 && element.configuratorTargets[0].optional

    return useMemo( () => {
        if ( element.configuratorTargets.length === 1 ) {
            return <><AddConfiguratorTargetButton onClick={ addConfiguratorTarget } id={ firstElementId } building={building} optional={ firstElementOptional}/> <span>{firstElementLabel}</span></>
        }
        else {
            return <>
                <span> { t(`policyTargetCondition.operator.${element.operator.name}.configuratorTarget.tooltip`) } </span>
                <ul className={ "mb-0" }>
                    { element.configuratorTargets.map( ( configuratorTarget ) => {
                        return <li className={"mb-2"}><AddConfiguratorTargetButton onClick={ addConfiguratorTarget } id={ configuratorTarget.id } building={building} optional={ configuratorTarget.optional }/> { configuratorTarget.label }</li>
                    } ) }
                </ul>
            </>
        }
    }, [element.configuratorTargets, element.operator.name, t, addConfiguratorTarget, building, firstElementId, firstElementLabel, firstElementOptional])
}

export default ObligatoryElementTargets