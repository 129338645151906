import React from 'react';
import {Alert, Button, Col, ProgressBar, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function SettlementBatchProgress( { settlementBatch, settlementBatchUpdateState, restartSettlementBatches } ) {
	const {t} = useTranslation()

	const exceptionElement = () => {
		return (
			<Col>
				<Alert variant={"danger"}>
					<div>
						<p>{settlementBatch.exception}</p>
					</div>
					<div>
						<Button variant={"primary"} onClick={restartSettlementBatches}> { t('previewInvoiceBatch.restart') } </Button>
					</div>
				</Alert>
			</Col>
		)
	}

	const progressElement = () => {
		return (
			<Col sm={12} md={{span:8, offset:2}} lg={{span:6, offset:3}}>
				<Alert>
					<p>
						{t('previewInvoiceBatchProgress.progress.message')}
					</p>
					<div className={"mt-4"}>
						{ settlementBatchUpdateState.currentIdx > 0 ?
							<ProgressBar max={ settlementBatchUpdateState.totalCount }
							             now={ settlementBatchUpdateState.currentIdx }
							             label={ `${ settlementBatchUpdateState.currentIdx || '' } / ${ settlementBatchUpdateState.totalCount || '' }` }/>
							:
							<ProgressBar animated now={ 100 }/>
						}
					</div>
				</Alert>
			</Col>
		)
	}

	return (
		<Row className={"mt-5"}>
			{ settlementBatchUpdateState.currentIdx ? progressElement() : exceptionElement() }
		</Row>
	)
}

export default SettlementBatchProgress