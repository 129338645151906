import React, {useCallback, useContext, useEffect} from 'react';
import restService from "../../_services/rest.service";
import FormCheckControl from "../FormCheckControl";
import {RoleNodeTreeDispatchContext} from "./RoleNodeTreeContext";
import {findNode} from "./roleNodeTreeReducer";
import {useFormContext} from "react-hook-form";

function RoleGroupSelection( { nodes } ) {
    const dispatch = useContext(RoleNodeTreeDispatchContext);
    const { setValue } = useFormContext();
    const [allRoleGroups] = restService.useDomainInstancesList('roleGroup', undefined, undefined, 'name', undefined, undefined, undefined, "select");


    const updateRoleGroup = (target) => {
        let roleGroupId = target.name.split(".")[1]
        let foundRoleGroup = allRoleGroups.find( (roleGroup) => {
            let result = undefined
            if (roleGroup.id === parseInt(roleGroupId)) {
                result = roleGroup
            }
            return result
        })
        if ( foundRoleGroup ) {
            foundRoleGroup.roleGroupRoles.forEach((roleGroupRole) => {
                if ( target.checked ) {
                    dispatch({type:  'select', authority: roleGroupRole.authority});
                }
            })
        }
    }

    const isRoleGroupSelected = useCallback((name, roleGroupRoles ) => {
        //check if all roleGroupRoles are selected
        let selected = true;
        if ( roleGroupRoles ) {
            for ( let roleGroupRole of roleGroupRoles ) {
                let node = findNode( { roots: nodes }, roleGroupRole.authority )
                if ( !node.selected ) {
                    selected = false;
                    break
                }
            }
        }
        return selected
    }, [nodes])

    useEffect(() => {
        allRoleGroups.forEach( (roleGroup) => {
            let name = `selectedGroups.${roleGroup.id}`
            let selected = isRoleGroupSelected( name, roleGroup.roleGroupRoles)
            if ( !selected ) {
                setValue(name, selected)
            }
        })
    }, [nodes, allRoleGroups, isRoleGroupSelected, setValue])

    const renderGroupsCheckboxes = () => {
        if (allRoleGroups && allRoleGroups.length > 0) {
            return allRoleGroups.map( (roleGroup) => {
                let name = `selectedGroups.${roleGroup.id}`
                let selected = isRoleGroupSelected( name, roleGroup.roleGroupRoles)
                return (
                    <FormCheckControl
                        key={roleGroup.id}
                        name={name}
                        type={"checkbox"}
                        value={selected}
                        onChange={ (e) => {updateRoleGroup(e.target)}}
                        label={roleGroup.name}
                    />
                )
            })
        }
    }

    return (
        <>
            { renderGroupsCheckboxes() }
        </>
    )
}

export default RoleGroupSelection
