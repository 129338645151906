import React from 'react';
import DOMPurify from 'dompurify';

const DynamicText = ({ htmlContent, className }) => {
    const sanitizedHtmlContent = DOMPurify.sanitize(htmlContent);

    return (
        <span className={className}
            dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }}
        />
    );
};

export default DynamicText