import React from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import FormControl from "../_components/FormControl";
import {securityService} from "../_services/security.service";
import PlainValue from "../_components/PlainValue";
import EditableTableControl from "../form/EditableTableControl";
import restService from "../_services/rest.service";

function FormElements({domainInstance, defaultValues}) {
	const { t } = useTranslation();
	const hasAccessToRoleName = securityService.useAccessToProperty( "aclUserGroup.name" )
	const [users] = restService.useDomainInstancesList('user', 1, 10000, undefined, undefined, undefined, undefined, "select")
	const usersUniqueValidator = (( value, data, rowIndex ) => {
		if ( data[rowIndex].deleted ) return
		//check uniqueness
		if ( data.find( (item, index) => {
			if ( index !== rowIndex && item.user.id === value.id && !item.deleted) {
				return true
			}
			return false
		})) {
			return t('userAclUserGroup.unique.message')
		}
	})
	const usersColumns = [
		{
			id: "user",
			label: '' ,
			input: {
				tag: "typeahead",
				id: "user",
				options: users,
				required: true
			},
			validator: usersUniqueValidator,
			formatter: (row) => row.label
		},
	]

	const showRoleName = () => {
		if ( hasAccessToRoleName.ready ) {
			if ( !hasAccessToRoleName.write ) {
				return (
					<Form.Group as={Col} md="4" controlId="formAclUserGroupName">
						<Form.Label>{t('aclUserGroup.roleName.label')}</Form.Label>
						<PlainValue value={domainInstance.roleName}/>
					</Form.Group>
				)
			}
		}
	}

	return (
		<>
			<Card className={"mb-2"}>
				<Card.Header>{t('aclUserGroup.card.info')}</Card.Header>
				<Card.Body>
					<Row className={"mb-3"}>
						<Form.Group as={Col} md="4" controlId="formAclUserGroupName">
							<Form.Label>{t('aclUserGroup.name.label')}</Form.Label>
							<FormControl
								name={"name"}
								type={"text"}
								value={domainInstance.name || (defaultValues && defaultValues.name)}
								rules={ {
									required: true,
								} }
							/>
						</Form.Group>
						{showRoleName()}
					</Row>
				</Card.Body>
			</Card>
			<Card className={"mb-2"}>
				<Card.Header>{t('aclUserGroup.users')}</Card.Header>
				<Card.Body>
					<Row>
						<EditableTableControl
							name={"userAclUserGroups"}
							columns={usersColumns}
							data={domainInstance.userAclUserGroups}
						/>
					</Row>
				</Card.Body>
			</Card>
		</>
	);
}

export { FormElements };
