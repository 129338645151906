import React from 'react';
import {Badge, Button, CloseButton, Col, Row, Toast, ToastContainer} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCodeMerge} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import {useHistory} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";

function MergePopup( { domains, onClose, onRemove0, onRemove1 } ) {
	const history = useHistory()
	const {t} = useTranslation()

	if ( domains && domains.length >= 3 ) {
		throw new Error("At most two domains are allowed in MergePopup")
	}

	if ( domains && domains.length === 2 ) {
		if ( domains[0].classSimpleName !== domains[1].classSimpleName ) {
			throw new Error(`Both domains must be of same class. Classes '${domains[0].classSimpleName}}' and '${domains[1].classSimpleName}' found.`)
		}
	}

	const getNoDomainContent = () => {
		return <Trans
			i18nKey={'mergePopup.noDomain.content'}
			components={{ p: <p/>, FontAwesomeIcon: <FontAwesomeIcon icon={faCodeMerge}/> }}
		/>
	}

	const getOneDomainContent = () => {
		return <Trans
			i18nKey={'mergePopup.oneDomain.content'}
			components={ { p: <p/>, Badge: <Badge bg={'success'} />, CloseButton: <CloseButton onClick={onRemove0} /> } }
			tOptions={ { domain0: domains[0].label } }
		/>
	}

	const handleMerge = () => {
		history.push(`/merge?classSimpleName=${domains[0].classSimpleName}&idSource=${domains[0].id}&idTarget=${domains[1].id}`)
	}

	const getTwoDomainsContent = () => {
		return <>
			<Row>
				<Col>
					<Trans
						i18nKey={'mergePopup.twoDomains.content'}
						components={ { p: <p/>, Badge: <Badge bg={'success'}/>, CloseButton0: <CloseButton onClick={onRemove0}/>, CloseButton1: <CloseButton onClick={onRemove1}/>, Button: <Button onClick={ handleMerge } /> } }
						tOptions={ { domain0: domains[0].label, domain1: domains[1].label } }
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Button className={"mt-3"} onClick={ handleMerge } >{ t('default.next') }</Button>
				</Col>
			</Row>
			</>
	}

	const getContent = () => {
		if ( domains && domains.length === 2 ) {
			return getTwoDomainsContent()
		} else if ( domains && domains.length === 1 ) {
			return getOneDomainContent()
		}
		else {
			return getNoDomainContent()
		}
	}

	return (
		<ToastContainer
			position={'top-center'}
			style={{ zIndex: 1 }}
		>
			<Toast onClose={ onClose }>
				<Toast.Header>
					<FontAwesomeIcon icon={ faCodeMerge }/>
					<strong className="ms-1 me-auto"> Merge </strong>
				</Toast.Header>
				<Toast.Body>
					{ getContent() }
				</Toast.Body>
			</Toast>
		</ToastContainer>
	)
}

export default MergePopup

MergePopup.propTypes = {
	domains: PropTypes.array,
	onClose: PropTypes.func,
	onRemove0: PropTypes.func,
	onRemove1: PropTypes.func,
}











