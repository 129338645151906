import React from 'react';
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import FormControl from "../_components/FormControl";
import {FormProvider, useForm} from "react-hook-form";
import {policyService} from "../_services/policy.service";
import {useTranslation} from "react-i18next";
import appService from "../_services";

function InvoicedAppversPart() {
    const {t} = useTranslation();
    const useFormObject = useForm();

    appService.useTitle( t('reports.invoicedAppversPart.title') )

    const onSubmit = (formData) => {
        policyService.invoicedAppversPart(formData.dateFrom, formData.dateTo)
    };

	return (
        <>
            <h2>{t('reports.invoicedAppversPart.title')}</h2>

            <FormProvider {...useFormObject}>
                <Form onSubmit={useFormObject.handleSubmit(onSubmit)}>
                    <Card>
                        <Card.Header>
                            {t('default.filter')}
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Form.Group className="col-md-6" controlId="dateFrom">
                                    <Form.Label>{t('invoicedAppversPart.dateFrom.label')}</Form.Label>
                                    <FormControl
                                        name={"dateFrom"}
                                        type={"date"}
                                        required={true}
                                    />
                                </Form.Group>

                                <Form.Group className="col-md-6" controlId="dateTo">
                                    <Form.Label>{t('invoicedAppversPart.dateTo.label')}</Form.Label>
                                    <FormControl
                                        name={"dateTo"}
                                        type={"date"}
                                        required={true}
                                    />
                                </Form.Group>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Row className={"mt-3"}>
                        <Col md={2}>
                            <Button type={"submit"}>{ t('default.export') }</Button>
                        </Col>
                    </Row>
                </Form>
            </FormProvider>
        </>
	)
}

export default InvoicedAppversPart