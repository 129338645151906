import React, {useContext, useEffect, useMemo, useState} from 'react';
import {AllowedContext, AllowedForEnum} from "../form/form.service";
import appService from "../_services";
import restService from "../_services/rest.service";

function AllowedContextWrapper({children, domainName, id, allowShow, allowEdits, allowDeletions}) {
	const classSimpleName= appService.useClassSimpleNameAndIdFromRoute( domainName, id )
	const [object, setObject] = useState( undefined )

	const allowedContext = useContext(AllowedContext);

	useEffect( () => {
		if ( id ) { //if there is the id, then the object is fetched from the server
			if ( classSimpleName ) { //look for the domain instance not earlier than the classSimpleName is available
				restService.getDomainInstance( classSimpleName, id )
					.then( ( result ) => setObject( result ) )
			}
		}
		else if ( classSimpleName ) { //otherwise we use the classSimpleName and id from the route (we are probably in /create action)
			setObject( { classSimpleName: classSimpleName, id: id } )
		}
	}, [classSimpleName, id] )

	const _allowShow = allowShow !== undefined ? allowShow : ((allowedContext && allowedContext[AllowedForEnum.SHOW]) ? allowedContext[AllowedForEnum.SHOW] : undefined )
	const _allowEdits = allowEdits !== undefined ? allowEdits : ((allowedContext && allowedContext[AllowedForEnum.EDIT]) ? allowedContext[AllowedForEnum.EDIT] : undefined )
	const _allowDeletions = allowDeletions !== undefined ? allowDeletions : ((allowedContext && allowedContext[AllowedForEnum.DELETE]) ? allowedContext[AllowedForEnum.DELETE] : undefined )
	const _object = object !== undefined ? object : (allowedContext && allowedContext['data'])

	const value = useMemo( () => {
		let result = {}

		if ( _allowShow !== undefined ) {
			result[AllowedForEnum.SHOW] = _allowShow
		}

		if ( _allowEdits !== undefined  ) {
			result[AllowedForEnum.EDIT] = _allowEdits
		}

		if ( _allowDeletions !== undefined  ) {
			result[AllowedForEnum.DELETE] = _allowDeletions
		}

		if ( _object !== undefined ) {
			result.data = _object
		}

		// console.log('AllowedContextWrapper', result)

		return result
	}, [_object, _allowShow, _allowEdits, _allowDeletions] )

	return (
		<>
			{/*{ <p>AllowedContextWrapper: { JSON.stringify(value) }</p> }*/}
			{ _object &&
				<AllowedContext.Provider value={ value }>
			        { children }
		        </AllowedContext.Provider>
			}
		</>
	)
}

export default AllowedContextWrapper