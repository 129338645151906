import React, {useEffect, useMemo, useState} from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import FormControl from "../_components/FormControl";
import {RestAsyncSelectControl} from "../_components/RestAsyncSelectControl";
import moment from "moment";
import {useFormContext} from "react-hook-form";
import textService from "../_services/text.service";
import {invoiceService} from "../_services/invoice.service";

function FormElements({domainInstance}) {
	const { t } = useTranslation();
	const { setValue, register} = useFormContext()
	const [pairedWithBankPayment, setPairedWithBankPayment] = useState( false )
	const [amount, setAmount] = useState( 0 )

	useEffect (() => {
		if ( domainInstance && domainInstance.bankPayment && domainInstance.bankPayment.id ) {
			setPairedWithBankPayment(true)
		}
		if ( domainInstance && domainInstance.amount ) {
			setAmount( domainInstance.amount )
		}
	}, [domainInstance])

	const showBankPaymentLabel = (option) => {
		return (
			<span>
				{option.refNr}, {option.id}, {textService.formatDateTime(Date.parse(option.amountDate),{dateStyle: 'medium'})}, {option.amount}
			</span>
		)
	}

	const handleInvoiceChange = (value) => {
		let calculatedAmount = value.total - value.paymentTotal
		setAmount( calculatedAmount.toFixed(2) )
		setValue( 'amount', calculatedAmount.toFixed(2) )
	}

	const amountDate = useMemo( () => {
		if ( domainInstance && domainInstance.amountDate ) {
			return moment(domainInstance.amountDate).format('YYYY-MM-DD')
		}
		else {
			return moment().format('YYYY-MM-DD')
		}
	}, [domainInstance] )

	const renderAmount = () => {
		return (
			<FormControl
				name={"amount"}
				type={"text"}
				value={amount}
				rules={{
					required: true
				}}
			/>
		)
	}

	const renderInvoiceData = () => {
		if ( !pairedWithBankPayment ) {
			return (
				<RestAsyncSelectControl
					domainName={"invoice"}
					value={domainInstance && domainInstance.invoice}
					name={'invoice'}
					onGetOptionLabel={invoiceService.showInvoiceLabel}
					onChange={handleInvoiceChange}
					rules={{
						required: true
					}}
				/>
			)
		}
		else if ( domainInstance && domainInstance.invoice ) {
			return (
				<div>
					<Form.Control
						hidden={true}
						{...register('invoice.id')}
						readOnly={true}
						value={domainInstance && domainInstance.invoice.id}
					/>
					{invoiceService.showInvoiceLabel(domainInstance.invoice)}
				</div>
			)
		}
	}

	const renderBankPaymentInfo = () => {
		if ( pairedWithBankPayment ) {
			return (
				<Row className={"mb-3"}>
					<Form.Control {...register("bankPayment.id")} hidden={true} value={domainInstance.bankPayment.id} readOnly={true}/>
					<Form.Group as={Col} md="" controlId="bankPaymentInfo">
						<Form.Label>{t('manualPayment.bankPayment')}</Form.Label>
						<div className={"fw-bold"}>
							{showBankPaymentLabel(domainInstance.bankPayment)}
						</div>
					</Form.Group>
				</Row>
			)
		}
	}

	return (
		<>
			{ domainInstance && <Card className={"mb-2"}>
				<Card.Header>{t('manualPayment.card.info')}</Card.Header>
				<Card.Body>
					<Row className={"mb-3"}>
						<Form.Group as={Col} md="4" controlId="formGroupAmountDate">
							<Form.Label>{t('payment.amountDate')}</Form.Label>
							<FormControl
								name={"amountDate"}
								type={"date"}
								value={amountDate}
								rules={{
									required: true
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} md="8" controlId="formGroupNote">
							<Form.Label>{t('manualPayment.note')}</Form.Label>
							<FormControl
								name={"note"}
								type={"text"}
								value={domainInstance.note}
								rules={{
									required: true
								}}
							/>
						</Form.Group>
					</Row>
					{renderBankPaymentInfo()}
					<Row className={"mb-3"}>
						<Form.Group as={Col} md="9" controlId="formGroupRefNr">
							<Form.Label>{t('payment.invoice')}</Form.Label>
							{ renderInvoiceData() }
						</Form.Group>
						<Form.Group as={Col} md="3" controlId="formGroupAmount">
							<Form.Label>{t('payment.amount')}</Form.Label>
							{ renderAmount() }
						</Form.Group>
					</Row>
				</Card.Body>
			</Card>}
		</>
	);
}

export { FormElements };
