import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AddEditForm from "../form/AddEditForm";
import ListForm from "./ListForm";
import textService from "../_services/text.service";
import AllowedContextWrapper from "../_components/AllowedContextWrapper";

function Index(props) {
	const path = props.path;

	function AddEdit(props) {
		return (
			<AddEditForm
				domainName={props.domainName}
				instanceToString={(data) => props.instanceToString(data, props.domainName, props.toString)}
				beforeSubmit={props.beforeSubmit}
				afterCreate={props.afterCreate}
				afterUpdate={props.afterUpdate}
				routeParams={props.routeParams}
			>
				{ props.formElements }
			</AddEditForm>
		)
	}

	function List(props) {
		return (
			<>
				<ListForm
					instanceToString={(data) => props.instanceToString(data, props.domainName, props.toString)}
					allowEdits={props.allowEdits}
					allowDeletions={props.allowDeletions}
					allowShow={props.allowShow}
					actionIdColumn={props.actionIdColumn}
					additionalButtons={props.additionalButtons}
					columns={props.columns}
					domainName={props.domainName}
					sortField={props.sortField}
					sortOrder={props.sortOrder}
					addButtons={props.addButtons}
					getEditUrlParams={props.getEditUrlParams}
					expandRow={props.expandRow}
					rowStyle={props.rowStyle}
					getIndexData={props.getIndexData}
					pagination={props.pagination}
				/>
			</>
		);
	}

	const getWrappedWithAllowedContext = (element, domainName, id, allowShow, allowEdits, allowDeletions ) => {
		return (
			<AllowedContextWrapper domainName={domainName} id={id} allowShow={allowShow} allowEdits={allowEdits} allowDeletions={allowDeletions}>
				{ element }
			</AllowedContextWrapper>
		)
	}

	const getDomainName = (props, routeComponentProps) => {
		let result

		if ( routeComponentProps.match && routeComponentProps.match.params && routeComponentProps.match.params.domainName ) {
			result = routeComponentProps.match.params.domainName
		}
		else {
			result = props.domainName
		}

		return result
	}

	const createElement = (routeComponentProps) => {
		const domainName = getDomainName( props, routeComponentProps )

		const getElement = () => {
			if ( props.createElement ) {
				return React.cloneElement( props.createElement, { routeParams: routeComponentProps.match.params } )
			} else {
				return ( <AddEdit domainName={ domainName } formElements={ props.formElements }
				                  instanceToString={ textService.objectToString } toString={ props.toString }
				                  beforeSubmit={ props.beforeSubmit }
				                  routeParams={ routeComponentProps.match.params }
				                  afterCreate={ props.afterCreate }
				                  afterUpdate={ props.afterUpdate }
				/> )
			}
		}



		return getWrappedWithAllowedContext( getElement(), domainName, null, props.allowShow, props.allowEdits, props.allowDeletions  )
	}

	const editElement = (routeComponentProps, wrappedWithAllowedContext = true) => {
		const domainName = getDomainName( props, routeComponentProps )

		const getElement = () => {
			if ( props.editElement ) {
				return React.cloneElement( props.editElement, { routeParams: routeComponentProps.match.params } )
			} else {
				return <AddEdit domainName={ domainName } formElements={ props.formElements }
				                instanceToString={ textService.objectToString } toString={ props.toString }
				                beforeSubmit={ props.beforeSubmit } routeParams={ routeComponentProps.match.params }
				                afterCreate={ props.afterCreate } afterUpdate={ props.afterUpdate }
				/>
			}
		}

		let result

		if ( wrappedWithAllowedContext ) {
			result = getWrappedWithAllowedContext( getElement(), domainName, routeComponentProps.match.params.id, props.allowShow, props.allowEdits, props.allowDeletions )
		}
		else {
			result = getElement()
		}

		return result
	}

	const showElement = (routeComponentProps) => {
		const domainName = getDomainName( props, routeComponentProps )

		let element
		let allowEdits
		if ( props.showElement ) {
			element = React.cloneElement( props.showElement, { id: routeComponentProps.match.params.id } )
			allowEdits = props.allowEdits
		} else {
			element = editElement( routeComponentProps, false )
			allowEdits = false
		}

		return getWrappedWithAllowedContext( element, domainName, routeComponentProps.match.params.id, props.allowShow, allowEdits, props.allowDeletions  )
	}

	return (
		<Switch>
			<Route exact path={path} render={() => (
				<List domainName={props.domainName} columns={props.columns} actionIdColumn={props.actionIdColumn}
					  instanceToString={textService.objectToString} toString={ props.toString } sortField={props.sortField}
					  sortOrder={props.sortOrder} allowEdits={props.allowEdits} allowDeletions={props.allowDeletions}
					  allowShow={props.allowShow} addButtons={props.addButtons}
					  additionalButtons={props.additionalButtons} getEditUrlParams={props.getEditUrlParams}
					  expandRow={props.expandRow} rowStyle={props.rowStyle} getIndexData={props.getIndexData} pagination={props.pagination}/>
			)}/>
			<Route path={`${path}/create/:parentId?`} render={(routeComponentProps) => (
				createElement(routeComponentProps)
			)}/>
			<Route path={`${path}/edit/:id`} render={(routeComponentProps) => (
				editElement(routeComponentProps)
			)}/>
			<Route path={`${path}/show/:id`} render={(routeComponentProps) => (
				showElement(routeComponentProps)
			)}/>
		</Switch>
	);
}

export default Index;
