import React, {useCallback, useEffect, useMemo, useState} from "react";
import ShowField from "../form/ShowField";
import {Alert, Button, Card, Col, Form, Modal, Row, Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {PolicyTargets} from "./PolicyTargets";
import {PolicyVersionStatus} from "../_enum/enum";
import {policyService} from "../_services/policy.service";
import moment from "moment";
import restService from "../_services/rest.service";
import {Dropdown} from "../_components/Dropdown";
import ActivateDeactivateLastSettlement from "./ActivateDeactivateLastSettlement";
import {Link, useHistory} from "react-router-dom";
import ReinsuranceRow from "./ReinsuranceRow";
import {RestAsyncSelectControl} from "../_components/RestAsyncSelectControl";
import {messageBoxService} from "../_services/messageBox.service";
import {MessageBoxButtons} from "../_components/MessageBox";
import {alertService} from "../_services";
import FormControl from "../_components/FormControl";
import textService from "../_services/text.service";
import {configuratorService} from "../_services/configurator.service";
import Acl from "../_components/Acl";
import Secured from "../_components/Secured";
import {AllowedForEnum} from "../form/form.service";
import Allowed from "../_components/Allowed";
import DisplayIfAnyChildPropertyPresent from "../form/DisplayIfAnyChildPropertyPresent";
import EditableInAllowedContext from "../_components/EditableInAllowedContext";
import {securityService} from "../_services/security.service";
import ToBeActivated from "./ToBeActivated";
import ActivateCheckMissingRV from "./ActivateCheckMissingRV";

function PreviewPolicyVersionBase({ policyVersion, setUpdatedPolicyVersion, setPolicyVersion, savePolicyVersionValue }) {
	const ActivateDeactivate = Object.freeze({
		ACTIVATE:'activate',
		DEACTIVATE:'deactivate',
	});
	const {t} = useTranslation();
	const [activateDeactivate, setActivateDeactivate] = useState(ActivateDeactivate.ACTIVATE);
	const [showActivateDeactivateConfirm, setShowActivateDeactivateConfirm] = useState(undefined);
	const [activateValidFrom, setActivateValidFrom] = useState(undefined);
	const [deactivateValidTo, setDeactivateValidTo] = useState(moment().format( 'YYYY-MM-DD' ))
	const [policyCancellationReason, setPolicyCancellationReason] = useState(moment().format( 'YYYY-MM-DD' ))
	const [productTypes] = restService.useDomainInstancesList('productType');
	const [cancelLastInvoice, setCancelLastInvoice] = useState(false);
	const history = useHistory();
	const hasAccessToActivatePolicyVersion = securityService.useGranted( `/api/policy/activatePolicyVersion` )
	const [showToBeActivated, setShowToBeActivated] = useState(false);

	const reinsuranceRates = useMemo( () => {
		let result = {};

		if ( productTypes &&  productTypes.length > 0 ) {
			productTypes.forEach( (productType) => {
				if ( productType.separateReinsurance ) {
					result[ productType.id ] = productType.reinsuranceRates;
				}
			});
		}

		return result;
	}, [productTypes]);

	useEffect( ()=> {
		if ( !activateValidFrom && policyVersion.validFrom ) {
			setActivateValidFrom( moment() < moment( policyVersion.validFrom ) ? policyVersion.validFrom : moment().format( 'YYYY-MM-DD' ) );
		}
	}, [activateValidFrom, setActivateValidFrom, policyVersion.validFrom]);

	const onSubmitToBeActivated = (data) => {
		return new Promise( (resolve, reject) => {
			policyService.setPolicyVersionToBeActivated( policyVersion.id, data.fileChanges )
				.then( (pv) => {
					setUpdatedPolicyVersion(pv)
					setShowToBeActivated(false)
					resolve()
				})
				.catch( (error) => {
					reject(error)
				} )
		} )
	}

	const getChangeStateButtons = () => {
		const handleDeactivate = () => {
			setActivateDeactivate(ActivateDeactivate.DEACTIVATE);
			setShowActivateDeactivateConfirm(true);
		}

		const handleActivate = () => {
			setActivateDeactivate(ActivateDeactivate.ACTIVATE);
			setShowActivateDeactivateConfirm(true);
		}

		const handleToBeActivated = () => {
			setShowToBeActivated(true);
		}

		const handleRevertPolicyVersionActivation = () => {
			messageBoxService.display( t('policyVersion.revertActivation.message'), t('policyVersion.revertActivation.label'), [MessageBoxButtons.YES, MessageBoxButtons.NO], {headerClassName: 'bg-danger text-white h5'} )
				.then( (button) => {
					if ( button === MessageBoxButtons.YES ) {
						policyService.revertPolicyVersionActivation( policyVersion.id ).then( (pv) => {
							setUpdatedPolicyVersion(pv)
						})
					}
				})
		}

		const handleRevertPolicyVersionDeactivation = () => {
			messageBoxService.display( t('policyVersion.revertDeactivation.message'), t('policyVersion.revertDeactivation.label'), [MessageBoxButtons.YES, MessageBoxButtons.NO], {headerClassName: 'bg-danger text-white h5'} )
				.then( (button) => {
					if ( button === MessageBoxButtons.YES ) {
						policyService.revertPolicyVersionDeactivation( policyVersion.id ).then( (pv) => {
							setUpdatedPolicyVersion(pv)
						})
					}
				})
		}

		const handleDelete = () => {
			const content = <Alert variant={"danger"}>{t('previewPolicyVersion.delete.message', {number: policyVersion.policyNumber, policyHolder: policyVersion.policyHolder.label })}</Alert>

			messageBoxService.display(content, t('previewPolicyVersion.delete.title'), [MessageBoxButtons.YES, MessageBoxButtons.NO], {headerClassName: 'bg-danger text-white h5'})
				.then( (button) => {
					if ( button === MessageBoxButtons.YES ) {
						policyService.deletePolicyVersion(policyVersion.id)
							.then( () => {
								alertService.success(t("default.deleted", {what: t('policyVersion.label')}), { keepAfterRouteChange: true });
								history.push('/policy');
							});
					}
				});
		}

		const getDeactivateButton = () => {
			return <Button variant={"warning"} type={"button"} size={"sm"} onClick={handleDeactivate}>{t('policyVersion.deactivate.label')}</Button>
		}
		const getDeleteButton = () => {
			return <Button variant={"danger"} type={"button"} size={"sm"} onClick={handleDelete}>{t('policyVersion.delete.label')}</Button>
		}

		const getRevertPolicyVersionActivationButton = () => {
			return <Button variant={"danger"} type={"button"} size={"sm"} onClick={handleRevertPolicyVersionActivation}>{t('policyVersion.revertActivation.label')}</Button>
		}

		const getRevertPolicyVersionDeactivationButton = () => {
			return <Button variant={"danger"} type={"button"} size={"sm"} onClick={handleRevertPolicyVersionDeactivation}>{t('policyVersion.revertDeactivation.label')}</Button>
		}

		switch( policyVersion.state ) {
			case PolicyVersionStatus.TO_BE_ACTIVATED:
				return (
					<>
						<Form.Group as={Col} md={12}>
							{ ( hasAccessToActivatePolicyVersion.ready && hasAccessToActivatePolicyVersion.value ) ?
								<Secured granted={`/api/policyVersion/${ policyVersion.id }/edit#GET`}>
									<Link className={`btn btn-sm btn-warning`} to={`/policyVersion/edit/${policyVersion.id}`}>
										{t('default.edit')}
									</Link>
								</Secured>
								:
								<Alert variant={"warning"} className={"p-2"}> {t('previewPolicyVersionBase.toBeActivated.tooltip')} </Alert>
							}

							<Secured granted={ [`/api/policyVersion/${ policyVersion.id }/edit#GET`, `/api/policy/activatePolicyVersion`] }>
								<Button className={"ms-3"} variant={"success"} type={"button"} size={"sm"} onClick={handleActivate}>{t('policyVersion.activate.label')}</Button>
								<span className={"ms-3"}>
									{getDeactivateButton()}
								</span>
							</Secured>

							<Secured granted={`/api/policyVersion/${ policyVersion.id }#DELETE`}>
								<span className={"ms-3"}>
									{getDeleteButton()}
								</span>
							</Secured>
						</Form.Group>
					</>
				)
			case PolicyVersionStatus.IN_PROCESS:
				return (
					<>
						<Form.Group as={Col} md={12}>
							<Secured granted={`/api/policyVersion/${ policyVersion.id }/edit#GET`}>
								<Link className={`btn btn-sm btn-warning`} to={`/policyVersion/edit/${policyVersion.id}`}>
									{t('default.edit')}
								</Link>
							</Secured>

							{ ( hasAccessToActivatePolicyVersion.ready && hasAccessToActivatePolicyVersion.value ) ?
								<>
									<Button className={ "ms-3" } variant={ "success" } type={ "button" } size={ "sm" }
									        onClick={ handleActivate }>{ t( 'policyVersion.activate.label' ) }</Button>
									<span className={ "ms-3" }>
										{ getDeactivateButton() }
									</span>
								</>
								:
								<Button className={"ms-3"} variant={"success"} type={"button"} size={"sm"} onClick={handleToBeActivated}>{t('policyVersion.toBeActivated.label')}</Button>
							}
							<Secured granted={`/api/policyVersion/${ policyVersion.id }#DELETE`}>
								<span className={"ms-3"}>
									{getDeleteButton()}
								</span>
							</Secured>
						</Form.Group>
					</>
				)
			case PolicyVersionStatus.IN_PROCESS_CANCELLED:
				return (
					<Secured granted={`/api/policyVersion/${ policyVersion.id }#DELETE`}>
						<Form.Group as={Col} md={12}>
							<span className={"ms-3"}>
								{getDeleteButton()}
							</span>
						</Form.Group>
					</Secured>
				)
			case PolicyVersionStatus.ACTIVE:
				return (
					<>
						<Form.Group as={ Col } md={ 12 }>
							<Secured granted={'/api/policyVersion/create'}>
								<Link className="btn btn-sm btn-success"
								      to={ `/policyVersion/create/${ policyVersion.policy.id }` }>
									{ t( 'policyVersion.createNewVersion.label' ) }
								</Link>
							</Secured>

							<Secured granted={'/api/policy/deactivatePolicyVersion'}>
								<span className={ "ms-3" }>
									{ getDeactivateButton() }
								</span>
							</Secured>

							<Secured granted={'/api/policy/revertPolicyVersionActivation'}>
								<span className={ "ms-3" }>
									{ getRevertPolicyVersionActivationButton() }
								</span>
							</Secured>
						</Form.Group>
					</>
				)
			case PolicyVersionStatus.INACTIVE:
				return (
					<Secured granted={'/api/policy/revertPolicyVersionDeactivation'}>
						<Form.Group as={ Col } md={ 12 }>
							<span className={ "ms-3" }>
								{ getRevertPolicyVersionDeactivationButton() }
							</span>
						</Form.Group>
					</Secured>
				)
			default:
				return undefined;
		}
	}

	const handleCloseActivateDeactivateConfirm = () => {
		setShowActivateDeactivateConfirm(false);
	}

	const handleActivateDeactivate = () => {
		var params = {
			policyVersionId: policyVersion.id,
			cancelLastInvoice: cancelLastInvoice,
		}
		switch(activateDeactivate) {
			case ActivateDeactivate.ACTIVATE:
				params.validFrom = activateValidFrom;
				break;
			case ActivateDeactivate.DEACTIVATE:
				params.validTo = deactivateValidTo;
				params.policyCancellationReason = policyCancellationReason
				break;
			default:
		}

		policyService.activateDeactivate( activateDeactivate, params ).then( (pv) => {
			setUpdatedPolicyVersion(pv)
		})
		setShowActivateDeactivateConfirm( false );
	}

	const handlePolicyCancellationOnChange = (value) => {
		setPolicyCancellationReason(value)
	}

	const getInactiveTargets = useCallback( () => {
		let result = []

		if ( policyVersion && policyVersion.policyTargets ) {
			result = policyVersion.policyTargets.filter( ( target ) => configuratorService.isObsolete( target.validFrom, target.validTo, activateValidFrom ) )
		}

		return result;
	}, [activateValidFrom, policyVersion] )

	const activationWarning = useMemo( () => {
		if ( policyVersion && policyVersion.policyTargets ) {
			const inactiveTargets = getInactiveTargets()

			if ( inactiveTargets.length > 0 ) {
				return (
					<Card className={ "mt-3" } border={"danger"}>
						<Card.Header className="bg-danger">{t('previewPolicyVersion.activationWarning.header')}</Card.Header>
						<Card.Body>
							<Card.Text>
								{ t('previewPolicyVersion.activationWarning.text1', {date: textService.formatDateTime( moment(activateValidFrom), {dateStyle: 'medium'})} ) }
								<ul>
									{ inactiveTargets.map( ( target ) => {
										return (
											<li key={target.id}>
												{ t('previewPolicyVersion.activationWarning.li', { tariff: target.pathAndNameAsText, amount: textService.formatNumber(target.amount, {thousandSeparator: true, minimumFractionDigits: 0}) } ) }
											</li>
										)
									})}
								</ul>
								{ t('previewPolicyVersion.activationWarning.text2') }
							</Card.Text>
						</Card.Body>
					</Card>
				)
			}
			else {
				return undefined;
			}
		}
	}, [getInactiveTargets, activateValidFrom, policyVersion, t] )

	const getBaseData = () => {
		return (
			<>
				<Card className={"mb-2"}>
					<Card.Header>{t('createPolicy.policy.validity.header')}</Card.Header>
					<Card.Body>
						<Row>
							<ShowField label={'createPolicy.version.state'} object={policyVersion} property={'state'} type={'appvers.enums.PolicyVersionStatus'} size={4}/>
							{ !PolicyVersionStatus.isInProcess( policyVersion.state ) &&
								<>
									<ShowField label={ 'createPolicy.version.validFrom' } object={ policyVersion }
									           property={ 'validFrom' } type={ 'date' } size={ 4 }/>
									{ policyVersion.validTo ?
										<ShowField label={ 'createPolicy.version.validTo' } object={ policyVersion }
										property={ 'validTo' } type={ 'date' } size={ 4 }/>
										:
										<ShowField label={ 'createPolicy.version.validTo' } object={ policyVersion }
										property={ 'validToCalculated' } type={ 'date' } size={ 4 } appendix={ ' (' + t('default.calculated') + ')' }/>
									}
								</>
							}
						</Row>
						<Row>
							{ getChangeStateButtons() }
						</Row>

						{ PolicyVersionStatus.isInProcess( policyVersion.status ) &&
							<Row>
								<ShowField label={ 'createPolicy.version.offerValidTo.label' } object={ policyVersion }
								           property={ 'offerValidTo' } type={ 'date' } size={ 4 }/>
							</Row>
						}
					</Card.Body>
				</Card>
				<Modal show={showActivateDeactivateConfirm} onHide={handleCloseActivateDeactivateConfirm} animation={false}>
					<Modal.Header closeButton className={(activateDeactivate === ActivateDeactivate.ACTIVATE ? 'bg-success': 'bg-danger') + ' text-white'}>
						<Modal.Title>{t(`policyVersion.dialog.${activateDeactivate}.header`)}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div>
							{t(`policyVersion.dialog.${activateDeactivate}.question`, {policyType: policyVersion.policyType, policyNumber: policyVersion.policyNumber, policyHolder: policyVersion && policyVersion.policyHolder && policyVersion.policyHolder.label})}
						</div>
						<Form onSubmit={handleActivateDeactivate}>
							{ activateDeactivate === ActivateDeactivate.ACTIVATE &&
								<>
									<Form.Group as={Row} className="mt-3" controlId="activateValidFrom">
										<Form.Label column sm={4}>{t('createPolicy.version.validFrom')}</Form.Label>
										<Col sm={8}>
											<Form.Control
												name={"activateValidFrom"}
												type={"date"}
												value={activateValidFrom}
												onChange={ (event) => setActivateValidFrom(event.target.value) }
											/>
										</Col>
									</Form.Group>

									<ActivateDeactivateLastSettlement
										policyVersionId={policyVersion && policyVersion.previousPolicyVersionId}
										validTo={moment(Date.parse(activateValidFrom)).subtract(1, 'd')}
										cancelLastInvoice={ cancelLastInvoice }
										setCancelLastInvoice={ setCancelLastInvoice }
									/>
									{ activationWarning }
									<ActivateCheckMissingRV policyVersionId={policyVersion && policyVersion.id}/>
								</>
							}
							{ activateDeactivate === ActivateDeactivate.DEACTIVATE &&
								<>
									{ policyVersion.state === PolicyVersionStatus.ACTIVE &&
										<Form.Group as={Row} className="mt-3" controlId="deactivateValidTo">
											<Form.Label column sm={4}>{t('createPolicy.version.validTo')}</Form.Label>
											<Col sm={8}>
												<Form.Control
													name={"deactivateValidTo"}
													type={"date"}
													value={deactivateValidTo}
													onChange={ (event) => setDeactivateValidTo(event.target.value) }
													required={true}
												/>
											</Col>
										</Form.Group>
									}
									<Form.Group as={Row} className="mt-3" controlId="deactivatePolicyCancellationReason">
										<Form.Label column sm={4}>{t('createPolicy.version.policyCancellationReason')}</Form.Label>
										<Col sm={8}>
											<Dropdown
												name={ "policyCancellationReason" }
												optionsDomainName={ "policyCancellationReason" }
												keyPropName={ 'id' }
												valuePropName={ 'label' }
												value={ policyCancellationReason }
												nullable={ true }
												onChange={event => handlePolicyCancellationOnChange(event.target.value)}
												required={true}
											/>
										</Col>
									</Form.Group>
									<ActivateDeactivateLastSettlement
										policyVersionId={policyVersion && policyVersion.id}
										validTo={moment(Date.parse(deactivateValidTo))}
										cancelLastInvoice={ cancelLastInvoice }
										setCancelLastInvoice={ setCancelLastInvoice }
									/>
								</>
							}
							<div className={"mt-5"}>
								{/*{ getInactiveTargets().length === 0 &&*/}
									<Button variant="primary" type={"submit"}>
										{t(`policyVersion.${activateDeactivate}.label`)}
									</Button>
								{/*}*/}
								<Button className={"ms-2"} variant="secondary" onClick={handleCloseActivateDeactivateConfirm}>
									{t('default.cancel')}
								</Button>
							</div>
						</Form>
					</Modal.Body>
				</Modal>
			</>
		)
	}

	const getLeadingData = () => {
		if ( undefined === policyVersion || !policyVersion.policy ) return;
		let otherLeadershipText;
		if ( policyVersion.otherLeadership ) {
			otherLeadershipText = t("createPolicy.version.otherLeadership.onlabel");
		}
		else {
			otherLeadershipText = t("createPolicy.version.otherLeadership.offlabel")
		}
		let collectivePolicyText;
		if ( policyVersion.collectivePolicy ) {
			collectivePolicyText = t("createPolicy.version.collectivePolicy.onlabel")
		}
		else {
			collectivePolicyText = t("createPolicy.version.collectivePolicy.offlabel")
		}
		return (
			<DisplayIfAnyChildPropertyPresent>
				<Card className={"mb-2"}>
					<Card.Header>{t('createPolicy.policy.leadingData.header')}</Card.Header>
					<Card.Body>
						<Row>
							<Col md={4}>
								<Allowed allowedFor={AllowedForEnum.SHOW} propertyName={"otherLeadership"} >
									<div className={'fw-bold'}>
										{otherLeadershipText}
									</div>
								</Allowed>
							</Col>
							<Col md={4}>
								<Allowed allowedFor={AllowedForEnum.SHOW} propertyName={"collectivePolicy"} >
									<div className={'fw-bold'}>
										{collectivePolicyText}
									</div>
								</Allowed>
							</Col>
						</Row>
						{policyVersion.otherLeadership && <Row>
							<Allowed allowedFor={AllowedForEnum.SHOW} propertyName={"leadingCompany"} >
								<ShowField label={'createPolicy.version.leadingCompany.label'} object={policyVersion} property={'leadingCompany.label'} type={'text'} size={8}/>
							</Allowed>
							<Allowed allowedFor={AllowedForEnum.SHOW} propertyName={"collectivePolicyNr"} >
								<ShowField label={'createPolicy.version.collectivePolicyNr.label'} object={policyVersion} property={'collectivePolicyNr'} type={'text'} size={4}/>
							</Allowed>
						</Row>}
					</Card.Body>
				</Card>
			</DisplayIfAnyChildPropertyPresent>
		)
	}

	const getProfitParticipationData = () => {
		if ( undefined === policyVersion || !policyVersion.policy ) return;
		let extraParticipationText;
		if ( policyVersion.profitParticipation ) {
			extraParticipationText = t("createPolicy.version.profitParticipation.onlabel");
		}
		else {
			extraParticipationText = t("createPolicy.version.profitParticipation.offlabel")
		}
		return (
			<DisplayIfAnyChildPropertyPresent>
				<Card className={"mb-2"}>
					<Card.Header>{t('createPolicy.version.profitParticipation.header')}</Card.Header>
					<Card.Body>
						<Row>
							<Col md={6}>
								<Allowed allowedFor={AllowedForEnum.SHOW} propertyName={"profitParticipation"} >
									<div className={'fw-bold'}>
										{extraParticipationText}
									</div>
								</Allowed>
							</Col>
						</Row>
						<Allowed allowedFor={AllowedForEnum.SHOW} propertyName={"profitParticipationAnsatz"} >
							{policyVersion.profitParticipation && <Row>
								<ShowField label={'createPolicy.version.groupProfitParticipationAnsatz.label'} object={policyVersion} property={'profitParticipationAnsatz'} type={'text'} size={8}/>
							</Row>}
						</Allowed>
					</Card.Body>
				</Card>
			</DisplayIfAnyChildPropertyPresent>
		)
	}

	const getBrokerData = () => {
		if ( undefined === policyVersion || !policyVersion.policy ) return;
		return (
			<DisplayIfAnyChildPropertyPresent>
				<Card className={"mb-2"}>
					<Card.Header>{policyVersion.broker ? t('createPolicy.version.broker.label') : t('createPolicy.version.consultant.label')}</Card.Header>
					<Card.Body>
						<Row>
							{ policyVersion.broker &&
								<Allowed key={'broker'} allowedFor={AllowedForEnum.SHOW} propertyName={"broker"} >
									<ShowField object={policyVersion} property={'broker.label'} type={'text'} size={8} link={{domain:'broker', action:'show', newTab:true}}/>
									<ShowField label={'createPolicy.version.brokerInterestRate.label'} object={policyVersion} property={'brokerInterestRate'} type={'text'} size={4}/>
								</Allowed>
							}
							{ policyVersion.partnerConsultantScope &&
								<Allowed key={'partnerConsultantScope'} allowedFor={AllowedForEnum.SHOW} propertyName={"partnerConsultantScope"} >
									<ShowField object={policyVersion} property={'partnerConsultantScope.label'} type={'text'} size={12} editable={{
										editFormElement:
											<RestAsyncSelectControl
												domainName={"partnerConsultantScope"}
												sort={"name_sort"}
												value={policyVersion.partnerConsultantScope && policyVersion.partnerConsultantScope.id}
												label={policyVersion.partnerConsultantScope && policyVersion.partnerConsultantScope.label}
												name={'partnerConsultantScope'}
												rules={{
													required: true
												}}
											/>,
										onSave: (formData) => savePolicyVersionValue({partnerConsultantScope: formData.partnerConsultantScope.id}),
										allowedContextPropertyName: 'partnerConsultantScope'
									}}/>
								</Allowed>
							}
						</Row>
					</Card.Body>
				</Card>
			</DisplayIfAnyChildPropertyPresent>
		)
	}

	const getAssistanceData = () => {
		if ( undefined === policyVersion || !policyVersion.policy ) return;
		return (
			<Allowed allowedFor={AllowedForEnum.SHOW} propertyName={"assistancePolicyNr"} >
				<Card className={"mb-2"}>
					<Card.Header>{t('createPolicy.version.assistance.header')}</Card.Header>
					<Card.Body>
						<Row>
							<ShowField label={'createPolicy.version.assistancePolicyNr.label'} object={policyVersion} property={'assistancePolicyNr'} type={'text'} size={12} editable={{
								editFormElement:
									<FormControl
										name={"assistancePolicyNr"}
										type={"text"}
										value={policyVersion.assistancePolicyNr}
									/>,
								onSave: (formData) => savePolicyVersionValue({assistancePolicyNr: formData.assistancePolicyNr}),
								allowedContextPropertyName: 'assistancePolicyNr'
							}} />
						</Row>
					</Card.Body>
				</Card>
			</Allowed>
		)
	}

	const onChanged = useCallback( () => {
		restService.getDomainInstance('policyVersion', policyVersion.id)
			.then( (domain) => setPolicyVersion( domain ) )
	}, [policyVersion.id, setPolicyVersion] )

	const getPolicyTargets = () => {
		if ( undefined === policyVersion || !policyVersion.policyTargets ) return;
		return (
			<PolicyTargets policyVersion={policyVersion} onChanged={onChanged}/>
		)
	}

	const getPolicyStandardText = () => {
		if ( undefined === policyVersion || !policyVersion.policyStandardTexts ) return;
		return (
			<Card className={"mb-2"}>
				<Card.Header>{t('createPolicy.standardText.card.header')}</Card.Header>
				<Card.Body>
					<Table>
						<thead>
						<tr>
							<th>{t('standardText.theme.label')}</th>
						</tr>
						</thead>
						<tbody>
						{policyVersion.policyStandardTexts.map( (policyStandardText, index) => {
							return <tr key={index}>
								<td>{policyStandardText.theme}</td>
							</tr>
						})}
						</tbody>
					</Table>
				</Card.Body>
			</Card>
		)
	}

	const getReinsurance = () => {
		if ( undefined === policyVersion || !policyVersion ) return;
		let reinsuranceRows = []
		const reinsuranceSorted = policyVersion.reinsurance ? policyVersion.reinsurance.sort( (a,b) => a.id - b.id ) : []

		for (const prop in reinsuranceSorted) {
			let rRow = policyVersion.reinsurance[prop]
			if ( rRow.expectedHarm !== undefined ) {
				let productType
				const productTypeId = parseInt(rRow.id)
				if ( productTypes ) {
					productType = productTypes.find( (pt) => parseInt(pt.id) === productTypeId )
				}
				let productTypeName
				if ( productType ) {
					productTypeName = productType.name
				}
				reinsuranceRows.push(
					<Row key={prop}>
						<EditableInAllowedContext
							onSave={ (formData) => {
								return new Promise( (resolve, reject) => {
									let reinsurance = [...policyVersion.reinsurance]

									Object.keys(formData).forEach( (key) => {
										const splittedKey = key.split("-")
										const propertyName = splittedKey[0]
										const productTypeId = splittedKey[1]
										const index = reinsurance.findIndex(  (r) => parseInt(r.id) === parseInt(productTypeId) );
										const editedReinsurance = reinsurance.splice(index, 1)[0];
										editedReinsurance[propertyName] = formData[key];
										reinsurance.push( editedReinsurance );
									});

									restService.updateDomainInstance('policyVersion', policyVersion.id, {version: policyVersion.version, reinsurance: reinsurance})
											.then( (domain) => {
												setPolicyVersion( domain )
												resolve();
											})
											.catch( (error) => reject(error) )
									})
							} }
							editFormElement={
								<Row>
									<ReinsuranceRow
										key={ productTypeId }
										id={ productTypeId }
										description={ productTypeName }
										expectedHarm={ rRow && rRow.expectedHarm }
										reinsuranceAnsatz={ rRow && rRow.reinsuranceAnsatz }
										defaultReinsuranceRates={ reinsuranceRates[productTypeId] || [] }
									/>
								</Row>
							}
							allowedContextPropertyName={'reinsurance'}
						>
							<Row>
								<Col md={6}>
									<ShowField
										label={'createPolicy.policy.expectedHarm.label'}
										labelAppendix={' ' + productTypeName}
										object={rRow}
										property={'expectedHarm'}
										type={'number'}
									/>
								</Col>
								<Col md={6}>
									<ShowField
										label={'createPolicy.policy.reinsuranceAnsatz.label'}
										object={rRow}
										property={'reinsuranceAnsatz'}
										type={'number'}
									/>
								</Col>
							</Row>
						</EditableInAllowedContext>
					</Row>
				)
			}
		}

		return (
			<Allowed allowedFor={AllowedForEnum.SHOW} propertyName={"reinsurance"} >
				<Card className={"mb-2"}>
					<Card.Header>{t('createPolicy.version.reinsurance.header')}</Card.Header>
					<Card.Body>
						<Row>
							<Col md={12}>
								<ShowField label={'createPolicy.version.reinsuranceCompany.label'} object={policyVersion} property={'reinsuranceCompany.label'} type={'text'} link={{domain:'reinsuranceCompany', action:'show', newTab:true}}/>
							</Col>
						</Row>
						{reinsuranceRows}
					</Card.Body>
				</Card>
			</Allowed>
		)
	}
	return (
		<>
			<ToBeActivated policyVersion={policyVersion} show={showToBeActivated} setShow={setShowToBeActivated} onSubmit={onSubmitToBeActivated} />

			{getBaseData()}

			{getPolicyTargets()}

			{getPolicyStandardText()}

			{getLeadingData()}

			{getProfitParticipationData()}

			{getBrokerData()}

			{getAssistanceData()}

			{getReinsurance()}

			<Acl domainInstance={policyVersion} editable={false}/>
		</>
	)
}

export default PreviewPolicyVersionBase
