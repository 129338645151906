import React, {useCallback, useEffect, useMemo, useState} from 'react';
import StepWizard from "react-step-wizard";
import {CreatePolicyPolicy} from "./CreatePolicyPolicy";
import {CreatePolicyDetail} from "./CreatePolicyDetail";
import {Button, OverlayTrigger, Popover} from "react-bootstrap";
import {CreatePolicyAddProductConfigurator} from "./CreatePolicyAddProductConfigurator";
import {CreatePolicyAddProductTariffs} from "./CreatePolicyAddProductTariffs";
import {CreatePolicyAddProductBuilding} from "./CreatePolicyAddProductBuilding";
import {CreatePolicyLastTouches} from "./CreatePolicyLastTouches";
import CreatePolicyReview from "./CreatePolicyReview";
import {policyService} from "../_services/policy.service";
import {isProduction} from "../config";
import {useTranslation} from "react-i18next";
import appService from "../_services";

function CreatePolicy( { routeParams } ) {
	const id = useMemo( () => routeParams && routeParams.id, [routeParams]);
	const queryParams = new URLSearchParams(window.location.search)
	const partnerId = queryParams.get('partnerId');
	const policyId = useMemo( () => routeParams && routeParams.parentId, [routeParams]);
	const initValues = policyService.usePolicyOffer(id, policyId, partnerId);
	const {t} = useTranslation();
	const [values, setValues] = useState( initValues );

	const policyHolderLabel  = ( values && values['policy'] && values['policy']['policyHolder'] ) ? values['policy']['policyHolder'].label : ''
	const policyNumber = ( values && values['policy'] && values['policy']['policyNumber'] ) ? values['policy']['policyNumber'] : ''
	const policyLabel = policyNumber + (policyNumber?' ':'') + policyHolderLabel
	appService.useTitle( t('policyVersion.new.title', {label: policyLabel} ) )

	useEffect( ()=>{
		if ( initValues ) {
			setValues( initValues );
		}
	}, [initValues])

	const onStepChange = (stats) => {
		//setActiveStep( stats.activeStep );
		window.scrollTo({top: 0, behavior: 'smooth'});
	}

	const handleUpdate = useCallback((step, vals) => {
		//console.log(`${step}: ${JSON.stringify(vals)}`);
		values[step] = vals;
		setValues( {...values} );
	}, [setValues, values])

	const getValues = () => {
		return JSON.stringify(values, null, 4);
	}

	const popover = (
		<Popover id="popover-basic" style={{maxWidth: 1000}}>
			<Popover.Header as="h3">Debug information</Popover.Header>
			<Popover.Body>
				<pre style={{marginTop:"20px", backgroundColor:"lightBlue"}}>{ getValues() }</pre>
			</Popover.Body>
		</Popover>
	);
	return (
		<div>
			<h4>{t('policyVersion.label')} </h4>
			<h5>{policyLabel}</h5>
			{values && <StepWizard onStepChange={ onStepChange }
				// instance={setStepWizardInstance}
			>
				<CreatePolicyPolicy stepName={"policy"} handleUpdate={handleUpdate} values={values}/>
				<CreatePolicyDetail stepName={"detail"} handleUpdate={handleUpdate} values={values}/>
				<CreatePolicyAddProductBuilding stepName={"addProductBuilding"} handleUpdate={handleUpdate} values={values}/>
				<CreatePolicyAddProductConfigurator stepName={"addProductConfigurator"} handleUpdate={handleUpdate} values={values}/>
				<CreatePolicyAddProductTariffs stepName={"addProductTariffs"} handleUpdate={handleUpdate} values={values}/>
				<CreatePolicyLastTouches stepName={"lastTouches"} handleUpdate={handleUpdate} values={values}/>
				<CreatePolicyReview stepName={"review"} handleUpdate={handleUpdate} values={values}/>
			</StepWizard>}

			{ !isProduction &&
				<OverlayTrigger trigger="click" placement="left" overlay={ popover } >
					<Button style={ { marginTop: "20px", float: "right" } } variant="success">Debug information</Button>
				</OverlayTrigger>
			}
		</div>
	);
}




export { CreatePolicy };
