import React, {useCallback, useMemo, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {Link} from 'react-router-dom';
import {Button, ToggleButton} from 'react-bootstrap'
import appService, {alertService} from "../_services";
import { withRouter } from 'react-router-dom';
import restService from "../_services/rest.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt, faPencilAlt, faEye, faCodeMerge} from "@fortawesome/free-solid-svg-icons";
import DomainTable from "../_components/DomainTable";
import AddButtons from "./AddButtons";
import DeleteConfirmDialog from "../_components/DeleteConfirmDialog";
import {AllowedContext, AllowedForEnum} from "./form.service";
import Allowed from "../_components/Allowed";
import MergePopup from "../_components/MergePopup";

function ListForm(props) {
	const domainTableRef = useRef();
	const instanceToString = props.instanceToString;
	const columns = props.columns;
	const { t } = useTranslation();
	const domainName = props.domainName;
	appService.useTitle(t(domainName + '.label'));
	const { path } = props.match;
	const actionIdColumn = props.actionIdColumn ? props.actionIdColumn : 'id';
	const queryParams = new URLSearchParams(window.location.search)
	const isMergeable = appService.useMergeable(domainName)
	const [isMerging, setIsMerging]  = useState(false)
	const [mergeDomains, setMergeDomains] = useState([])
	const [page, setPage] = useState( () => {
		const pageParam = parseInt( queryParams.get("page") )
		if ( isNaN(pageParam) ) {
			return 1
		}
		else {
			return pageParam
		}
	});
	const [sizePerPage, setSizePerPage] = useState( () => {
		const sizePerPage = parseInt( queryParams.get("sizePerPage") )
		if ( isNaN(sizePerPage) ) {
			return 10
		}
		else {
			return sizePerPage
		}
	});
	const [sortField, setSortField] = useState( () => {
		const sortFieldParam = queryParams.get("sortField")
		return sortFieldParam || props.sortField
	});
	const [sortOrder, setSortOrder] = useState( () => {
		const sortOrderParam = queryParams.get("sortOrder")
		return sortOrderParam || props.sortOrder || 'desc'
	});
	const [searchText, setSearchText] = useState( () => {
		return queryParams.get("searchText") || undefined //undefined is important, because otherwise searchText will be set to "null" and it causes one unnecessary rerender in DomainTable
	});
	const [rowToBeDeleted, setRowToBeDeleted] = useState({});
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const handleCloseDeleteConfirm = () => setShowDeleteConfirm(false);

	const handleTableStateChanged = (newState) => {
		let params = {};
		if ( newState.page ) {
			params['page'] = newState.page;
			setPage(newState.page)
		}
		if ( newState.sortField ) {
			params['sortField'] = newState.sortField;
			setSortField(newState.sortField)
		}
		if ( newState.sortOrder ) {
			params['sortOrder'] = newState.sortOrder;
			setSortOrder(newState.sortOrder)
		}
		if ( newState.searchText ) {
			params['searchText'] = newState.searchText;
			setSearchText(newState.searchText)
		}
		if ( newState.sizePerPage ) {
			params['sizePerPage'] = newState.sizePerPage;
			setSizePerPage(newState.sizePerPage)
		}

		const url = `${props.match.url}?${new URLSearchParams( params )}`
		if ( url !== window.location.pathname + window.location.search ) {
			console.log( url )
			window.history.replaceState(null, null, `${props.match.url}?${new URLSearchParams( params )}`);
		}
	}

	function deleteDomainInstance() {
		try {
			let row = rowToBeDeleted;
			restService.deleteDomainInstance( domainName, row.id )
				.then( () => {
					domainTableRef.current.onRowDeleted( row );
					alertService.success(t("default.deleted", {what: instanceToString(row)}), { keepAfterRouteChange: true });
				})
				.catch( (error, signal) => {
					restService.handleServerErrorsAxios(error, signal);
				})

		}
		catch (e) {
			alertService.error(e);
		}
	}

	const getActionDomainInstance = useCallback( (row, action, className, variant, icon) => {
		let urlParams = '';
		if ( props.getEditUrlParams ) {
			urlParams = props.getEditUrlParams(row)
		}
		return (
			<Link className={`${className} btn btn-sm btn-${variant}`} to={ { pathname: `${props.match.url}${urlParams}/${action}/${row[actionIdColumn]}`, state: row } }>
				<FontAwesomeIcon icon={icon}/>
			</Link>
		)
	}, [props, actionIdColumn]);

	const getSecuredButton = useCallback( ( button, allow, row) => {
		return (
			<Allowed allowedFor={allow} row={row}>
				{button}
			</Allowed>
		)
	}, [])

	const handleAddToMerge = useCallback( (event, row, mergeDomains) => {
		event.preventDefault();
		event.stopPropagation();
		if ( mergeDomains.length === 0 ) {
			setMergeDomains( [row] )
		}
		else if ( mergeDomains.length === 1 ) {
			setMergeDomains( [...mergeDomains, row] )
		}
		else {
			const newMergeDomains = [...mergeDomains]
			newMergeDomains[1] = row
			setMergeDomains( newMergeDomains )
		}
	}, [] )

	const getMergeToggleButton = useCallback( () => {
		return <ToggleButton
			className="ms-2"
			id="isMerging-check"
			type="checkbox"
			variant="outline-primary"
			checked={isMerging}
			value="1"
			onChange={(e) => setIsMerging(e.currentTarget.checked)}
			size={"xs"}
		>
			<FontAwesomeIcon size={'sm'} icon={faCodeMerge}/>
		</ToggleButton>
	}, [isMerging])

	const columnsWithActions = useMemo( () => {
		let result = columns.slice(0); //clone columns

		let actions = {
			dataField: 'actions',
			text: '',
			headerFormatter: () => {
				return (<>
					{t( 'default.actions' )}
					{ ( isMergeable && !isMerging ) ? getMergeToggleButton() : undefined }
			</>)},
			align: 'center',
			headerStyle: ( column, colIndex ) => {
				return { width: '180px', textAlign: 'center' };
			},
			formatter: ( cellContent, row ) => {
				const allowedContextValue = {
					[AllowedForEnum.SHOW]: props.allowShow,
					[AllowedForEnum.EDIT]: props.allowEdits,
					[AllowedForEnum.DELETE]: props.allowDeletions,
					data: row
				}

				return (
					<AllowedContext.Provider value={allowedContextValue}>
						{ getSecuredButton(
							getActionDomainInstance( row, 'show', 'me-1', 'primary', faEye),
							AllowedForEnum.SHOW,
							row )
						}
						{ getSecuredButton(
							getActionDomainInstance( row, 'edit', 'me-1', 'warning', faPencilAlt),
							AllowedForEnum.EDIT,
							row )
						}
						{ getSecuredButton(
							<Button
								variant={ 'danger' }
								size={ 'sm' }
								onClick={ ( event ) => {
									event.preventDefault();
									event.stopPropagation();
									setRowToBeDeleted( row );
									setShowDeleteConfirm( true );
								} }>
								<FontAwesomeIcon icon={ faTrashAlt }/>
							</Button>,
							AllowedForEnum.DELETE,
							row )
						}
						{ ( props.additionalButtons ? (
							props.additionalButtons(row)
						) : '' )}
					</AllowedContext.Provider>
				)
			}
		}

		result.push(actions);

		if ( isMerging  && ( mergeDomains.length === 0 || mergeDomains.length === 1 ) ) {
			let merging = {
				dataField: 'merge',
				text: '',
				align: 'center',
				headerStyle: ( column, colIndex ) => {
					return { width: '60px', textAlign: 'center' };
				},
				formatExtraData: {mergeDomains: mergeDomains},
				formatter: (cellContent, row, rowIndex, formatExtraData) => {
					const mergeDomainsAreEmpty = !formatExtraData.mergeDomains || formatExtraData.mergeDomains.length === 0
					const mergeDomainsHasOneElementThenShowOnlySameClass = formatExtraData.mergeDomains.length === 1 && formatExtraData.mergeDomains[0].classSimpleName === row.classSimpleName && formatExtraData.mergeDomains[0].id !== row.id

					if ( mergeDomainsAreEmpty || mergeDomainsHasOneElementThenShowOnlySameClass ) {
						return ( <Button
							variant={ 'primary' }
							size={ 'sm' }
							onClick={ ( event ) => handleAddToMerge( event, row, formatExtraData.mergeDomains ) }>
							<FontAwesomeIcon icon={ faCodeMerge }/>
						</Button> )
					}
				},
			}

			result.push(merging);
		}

		return result;
	}, [columns, getActionDomainInstance, props, t, getSecuredButton, isMerging, getMergeToggleButton, handleAddToMerge, mergeDomains, isMergeable]);

	const handleMergeClose = () => {
		setMergeDomains([])
		setIsMerging(false)
	}

	const handleRemove0 = () => {
		if ( mergeDomains.length === 2 ) {
			setMergeDomains( [mergeDomains[1]] )
		}
		else {
			setMergeDomains( [] )
		}
	}

	const handleRemove1 = () => {
		setMergeDomains( [mergeDomains[0]] )
	}

	return (
		<>
			<div className={"float-start mb-2 w-50"}>
				<AddButtons addButtons={props.addButtons} path={path} domainName={domainName}/>
			</div>
			{ isMerging &&
				<MergePopup domains={ mergeDomains } onClose={ handleMergeClose } onRemove0={ handleRemove0 } onRemove1={ handleRemove1 } />
			}
            <DomainTable getData={props.getIndexData} columns={columnsWithActions} domainName={domainName}
                         ref={domainTableRef}
                         sortField={sortField} sortOrder={sortOrder} searchText={searchText} sizePerPage={sizePerPage}
                         page={page} onTableStateChanged={handleTableStateChanged} expandRow={props.expandRow}
                         rowStyle={props.rowStyle} pagination={props.pagination}/>
			<DeleteConfirmDialog
				show={showDeleteConfirm}
				onHide={handleCloseDeleteConfirm}
				bodyText={t('deleteConfirm.body', {name: instanceToString(rowToBeDeleted)})}
				onDelete={deleteDomainInstance}
			/>
		</>
	);
}

export default withRouter(ListForm);
