import {fileService} from "./file.service";
import restService from "./rest.service";
import {messageBoxService} from "./messageBox.service";
import i18n from "i18next";
import {MessageBoxButtons} from "../_components/MessageBox";
import {useEffect, useState} from "react";
import {apiClientPrivate} from "../api/apiClient";

export const damageService = {
	printDamagePaymentInfo,
	printDamageStatement,
	printDamageDossier,
	downloadInvolvedInsuranceInvoices,
	markPaymentInfoAsBooked,
	markDamageAsResolved,
	getReserveAmounts,
	printDamageRendement,
	printDamageReserves,
	partlyResolveDamage,
	useDamagesForInterimBilling,
	revertDamagePaymentInfoBook,
	revertDamageResolve,
	getDamagePaymentInfoDetailsInfo,
}

function revertDamagePaymentInfoBook( damagePaymentInfoId ) {
	return new Promise( (resolve, reject) => {
		const controller = new AbortController();
		apiClientPrivate.get( `/api/damage/revertDamagePaymentInfoBook?${new URLSearchParams( { damagePaymentInfoId: damagePaymentInfoId } )}`, {
			signal: controller.signal,
		} )
			.then( response => restService.handleServerResponseAxios( response ) )
			.then( json => {
				resolve(json);
			} )
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
				reject();
			} );
	});
}

function getReserveAmounts( damagePaymentInfoId ) {
	return new Promise( (resolve, reject) => {

		apiClientPrivate.get( `/api/damagePaymentInfo/getReserveAmounts/${damagePaymentInfoId}` )
			.then( response => restService.handleServerResponseAxios(response) )
			.then( json => {
				resolve(json);
			} )
			.catch( error => {
				reject(error);
			} );
	});
}

function useDamagesForInterimBilling() {
	const [damagesForInterimBilling, setDamagesForInterimBilling] = useState( [] )

	useEffect( () => {
		const controller = new AbortController();

		apiClientPrivate.get( `/api/damage/getDamagesForInterimBilling`)
			.then( response => restService.handleServerResponseAxios(response) )
			.then( json => {
				setDamagesForInterimBilling( json.damagesForInterimBilling )
			} )
			.catch( error => {
				restService.handleServerErrorsAxios( error, controller.signal );
			} );
	}, [] );

	return damagesForInterimBilling
}

function printDamagePaymentInfo( id ) {
	return new Promise( (resolve, reject) => {
		const controller = new AbortController()
		apiClientPrivate.get( `/api/damagePaymentInfo/printDamagePaymentInfo/${ id }`, {
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( fileService.saveAttachmentWithContentDispositionFileName )
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
				reject();
			} );
	});
}

function printDamageStatement( id ) {
	return new Promise( (resolve, reject) => {
		const controller = new AbortController()
		apiClientPrivate.get( `/api/damage/printDamageStatement/${ id }`, {
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( fileService.saveAttachmentWithContentDispositionFileName )
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
				reject();
			} );
	});
}

function printDamageDossier( id ) {
	return new Promise( (resolve, reject) => {
		const controller = new AbortController()
		apiClientPrivate.get( `/api/damage/printDamageDossier/${ id }`, {
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( fileService.saveAttachmentWithContentDispositionFileName )
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
				reject();
			} );
	});
}

function printDamageRendement( params ) {
	return new Promise( (resolve, reject) => {
		const controller = new AbortController()
		apiClientPrivate.get( `/api/damage/printDamageRendement/?` + new URLSearchParams( params ), {
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( fileService.saveAttachmentWithContentDispositionFileName )
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
				reject();
			} );
	});
}

function printDamageReserves() {
	return new Promise( (resolve, reject) => {
		const controller = new AbortController()
		apiClientPrivate.get( `/api/damage/printDamageReserves/`, {
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( fileService.saveAttachmentWithContentDispositionFileName )
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
				reject();
			} );
	});
}


function downloadInvolvedInsuranceInvoices( id ) {
	//TODO: TEST THIS
	return new Promise( (resolve, reject) => {
		// const h = headers();
		// h['Content-Type'] = 'application/pdf';
		const controller = new AbortController()
		apiClientPrivate.get( `/api/damage/downloadInvolvedInsuranceInvoices/${ id }`, {
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( fileService.saveAttachmentWithContentDispositionFileName )
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
				reject();
			} );
	});
}

function markPaymentInfoAsBooked( id, newReserveAmounts, fileChanges ) {
	const controller = new AbortController()
	const data = {
		id: id,
		newReserveAmounts: newReserveAmounts,
		fileChanges: fileChanges,
	};
	apiClientPrivate.put( '/api/damagePaymentInfo/book', data, {
		signal: controller.signal
	} )
		.then( response => restService.handleServerResponseAxios( response ) )
		.then( () => { /*do nothing*/ } )
		.catch( ( error ) => {
			restService.handleServerErrorsAxios( error, controller.signal );
		} );
}

//TODO:
function markDamageAsResolved( id ) {
	messageBoxService.display( i18n.t( 'damage.markAsResolved.question' ), i18n.t( 'damage.markAsResolved' ), [MessageBoxButtons.YES, MessageBoxButtons.NO] )
		.then( ( button ) => {
			if ( button === MessageBoxButtons.YES ) {
				const controller = new AbortController()
				apiClientPrivate.get( `/api/damage/resolve/${ id }`, {
					signal: controller.signal
				} )
					.then( response => restService.handleServerResponseAxios( response ) )
					.then( () => { /*do nothing*/ } )
					.catch( ( error ) => {
						restService.handleServerErrorsAxios( error, controller.signal );
					} );
			}
		})
}

function revertDamageResolve( id ) {
	const controller = new AbortController()
	apiClientPrivate.get( `/api/damage/revertDamageResolve/${ id }`, {
		signal: controller.signal
	} )
		.then( response => restService.handleServerResponseAxios( response ) )
		.then( () => { /*do nothing*/ } )
		.catch( ( error ) => {
			restService.handleServerErrorsAxios( error, controller.signal );
		} );
}

function partlyResolveDamage( id ) {
	return new Promise( (resolve, reject) => {
		messageBoxService.display( i18n.t( 'damage.partlyResolveDamage.question' ), i18n.t( 'damage.partlyResolveDamage' ), [MessageBoxButtons.YES, MessageBoxButtons.NO] )
			.then( ( button ) => {
				if ( button === MessageBoxButtons.YES ) {
					const controller = new AbortController()
					apiClientPrivate.get( `/api/damage/partlyResolve/${ id }`, {
						signal: controller.signal
					} )
						.then( response => restService.handleServerResponseAxios( response ) )
						.then( () => {
							resolve();
						} )
						.catch( ( error ) => {
							restService.handleServerErrorsAxios( error, controller.signal );
							reject();
						} );
				}
			})
	})
}

function getDamagePaymentInfoDetailsInfo(damagePaymentInfoDetails) {
	const total =damagePaymentInfoDetails.reduce((sum, row) => sum + parseFloat(row.amount), 0)
	const someParticipationExists = damagePaymentInfoDetails.some( (detail) => detail.participation )
	const someNonParticipationExists = damagePaymentInfoDetails.some( (detail) => !detail.participation )

	return {total: total, someParticipationExists: someParticipationExists, someNonParticipationExists: someNonParticipationExists}
}
