import React from 'react';
import {InputGroup} from "react-bootstrap";
import {securityService} from "../_services/security.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquareCheck} from "@fortawesome/free-solid-svg-icons";
import {faSquare} from "@fortawesome/free-regular-svg-icons";

function InputGroupText({name, type, value, onChange}) {
    const accessToProperty = securityService.useAccessToPropertyOfAllowedContext()

    if ( accessToProperty.ready ) {
        if ( accessToProperty.write ) {
            return <InputGroup.Checkbox
                name={ name }
                type={ type }
                value={ value }
                onChange={ onChange }
            />
        } else if ( accessToProperty.read ) {
            if ( value ) {
                return <span className={ "border-bottom pe-3" }> <FontAwesomeIcon className={ "mt-auto mb-auto" }
                                                                                  icon={ faSquareCheck }/> </span>
            } else {
                return <span className={ "border-bottom pe-3" }> <FontAwesomeIcon className={ "mt-auto mb-auto" }
                                                                                  icon={ faSquare }/> </span>
            }
        }
    }

    return null
}

export default InputGroupText