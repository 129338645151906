import React, {useCallback} from 'react';
import InvoiceAdditionalInfo from "./InvoiceAdditionalInfo";
import restService from "../_services/rest.service";
import ShowField from "../form/ShowField";
import {Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function FormElementsPolicyVersion({settlement, onRefreshSettlement}) {
    const {t} = useTranslation();

    const afterInvoiceInfoUpdate = useCallback( () => {
        const controller = new AbortController();

        restService.getDomainInstance( settlement.classSimpleName, settlement.id, controller.signal )
            .then( (result) => onRefreshSettlement() )
            .catch( error => restService.handleServerErrorsAxios( error, controller.signal ) )

        return function cleanup() {
            controller.abort()
        }
    }, [settlement.id, onRefreshSettlement, settlement.classSimpleName]);

	return (
        <>
            <Row>
                <ShowField
                    label={'settlement.policyVersion'}
                    object={settlement}
                    property={'policyVersion.label'}
                    type={'text'}
                    link={{domain: 'policyVersion', action: 'show', newTab:true}}
                />
            </Row>
            <Row>
                <ShowField label={'settlement.billingFrom'} object={settlement} property={'billingFrom'} type={'date'} size={6}/>
                <ShowField label={'settlement.billingTo'} object={settlement} property={'billingTo'} type={'date'} size={6}/>
            </Row>
            <Row>
                <ShowField label={ 'settlement.netAmount' } object={ settlement } property={ 'netAmount' } type={ 'number' } size={ 6 } />
            </Row>
            <Row>
                <ShowField label={ 'settlement.rabattValue' } object={ settlement } property={ 'rabattValue' } type={ 'number' } size={ 6 } />
            </Row>

            <Row>
                <ShowField label={'settlement.loeschfuenferAmount'} object={settlement} property={'loeschfuenferAmount'} type={'number'} size={4}/>
            </Row>

            <Row>
                <ShowField label={ 'settlement.netAmountStampTaxRelevant' } object={ settlement } property={ 'netAmountStampTaxRelevant' } type={ 'number' } size={ 3 } />
                <ShowField label={ 'settlement.netAmountNotStampTaxRelevant' } object={ settlement } property={ 'netAmountNotStampTaxRelevant' } type={ 'number' } size={ 3 } />
                { settlement && settlement.stampTax1 !== 0 &&
                    <ShowField label={ 'settlement.stampTax' } object={ settlement } property={ 'stampTax1' }
                               type={ 'number' } size={ 3 } appendix={ ' (' + (settlement && settlement['stampTaxPercent1']) + ' %)'} />
                }
                { settlement && settlement.stampTax2 !== 0 &&
                    <ShowField label={ 'settlement.stampTax' } object={ settlement } property={ 'stampTax2' }
                               type={ 'number' } size={ 3 } appendix={' (' + (settlement && settlement['stampTaxPercent2']) + ' %)'} />
                }
            </Row>
            <InvoiceAdditionalInfo clazzName={settlement.classSimpleName} additionalInfoButtonText={t('default.edit')} updatingAdditionalInfo={false} instance={settlement} afterUpdate={afterInvoiceInfoUpdate}/>
        </>
	)
}

export default FormElementsPolicyVersion
