import React, {useCallback, useMemo, useState, useEffect, useRef} from 'react';
import {Alert, Badge, Button, Card, Col, Form, Row} from "react-bootstrap";
import {useForm, FormProvider} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {ConfiguratorTargetList} from "./ConfiguratorTargetList";
import textService from "../_services/text.service";
import {configuratorService} from "../_services/configurator.service";
import appService from "../_services";
import {messageBoxService} from "../_services/messageBox.service";
import i18n from "i18next";
import {MessageBoxButtons} from "../_components/MessageBox";
import {policyTargetConditionService} from "../_services/policyTargetCondition.service";
import ObligatoryElementCondition from "./obligatory/ObligatoryElementCondition";
import restService from "../_services/rest.service";
import settingService from "../_services/setting.service";

function CreatePolicyDetail(props) {
	const { t } = useTranslation()
	const ref = useRef(null);
	const useFormObject = useForm();
	const stepValues = useMemo( () => (props.values[props.stepName]), [props.values, props.stepName] );
	const policyValues = useMemo( () => (props.values['policy']), [props.values] );
	const detailValues = useMemo( () => (props.values['detail']), [props.values] );
	const validFrom = useMemo( () => (policyValues.validFrom), [policyValues.validFrom] );
	const [feeTotal, setFeeTotal] = useState(undefined);
	const [activeConfiguratorTargetKey, setActiveConfiguratorTargetKey] = useState(0);
	const [obligatoryTargetElementsData, setObligatoryTargetElementsData] = useState([]);
	const obsoleteTargets = useMemo( () => (props.values.obsoleteTargets || []), [props.values] );
	const {appenzellerVersicherungPartnerId} = settingService.useSetting(['appenzellerVersicherungPartnerId']);
	const [appversPartner] = restService.useDomainInstance('partner', appenzellerVersicherungPartnerId);
	const brokerId = useMemo( () => ( policyValues.broker && policyValues.brokerOrConsultant==='BROKER' && policyValues.broker.id ), [policyValues.broker, policyValues.brokerOrConsultant] );
	const _appversPartnerId = appversPartner && appversPartner.id
	const _appversPartnerLabel = appversPartner && appversPartner.label
	const [indexedInconsistencies, setIndexedInconsistencies] = useState( {} );

	const targets = useMemo( () => ( stepValues.targets ? stepValues.targets : [] ), [stepValues.targets] );

	const handleConfiguratorTargetHeaderClick = useCallback( (eventKey) => {
		configuratorService.expandAndScrollToConfiguratorHeader(eventKey);
	}, []);

	const expandConfiguratorTarget = useCallback( (key) => {
		if ( activeConfiguratorTargetKey === key  ) {
			setActiveConfiguratorTargetKey( 0 );
		}
		else {
			setActiveConfiguratorTargetKey( parseInt( key ) );
		}
	}, [setActiveConfiguratorTargetKey, activeConfiguratorTargetKey])

	useEffect( () => {
		ref.current = {expandConfiguratorTarget: expandConfiguratorTarget}
		configuratorService.registerConfiguratorAccordeon( ref );
	}, [expandConfiguratorTarget, ref]);

	const handleClickPrevious = () => {
		props.previousStep();
	}

	const onError = useCallback((errors, e) => {
		const firstErrorKey = Object.keys(errors)[0].match(/.*-(\d+)/)[1];
		handleConfiguratorTargetHeaderClick( String( firstErrorKey ), null );
	}, [handleConfiguratorTargetHeaderClick]);

	const onSubmit = useCallback((data) => {
		const targetsParticipations = targets.filter( (t) => t.action !== 'delete' ).map( target => {
			return {target: target.target, participations: data[`participation-${target.key}`].sort( (a,b) => a.partner.id - b.partner.id).map( p => {
					return  `${p.partner.id}-${p.percent}`
				} ).join(';')}
		})

		const grouppedParticipations =  appService.groupBy(targetsParticipations, t => t.participations );

		const submit = () => {
			//read values from ConfiguratorTarget elements and copy them to targets
			targets.forEach( (target) => {
				let changed = false;
				[
					'targetId',
					'amount',
					'policyType',
					'avb',
					'zip',
					'participation',
					'individualTexts',
					'tariff',
					'indexed',
					'additionalDiscountSurchargePercent',
					'additionalDiscountSurchargeValue',
					'discountSurchargeNames',
					'discountSurcharge',
					'useStampTax',
					'useLoeschfuenfer',
					'fee',
					'tariffUnit',
					'isManualTariff'
				].forEach( (property) => {
					let newValue = data[property+'-'+target.key];
					if ( target[property] !== newValue) {
						changed = true
						target[property] = newValue;
					}
				});
				if (changed && !target.action) {
					target.action = 'update';
				}
			});
			props.handleUpdate(props.stepName, {...stepValues, ...{targets: targets}});
			props.goToNamedStep('lastTouches');
		}

		if ( Object.keys(grouppedParticipations).length > 1 ) {
			messageBoxService.display( i18n.t( 'createPolicy.detail.products.differentParticipation.content' ), i18n.t( 'createPolicy.detail.products.differentParticipation.title' ), [MessageBoxButtons.YES, MessageBoxButtons.NO] )
				.then( ( button ) => {
					if ( button === MessageBoxButtons.YES ) {
						submit()
					}
				})
		}
		else {
			submit()
		}

	}, [targets, props, stepValues]);

	const handleAddTarget = () => {
		props.goToNamedStep('addProductBuilding');
	}

	const handleOnConfiguratorTargetClose = (key, _targets = null) => {
		if ( !_targets ) {
			//_targets is used for recursive call of this method. If the targets are not passed as param in this case,
			//then variable targets is used, but it contains still closed targets from previous calls of handleOnConfiguratorTargetClose
			_targets = targets
		}
		let closedTarget = _targets.find( (target) => target.key === key );
		let newTargets;
		if ( closedTarget.action === 'create' ) {
			newTargets = _targets.filter( (target) => target.key !== key );
		}
		else if ( !closedTarget.action || closedTarget.action === 'update' ) {
			closedTarget.action = 'delete';
			newTargets = [..._targets];
		}
		props.handleUpdate(props.stepName, {...stepValues, ...{targets: newTargets}});

		// const dependentTargets = stepValues.getDependentTargets(targets, closedTarget)
		// dependentTargets.forEach( (dependentTarget) => {
		// 	handleOnConfiguratorTargetClose(dependentTarget.key, newTargets);
		// })
	}

	const handleOnFeeTotalChanged = useCallback( (feeTotal) => {
		setFeeTotal(feeTotal)
	}, [setFeeTotal])

	const feeTotalElement = useMemo( () => {
		if ( feeTotal ) {
			return (
				<Row>
					<Col md={12}>
						<h5>
							<Badge pill variant="success">
								Total { textService.formatNumber(feeTotal) } CHF
							</Badge>
						</h5>
					</Col>
				</Row>
			)
		}
		else {
			return undefined;
		}
	}, [feeTotal])

	const obsoleteTargetsElement = useMemo(() => {
		if ( obsoleteTargets.length > 0 ) {
			return (
				<Alert variant={ "warning" }>
					{ t( 'createPolicy.detail.obsoleteTargets' ) }
					<ul className={ "mb-0" }>
						{ obsoleteTargets.map( ( obsoleteTarget ) => {
							return <li>{ obsoleteTarget }</li>
						} ) }
					</ul>
				</Alert>
			)
		}
		else {
			return undefined;
		}
	}, [obsoleteTargets, t])

	useEffect( () => {
		const configuratorTargetsGroupedByBuilding = appService.groupBy( targets.filter( (target) => target.action !== "delete"), (target) => target.building && target.building.id )
		const configuratorTargetIds = Object.keys(configuratorTargetsGroupedByBuilding).map( buildingId => {
			let result = {
				configuratorTargetIds: configuratorTargetsGroupedByBuilding[buildingId].map( (configuratorTarget) => configuratorTarget.targetId )
			}
			if ( buildingId !== 'undefined' ) {
				result.buildingId = buildingId
			}
			return result
		})

		policyTargetConditionService.isFulfilled( configuratorTargetIds, brokerId )
			.then( (result) => {
				setObligatoryTargetElementsData(result)
			})
	}, [targets, brokerId])

	const addConfiguratorTarget = useCallback( (id, building ) => {
		return new Promise( (resolve, reject) => {
			restService.getDomainInstance( 'configuratorTarget', id )
				.then( ( configuratorTarget ) => {
					configuratorTarget.participation = []
					if ( building ) {
						configuratorTarget.building = building
					}
					configuratorTarget.participation = [{
						partner: {
							id: _appversPartnerId,
							label: _appversPartnerLabel
						}, percent: 100, action: 'create'
					}];
					configuratorTarget.action = 'create'
					configuratorTarget.key = detailValues.getMaxId() + 1
					configuratorTarget.targetId = configuratorTarget.id

					configuratorService.getConfiguratorValues( id, building && building.id )
						.then( ( configuratorValues ) => {
							configuratorTarget.configuratorValues = configuratorValues
							detailValues.addConfiguratorTargets( [configuratorTarget], props.handleUpdate, props.values )
							resolve()
						} )
						.catch( error => reject( error ) )
				} )
				.catch( error => reject( error ) )
		} )
	}, [detailValues, props.handleUpdate, props.values, _appversPartnerId, _appversPartnerLabel])


	const obligatoryTargetElements = useMemo( () => {
		if ( obligatoryTargetElementsData.length > 0 ) {
			return (
				<Alert variant={ "danger" }>
					{ obligatoryTargetElementsData.map( ( buildingUnfulfilledData ) => {
						return <ul>
							<li>
								<span>{ buildingUnfulfilledData.building ? buildingUnfulfilledData.building.label : t('default.noBuilding') }</span>
								<ul>
								{ buildingUnfulfilledData.unfulfilled.map( ( unfulfilled ) => {
									return <li><ObligatoryElementCondition element={unfulfilled} addConfiguratorTarget={addConfiguratorTarget} building={buildingUnfulfilledData.building}/></li>
								} ) }
								</ul>
							</li>
						</ul>
					} ) }
				</Alert>
			)
		}
	}, [obligatoryTargetElementsData, t, addConfiguratorTarget] )

	const displaySubmitButton = useMemo( () => {
		const thereIsAtLeastOneTarget = targets.filter( (target) => !['delete'].includes(target.action) ).length > 0

		let obligatoryTargetExists
		obligatoryTargetExists = ( elements ) => {
			return elements.some ( element => {
				if ( element.unfulfilled && element.unfulfilled.length > 0 ) {
					return obligatoryTargetExists( element.unfulfilled )
				}
				else {
					return element.configuratorTargets.some( t => !t.optional )
				}
			})
		}

		const incorrectBrokerTariffExists = targets.some( target => target.action !== 'delete' && !detailValues.configuratorTargetBrokerCorrespondsWithPolicyVersionBroker( target.broker, policyValues.broker, policyValues.brokerOrConsultant ) )

		const thereIsMissingTarget = obligatoryTargetExists(obligatoryTargetElementsData)

		return thereIsAtLeastOneTarget && !thereIsMissingTarget && !incorrectBrokerTariffExists
	}, [targets, obligatoryTargetElementsData, policyValues.broker, policyValues.brokerOrConsultant, detailValues])

	const refreshIndexedInconsistencies = useCallback( (targets) => {
		let indexableTargets = targets.filter( (target) => target.indexable && target.action !== 'delete' )
		const groups = appService.groupBy( indexableTargets, (target) => target.building && target.building.id )


		let newIndexedInconsistencies = {}
		Object.keys(groups).forEach( (buildingId) => {
			let inconsistencies = [];
			const group = groups[buildingId]
			const groupHasIndexedTarget = group.some( ( target ) => !!target.indexed )
			const groupHasNonIndexedTarget = group.some( ( target ) => !target.indexed )
			const someTarget = group[0]
			const buildingLabel = someTarget.building ? someTarget.building.label : '';

			if ( groupHasIndexedTarget && groupHasNonIndexedTarget ) {
				group.forEach( ( correspondingTarget ) => {
					const targetKey = correspondingTarget.key
					const pathAndNameAsText = correspondingTarget.pathAndNameAsText
					const indexed = !!correspondingTarget.indexed

					inconsistencies.push( {
						targetKey: targetKey,
						pathAndNameAsText: pathAndNameAsText,
						indexed: indexed
					} )
				} )

				newIndexedInconsistencies[buildingId] = {
					building: { id: buildingId, label: buildingLabel },
					inconsistencies: inconsistencies
				}
			}
		} )

		setIndexedInconsistencies( newIndexedInconsistencies )
	}, [])


	useEffect( () => {
		refreshIndexedInconsistencies(targets)
	}, [targets, refreshIndexedInconsistencies])

	const handleIndexedChanged = useCallback( (targetKey, value) => {
		let target = targets.find( (target) => target.key === targetKey )
		target.indexed = value
		refreshIndexedInconsistencies(targets)
	}, [targets, refreshIndexedInconsistencies])

	const indexedInconsistenciesElement = useMemo( () => {
		let result
		if ( indexedInconsistencies && Object.values(indexedInconsistencies).length > 0 ) {
			result = <Alert variant={ "warning" }>
				<p>{t('createPolicyDetail.indexedInconsistencies.title')}</p>
				{
					Object.values(indexedInconsistencies).map( (buildingInconsistencies) => {
						return (
							<li> {buildingInconsistencies.building.label}
								<ul>
									{ buildingInconsistencies.inconsistencies.map( ( inconsistency ) => {
										return <li>{ inconsistency.pathAndNameAsText } { inconsistency.indexed ? <Badge bg={"primary"}>{t( 'configuratorTarget.indexed.onlabel' )}</Badge> : <Badge bg={"info"}>{t( 'configuratorTarget.indexed.offlabel' )}</Badge> }</li>
									} ) }
								</ul>
							</li>
						)
					} )
				}
			</Alert>
		}
		else {
			result = <></>
		}

		return result
	}, [indexedInconsistencies, t])

	const discountSurchargeWarning = useMemo( () => {
		let errors = {}

		targets.forEach( (target) => {
			Object.keys(target.discountSurchargeOfferInfo.discountSurcharge).forEach( (dsKey) => {
				const ds = target.discountSurchargeOfferInfo.discountSurcharge[dsKey]
				if ( ds.error ) {
					if ( errors[target.key] === undefined ) {
						errors[target.key] = new Set()
					}
					errors[target.key].add( ds.error )
				}
			})
		} )


		if ( Object.keys(errors).length > 0 ) {
			return <Alert variant={ "warning" }>
				{ Object.keys(errors).map( (targetKey) => {
					const target = targets.find( (target) => parseInt(target.key) === parseInt(targetKey) )
					const errorsSet = errors[targetKey]
					return (
						<li> { target.pathAndNameAsText }
							<ul>
								{ [...errorsSet].map( ( error, idx ) => {
									return <li key={idx}>{ error }</li>
								} ) }
							</ul>
						</li>
					)
				})}
			</Alert>
		}
		else {
			return <></>
		}
	}, [targets])

	return (
		<div>
			{ obsoleteTargetsElement }
			<FormProvider {...useFormObject}>
				<Form onSubmit={useFormObject.handleSubmit(onSubmit, onError)}>
					<Card className={"mb-2"}>
						<Card.Header>
							<div>{t('createPolicy.detail.products.header')}</div>
							{feeTotalElement}
						</Card.Header>
						<Card.Body>
							<Row>
								<Col md={12}>
									<ConfiguratorTargetList
										onConfiguratorTargetHeaderClick={handleConfiguratorTargetHeaderClick}
										activeConfiguratorTargetKey={activeConfiguratorTargetKey}
										targets={targets}
										onClose={handleOnConfiguratorTargetClose}
										validFrom={validFrom}
										feeTotalChanged={handleOnFeeTotalChanged}
										getCorrespondingTargets={stepValues.getCorrespondingTargets}
										onIndexedChanged={handleIndexedChanged}
										policyVersionBroker={policyValues.broker}
										brokerOrConsultant={policyValues.brokerOrConsultant}
										configuratorTargetBrokerCorrespondsWithPolicyVersionBroker={stepValues.configuratorTargetBrokerCorrespondsWithPolicyVersionBroker}
									/>
								</Col>
							</Row>
						</Card.Body>
					</Card>
					<div className="create-policy-footer p-3">
						{ obligatoryTargetElements }
						{ indexedInconsistenciesElement }
						{ discountSurchargeWarning }
						<Button className='btn btn-success me-1' onClick={handleAddTarget}>{t('createPolicy.detail.addProduct.label')}</Button>
						<Button className='btn btn-secondary me-1' onClick={handleClickPrevious}>{t('default.previous')}</Button>
						{ displaySubmitButton && <Button type={"submit"} className='btn btn-default'>{t('default.next')}</Button>}
					</div>
				</Form>
			</FormProvider>
		</div>
	);
}

export { CreatePolicyDetail };
