import React, {useCallback, useMemo, useState} from 'react';
import restService from "../_services/rest.service";
import {Button, Card, Col, Form, Modal, Row, Tab, Tabs} from "react-bootstrap";
import appService from "../_services";
import ShowField from "../form/ShowField";
import {useTranslation} from "react-i18next";
import ShowDamagePaymentInfoDetails from "./ShowDamagePaymentInfoDetails";
import {Link} from "react-router-dom";
import websocketService from "../_services/websocket.service";
import {damageService} from "../_services/damage.service";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DefaultSpinner from "../_components/DefaultSpinner";
import textService from "../_services/text.service";
import {messageBoxService} from "../_services/messageBox.service";
import {MessageBoxButtons} from "../_components/MessageBox";
import {ChonkyFileBrowser} from "../_components/ChonkyFileBrowser";
import FileUploader from "../_components/FileUploader";
import {FormProvider, useForm} from "react-hook-form";
import FormControl from "../_components/FormControl";

function ShowDamagePaymentInfo(props) {
    const [damagePaymentInfo, setDamagePaymentInfo] = restService.useDomainInstance( 'damagePaymentInfo', props.id, false );
    const [showMarkAsBooked, setShowMarkAsBooked] = useState(false);
    const [reserveAmounts, setReserveAmounts] = useState(null);
    const {t} = useTranslation();
    const linkToDamage = useMemo( () => {
        if ( damagePaymentInfo && damagePaymentInfo.damage ) {
            return `/damage/show/${damagePaymentInfo.damage.id}`
        }
    },[damagePaymentInfo])
    const useFormObject = useForm();

    console.log('ShowDamagePaymentInfo', props.id);

    const handlePrintDamagePaymentInfo = (id) => {
        damageService.printDamagePaymentInfo(id);
    }

    const refreshDamagePaymentInfo = useCallback( (controller) => {
        console.log('refreshDamagePaymentInfo', props.id);
        const signal = controller && controller.signal;
        restService.getDomainInstance( 'damagePaymentInfo', props.id, signal )
            .then( (result) => setDamagePaymentInfo(result) )
            .catch( error => restService.handleServerErrorsAxios( error, signal ) )
    }, [props.id, setDamagePaymentInfo])

    const afterUpdateDamagePaymentInfoCallback = useCallback( (changes) => {
        console.log( 'afterUpdateDamagePaymentInfoCallback', changes );
        if ( changes.payload.updatedProperties.includes( 'booked' ) ) {
            refreshDamagePaymentInfo();
        }
    }, [refreshDamagePaymentInfo]);

    const ids = useMemo( () => [parseInt(props.id)], [props.id] );
    websocketService.useAfterUpdateSubscription('damagePaymentInfo', ids, afterUpdateDamagePaymentInfoCallback);

    const showBaseData = () => {
        return (
            <Card className='mb-2'>
                <Card.Header><h4>{appService.nullSafeGet( damagePaymentInfo, 'id')}</h4></Card.Header>
                <Card.Body>
                    <Row>
                        <Col>
                            <ShowField label={'damage.label'} object={damagePaymentInfo} property={'damage.label'} type={'text'} link={{to: linkToDamage}} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ShowField label={'damagePaymentInfo.partner'} object={damagePaymentInfo} property={'partner.label'} type={'text'} />
                        </Col>
                        <Col>
                            <ShowField label={'damagePaymentInfo.description'} object={damagePaymentInfo} property={'description'} type={'text'} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ShowField label={'damagePaymentInfo.paymentInfoDate'} object={damagePaymentInfo} property={'paymentInfoDate'} type={'date'} />
                        </Col>
                        <Col>
                            <ShowField label={'damagePaymentInfo.amount'} object={damagePaymentInfo} property={'amount'} type={'text'}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ShowField label={'damagePaymentInfo.booked'} object={damagePaymentInfo} property={'booked'} type={'boolean'} />
                        </Col>
                        <Col>
                            <ShowField label={'damagePaymentInfo.bankAccountNr'} object={damagePaymentInfo} property={'bankAccountNr'} type={'text'} />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        )
    }

    const handleShowMarkAsBooked = useCallback( () => {
        setShowMarkAsBooked(true);
        damageService.getReserveAmounts(props.id)
            .then( (result) => {
                setReserveAmounts(result)
            })
            .catch( ( error ) => restService.handleServerErrorsAxios( error ) )
    }, [setShowMarkAsBooked, setReserveAmounts, props.id])

    const handleSubmitMarkAsBooked = useCallback( (data, event) => {
        event.preventDefault();
        event.stopPropagation();

        if ( damagePaymentInfo.amount > 0 && ( !data.fileChanges || !Object.values( data.fileChanges )?.some( fileChange => fileChange.action === 'create' ) ) ) {
            messageBoxService.display( t('damagePaymentInfo.markAsBook.noFile.content'), t('damagePaymentInfo.markAsBook.noFile.header'), [MessageBoxButtons.OK], {headerClassName: 'bg-danger text-white'} ).then( () => {} )
            return
        }

        setShowMarkAsBooked(false);

        let ras = []
        for( const rb in data ) {
            if ( rb.substring(0, 17) === 'newReserveAmount-' ) {
                const damageDetailId = rb.match( /newReserveAmount-(\d+)/ )[1];
                ras.push( { damageDetailId: damageDetailId, newReserveAmount: data[rb] } );
            }
        }

        damageService.markPaymentInfoAsBooked( damagePaymentInfo.id, ras, data.fileChanges )
    }, [setShowMarkAsBooked, damagePaymentInfo.id, damagePaymentInfo.amount, t])

    const handleRevertDamagePaymentInfoBook = useCallback( () => {
        messageBoxService.display( t('damagePaymentInfo.revertBook.message'), t('damagePaymentInfo.revertBook.label'), [MessageBoxButtons.YES, MessageBoxButtons.NO], {headerClassName: 'bg-danger text-white h5'} )
            .then( (button) => {
                if ( button === MessageBoxButtons.YES ) {
                    damageService.revertDamagePaymentInfoBook( damagePaymentInfo.id )
                        .then( (result) => setDamagePaymentInfo(result) )
                }
            })    }, [damagePaymentInfo.id, setDamagePaymentInfo, t])

    const showButtons = () => {
        if ( !damagePaymentInfo.booked ) {
            return (
                <>
                    <Link to={ `/damagePaymentInfo/edit/${props.id}` } className="btn btn-sm btn-primary mb-2 me-2">{t('default.edit')}</Link>
                    <Button className={"btn-sm mb-2"} variant={"warning"} onClick={ handleShowMarkAsBooked }>{t('damagePaymentInfo.markAsBooked')}</Button>
                </>
            )
        }
        else {
            return (
                <>
                    <Button className={"mb-2 me-2"} variant={"primary"} size={"sm"} onClick={ () => handlePrintDamagePaymentInfo(props.id) }>
                        <FontAwesomeIcon icon={faFilePdf}/>
                    </Button>
                    {!damagePaymentInfo.damageResolved && <Button className={"btn-sm mb-2"} variant={"danger"} onClick={ handleRevertDamagePaymentInfoBook }>{t('damagePaymentInfo.undoMarkAsBooked')}</Button> }
                </>
            )
        }
    }

    return (
        <>
            <Modal show={showMarkAsBooked} onHide={ () => setShowMarkAsBooked(false) } centered animation={false}>
                <FormProvider {...useFormObject}>
                    <Form onSubmit={ useFormObject.handleSubmit( handleSubmitMarkAsBooked ) }>
                        <Modal.Header closeButton>
                            <Modal.Title>{t( 'damagePaymentInfo.markAsBooked')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className={"mb-3"}>
                                <Col>
                                    {t('damagePaymentInfo.markAsBooked.question')}
                                </Col>
                            </Row>
                            { reserveAmounts != null ?
                                reserveAmounts.map( (reserveAmount) => {
                                    return (
                                        <Form.Group key={reserveAmount.damageDetailId} className={"mt-3"} as={Col} controlId="newReserveAmount">
                                            <Form.Label>{ t( 'damagePaymentInfo.markAsBooked.newReserveAmount', {damageDetailDescription: reserveAmount.damageDetailDescription, currentAmount: textService.formatNumber(reserveAmount.currentAmount)} ) }</Form.Label>
                                            <FormControl
                                                name={ `newReserveAmount-${reserveAmount.damageDetailId}` }
                                                type={ "number" }
                                                value={ reserveAmount.supposedAmount }
                                                required={ true }
                                            />
                                        </Form.Group>
                                    )
                                })
                                :
                                <Row>
                                    <DefaultSpinner loading={true}/>
                                </Row>
                            }

                            <Card className={"mt-3"}>
                                <Card.Header>{t('damagePaymentInfo.markAsBooked.upload.header')}</Card.Header>
                                <Card.Body>
                                    <p>{t(`damagePaymentInfo.markAsBooked.upload.body.${damagePaymentInfo.amount > 0?'positive':'negative'}Amount`)}</p>
                                    <FileUploader changesPropertyName={ 'fileChanges' } accept={{
                                        'application/pdf': ['.pdf'],
                                    }}/>
                                </Card.Body>
                            </Card>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" type={"submit"}>
                                {t('default.yes')}
                            </Button>
                            <Button variant="secondary" onClick={ () => setShowMarkAsBooked(false) }>
                                {t('default.no')}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </FormProvider>
            </Modal>
            {showBaseData()}
            <Row>
                <Col>
                    {showButtons()}
                </Col>
            </Row>
            { damagePaymentInfo && damagePaymentInfo.id &&
                <Tabs defaultActiveKey="damagePaymentInfoDetails" id="damagePaymentInfo-tabs">
                    <Tab eventKey="damagePaymentInfoDetails" title={t('damagePaymentInfo.tab.paymentInfoDetails')}>
                        <div className={"border-left border-right border-bottom rounded-bottom p-3"}>
                            <ShowDamagePaymentInfoDetails damagePaymentInfo={damagePaymentInfo}/>
                        </div>
                    </Tab>
                    <Tab eventKey="damagePaymentInfoAttachments" title={t('damagePaymentInfo.tab.attachments')}>
                        <div className={"border-left border-right border-bottom rounded-bottom p-3"}>
                            <Card className={"mb-2"}>
                                <Card.Header>{t('default.files')}</Card.Header>
                                <Card.Body>
                                    <Row>
                                        <ChonkyFileBrowser holderType={'damagePaymentInfo'} holderId={damagePaymentInfo.id} acceptFileTypes={{
                                            'application/pdf': ['.pdf'],
                                        }}/>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </div>
                    </Tab>
                </Tabs>
            }
        </>
    )
}

export default ShowDamagePaymentInfo
