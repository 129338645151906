import React from 'react';
import {InputGroup} from "react-bootstrap";
import {securityService} from "../_services/security.service";
import PlainValue from "./PlainValue";

function InputGroupText({children}) {
    const accessToProperty = securityService.useAccessToPropertyOfAllowedContext()

    if ( accessToProperty.ready ) {
        if ( accessToProperty.write ) {
            return <InputGroup.Text>{ children }</InputGroup.Text>
        } else if ( accessToProperty.read ) {
            return <PlainValue value={ children } className={ "pe-3" }/>
        }
    }

    return null
}

export default InputGroupText