import React from 'react';
import {useTranslation} from "react-i18next";
import {FormElements} from "./FormElements";
import Index from "../form/Index";
import ShowAccountingExport from "./ShowAccountingExport";
import bootstrapTableService from "../_components/bootstrapTable.service";

function AccountingExport({ match }) {
	const { t } = useTranslation();

	const columns = [{
		dataField: 'id',
		text: t('accountingExport.id.label'),
		hidden: true
	}, {
		dataField: 'createdOn',
		text: t('accountingExport.createdOn.label'),
		formatter: (cellContent, row) => bootstrapTableService.formatterDateTime(cellContent),
		sort: true
	}, {
		dataField: 'createdBy',
		text: t('accountingExport.createdBy.label'),
		sort: true
	}
	];

	return (
		<Index
			path={match.path}
			domainName={'accountingExport'}
			columns={columns}
			toString={ (object) => t('accountingExport.label') }
			formElements={<FormElements/>}
			sortField={'createdOn'}
			sortOrder={'desc'}
			allowShow={true}
			showElement={<ShowAccountingExport/>}
		/>
	);
}


export { AccountingExport };
