import React from 'react';
import {useTranslation} from "react-i18next";
import Index from "../form/Index";
import EditPolicyTargetCondition from "./EditPolicyTargetCondition";
import restService from "../_services/rest.service";

function PolicyTargetConditionIndex({ match }) {
	const { t } = useTranslation();

	const columns = [{
		dataField: 'id',
		text: t('policyTargetCondition.id.label'),
		hidden: true
	}, {
		dataField: 'name',
		sortField: 'name_sort',
		text: t('policyTargetCondition.name.label'),
		sort: true
	}
	];

	const getIndexData = ( page, sizePerPage, sort, searchText, searchOptions, signal ) => {
		let _searchOptions = { ...searchOptions }
		let queryStrings = _searchOptions.queryStrings = _searchOptions.queryStrings || []
		queryStrings.push({field: 'root', text: true})
		restService.addSearchTextProperties( 'policyTargetCondition', _searchOptions )

		return restService.getDomainInstancesList('policyTargetCondition', page, sizePerPage, sort, searchText, _searchOptions, undefined, undefined, signal)
	}

	return (
		<Index
			path={match.path}
			domainName={'policyTargetCondition'}
			columns={columns}
			toString={ (object) => object.name }
			sortField={'name_sort'}
			createElement={<EditPolicyTargetCondition/>}
			editElement={<EditPolicyTargetCondition/>}
			allowEdits={true}
			allowDeletions={true}
			allowShow={false}
			getIndexData={getIndexData}
		/>
	);
}

export { PolicyTargetConditionIndex };
