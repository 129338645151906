import {useContext, useEffect, useState} from "react";
import {WebsocketContext} from "../_components/WebsocketProvider";

const websocketService = {
	useWebSocketSubscriptionData,
	useAfterUpdateSubscription,
	useWebSocketSubscription
};

function useWebSocketSubscription( destination, callback ) {
	const [ready, stompClient] = useContext(WebsocketContext); // use it just like a hook

	useEffect( () => {
		let subscription

		const tryUnsubscribe = () => {
			if (subscription) {
				subscription.unsubscribe();
			}
		}

		tryUnsubscribe()
		if ( ready ) {
			subscription = stompClient.subscribe( destination, function ( data ) {
				let body
				if ( data.headers['content-type'].includes('application/json') ) {
					body = JSON.parse(data.body)
				}
				else {
					body = data.body;
				}

				callback(body)
			} );
		}

		return tryUnsubscribe

	}, [ready, destination, stompClient, callback])

}

function useWebSocketSubscriptionData( destination, defaultValue = undefined ) {
	const [ready, stompClient] = useContext(WebsocketContext); // use it just like a hook
	const [data, setData] = useState(defaultValue);

	useEffect( () => {
		let subscription

		const tryUnsubscribe = () => {
			if (subscription) {
				subscription.unsubscribe();
			}
		}

		tryUnsubscribe()
		if ( ready ) {
			subscription = stompClient.subscribe( destination, function ( data ) {
				if ( data.headers['content-type'].includes('application/json') ) {
					setData( JSON.parse(data.body) );
				}
				else {
					setData( data.body );
				}
			} );
		}

		return tryUnsubscribe

	}, [ready, destination, stompClient])

	return [data, setData]
}

function useAfterUpdateSubscription(className, ids = undefined, callback) {
	const [changes, setChanges] = websocketService.useWebSocketSubscriptionData(`/topic/afterCRUD/${className}`, undefined)

	useEffect( () => {
		if ( changes ) {
			if ( ids ) {
				if ( ids.some( it => String(it) === String(changes.payload.id) ) ) {
					callback(changes);
					setChanges(undefined);
				}
			}
			else {
				callback(changes);
				setChanges(undefined);
			}
		}
	}, [changes, setChanges, callback, ids])
}

export default websocketService
