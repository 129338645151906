import React from 'react';
import {Button, Toast} from "react-bootstrap";
import textService from "../_services/text.service";
import NotificationCommand from "./NotificationCommand";
import DynamicText from "../_components/DynamicText";
import {useTranslation} from "react-i18next";
import Snooze from "./Snooze";
import notificationService from "../_services/notification.service";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faPencil} from "@fortawesome/free-solid-svg-icons";
import TicketEditor from "./TicketEditor";
import restService from "../_services/rest.service";
import {TicketState} from "../_enum/enum";

function Ticket( {ticket, onUpdate}) {
	const {t} = useTranslation();
	const [showEditForm, setShowEditForm] = React.useState(false)

	const handleCloseTicket = () => {
		onUpdate(ticket.id, {state: TicketState.CLOSED})
	}

	const handleEditTicket = () => {
		setShowEditForm(true)
	}

	const handleCancelEditForm = () => {
		setShowEditForm(false)
	}

	const handleSubmitEditForm = (data) => {
		restService.updateDomainInstance('ticket', data.id, data)
			.then( () => {
				setShowEditForm(false)
			})
			.catch( (error) => {
				console.log(error)
			})
	}

	const ticketStyle = notificationService.getTicketStyle(ticket)

	return (
		<Toast bg={ticketStyle.bg} className={ticketStyle.textStyle}>
			<Toast.Header closeButton={false}>
				<div className={ "w-100 position-relative" }>
					{ ticket.state === TicketState.CLOSED &&
						<div style={ {
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							opacity: '40%'
						} }>
							<FontAwesomeIcon icon={ faCheck } size={ "10x" } color={ "green" }/>
						</div>
					}
					<div className={ "w-100" }>
						<span className={ `float-start fw-bold` }>{ ticket.title }</span>
						<Snooze notification={ ticket } onUpdate={ onUpdate } className={ 'float-end' } defaultValue={ ticket.dueDate }/>
						<small className={ 'float-end' }>
							{ textService.formatDateTime( Date.parse( ticket.createdOn ), {
								dateStyle: 'medium',
								timeStyle: 'short'
							} ) }
						</small>
						<div className={ "clearfix" }></div>
					</div>
					<hr/>
					<div className={ "w-100" }>
						<table className={ "float-start" }>
							<tbody>
							<tr>
								<td className={ "fw-bold" }>{ t( 'ticket.responsible' ) }:</td>
								<td className={ "ps-2" }>{ ticket.responsible ? ticket.responsible.label : '' }</td>
							</tr>
							<tr>
								<td className={ "fw-bold" }>{ t( 'ticket.state' ) }:</td>
								<td className={ "ps-2" }>{ t( `appvers.enums.TicketState.${ ticket.state }` ) }</td>
							</tr>
							<tr>
								<td className={ "fw-bold" }>{ t( 'ticket.dueDate' ) }:</td>
								<td className={ "ps-2" }>{ textService.formatDateTime( moment( ticket.dueDate ), { dateStyle: 'medium' } ) }</td>
							</tr>
							<tr>
								<td className={ "fw-bold" }>{ t( 'ticket.priority' ) }:</td>
								<td className={ "ps-2" }>{ t( `appvers.enums.TicketPriority.${ ticket.priority }` ) }</td>
							</tr>
							</tbody>
						</table>
						<div className={ "float-end" }>
							<TicketEditor ticket={ ticket } showForm={ showEditForm } onCancel={ handleCancelEditForm }
							              onSubmit={ handleSubmitEditForm }/>
							<div>
								<Button size={ "sm" } variant={ "outline-secondary" } onClick={ handleEditTicket }>
									<FontAwesomeIcon icon={ faPencil } className={ "cursor-pointer" }/>
								</Button>
							</div>
							{ ticket.state !== TicketState.CLOSED && <div className={'mt-1'}>
								<Button size={ "sm" } variant={ "outline-secondary" } onClick={ handleCloseTicket }>
									<FontAwesomeIcon icon={ faCheck } className={ "cursor-pointer" }/>
								</Button>
							</div> }
						</div>
						<div className={ "clearfix" }></div>
					</div>
				</div>
			</Toast.Header>
			<Toast.Body className={ ticketStyle.bodyBg }>
				<DynamicText htmlContent={ ticket.text } className={ "fading-div" }/>
				{ ticket.commands &&
					<div className={ "mt-3 mb-3 " }>
						{ ticket.commands.map( ( command, index ) => <NotificationCommand key={index} command={command}/> )}
					</div>
				}
			</Toast.Body>
		</Toast>
	)
}

export default Ticket