import React from 'react';
import {useTranslation} from "react-i18next";
import {FormElements} from "./FormElements";
import Index from "../form/Index";
import bootstrapTableService from "../_components/bootstrapTable.service";

function User({ match }) {
	const { t } = useTranslation();

	const columns = [{
		dataField: 'id',
		text: t('user.id.label'),
		hidden: true
	}, {
		dataField: 'username',
		text: t('user.username.label'),
		sort: true
	}, {
		dataField: 'email',
		text: t('user.email.label'),
		sort: true
	}, {
		dataField: 'nameOfUser',
		sortField: 'nameOfUser_sort',
		text: t('user.nameOfUser.label'),
		sort: true,
	}, {
		dataField: 'enabled',
		text: t('user.enabled.label'),
		sort: true,
		formatter: bootstrapTableService.formatterBoolean,
		headerAlign: 'center',
		align: 'center',
	}, {
		dataField: 'twoFactorEnabled',
		text: t('user.twoFactorEnabled.label'),
		sort: true,
		formatter: bootstrapTableService.formatterBoolean,
		headerAlign: 'center',
		align: 'center',
	}, {
		dataField: 'accountExpired',
		text: t('user.accountExpired.label'),
		sort: true,
		formatter: bootstrapTableService.formatterBoolean,
		headerAlign: 'center',
		align: 'center',
	}, {
		dataField: 'accountLocked',
		text: t('user.accountLocked.label'),
		sort: true,
		formatter: bootstrapTableService.formatterBoolean,
		headerAlign: 'center',
		align: 'center',
	}, {
		dataField: 'passwordExpired',
		text: t('user.passwordExpired.label'),
		sort: true,
		formatter: bootstrapTableService.formatterBoolean,
		headerAlign: 'center',
		align: 'center',
	}
	];

	return (
		<Index
			path={match.path}
			domainName={'user'}
			columns={columns}
			toString={ (object) => ( object.username ? object.username : object.username ) }
			formElements={<FormElements/>}
			sortField={'username'}
			allowEdits={true}
			allowDeletions={true}
		/>
	);
}

export { User };
