import React, {useCallback, useMemo} from "react";
import {Settlements} from "../settlement/Settlements";
import restService from "../_services/rest.service";
import {apiClientPrivate} from "../api/apiClient";

function PreviewPolicyVersionProfitParticipationSettlements( {policy}) {
	const policyId = useMemo( () => policy ? policy.id : null, [policy] )

	const getData = useCallback((page, sizePerPage, sort, searchText, searchOptions, signal) => {
		return new Promise( (resolve, reject) => {
			const params = {
				policyId: policyId,
				page: page,
				sizePerPage: sizePerPage,
				sort: sort,
				searchText: searchText,
				searchOptions: searchOptions,
			}
			apiClientPrivate.get( `/api/settlement/getCreditNoteProfitParticipations?${ new URLSearchParams( params ) }`, {
				signal: signal
			} )
				.then( r => restService.handleServerResponseAxios(r) )
				.then( json => {
					//debugger;
					resolve( {totalSize: json.count, data: json.data } );
				} )
				.catch( reject )
		})
	}, [policyId] );

	return (
		<>
			<div className={'mt-3'}>
				{policyId ? <Settlements getData={getData} hiddenFields={['policyNumber']}/> : null}
			</div>
		</>

	)
}

export default PreviewPolicyVersionProfitParticipationSettlements
