import React, {useMemo} from 'react';
import {securityService} from "../_services/security.service";
import PropTypes from "prop-types";
import Editable from "./Editable";

function EditableInAllowedContext( {children, onSave, editFormElement, allowedContextPropertyName} ) {
    const classSimpleName = securityService.useClassSimpleNameOfAllowedContext()
    const propertyName = useMemo( () => classSimpleName + '.' + allowedContextPropertyName, [classSimpleName, allowedContextPropertyName] )
    const accessToProperty = securityService.useAccessToProperty(propertyName)
    const isEditable = useMemo(() => {
        if ( accessToProperty.ready && accessToProperty.write ) {
            return true
        }
        else {
            return false
        }
    }, [accessToProperty])

    return (
        <Editable onSave={onSave} editFormElement={editFormElement} isEditable={isEditable}>
            {children}
        </Editable>
    )
}

EditableInAllowedContext.propTypes = {
    editFormElement: PropTypes.element.isRequired,
    onSave: PropTypes.func.isRequired,
    allowedContextPropertyName: PropTypes.string.isRequired,
}
export default EditableInAllowedContext
