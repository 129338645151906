import React, {useState} from 'react';
import {Form, Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useForm, FormProvider} from "react-hook-form";
import {FormElements} from "./FormElements";
import restService from "../_services/rest.service";
import {useHistory} from "react-router-dom";
import {apiClientPrivate} from "../api/apiClient";

function CreateEditBankToCustomerNotification() {
    const {t} = useTranslation();
    const [bankPaymentData] = useState({})
    const methods = useForm();
    const history = useHistory();
    const [controlDisabled, setControlDisabled] = useState(false);

    const handleFormSubmit = (data) => {
        save(data)
            .then( (id) => {
                setControlDisabled(false);
                handleSuccess(id);
            } )
            .catch( error => {
                setControlDisabled(false);
            })
    }

    const save = (data) => {
        setControlDisabled(true);
        return new Promise( (resolve, reject) => {
            let formData = new FormData()
            formData.append('file', data.file[0])
            apiClientPrivate.post( `/api/bankToCustomerNotification`, formData, {
                headers: {'Content-Type': 'multipart/form-data'}
            })
                .then( r => restService.handleServerResponseAxios( r ) )
                .then( json => resolve(json.id) )
                .catch( (error) => {
                    restService.handleServerErrorsAxios( error )
                    reject( error )
                });
        });
    }

    const handleSuccess = (id) => {
        history.push(`/bankToCustomerNotification/show/${id}`)
    }
    const handleCancel = () => {
        history.push('/bankToCustomerNotification')
    }

    return (
        <FormProvider {...methods}>
            <Form disabled={controlDisabled} method="post" encType={'multipart/form-data'} onSubmit={methods.handleSubmit(handleFormSubmit)}>
                <fieldset disabled={controlDisabled}>
                    <FormElements domainInstance={ bankPaymentData }/>
                    <div className={"d-grid gap-2 d-md-flex mb-2"}>
                        <Button variant="primary" type="submit" >
                            {t('default.create')}
                        </Button>
                        <Button variant="secondary" onClick={handleCancel}>
                            {t('default.cancel')}
                        </Button>
                    </div>
                </fieldset>
            </Form>
        </FormProvider>
    )
}
export default CreateEditBankToCustomerNotification
