import React, {useMemo} from 'react';
import {Button} from "react-bootstrap";
import {useFormContext} from "react-hook-form";
import appService from "../_services";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

function MergeHint({name, domain1, domain2, labelProperty}) {
    const {t} = useTranslation()
    const {setValue} = useFormContext();
    const [offeredValueIndex, setOfferedValueIndex] = React.useState(2)
    const value1 = appService.nullSafeGet(domain1, name)
    const value2 = appService.nullSafeGet(domain2, name)

    const offeredValue = useMemo( () => {
        return offeredValueIndex === 1 ? value1 : value2
    }, [offeredValueIndex, value1, value2] )

    const handleTest = () => {
        setValue(name, ( offeredValue || offeredValue === false || offeredValue === 0 ) ? offeredValue : '')
        setOfferedValueIndex( offeredValueIndex === 1 ? 2 : 1 )
    }

    const readableOfferedValue = useMemo( () => {
        let result
        if ( labelProperty ) {
            result = appService.nullSafeGet(offeredValue, labelProperty)
        }
        else {
            result = offeredValue
        }

        if ( result === false ) {
            result = t('default.no')
        }
        else if ( result === true ) {
            result = t('default.yes')
        }
        else if ( result === 0 ) {
            result = '0'
        }
        else if ( result === '' || result === null || result === undefined ) {
            result = t('default.empty')
        }

        return result
    }, [labelProperty, offeredValue, t] )

    const valuesAreEqual = useMemo( () => {
        if ( domain1 && domain2 ) {
            return appService.compareObjectsByValue( value1, value2 )
        }
        else {
            return true
        }

    }, [domain1, domain2, value1, value2] )

	return (
        !valuesAreEqual ?
            <Button onClick={ handleTest } className={"ms-2"} variant={ offeredValueIndex === 1 ? 'outline-primary' : "primary" } size={"sm"}>
                <span>{ readableOfferedValue === '' ? t('default.empty') : readableOfferedValue }</span>
            </Button>
            :
            <></>
    );
}

MergeHint.propTypes = {
    __TYPE: PropTypes.string,
};

MergeHint.defaultProps = {
    __TYPE: 'MergeHint',
};

export default MergeHint