import React, {useMemo, useRef, useState} from 'react';
import PropTypes from "prop-types";
import DomainTable from "../_components/DomainTable";
import bootstrapTableService from "../_components/bootstrapTable.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {faMessage} from "@fortawesome/free-regular-svg-icons";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Button, OverlayTrigger, Popover} from "react-bootstrap";
import restService from "../_services/rest.service";
import DeleteConfirmDialog from "../_components/DeleteConfirmDialog";
import {alertService} from "../_services";
import Secured from "../_components/Secured";

function Commissions( {searchOptions, hiddenFields, policyVersionId}) {
    const {t} = useTranslation();
    const domainTableRef = useRef()
    const [rowToBeDeleted, setRowToBeDeleted] = useState({});
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const handleCloseDeleteConfirm = () => setShowDeleteConfirm(false);

    const columns = useMemo( () => {
        const _columns = [
            {
                dataField: 'id',
                text: t('default.id'),
                hidden: true
            },
            {
                dataField: 'policyId',
                text: t('commission.policyId'),
            },
            {
                dataField: 'partner.label',
                sort: true,
                sortField: 'partner.label_sort',
                text: t('commission.partner'),
                headerStyle: (column, colIndex) => {
                    return { width: '20%' };
                },
                formatter: (cellContent, row) => {
                    return <Link to={restService.getTo( row.partner, 'show' ) || ''} target={"_blank"} rel={"noopener noreferrer"}>{row.partner.label}</Link>
                },
            },
            {
                dataField: 'percent',
                text: t('commission.percent'),
                formatter: bootstrapTableService.formatterPercent,
                classes: (cell, row, rowIndex, colIndex) => 'text-end',
                headerStyle: (column, colIndex) => {
                    return { textAlign: 'end',  width: '5%' };
                },
            },
            {
                dataField: 'amount',
                sort: true,
                text: t('commission.amount'),
                formatter: bootstrapTableService.formatterAmount,
                classes: (cell, row, rowIndex, colIndex) => 'text-end',
                headerStyle: (column, colIndex) => {
                    return { textAlign: 'end',  width: '5%' };
                },
            },
            {
                dataField: 'year',
                sort: true,
                text: t('commission.year'),
                classes: (cell, row, rowIndex, colIndex) => 'text-end',
                headerStyle: (column, colIndex) => {
                    return { textAlign: 'end',  width: '5%' };
                },
            },
            {
                dataField: 'commissionDate',
                sort: true,
                text: t('commission.commissionDate'),
                formatter: bootstrapTableService.formatterDate,
                classes: (cell, row, rowIndex, colIndex) => 'text-center',
                headerStyle: (column, colIndex) => {
                    return { textAlign: 'center',  width: '7%' };
                },
            },
            {
                dataField: 'settlement.label',
                text: t('commission.settlementNumber'),
                formatter: (cellContent, row) => {
                    let result = undefined;
                    if ( row.settlement ) {
                        result = <Link to={ restService.getTo( row.settlement, 'show' ) || '' } target={ "_blank" }
                                     rel={ "noopener noreferrer" }>{ row.settlement.label }</Link>
                    }
                    return result;
                },
            },
            {
                dataField: '',
                text: '',
                sort: false,
                classes: (cell, row, rowIndex, colIndex) => 'text-end',
                formatter: (cellContent, row) => {
                    const editButtons = () => {
                        return (
                            <>
                                <Secured granted={`/api/commission/${ row.id }/edit#GET`}>
                                    <Link className={`btn btn-sm btn-primary ms-1`} to={ restService.getTo( row, `edit` ) } target={"_blank"} rel={"noopener noreferrer"}>
                                        <FontAwesomeIcon icon={faPencilAlt}/>
                                    </Link>
                                </Secured>
                                <Secured granted={`/api/commission/${row.id}#DELETE`}>
                                    <Button
                                        className={"ms-1"}
                                        variant={ 'danger' }
                                        size={ 'sm' }
                                        onClick={ ( event ) => {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            setRowToBeDeleted( row );
                                            setShowDeleteConfirm( true );
                                        } }>
                                        <FontAwesomeIcon icon={ faTrashAlt }/>
                                    </Button>
                                </Secured>
                            </>
                        )
                    }

                    const creditNoteCommission = () => {
                        return (
                            <Link className={`ms-1`} to={ restService.getTo( row.creditNoteCommission, `show` ) } target={"_blank"} rel={"noopener noreferrer"}>
                                {row.creditNoteCommission.label}
                            </Link>
                        )
                    }

                    if ( row.isIVSCommission ) {
                        return <></>
                    }
                    else if ( row.creditNoteCommission ) {
                        return creditNoteCommission()
                    }
                    else {
                        return editButtons()
                    }
                },
            },
            {
                dataField: 'settlement.memo',
                text: t('commission.memo'),
                headerStyle: (column, colIndex) => {
                    return { textAlign: 'center',  width: '3%' };
                },
                classes: (cell, row, rowIndex, colIndex) => 'text-center',
                formatter: (cellContent, row) => {
                    if ( row.memo ) {
                        return (
                            <OverlayTrigger
                                delay={ { show: 250, hide: 400 } }
                                overlay={
                                    <Popover>
                                        <div className={"m-2 font-si"} style={{whiteSpace: "pre-wrap"}}>
                                             { row.memo }
                                         </div>
                                    </Popover>
                                }
                                placement={ 'left' }
                            >
                                <div>
                                    <FontAwesomeIcon icon={faMessage} />
                                </div>
                            </OverlayTrigger>
                        );
                    }
                },
            },
        ]
        return _columns.filter( (column) => !hiddenFields.includes( column.dataField ) );
    }, [hiddenFields, t])

    function deleteDomainInstance() {
        try {
            restService.deleteDomainInstance( 'commission', rowToBeDeleted.id )
                .catch( (error, signal) => {
                    restService.handleServerErrorsAxios(error, signal);
                })

        }
        catch (e) {
            alertService.error(e);
        }
    }

    return (
        <>
            <Secured granted={`/api/commission/create`}>
                <Link className={`btn btn-sm btn-primary ms-1 mt-3 mb-3`} to={ `/commission/create/${policyVersionId}` } target={"_blank"} rel={"noopener noreferrer"}>
                    {t('default.createWhat', {what: t('commission.label')})}
                </Link>
            </Secured>
            <DomainTable
                ref={domainTableRef}
                domainName={'commission'}
                columns={columns}
                searchOptions={searchOptions}
                // rowClasses={rowClasses}
                sortField={'commissionDate'}
                sortOrder={'desc'}
            />
            <DeleteConfirmDialog
                show={showDeleteConfirm}
                onHide={handleCloseDeleteConfirm}
                bodyText={t('deleteConfirm.body', {name: t('commission.label')})}
                onDelete={deleteDomainInstance}
            />
        </>
    )
}


Commissions.defaultProps = {
    hiddenFields: []
};

Commissions.propTypes = {
    namedCriteria: PropTypes.object,
    hiddenFields: PropTypes.array
};

export { Commissions };
