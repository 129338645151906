import React from 'react';
import {Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

function ConnectedUsers( {domainInstance} ) {
    const {t} = useTranslation();

    const renderConnectedUsers = () => {
        if (!domainInstance.connectedUsers) {
            return null
        }
        let counter = 0
        return (
            <Card>
                <Card.Header>{t('connectedUsers.panel.label')}</Card.Header>
                <Card.Body>
                    <ul>
                        {domainInstance.connectedUsers.map( (user) => {
                                let result = <Link to={`/user/show/${user.id}`} target={"_blank"} rel={"noopener noreferrer"}>
                                    {user.username}
                                </Link>
                                counter++
                                if (counter < domainInstance.connectedUsers.length) {
                                    result += ', '
                                }
                                return result
                            })
                        }
                    </ul>
                </Card.Body>
            </Card>
        )
    }

    return (
        <>
            {renderConnectedUsers()}
        </>
    )
}

export default ConnectedUsers
