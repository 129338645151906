import React, {useCallback, useMemo, useState} from 'react';
import {Card} from "react-bootstrap";
import PropTypes from "prop-types";
import restService from "../../_services/rest.service";
import {alertService} from "../../_services";
import {useTranslation} from "react-i18next";
import {invoiceService} from "../../_services/invoice.service";
import { useHistory } from 'react-router-dom';
import SubmitButton from "../SubmitButton";

function CreateSettlementBatch( {getInvoicesTable, filter, settlementBatch} ) {
	const {t} = useTranslation();
	const history = useHistory();
	const [data, setData] = useState({objects: []})
	const params = useMemo( () => {
		return {...data, ...{ settlementBatchId: settlementBatch.id, className: settlementBatch.className } }
	}, [data, settlementBatch.className, settlementBatch.id])
	const [objects, setObjects] = invoiceService.useSettlementBatchObjects( settlementBatch.className, params )
	const [isSubmitting, setIsSubmitting] = useState(false)

	const handleCreateInvoices = () => {
		setIsSubmitting(true);
		restService.saveDomainInstance( settlementBatch.className, {...params, ...{objects: objects} } )
			.then( (settlementBatch) => {
				history.push(`/${settlementBatch.className}/show/`+settlementBatch.id)
				alertService.success( t( 'default.updated', { what: t( 'policyVersionSettlementBatch.label' ) } ), { keepAfterRouteChange: true } );
				setIsSubmitting(false);
			} )
			.catch( error => {
				restService.handleServerErrorsAxios( error )
				setIsSubmitting(false);
			} );
	}

	const clearData = useCallback( () => {
		setObjects([]);
	}, [setObjects])

	const invoicesTable = useMemo( () => {
		return getInvoicesTable(objects)
	}, [objects, getInvoicesTable]);

	return (
		<>
			<Card className={ "mb-2" }>
				<Card.Header>{ t( 'policyVersionSettlementBatch.fromToQuestion.header' ) }</Card.Header>
				<Card.Body>
					{React.cloneElement(filter, {...{ settlementBatch: settlementBatch, setData: setData, clearData: clearData }})}
				</Card.Body>
			</Card>

			<Card className={ "mt-4" }>
				<Card.Header>
					{ t( 'createSettlementBatch.invoicesCount', {invoicesCount: invoicesTable.size} ) }
				</Card.Header>
				<Card.Body>
					{ invoicesTable.element }
				</Card.Body>
			</Card>
			<SubmitButton isSubmitting={isSubmitting} type={ "button" } variant={ 'primary' } className={ "mt-4" }
			        onClick={ () => handleCreateInvoices() }>{ invoicesTable.size > 1 ? t( 'policyVersionSettlementBatch.create.label.plural', { count: invoicesTable.size } ) : t( 'policyVersionSettlementBatch.create.label', { count: invoicesTable.size } ) }</SubmitButton>
		</>
	)
}

CreateSettlementBatch.propTypes = {
	filter: PropTypes.element.isRequired,
	getInvoicesTable: PropTypes.func.isRequired,
	settlementBatch: PropTypes.object,
}

export default CreateSettlementBatch
