export const ConfiguratorTargetMethod = Object.freeze({
	INTERVALS:'INTERVALS',
	GRADUAL:'GRADUAL',
});

export const ConfiguratorTargetType = Object.freeze({
	VALUE:'VALUE',
	COUNT:'COUNT',
});

export const TariffUnit = Object.freeze({
	PERCENT:'PERCENT',
	CURRENCY:'CURRENCY',
});

export const PartnerType = Object.freeze({
	PERSON:'PERSON',
	COMPANY:'COMPANY',
});

export const InvoiceCycle = Object.freeze({
	YEARLY:'YEARLY',
	HALF_YEARLY:'HALF_YEARLY',
	QUARTERLY:'QUARTERLY',
	MONTHLY:'MONTHLY'
});

export const BaseTargetNameType = Object.freeze({
	DEFAULT:'DEFAULT',
	MANUAL:'MANUAL',
	BUILDING_NAME:'BUILDING_NAME'
});

export const PolicyVersionStatus = Object.freeze({
	ACTIVE:'ACTIVE',
	INACTIVE:'INACTIVE',
	REPLACEMENT:'REPLACED',
	IN_PROCESS:'IN_PROCESS',
	IN_PROCESS_CANCELLED:'IN_PROCESS_CANCELLED',
	TO_BE_ACTIVATED:'TO_BE_ACTIVATED',
	OTHER: 'OTHER',
	isInProcess: ( status, orInProcessCancelled ) => {
		//there is analogous function in the backend in PolicyVersionStatus
		return status === 'IN_PROCESS' || (orInProcessCancelled && status === 'IN_PROCESS_CANCELLED') || status === 'TO_BE_ACTIVATED';
	},
	isNotInProcessQueryString: () => {
		return [
			{field: 'state', text: 'IN_PROCESS_CANCELLED', innerPredicate: 'MUST_NOT'},
			{field: 'state', text: 'IN_PROCESS', innerPredicate: 'MUST_NOT'},
			{field: 'state', text: 'TO_BE_ACTIVATED', innerPredicate: 'MUST_NOT'}
		]
	}
});

export const SettlementType = Object.freeze( {
	INVOICE_PERIODIC: 'INVOICE_PERIODIC',
	INVOICE_PRO_RATA: 'INVOICE_PRO_RATA',
	CREDIT_NOTE_POLICY_VERSION: 'CREDIT_NOTE_POLICY_VERSION',
	INVOICE_DAMAGE_PARTICIPATION_POLICY_HOLDER: 'INVOICE_DAMAGE_PARTICIPATION_POLICY_HOLDER',
	INVOICE_DAMAGE_PARTICIPATION_INVOLVED_INSURANCE: 'INVOICE_DAMAGE_PARTICIPATION_INVOLVED_INSURANCE',
	CREDIT_NOTE_DAMAGE_PAYMENT_NOTIFICATION: 'CREDIT_NOTE_DAMAGE_PAYMENT_NOTIFICATION',
	INVOICE_PARTICIPATION: 'INVOICE_PARTICIPATION',
	CREDIT_NOTE_PARTICIPATION: 'CREDIT_NOTE_PARTICIPATION',
	INVOICE_PROFIT_PARTICIPATION: 'INVOICE_PROFIT_PARTICIPATION',
	CREDIT_NOTE_PROFIT_PARTICIPATION: 'CREDIT_NOTE_PROFIT_PARTICIPATION',
	CREDIT_NOTE_COMMISSION: 'CREDIT_NOTE_COMMISSION',
	CREDIT_NOTE_OTHER_LEADERSHIP_DISCOUNT: 'CREDIT_NOTE_OTHER_LEADERSHIP_DISCOUNT'
});

export const SettlementCancelReason = Object.freeze( {
	NOT_CANCELLED: 'NOT_CANCELLED',
	OTHER: 'OTHER'
});

export const UserAttachmentType = Object.freeze( {
	SIGNATURE: 'SIGNATURE',
	OTHER: 'OTHER'
});

export const PaymentState = Object.freeze( {
	UNPAID: 'UNPAID',
	PAID_PARTIALLY: 'PAID_PARTIALLY',
	PAID: 'PAID',
	OVERPAID: 'OVERPAID',
	PAYMENT_NOT_NECESSARY: 'PAYMENT_NOT_NECESSARY',
});

export const AccountingDocumentEvent = Object.freeze( {
	BOOK: 'BOOK',
	CANCEL: 'CANCEL',
});

export const AccountingDocumentOrigin = Object.freeze( {
	FINANCE: 'FINANCE',
	DEBTORS: 'DEBTORS',
	CREDITORS: 'CREDITORS',
	OTHER: 'OTHER',
});

export const InnerPredicateEnum = Object.freeze( {
	MUST: 'MUST',
	MUST_NOT: 'MUST_NOT',
	AT_LEAST: 'AT_LEAST',
	GREATER_THAN: 'GREATER_THAN',
	AT_MOST: 'AT_MOST',
	LESS_THAN: 'LESS_THAN'
});

export const SecuredType = Object.freeze( {
	ANY: 'ANY',
	ALL: 'ALL',
});

export const ConfiguratorNodeAccessType = Object.freeze( {
	ALLOWED: 'ALLOWED',
	DENIED: 'DENIED',
});

export const PolicyTargetConditionOperator = Object.freeze( {
	AND: 'AND',
	OR: 'OR',
});

export const NotAllowedGenerateType = Object.freeze( {
	GENERATE_NOTHING: 'GENERATE_NOTHING',
	GENERATE_BUT_HIDE: 'GENERATE_BUT_HIDE',
});

export const PlainMessageState = Object.freeze( {
	UNREAD: 'UNREAD',
	READ: 'READ',
});

export const TicketPriority = Object.freeze( {
	IMMEDIATE: 'IMMEDIATE',
	HIGH: 'HIGH',
	NORMAL: 'NORMAL',
	LOW: 'LOW',
});

export const TicketState = Object.freeze( {
	OPEN: 'OPEN',
	DISCUSS: 'DISCUSS',
	CLOSED: 'CLOSED',
});


export const UploadStatus = Object.freeze({
	WAITING: 'WAITING',
	UPLOADING: 'UPLOADING',
	DONE: 'DONE',
	CANCELED: 'CANCELED',
	REMOVED: 'REMOVED',
	ERROR: 'ERROR',
});


export function getEnumType(enumClassName, value) {
	return { enumType: enumClassName, name: value }
}