import restService from "../_services/rest.service";
import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {policyService} from "../_services/policy.service";
import {Table} from "react-bootstrap";

function PolicyVersionList( {policyId, excludePolicyVersionId} ) {
	const {t} = useTranslation()

	const searchOptions = useMemo( () => {
		return {
			queryStrings: [
				{
					field: "policy.id",
					text: `${policyId}`
				},
				{
					field: "id",
					innerPredicate: "MUST_NOT",
					text: `${excludePolicyVersionId}`
				}
			]
		}
	}, [policyId, excludePolicyVersionId])

	const [policyVersions,,,,loaded] = restService.useDomainInstancesList('policyVersion', undefined, undefined, 'policyNumber_sort desc', undefined, searchOptions)

	const policyVersionsItems = useMemo( () => {
		let result = undefined
 		if ( policyVersions.length > 0 ) {
			result = policyVersions.map( ( pv, index ) => {
				return (
					<tr key={ index }>
						<td>{ pv.policyNumber }</td>
						<td className={policyService.getPolicyStateClass(pv.state)}>{ t('appvers.enums.PolicyVersionStatus.' + pv.state) }</td>
						<td colSpan="2"/>
						<td style={{textAlign: 'center'}}><NavLink className={'btn btn-primary btn-sm'} to={'/policyVersion/show/'+pv.id}><FontAwesomeIcon icon={ faEye }/></NavLink></td>
					</tr>
				)
			} )
		}
		else {
			if ( loaded ) {
				result = <tr>
					<td colSpan="5">{ t( 'policyVersionList.noPolicyVersions', {policyId: policyId} ) }</td>
				</tr>
			}
		}
		return result;
	}, [policyVersions, t, loaded, policyId]);

	return (
		<>
			<Table className={'policy-versions-items'}>
				<colgroup>
					<col style={{width: '120px'}}/>
					<col style={{width: '120px'}}/>
					<col style={{width: '100%'}}/>
					<col style={{width: '100px'}}/>
					<col style={{width: '100px'}}/>
				</colgroup>
				<tbody>
					{policyVersionsItems}
				</tbody>
			</Table>
		</>
	)
}

export default PolicyVersionList
