import React, {useMemo} from 'react';
import {damageService} from "../_services/damage.service";
import SimpleTable from "../_components/SimpleTable";
import {Link} from "react-router-dom";
import textService from "../_services/text.service";
import moment from "moment/moment";
import {useTranslation} from "react-i18next";
import restService from "../_services/rest.service";
import {Alert} from "react-bootstrap";
import Trans from "../_components/Trans";
import appService from "../_services";

function InterimBillingDamages() {
    const useDamagesForInterimBilling = damageService.useDamagesForInterimBilling();
	const {t} = useTranslation();

	appService.useTitle( t('reports.interimBillingDamages.title') )

	const columns = useMemo( () => {
		return [
			{
				key: 'damage',
				label: t( 'damage.label' ),
				formatter: ( ibd ) => <Link to={restService.getTo( ibd.damage, 'show' )} target={"_blank"} rel={"noopener noreferrer"}>{ibd.damage.label}</Link>
			},
			{
				key: 'policyVersion',
				label: t( 'policyVersion.label' ),
				formatter: ( ibd ) => <Link to={restService.getTo( ibd.policyVersion, 'show' )} target={"_blank"} rel={"noopener noreferrer"}>{ibd.policyVersion.label}</Link>
			},
			{
				key: 'dateOfDamage',
				label: t( 'damage.dateOfDamage' ),
				formatter: ( ibd ) => textService.formatDateTime( moment( ibd.dateOfDamage ), { dateStyle: 'medium' } )
			},
			{
				key: 'damageEvent',
				label: t( 'damage.damageEvent' ),
				formatter: ( ibd ) => ibd.damageEvent ? ibd.damageEvent.label : ''
			}
		]
	}, [t])


	return (
		<>
			<h2>{t('reports.interimBillingDamages.title')}</h2>
			<Alert variant={'info'}>
				<Trans i18nKey={'interimBillingDamages.tooltip'}/>
			</Alert>
			<SimpleTable columns={columns} rows={useDamagesForInterimBilling}/>
		</>
	)
}

export default InterimBillingDamages