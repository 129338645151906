import React from 'react';
import {Col, Row} from "react-bootstrap";
import ShowField from "../form/ShowField";
import ChangeBankAccount from "../_components/ChangeBankAccount";
import restService from "../_services/rest.service";
import Secured from "../_components/Secured";

function FormElementsCreditNote({ settlement, onRefreshSettlement }) {
    const handleOnBankAccountChanged = (newBankAccount) => {
        restService.updateDomainInstance( 'settlement', settlement.id, { version: settlement.version, bankAccountNr: newBankAccount } )
            .then( json => {
                //this json is returned from update() action, that doesn't use views/settlement/_settlement
                onRefreshSettlement()
            })
            .catch( (error) => {
                restService.handleServerErrorsAxios(error)
            });
    }

	return (
        <Row>
            <Col lg={ 4 }><ShowField label={ 'settlement.bankAccountNr' } object={ settlement }
                                     property={ 'bankAccountNr' } type={ 'text' }
                                     size={ 12 }/></Col>

            <Secured granted={`/api/settlement/${settlement.id}/edit#GET`}>
                <ChangeBankAccount onBankAccountChanged={handleOnBankAccountChanged} defaultBankAccountNr={settlement.bankAccountNr} partner={settlement.partner}/>
            </Secured>
        </Row>
	)
}

export default FormElementsCreditNote
