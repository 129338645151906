import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import AppMenuItem from "./AppMenuItem";
import appService from "./_services";
import i18n from "i18next";
import {faAnglesLeft} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router-dom";

function AppMenu() {
	const allAllowedRoutes = appService.useAllowedRoutes()
	const [routes, setRoutes] = useState( [] )
	const history = useHistory()
	const queryParams = new URLSearchParams(window.location.search)
	const [lastRoute, setLastRoute] = useState( null )

	appService.useTitle( lastRoute ? lastRoute.sidebar() : null )

	let menu
	if ( queryParams.has('menu') ) {
		menu = queryParams.get( 'menu' ).split( ',' ).filter( key => !isNaN( parseInt(key) ) ).map( key => parseInt(key) )
	}
	else {
		menu = []
	}



	useEffect(() => {
		if ( allAllowedRoutes && allAllowedRoutes.length > 0 ) {
			let routes = allAllowedRoutes
			let lastRoute
			menu.forEach( key => {
				const route = routes.find( route => route.key === key )
				if ( !route ) {
					throw new Error( `Route with key:${ key } not found` )
				}
				routes = route.routes
				lastRoute = route
			} )
			setRoutes( routes )
			setLastRoute( lastRoute )
		}
	}, [menu, allAllowedRoutes] )

	const handleOnMenuClick = (key) => {
		let newMenu = menu
		if ( !key ) {
			newMenu.pop()
		}
		else {
			newMenu.push( key )
		}

		const search = new URLSearchParams( {menu: newMenu} )
		history.push(`${window.location.pathname}?${search.toString()}`);
	}

	function getRoutes() {
		let result
		if ( menu.length > 0 ) {
			const back = {
				sidebar: () => i18n.t('default.back'),
				icon: faAnglesLeft,
				key: undefined,
				variant: 'secondary',
			}
			result = [ back, ...routes ]
		}
		else {
			result = routes
		}

		return result
	}

	return (
		<Container style={{maxWidth: "800px"}}>
			<Row className={"gx-3 gy-3"}>
				{(getRoutes().filter( (route) => route.sidebar !== undefined )).map((route, index) =>
					<Col key={index}
						xs={{ span: 6 }} sm={{ span: 4 }} md={{ span: 3 }}
						lg={{ span: 3 }} xl={{ span: 3 }}
					><AppMenuItem route={route} onMenuClick={handleOnMenuClick}/></Col>
				)}
			</Row>
		</Container>
	)
}

export default AppMenu