import React from 'react';
import Attachment from "./Attachment";
import {useFormContext} from "react-hook-form";

export default function Attachments({attachments, changesPropertyName, showImages, onFileChange, additionalFileProperty, readOnly}) {
    const { setValue, getValues } = useFormContext();

    const getFileChanges = () => {
        let fileChanges = getValues(changesPropertyName);
        if ( !fileChanges ) {
            fileChanges = {};
        }
        return fileChanges;
    }

    const handleAttachmentChange = (status, id) => {
        let fileChanges = getFileChanges();
        if ( status === 'delete' ) {
            fileChanges[id] = {action: status}
        }
        else {
            delete fileChanges[id]
        }
        setValue( changesPropertyName, fileChanges );
        if ( onFileChange ) {
            onFileChange( fileChanges )
        }
    }

    return (
        attachments.length > 0 && attachments.map( (attachment,index ) => {
            return <Attachment key={index} index={index} attachment={attachment} onChange={!readOnly ? handleAttachmentChange : undefined} showImages={showImages}/>
        })
    )

}
