import React from 'react';
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import settingService from "./_services/setting.service";

function AppMenuItem({ route, onMenuClick }) {
	const {_menuColor} = settingService.useSetting(['_menuColor']);
	const menuItemStyle = _menuColor ? {color: _menuColor} : {};

	const getTile = ( label, icon, variant ) => {
		return (
			<span
				className={"menu-item text-center menu-item appvers-style p-0"} style={ menuItemStyle }
			>
				<div className={"vert-horz-centered w-100"} style={{width:'150px', height:'150px'}}>
					{ icon ? <div className={"mb-2"}><FontAwesomeIcon icon={icon} size={"3x"} /></div> : undefined }
					<div className={"w-100 d-inline-block text-truncate text-wrap"}>{label}</div>
				</div>
			</span>
		)
	}

	const link = () => {
		return <NavLink to={route.link? route.link : route.path} activeClassName="active-menu-item" className="px-0 align-middle text-secondary">
			{ getTile( route.sidebar(), route.icon, route.variant ) }
		</NavLink>
	}

	const menu = () => {
		return <div className={"cursor-pointer"} onClick={ () => onMenuClick(route.key) }>
			{ getTile( route.sidebar(), route.icon, route.variant ) }
		</div>
	}

	return !route.key || route.routes ? menu() : link()
}

AppMenuItem.propTypes = {
	route: PropTypes.object.isRequired,
}

export default AppMenuItem
