import {useEffect, useState} from "react";
import restService from "./rest.service";
import {apiClientPrivate} from "../api/apiClient";

export const configuratorNodeOrderService = {
	useConfiguratorNodeOrders,
	saveConfiguratorNodeOrders
}

function useConfiguratorNodeOrders() {
	const [ configuratorNodeOrders, setConfiguratorNodeOrders ] = useState([])

	useEffect( () => {
		const controller = new AbortController();
		apiClientPrivate.get('/api/configuratorNode/getConfiguratorNodeOrders', {
			signal: controller.signal
		})
			.then( ( response ) => ( restService.handleServerResponseAxios( response ) ) )
			.then( ( json ) => {
				return setConfiguratorNodeOrders( json.configuratorNodeOrders )
			} )
			.catch( (error) => restService.handleServerErrorsAxios( error, controller.signal ) );
		return function cleanup() {
			controller.abort()
		}
	}, [])

	return [ configuratorNodeOrders, setConfiguratorNodeOrders ];
}

function saveConfiguratorNodeOrders( configuratorNodeOrders ) {
	return new Promise( (resolve, reject) => {
		const params = { data: JSON.stringify( configuratorNodeOrders ) }
		apiClientPrivate.post( `/api/configuratorNode/saveConfiguratorNodeOrders`, params)
			.then( r => restService.handleServerResponseAxios( r ) )
			.then( resolve )
			.catch( reject );
	});
}
