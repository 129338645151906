import {useEffect, useState} from "react";
import restService from "./rest.service";
import {apiClientPrivate} from "../api/apiClient";

const reportMessageService = {
	useComposedReportMessage,
	saveComposedReportMessage
};

function useComposedReportMessage( code ) {
	const [composedReportMessage, setComposedReportMessage] = useState( [] )

	useEffect( () => {
		if ( code ) {
			const controller = new AbortController();
			const params = {
				code: code
			}

			apiClientPrivate.get( `/api/reportMessage/getComposedReportMessage?` + new URLSearchParams( params ) )
				.then( r => restService.handleServerResponseAxios( r ) )
				.then( json => {
					setComposedReportMessage( json )
				} )
				.catch( error => {
					restService.handleServerErrorsAxios( error, controller.signal );
				} );
		}
	}, [code] );

	return composedReportMessage
}

function saveComposedReportMessage(composedReportMessage) {
	return new Promise( (resolve, reject) => {
		const controller = new AbortController();
		const params = {
			composedReportMessage: JSON.stringify(composedReportMessage)
		}

		apiClientPrivate.get( `/api/reportMessage/saveComposedReportMessage?` + new URLSearchParams( params ), {
			signal: controller.signal,
		} )
			.then( r => restService.handleServerResponseAxios(r) )
			.then( json => {
				resolve();
			} )
			.catch( error => {
				reject()
			} );
	})
}

export default reportMessageService;
