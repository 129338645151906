import React, {useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import PropTypes from "prop-types";
import paginationFactory from "react-bootstrap-table2-paginator";
import restService from "../_services/rest.service";
import TableSearch from "../form/TableSearch";
import {domainTableService} from "../_services/domainTable.service";
import websocketService from "../_services/websocket.service";

const DomainTable = React.forwardRef(({
										  id,
										  columns,
										  getData,
										  domainName,
										  namedCriteria,
										  rowClasses,
										  sortField,
										  sortOrder,
										  sortType,
										  sizePerPage,
										  searchText,
										  searchOptions,
										  showSearchbar,
										  showPagination,
										  page,
										  onTableStateChanged,
										  expandRow,
										  rowStyle,
										  templateSuffix
	}, ref) => {
	const thisRef = useRef();
	const [_sortField, set_sortField] = useState( sortField )
	const [_sortOrder, set_sortOrder] = useState( sortOrder );
	const [_searchText, set_searchText] = useState( searchText );
	const [_searchOptions, set_searchOptions] = useState(); //eslint-disable-line no-unused-vars
	const [previousSearchOptions, setPreviousSearchOptions] = useState();
	const [_sizePerPage, set_sizePerPage] = useState( sizePerPage );
	const [_page, set_Page] = useState( page );
	const internalSearchOptions = useMemo( () => {
		if ( !previousSearchOptions ) {
			setPreviousSearchOptions( searchOptions )
			return searchOptions
		}
		else if ( JSON.stringify( previousSearchOptions ) !== JSON.stringify( searchOptions ) ) {
			setPreviousSearchOptions( searchOptions )
			set_Page(1)
			return searchOptions
		}
		else if ( _searchOptions ) {
			return _searchOptions
		}
		else {
			return searchOptions
		}
	},[_searchOptions, searchOptions, previousSearchOptions, setPreviousSearchOptions, set_Page])
	const [data, setData] = useState([]);
	const [totalSize, setTotalSize] = useState(0);
	const [reloadTimeoutId, setReloadTimeoutId] = useState(undefined);

	useImperativeHandle(
		ref,
		() => ({
			onRowChanged(row) {
				const idx = data.findIndex( (r) => r.id === row.id )
				if ( idx >= 0 ) {
					data.splice( idx, 1, row )
					setData( [...data] );
				}
			},
			onRowDeleted(row) {
				const idx = data.findIndex( (r) => r.id === row.id )
				if ( idx >= 0 ) {
					loadData()
				}
			},
		}),
	)

	const sort = useMemo( () => {
		const sfColumn = columns.find((column) => column.dataField === _sortField);
		let sf = sfColumn ? sfColumn.sortField : undefined;
		let st = sfColumn ? sfColumn.sortType : undefined;
		if (undefined === sf && sfColumn && sfColumn.sort) {
			sf = sfColumn.dataField;
		}
		let sort = undefined
		if ( sf ) {
			sort = `${ st ? ( '[' + st + ']' ) : '' }${ sf }${ _sortOrder ? ( ' ' + _sortOrder ) : '' }`
		}
		return sort
	}, [_sortField, _sortOrder, columns])

	const loadData = useCallback( ( signal ) => {
			function _getData() {
				if (getData) {
					return getData(_page, _sizePerPage, sort, _searchText, internalSearchOptions, signal)
				} else {
					return restService.getDomainInstancesList(domainName, _page, _sizePerPage, sort, _searchText, internalSearchOptions, namedCriteria, templateSuffix, signal)
				}
			}

		_getData().then((result) => {
				if ( !signal || (signal && !signal.aborted ) ) {
					setTotalSize(result.totalSize);
					setData(result.data);
				}
			}).catch((error) => restService.handleServerErrorsAxios(error, signal));
		}, [domainName, _page, _sizePerPage, namedCriteria, _searchText, internalSearchOptions, getData, templateSuffix, sort]
	)

	//EXPERIMENTAL:
	// const observeIds = useMemo( () => {
	// 	return data.map( ( d ) => d.id );
	// }, [data] ) -> no observeIds in order to observe creates as well
	//timeout is here because, when for example monthly invoicing will be running, then someone, who has
	//opened list of invoices or commissions in his browser, would have received lots of changes.
	websocketService.useAfterUpdateSubscription( domainName, undefined, () => {
		clearTimeout(reloadTimeoutId);
		setReloadTimeoutId( setTimeout(loadData, 500) );
	} );

	useEffect(() => {
		thisRef.current = {refresh: loadData}
		const controller = new AbortController();
		domainTableService.register( id, thisRef );
		loadData( controller.signal )
		return () => {
			domainTableService.unregister( id )
			controller.abort();
		}
	}, [ loadData, id ]);

	const paginationOptions = {
		page: _page,
		sizePerPage: _sizePerPage,
		totalSize: totalSize,
	};

	const handleTableChange = (type, newState) => {
		let state = {
			page: _page,
			sizePerPage: _sizePerPage,
			sortField: _sortField,
			sortOrder: _sortOrder,
			searchText: _searchText,
			searchOptions: internalSearchOptions,
		}

		switch(type) {
			case 'pagination':
				if ( newState.page !== _page || newState.sizePerPage !== _sizePerPage ) {
					state.page = newState.page
					state.sizePerPage = newState.sizePerPage
					state.searchOptions = newState.searchOptions
				}
				break;
			case 'sort':
				if ( newState.sortField !== _sortField || newState.sortOrder !== _sortOrder ) {
					state.sortField = newState.sortField
					state.sortOrder = newState.sortOrder
					state.searchOptions = newState.searchOptions
				}
				break;
			case 'search':
				if ( newState.searchText !== _searchText ) {
					state.searchText = newState.searchText
					state.searchOptions = newState.searchOptions
				}
				break;
			default:
				break;
		}

		if ( state.searchText && _searchText !== state.searchText ) {
			set_Page(1)
		}
		else {
			set_Page(state.page);
		}
		set_sizePerPage(state.sizePerPage);
		set_sortField(state.sortField);
		set_sortOrder(state.sortOrder);
		set_searchText(state.searchText);
		if ( onTableStateChanged ) {
			onTableStateChanged(state);
		}
	}

	return (
		<ToolkitProvider
			keyField="id"
			data={ data }
			columns={ columns }
			search = { {
				defaultSearch: _searchText
			} }
		>
			{
				toolkitprops => [
					showSearchbar && <TableSearch key={"searchBar"}  {...toolkitprops.searchProps}  />,
					<BootstrapTable
						key={"table"}
						bootstrap4
						remote
						{ ...toolkitprops.baseProps }
						pagination={ showPagination && paginationFactory(paginationOptions) }
						onTableChange={ handleTableChange }
						defaultSorted={ _sortField ?
							[{
								dataField: _sortField,
								order: _sortOrder ? _sortOrder : 'desc',
							}] : undefined}
						rowClasses={ rowClasses }
						expandRow={ expandRow }
						rowStyle={ rowStyle }
					/>
				]
			}
		</ToolkitProvider>
	)
})

DomainTable.propTypes = {
	columns: PropTypes.array.isRequired,
	domainName: PropTypes.string.isRequired,
	getData: PropTypes.func,
	namedCriteria: PropTypes.object,
	rowClasses:  PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
	sortField: PropTypes.string,
	sortOrder: PropTypes.string,
	sizePerPage: PropTypes.number,
	searchText: PropTypes.string,
	searchOptions: PropTypes.object,
	page: PropTypes.number,
	onTableStateChanged: PropTypes.func,
	expandRow: PropTypes.object,
	rowStyle: PropTypes.object,
	showSearchbar: PropTypes.bool,
	showPagination: PropTypes.bool,
}

DomainTable.defaultProps = {
	sizePerPage: 10,
	page: 1,
	showSearchbar: true,
	showPagination: true,
}

export default DomainTable;
