
function settlementsSelectionReducer (state, action) {
    switch (action.type) {
        case 'selection':
            return JSON.parse(JSON.stringify(action.value));
        default:
            return state;
    }
}

function settlementsAllSelectedReducer (state, action) {
    switch (action.type) {
        case 'allSelected':
            return action.value;
        default:
            return state;
    }
}

export function settlementsReducer (state, action) {
    return {
        selection: settlementsSelectionReducer( state.selection, action ),
        allSelected: settlementsAllSelectedReducer( state.allSelected, action ),
    }
}
