import React, {useCallback, useEffect, useState} from 'react';
import EditableTable, {validateTable} from "./EditableTable";
import {Controller, useFormContext} from "react-hook-form";
import * as PropTypes from "prop-types";
import SimpleTable from "../_components/SimpleTable";
import {securityService} from "../_services/security.service";

function Field(props) {
	return null;
}

Field.propTypes = {type: PropTypes.string};

function EditableTableControl(props) {
	const { reset, getValues, control } = useFormContext();
	const [emptyData] = useState( [] );
	const data = props.data || emptyData;
	const [_validationErrors, _setValidationErrors] = useState( undefined )
	const accessToProperty = securityService.useAccessToPropertyOfAllowedContext()

	const setValidationErrors = useCallback( ( value ) => {
		_setValidationErrors( value );
		if ( props.onValidationErrorsChange ) {
			props.onValidationErrorsChange( value );
		}
	}, [_setValidationErrors, props] )

	useEffect( () => {
		if ( getValues && reset ) {
			const val = { ...getValues(), [props.name]: ( data === undefined || data === null ) ? '' : data };
			reset( val ); //defaultValue is normally set only once. In case of asynchronous loading of the form is undefined loaded first and later is loaded the correct value. reset() must be called for such case.
		}
	}, [data, getValues, props.name, reset] );

	const getControl = () => {
		return (
			<Controller
				control={ control }
				name={ props.name }
				rules={ {
					validate: ( value ) => {
						let errors = validateTable( value, props.columns, props.globalValidator );
						setValidationErrors( errors );
						if ( errors ) {
							return false;
						} else {
							return true;
						}
					}
				} }
				defaultValue={ () => {
					return data === undefined || data === null ? [] : data
				} }
				render={ ( { field, fieldState } ) =>
					<EditableTable
						autoOrderFn={ props.autoOrderFn }
						normalizeData={ props.normalizeData }
						orderable={ props.orderable }
						columns={ props.columns }
						allowDeletions={ props.allowDeletions }
						allowAdditions={ props.allowAdditions }
						beforeDelete={ props.beforeDelete }
						beforeChange={ props.beforeChange }
						data={ data }
						onChange={ ( e ) => {
							field.onChange( e );
							if ( props.onChange ) {
								props.onChange( e );
							}
						} }
						validationErrors={ _validationErrors }
					/>
				}
			/>
		)
	}

	if ( accessToProperty.ready ) {
		if ( accessToProperty.write ) {
			return getControl()
		} else if ( accessToProperty.read ) {
			return <SimpleTable columns={ props.columns } rows={ data }/>
		}
	}

	return null
}

export default EditableTableControl;
