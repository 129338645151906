import React, {useMemo} from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import FormControl from "../_components/FormControl";
import SwitchButtonControl from "../_components/SwitchButtonControl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {configuratorService} from "../_services/configurator.service";
import EditableTableControl from "../form/EditableTableControl";

function FormElements({domainInstance, defaultValues, symbols}) {
	const { t } = useTranslation();
	const standardTextAvailabilitiesOptions = configuratorService.useConfiguratorNodes(2);

	const columns = useMemo( () => [
		{ id: "configuratorNode", label: t("standardText.standardTextAvailabilities.label"), input: {tag: "select", values: standardTextAvailabilitiesOptions, nullable: true, required: true }, formatter: (value) => value && value.configuratorNodeLabel },
	], [t, standardTextAvailabilitiesOptions] )

	const symbolsElements = useMemo( () => {
		return Object.keys(symbols).map( (symbol) => (
			<Row key={symbol}>
				<Form.Group as={Col} md="4" controlId={`formGroup${symbol}`}>
					<Form.Label></Form.Label>
					<SwitchButtonControl
						value={!!(domainInstance[symbol] || (defaultValues && defaultValues[symbol]))}
						offlabel={t(`standardText.${symbol}.offlabel`)} onlabel={<><FontAwesomeIcon className={"mr-2"} icon={symbols[symbol]}/><span>{t(`standardText.${symbol}.onlabel`)}</span></>} name={symbol}
					/>
				</Form.Group>
			</Row>
		) )
	}, [symbols, defaultValues, domainInstance, t] )

	const standardTextAvailabilities = useMemo( () => domainInstance.standardTextAvailabilities || [], [domainInstance.standardTextAvailabilities]);

	return (
		<>
			<Card className={"mb-2"}>
				<Card.Header>{t('standardText.card.info')}</Card.Header>
				<Card.Body>
					<Row className={"mb-3"}>
						<Form.Group as={Col} md="12" controlId="formGroupLabel" className={"mb-3"}>
							<Form.Label>{t('standardText.label.label')}</Form.Label>
							<FormControl
								name={"label"}
								type={"text"}
								value={domainInstance.label || (defaultValues && defaultValues.label)}
								rules={ {
									required: true,
								} }
							/>
						</Form.Group>
						<Form.Group as={Col} md="12" controlId="formGroupTheme" className={"mb-3"}>
							<Form.Label>{t('standardText.theme.label')}</Form.Label>
							<FormControl
								name={"theme"}
								type={"text"}
								as="textarea"
								rows={3}
								value={domainInstance.theme || (defaultValues && defaultValues.theme)}
								rules={ {
									required: true,
								} }
							/>
						</Form.Group>
						<Form.Group as={Col} md="12" controlId="formGroupText" className={"mb-3"}>
							<Form.Label>{t('standardText.text.label')}</Form.Label>
							<FormControl
								name={"text"}
								type={"text"}
								as="textarea"
								rows={3}
								value={domainInstance.text || (defaultValues && defaultValues.text)}
								rules={ {
									required: true,
								} }
							/>
						</Form.Group>

						<Form.Group as={Col} md="4" controlId="formGroupActive">
							<SwitchButtonControl
								value={!!(domainInstance.active || (defaultValues && defaultValues.active))}
								offlabel={t("standardText.active.offlabel")} onlabel={t("standardText.active.onlabel")} name={"active"}
							/>
						</Form.Group>
					</Row>
				</Card.Body>
			</Card>
			<Card className={"mb-2"}>
				<Card.Header>{t('standardText.flags.label')}</Card.Header>
				<Card.Body>
					{ symbolsElements }
				</Card.Body>
			</Card>
			<Card className={"form-card"}>
				<Card.Header>{t('standardText.standardTextAvailabilities.label')}</Card.Header>
				<Card.Body>
					<Form.Group as={Col} md="12" controlId="formGroupStandardTextAvailabilities">
						<EditableTableControl name={"standardTextAvailabilities"} columns={columns} data={standardTextAvailabilities}/>
					</Form.Group>
				</Card.Body>
			</Card>
		</>
	);
}

export { FormElements };
