import React, {useMemo, useState} from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import FormControl from "../_components/FormControl";
import {RestAsyncSelectControl} from "../_components/RestAsyncSelectControl";
import {FormElements as CountryFormElements} from "../country/FormElements";
import {FormElements as CountyFormElements} from "../county/FormElements";
import MergeHints from "../_components/MergeHints";
import MergeHint from "../_components/MergeHint";


function FormElements({domainInstance, defaultValues, mergeDomainInstance}) {
	const { t } = useTranslation();
	const [ country, setCountry ] = useState(undefined);

	const hsNamedRestrictionCountry = useMemo(() => {
		if (country) {
			return {
				namedRestriction: {
					queryName: "countryRestriction",
					params: {countryId: country.id}
				}
			}
		}
		else {
			return undefined;
		}
	}, [country])

	return (
		<>
			<MergeHints domain1={domainInstance} domain2={mergeDomainInstance}>
				<Card className={"mb-2"}>
					<Card.Header>{t('zip.card.info')}</Card.Header>
					<Card.Body>
						<Row className={"mb-3"}>
							<Form.Group as={Col} md="4" controlId="formGroupZipZip">
								<Form.Label>{t('zip.zip')} <MergeHint name={"zip"}/></Form.Label>
								<FormControl
									name={"zip"}
									type={"text"}
									value={domainInstance.zip || (defaultValues && defaultValues.zip)}
									rules={ {
										required: true,
									} }
								/>
							</Form.Group>
							<Form.Group as={Col} md="8" controlId="formGroupZipPlace">
								<Form.Label>{t('zip.place')} <MergeHint name={"place"}/></Form.Label>
								<FormControl
									name={"place"}
									type={"text"}
									value={domainInstance.place || (defaultValues && defaultValues.place)}
									rules={ {
										required: true,
									} }
								/>
							</Form.Group>
						</Row>
						<Row>
							<Form.Group as={Col} md="6" controlId="formGroupZipCountry">
								<Form.Label>{t('zip.country')} <MergeHint name={"country"}/></Form.Label>
								<RestAsyncSelectControl
									domainName={"country"}
									sort={"name_sort"}
									value={ domainInstance && domainInstance.country && domainInstance.country.id }
									label={ domainInstance && domainInstance.country && domainInstance.country.label }
									name={'country'}
									rules={ {
										required: true,
									} }
									createable={{formElements: <CountryFormElements/>}}
									onChange={ (value) => setCountry(value) }
								/>
							</Form.Group>
							<Form.Group as={Col} md="6" controlId="formGroupZipCounty">
								<Form.Label>{t('zip.county')} <MergeHint name={"county"}/></Form.Label>
								<RestAsyncSelectControl
									domainName={"county"}
									searchOptions={ hsNamedRestrictionCountry }
									sort={"name"}
									value={ domainInstance && domainInstance.county && domainInstance.county.id }
									label={ domainInstance && domainInstance.county && domainInstance.county.label }
									name={'county'}
									rules={ {
										required: country && country.id === 1, //switzerland
									} }
									createable={{formElements: <CountyFormElements/>}}
								/>
							</Form.Group>
						</Row>
					</Card.Body>
				</Card>
			</MergeHints>
		</>
	);
}

export { FormElements };
