import React from 'react';
import {PolicyVersionStatus} from "../_enum/enum";
import {useTranslation} from "react-i18next";
import restService from "../_services/rest.service";
import {Link} from "react-router-dom";
import {Badge} from "react-bootstrap";

function DomainPartner( { domain } ) {
	const {t} = useTranslation();

	const getPolicyVersions = (domain) => {
		let result = []

		if ( domain && domain.policyVersions ) {
			result = domain.policyVersions.filter( (policyVersion) => PolicyVersionStatus.isInProcess( policyVersion.state ) || policyVersion.state === PolicyVersionStatus.ACTIVE )
		}

		return result
	}

	return (
		<>
			<strong>{domain.label}</strong>
			<ul className={'list-unstyled'}>
				{getPolicyVersions(domain).map( (policyVersion) => {
					return (
						<li key={policyVersion.id}>
							<Link to={restService.getTo(policyVersion, 'show')} target={"_blank"} rel={"noopener noreferrer"}>
								<Badge bg={"primary"}>
									{policyVersion.policyNumber} {policyVersion.policyType} ({ t('appvers.enums.PolicyVersionStatus.' + policyVersion.state)})
								</Badge>
							</Link>
						</li>
					)}
				)}
			</ul>
		</>
	)
}

export default DomainPartner