import React from 'react';
import Login from "./Login";
import {Route, Switch} from "react-router-dom";
import PasswordRecoveryRequest from "./PasswordRecoveryRequest";
import PasswordSetup from "./PasswordSetup";
import TwoFactorAuthentication from "./TwoFactorAuthentication";
import TwoFactorSetup from "./TwoFactorSetup";
import AccountActivation from "./AccountActivation";

function LoginRouter() {

    return (
        <Switch>
            <Route path={"/passwordRecoveryRequest"}>
                    <PasswordRecoveryRequest/>
            </Route>
            <Route path={"/resetPassword/:token"}
                render={(routerProps) =>
                    <PasswordSetup token={routerProps.match.params.token}/>
                }
            />
            <Route path={"/accountActivation/:username?"}
                   render={(routerProps) =>
                       <AccountActivation username={routerProps.match.params.username}/>
                   }
            />
            <Route path={"/createPassword/:token"}
                render={(routerProps) =>
                    <PasswordSetup token={routerProps.match.params.token} isPasswordReset={false}/>
                }
            />
            <Route path={"/validateOtp"}>
                <TwoFactorAuthentication />
            </Route>
            <Route path={"/setupOtp"}>
                <TwoFactorSetup />
            </Route>
            <Route>
                <Login />
            </Route>
        </Switch>
    )
}

export default LoginRouter
