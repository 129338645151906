import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Form, FormControl} from 'react-bootstrap'
import {Dropdown} from "../_components/Dropdown";
import editableTableService from "./editableTable.service";
import {Typeahead} from "react-bootstrap-typeahead";
import {getValue} from "./EditableTable";
import {NumericFormat} from "react-number-format";
import appService from "../_services";

function EditableRowCell( props ) {
    const rowData = props.rowData;
    const [edited, setEdited] = useState(false)

    const ValueType = Object.freeze( {
        'ENUM':1,
        'PRIMITIVE':2
    } )

    const getValueType = useCallback( (value) => {
        if ( value && value.hasOwnProperty('enumType') ) {
            //we are dealing with an enum
            return ValueType.ENUM
        }
        else {
            return ValueType.PRIMITIVE
        }
    }, [ValueType.ENUM, ValueType.PRIMITIVE]);

    const cellValueType = useMemo( () => {
        return getValueType( rowData[props.column.id] )
    }, [getValueType, rowData, props.column.id] );

    const getCellValue = useCallback( (value) => {
        switch(cellValueType) {
            case ValueType.ENUM:
                return value.name || ''
            case ValueType.PRIMITIVE:
                return (value === undefined || value === null) ? '' : value
            default:
                throw new Error("ValueType '" + value + "' is not supported.")
        }
    }, [cellValueType, ValueType.ENUM, ValueType.PRIMITIVE]);

    const [rawValue, setRawValue] = useState(getCellValue( appService.nullSafeGet(rowData, props.column.id) ));

    const handleCellChange = useCallback((rawVal) => {
        let value = editableTableService.getValue(props.column, rawVal).value;
        if (!props.beforeChange || props.beforeChange(rowData, props.column.id, value)) {
            setRawValue( rawVal );
            setEdited( true );
            props.onChange( value, props.column.id );
        }
    },[props, rowData])

    useEffect( () => {
        if(!edited) {
            setRawValue( getCellValue( appService.nullSafeGet(rowData, props.column.id) ) );
        }
        props.onRef((newRowData) => {
            setRawValue( getCellValue( appService.nullSafeGet(newRowData, props.column.id) ) );
        });
    }, [rowData, props, getCellValue, edited])

    const getEditorElement = () => {
        const column = props.column;
        const value = getValue(column, rawValue)
        let ctrlProps
        let result
        switch ( column.input.tag ) {
            case 'check':
                ctrlProps = {
                    size: "sm",
                    type: column.input.type,
                    checked: value.value,
                    onChange: (e) => {
                        handleCellChange(e.target.checked)
                    },
                    isInvalid: !!props.validationError
                };
                if (column.input.readonly) {
                    ctrlProps.readOnly = true
                }
                result = <Form.Check {...ctrlProps}/>
                break
            case 'input':
                ctrlProps = {
                    size: "sm",
                    type: column.input.type,
                    value: editableTableService.parseInputTypeValue( column.input.type, value.value ).stringValue,
                    onChange: (e) => handleCellChange(e.target.value),
                    isInvalid: !!props.validationError
                };
                if (column.input.readonly) {
                    ctrlProps.readOnly = true
                }
                result = <Form.Control {...ctrlProps}/>
                break
            case 'numericFormat':
                ctrlProps = {
                    value: value.value,
                    isInvalid: !!props.validationError,
                    size: "sm",
                    allowLeadingZeros: column.input.allowLeadingZeros,
                    allowNegative: column.input.allowNegative,
                    allowedDecimalSeparators: column.input.allowedDecimalSeparators || [',','.'],
                    decimalScale: column.input.decimalScale,
                    decimalSeparator: column.input.decimalSeparator,
                    prefix: column.input.prefix,
                    suffix: column.input.suffix,
                    thousandsGroupStyle : column.input.thousandsGroupStyle ,
                    thousandSeparator : column.input.thousandSeparator ,
                    onChange: (e) => handleCellChange(e.target.value)
                };
                result = <NumericFormat {...ctrlProps} customInput={FormControl}/>
                break
            case 'select':
                ctrlProps = {
                    nullable: column.input.nullable,
                    size: "sm",
                    value: value.value,
                    options: column.input.values,
                    onChange: (e) => handleCellChange(e.target.value),
                    keyPropName: 'id',
                    valuePropName: 'label',
                    isInvalid: !!props.validationError,
                    optionClassName: column.input.optionClassName
                };
                if (column.input.readonly) {
                    ctrlProps.readOnly = true
                }
                result = <Dropdown {...ctrlProps} />
                break
            case 'typeahead':
                result = (
                    <div style={{position: 'relative'}}>
                        <Typeahead
                                id={column.input.id}
                                defaultSelected={ value.defaultSelected }
                                selected={ value.selected }
                                value={ value.value }
                                className={ !!props.validationError ? ' is-invalid' : '' }
                                size={'sm'}
                                onChange={ (selection) => {
                                    if ( selection && selection.length>0 ) {
                                        handleCellChange(selection[0])
                                    }
                                } }
                                onInputChange={ (value) => handleCellChange(value) }
                                options={column.input.options}
                                isInvalid={ !!props.validationError }
                        />
                    </div>
                )
                break
            case 'label':
                result = <p>{ value.value }</p>
                break
            default:
                break;
        }
        if ( result ) {
            return result
        }
        else {
            if ( column.formatter ) {
                return column.formatter( result, rowData )
            }
            else {
                throw new Error("No input.tag and no formatter found for column '" + JSON.stringify(column) + "'.")
            }
        }
    }

    return (
        <>
            <td>
                {getEditorElement()}
                <Form.Control.Feedback type='invalid'>
                    { props.validationError }
                </Form.Control.Feedback>
            </td>
        </>
    );
}

export default EditableRowCell
