import React, {createContext, useState, useContext, useEffect, useDebugValue} from 'react';
import { setLoadingFunctions } from './loadingManager';  // Import global utility

// Create a LoadingContext
const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
    const [requestCount, setRequestCount] = useState(0);  // Track the number of running requests

    useDebugValue(`requestCount: ${requestCount}`);

    const showLoading = () => setRequestCount(prevCount => prevCount + 1);
    const hideLoading = () => setRequestCount(prevCount => (prevCount > 0 ? prevCount - 1 : 0));

    const isLoading = requestCount > 0;

    // Set global loading functions when the provider is mounted
    useEffect(() => {
        setLoadingFunctions(showLoading, hideLoading);
    }, []);

    return (
        <LoadingContext.Provider value={{ isLoading, showLoading, hideLoading }}>
            {children}
        </LoadingContext.Provider>
    );
};

// Custom hook to use the loading context
export const useLoading = () => {
    return useContext(LoadingContext);
};
