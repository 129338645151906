import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { alertService, AlertType } from '../_services';
import AlertBs from 'react-bootstrap/Alert'
import DynamicText from "./DynamicText";

const propTypes = {
	id: PropTypes.string,
	fade: PropTypes.bool
};

const defaultProps = {
	id: 'default-alert',
	fade: true
};

function Alert({ id, fade }) {
	const history = useHistory();
	const [alerts, setAlerts] = useState([]);
	const ref = useRef()
	const autoCloseTimeoutRef = useRef()
	const scrollIntoViewTimeoutRef = useRef()

	useEffect(() => {
		// subscribe to new alert notifications
		const subscription = alertService.onAlert(id)
			.subscribe(alert => {
				// clear alerts when an empty alert is received
				if (!alert.message) {
					setAlerts(alerts => {
						// filter out alerts without 'keepAfterRouteChange' flag
						const filteredAlerts = alerts.filter(x => x.keepAfterRouteChange);

						// remove 'keepAfterRouteChange' flag on the rest
						filteredAlerts.forEach(x => delete x.keepAfterRouteChange);
						return filteredAlerts;
					});
				} else {
					// add alert to array
					setAlerts(alerts => ([...alerts, alert]));

					// auto close alert if required
					if (alert.autoClose) {
						autoCloseTimeoutRef.current = setTimeout(() => removeAlert(alert), 7000);
					}

					if (alert.scrollIntoView) {
						scrollIntoViewTimeoutRef.current = setTimeout( () => {
							if ( ref && ref.current ) {
								ref.current.scrollIntoView( { behavior: 'smooth', block: 'start' } )
							}
						}, 10 )
					}
				}
			});

		// clear alerts on location change
		const historyUnlisten = history.listen(({ pathname }) => {
			// don't clear if pathname has trailing slash because this will be auto redirected again
			if (pathname.endsWith('/')) return;

			alertService.clear(id);
		});

		// clean up function that runs when the component unmounts
		return () => {
			// unsubscribe & unlisten to avoid memory leaks
			subscription.unsubscribe();
			historyUnlisten();
			clearTimeout(autoCloseTimeoutRef.current);
			clearTimeout(scrollIntoViewTimeoutRef.current);
		};

		/*
			React warns us: React Hook useEffect has a missing dependency: 'removeAlert'. Either include it or remove the dependency array.
			Correct way is to just update state using setAlerts(...), which causes rerendering of this component and calling useEffect().
			If we didn't want to hide the alert using fading, we didn't need to use removeAlert() and just call
			setAlerts(alerts => alerts.filter(x => x !== alert));
			in useEffect and in the <div> below. But we are using fading, thus we would repeat more code, so we will use suppressing of the warning.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history, id]);

	function removeAlert(alert) {
		if (fade) {
			// fade out alert
			const alertWithFade = { ...alert, fade: true };
			setAlerts(alerts => alerts.map(x => x === alert ? alertWithFade : x));

			// remove alert after faded out
			setTimeout(() => {
				setAlerts(alerts => alerts.filter(x => x !== alertWithFade));
			}, 250);
		} else {
			// remove alert
			setAlerts(alerts => alerts.filter(x => x !== alert));
		}
	}

	function getVariant(alert) {
		if (!alert) return;

		const alertVariants = {
			[AlertType.Success]: 'success',
			[AlertType.Error]: 'danger',
			[AlertType.Info]: 'info',
			[AlertType.Warning]: 'warning'
		}

		return alertVariants[alert.type];
	}

	if (!alerts.length) return null;

	return (
			<div ref={ref}>
				{alerts.map((alert, index) =>
					<AlertBs key={index} variant={getVariant(alert)} dismissible>
						<DynamicText htmlContent = {alert.message} />
					</AlertBs>
				)}
			</div>
	);
}

Alert.propTypes = propTypes;
Alert.defaultProps = defaultProps;
export { Alert };
