import React, {useCallback, useEffect, useMemo, useState} from "react";
import restService from "../_services/rest.service";
import PropTypes from "prop-types";
import { alertService } from "../_services";
import {Controller, useFormContext} from "react-hook-form";
import {Form} from "react-bootstrap";
import reactHookFormService from "./reactHookForm.service";
import Creatable from "react-select/creatable";
import {AsyncPaginate, withAsyncPaginate} from "react-select-async-paginate";
import AddEditPopupForm from "../form/AddEditPopupForm";
import {selectControlService} from "../_services/selectControl.service";
import PlainValue from "./PlainValue";
import {securityService} from "../_services/security.service";
import {AllowedContext} from "../form/form.service";
const CreatableAsyncPaginate = withAsyncPaginate(Creatable);


function RestAsyncSelectControl( {
									 domainName,
									 getData,
									 name,
									 value,
									 label,
									 sort,
									 valuePropertyName,
									 labelPropertyName,
									 onChange,
									 placeholder,
									 rules,
									 validationMessages,
									 noSelection,
									 createable,
									 namedCriteria,
									 searchOptions,
									 onGetOptionLabel,
									 disabled = false,
									 isMulti = false
} ) {
	const { setValue, getValues, control } = useFormContext();
	const [asyncPlaceholder, setAsyncPlaceholder] = useState( placeholder );
	const [options, setOptions] = useState( [] );
	const [showCreate, setShowCreate] = useState( false );
	const [createDefaultValues, setCreateDefaultValues] = useState( undefined );
	const [dummy, setDummy] = useState( undefined );
	const [_namedCriteria, set_NamedCriteria] = useState( namedCriteria );
	const [_searchOptions, set_SearchOptions] = useState( searchOptions );
	const [createableDomainName, setCreateableDomainName] = useState( '' )
	const accessToProperty = securityService.useAccessToPropertyOfAllowedContext()
	const hasAccessToDomainCreate = securityService.useGranted( `/api/${domainName}/create` )
	const defVal = useMemo( () => {
		if ( value ) {
			if ( isMulti || onGetOptionLabel ) {
				return value;
			} else {
				return { [valuePropertyName]: value, [labelPropertyName]: label };
			}
		} else {
			return '';
		}
	}, [value, label, labelPropertyName, valuePropertyName, isMulti, onGetOptionLabel] )
	const [currentValue, setCurrentValue] = useState( defVal );

	const setNewValue = useCallback( ( newValue ) => {
		setCurrentValue( newValue );
		setValue(name, newValue);
	}, [name, setValue] );

	useEffect( () => {
		if ( getValues ) {
			setAsyncPlaceholder( placeholder );
			setNewValue( defVal );
			set_SearchOptions( searchOptions );
			set_NamedCriteria( namedCriteria );
			setDummy( JSON.stringify( namedCriteria ) + JSON.stringify( searchOptions ) );
		}
	}, [value, getValues, name, defVal, namedCriteria, searchOptions, placeholder, setNewValue] );

	const _getData = (page, PAGE_SIZE, sort, search) => {
		if ( getData ) {
			return getData( page, PAGE_SIZE, sort, search, _searchOptions, _namedCriteria )
		}
		else {
			return restService.getDomainInstancesList( domainName, page, PAGE_SIZE, sort, search, _searchOptions, _namedCriteria, 'select' )
		}
	}


	const promiseOptions = ( search, prevOptions ) => {
		return new Promise( ( resolve, reject ) => {
			const PAGE_SIZE = 50;
			const page = Math.ceil( ( prevOptions.length + 1 ) / PAGE_SIZE );
			_getData(page, PAGE_SIZE, sort, search)
				.then( ( entries ) => {
					if ( noSelection ) {
						let _noSelection
						if ( typeof ( noSelection ) === "boolean" ) {
							_noSelection = { [valuePropertyName]: '', [labelPropertyName]: '\xa0' };
						} else {
							_noSelection = noSelection;
						}
						if ( placeholder ) {
							console.warn( "Placeholder used together with noSelection in RestAsyncSelect" )
						} else {
							setAsyncPlaceholder( _noSelection.label );
						}
						entries.data.unshift( _noSelection );
					}

					const hasMore = entries.totalSize > prevOptions.length + PAGE_SIZE;
					const newOptions = options.concat( entries.data );
					const uniqueOptions = newOptions.filter( ( v, i, a ) => a.findIndex( ( item ) => item[valuePropertyName] === v[valuePropertyName] ) === i ); //make them unique
					setOptions( uniqueOptions );

					resolve( {
						options: entries.data,
						hasMore
					} );
				} )
				.catch( ( error ) => {
					console.log( 'CreatePolicyPolicy::promiseOptions()' + error );
					alertService.error( error );
					reject( error );
				} );
		} );
	}

	const isCreatable = () => {
		return !!createable && hasAccessToDomainCreate.ready && hasAccessToDomainCreate.value
	}

	const getHandleCreateOption = () => {
		if ( isCreatable ) {
			return ( label ) => {
				const openCreateForm = ( params ) => {
					setCreateableDomainName( params.domainName || domainName );
					setCreateDefaultValues( params.defaultValues );
					setShowCreate( true );
				}

				const dv = {
					[( createable.target ) || labelPropertyName]: label
				}
				if ( createable.beforeCreate ) {
					createable.beforeCreate( dv )
						.then( openCreateForm )
						.catch( () => {
							//do nothing
						} )
				} else {
					openCreateForm( dv );
				}
			}
		} else {
			return false;
		}
	}

	const handleAfterOptionCreated = ( object ) => {
		setNewValue( {
			[valuePropertyName]: object[valuePropertyName],
			[labelPropertyName]: object[labelPropertyName]
		} );
		setShowCreate( false );
		setDummy( object[valuePropertyName] );
	}

	const handleAfterOptionCreationCancelled = () => {
		setShowCreate( false );
	}

	const getControl = () => {
		return (
			<>
				{ showCreate &&
					<AllowedContext.Provider value={ securityService.getAllowedContextValue( {classSimpleName: domainName}, true, true, false ) }>
					    <AddEditPopupForm domainName={ createableDomainName } show={ showCreate }
					                                  onUpdate={ handleAfterOptionCreated }
					                                  onCancel={ handleAfterOptionCreationCancelled }
					                                  defaultValues={ createDefaultValues }>{ createable.formElements }</AddEditPopupForm>
					</AllowedContext.Provider>
				}
				<Controller
					control={ control }
					name={ name }
					rules={ rules }
					defaultValue={ currentValue }
					value = { currentValue }
					render = {({ field, fieldState }) => {
						const options = {
							key: dummy, //this is here only because of refresh of options - when 'dummy' changes, options will be reloaded
							onChange: ( val ) => {
								setCurrentValue( val );
								field.onChange( val );
								if ( onChange ) {
									onChange( val );
								}
							},
							onBlur: field.onBlur,
							value: field.value,
							name: field.name,
							selectRef: field.ref,
							styles: selectControlService.getCustomStyles( !!fieldState.error ),
							className: !!fieldState.error ? 'is-invalid' : '',
							placeholder: asyncPlaceholder,
							loadOptions: promiseOptions,
							onCreateOption: getHandleCreateOption(),
							isDisabled: disabled,
							isMulti: isMulti,
							getOptionLabel: ( option ) => {
								if ( onGetOptionLabel ) {
									return onGetOptionLabel( option )
								} else {
									return option[labelPropertyName]
								}
							},
							getOptionValue: ( option ) => ( option[valuePropertyName] ),
						}
						return (
							<>
								{ isCreatable() ? <CreatableAsyncPaginate { ...options } /> :
									<AsyncPaginate { ...options } /> }
								<Form.Control.Feedback type="invalid">
									{ reactHookFormService.getValidationMessage( fieldState, validationMessages ) }
								</Form.Control.Feedback>
							</>
						)
					}
					}
				/>
			</>
		);
	}

	const getElement = () => {
		if ( accessToProperty.ready ) {
			if ( accessToProperty.write ) {
				return getControl()
			} else if ( accessToProperty.read ) {
				return <PlainValue value={ label } hidden={ false }/>
			}
		}

		return null
	}

	return (
		<>
			{/*<p>isDomainEditable: {isDomainEditable?'YES':'NO'}, isPropertyWritable: {isPropertyWritable?'YES':'NO'}</p>*/}
			{getElement()}
		</> )
}

RestAsyncSelectControl.defaultProps = {
	valuePropertyName: 'id',
	labelPropertyName: 'label'
};

RestAsyncSelectControl.propTypes = {
	name: PropTypes.string,
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
	value: PropTypes.any,
	rules: PropTypes.object,
	validationMessages: PropTypes.object,
	domainName: PropTypes.string,
	getData: PropTypes.func,
	sortField: PropTypes.string,
	sortOrder: PropTypes.string,
	sortType: PropTypes.string,
	valuePropertyName: PropTypes.string,
	labelPropertyName: PropTypes.string,
	noSelection: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
	createable: PropTypes.object,
	namedCriteria: PropTypes.object,
	searchOptions: PropTypes.object,
	disabled: PropTypes.bool,
	onGetOptionLabel: PropTypes.func,
	domainNameOrGetDataRequired: (props, propName, componentName) => {
		if (!props.domainName && !props.getData) {
			return new Error(
				`One of 'domainName' or 'getData' is required in '${componentName}'.`
			);
		}
	}
};

export { RestAsyncSelectControl };
