import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Pagination as ReactBootstrapPagination} from "react-bootstrap";

function Pagination({totalElements, pageSize, onPageChange, size="sm", className}) {
	const [currentPage, setCurrentPage] = useState(1);

	const handlePageChange = useCallback( (page) => {
		setCurrentPage(page);
		onPageChange(page);
	}, [setCurrentPage, onPageChange]);

	const totalPages = useMemo( () => Math.ceil(totalElements / pageSize), [totalElements, pageSize]);

	useEffect( () => {
		if ( totalPages > 0 && currentPage > totalPages ) {
			handlePageChange(totalPages);
		}
	}, [ currentPage, totalPages, handlePageChange ] );

	const first = useMemo( () => {
		if ( 3 <= currentPage ) {
			return <ReactBootstrapPagination.First onClick={ () => handlePageChange(1) } />;
		} else {
			return <></>;
		}
	}, [currentPage, handlePageChange]);

	const prev = useMemo( () => {
		if ( 2 <= currentPage ) {
			return <ReactBootstrapPagination.Prev onClick={ () => handlePageChange(currentPage-1) } />;
		} else {
			return <></>;
		}
	}, [currentPage, handlePageChange]);

	const last = useMemo( () => {
		if ( currentPage <= totalPages - 2 ) {
			return <ReactBootstrapPagination.Last onClick={ () => handlePageChange(totalPages) } />;
		} else {
			return <></>;
		}
	}, [currentPage, totalPages, handlePageChange]);

	const next = useMemo( () => {
		if ( currentPage <= totalPages - 1 ) {
			return <ReactBootstrapPagination.Next onClick={ () => handlePageChange(currentPage+1) } />;
		} else {
			return <></>;
		}
	}, [currentPage, totalPages, handlePageChange]);

	const pages = useMemo( () => {
		const PAGES = 5;
		let result = []

		let startPage = Math.max(1, currentPage - Math.floor(PAGES / 2));

		if ( totalPages > 1 ) {
			for ( let page = startPage; page < Math.min( startPage + PAGES, totalPages + 1 ); page++ ) {
				result.push( <ReactBootstrapPagination.Item key={ page } active={ page === currentPage }
				                                            onClick={ () => handlePageChange( page ) }>{ page }</ReactBootstrapPagination.Item> )
			}
		}

		return result;
	}, [currentPage, totalPages, handlePageChange]);

	return (
		<ReactBootstrapPagination size={size} className={className}>
			{ first }
			{ prev }
			{ pages }
			{ next }
			{ last }
		</ReactBootstrapPagination>
	)
}

export default Pagination