import React, {useMemo, useState} from 'react';
import {FormElements} from "./FormElements";
import {FormProvider, useForm} from "react-hook-form";
import {Button, Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import restService from "../_services/rest.service";
import store from "../store";
import {apiClientPrivate} from "../api/apiClient";

function CreateManualPayment({bankPaymentId, invoiceId}) {
    const {t} = useTranslation();
    const [bankPayment] = restService.useDomainInstance('bankPayment', bankPaymentId)
    const [invoice] = restService.useDomainInstance('invoice', invoiceId)
    const methods = useForm();
    const history = useHistory();
    const [controlDisabled, setControlDisabled] = useState(false);

    const manualPaymentData = useMemo( () => {
        let data
        if ( bankPayment.id ) {
            data = {bankPayment: bankPayment}
            if ( invoice.id || bankPayment.invoice ) {
                let invoiceData = invoice.id ? invoice : bankPayment.invoice
                if (invoiceData) {
                    let amountDifference = invoiceData.total - invoiceData.paymentTotal
                    data = {...data, invoice: invoiceData, amount: amountDifference.toFixed(2)}
                }
            }
        }
        return data
    }, [bankPayment, invoice])

    const handleFormSubmit = (data) => {
        save(data)
            .then( (id) => {
                setControlDisabled(false);
                handleSuccess(id);
            } )
            .catch( error => {
                setControlDisabled(false);
                restService.handleServerErrorsAxios( error )
            })
    }

    const save = (data) => {
        setControlDisabled(true);
        return new Promise( (resolve, reject) => {
            const params = JSON.parse(JSON.stringify( {...data, invoice: data.invoice.id, bankPayment: data.bankPayment.id} ))
            apiClientPrivate.post( `/api/manualPayment`, params)
                .then( r => restService.handleServerResponseAxios( r ) )
                .then( json => resolve(json.id) )
                .catch( reject );
        });
    }

    const handleSuccess = (id) => {
        if ( bankPayment ) {
            const queryParams = store.getState().bankToCustomerNotification.queryParams
            history.push(`/bankToCustomerNotification/show/${ bankPayment.bankToCustomerNotification.id }${queryParams ? `?${queryParams}` : ''}`)
        }
        else {
            history.push(`/manualPayment/show/${ id }`)
        }
    }
    const handleCancel = () => {
        if ( bankPaymentId ) {
            history.push(`/bankPayment/show/${bankPaymentId}`)
        }
        else {
            history.push('/manualPayment')
        }
    }

    return (
        <FormProvider {...methods}>
            <Form disabled={controlDisabled} method="post" onSubmit={methods.handleSubmit(handleFormSubmit)}>
                <fieldset disabled={controlDisabled}>
                    <FormElements domainInstance={ manualPaymentData }/>
                    <div className={"d-grid gap-2 d-md-flex mb-2"}>
                        <Button variant="primary" size={'sm'} type="submit" >
                            {t('default.create')}
                        </Button>
                        <Button variant="secondary" size={'sm'} onClick={handleCancel}>
                            {t('default.cancel')}
                        </Button>
                    </div>
                </fieldset>
            </Form>
        </FormProvider>
    )
}

export default CreateManualPayment
