import React from 'react';
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import FormControl from "../_components/FormControl";
import {FormProvider, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {profitParticipationService} from "../_services/profitParticipation.service";
import appService from "../_services";

function ProfitParticipationSummary() {
    const {t} = useTranslation();
    const useFormObject = useForm();

    appService.useTitle( t('reports.profitParticipationSummary.title') )

    const onSubmit = (formData) => {
        profitParticipationService.printSummary(formData.from, formData.to, formData.dataValidOn)
    };

	return (
        <>
            <h2>{t('reports.profitParticipationSummary.title')}</h2>

            <FormProvider {...useFormObject}>
                <Form onSubmit={useFormObject.handleSubmit(onSubmit)}>
                    <Card>
                        <Card.Header>
                            {t('default.filter')}
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Form.Group className="col-md-6" controlId="validFrom">
                                    <Form.Label>{t('profitParticipationSummary.from.label')}</Form.Label>
                                    <FormControl
                                        name={"from"}
                                        type={"date"}
                                        required={true}
                                    />
                                </Form.Group>

                                <Form.Group className="col-md-6" controlId="validTo">
                                    <Form.Label>{t('profitParticipationSummary.to.label')}</Form.Label>
                                    <FormControl
                                        name={"to"}
                                        type={"date"}
                                        required={true}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className={"mt-3"}>
                                <Form.Group className="col-md-6" controlId="dataValidOn">
                                    <Form.Label>{t('profitParticipationSummary.dataValidOn.label')}</Form.Label>
                                    <FormControl
                                        name={"dataValidOn"}
                                        type={"date"}
                                        required={true}
                                    />
                                </Form.Group>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Row className={"mt-3"}>
                        <Col md={2}>
                            <Button type={"submit"}>{ t('default.export') }</Button>
                        </Col>
                    </Row>
                </Form>
            </FormProvider>
        </>
	)
}

export default ProfitParticipationSummary