import React from 'react';
import FileUploader from "../_components/FileUploader";
import {Button, Card, Col, Form, Modal, Row} from "react-bootstrap";
import {FormProvider, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import DynamicText from "../_components/DynamicText";
import SubmitButton from "../_components/SubmitButton";
import restService from "../_services/rest.service";
import {messageBoxService} from "../_services/messageBox.service";
import {MessageBoxButtons} from "../_components/MessageBox";

function ToBeActivated({policyVersion, show, setShow, onSubmit}) {
	const useFormObject = useForm()
	const {t} = useTranslation()

	const handleSubmit = (data) => {
		if ( !data.fileChanges || !Object.values( data.fileChanges )?.some( fileChange => fileChange.action === 'create' ) ) {
			messageBoxService.display( t('toBeActivated.noFile.error.content'), t('toBeActivated.noFile.error.header'), [MessageBoxButtons.OK], {headerClassName: 'bg-danger text-white'} ).then( () => {} )
			return
		}

		return new Promise( (resolve, _) => {
			onSubmit(data)
				.then( () => {
					resolve()
				})
				.catch( (error) => {
					resolve()
					console.log(error);
					restService.handleServerErrorsAxios( error )
				})
		} )
	}

	return (
		<Modal show={show} onHide={ () => setShow(false) } >
			<Modal.Header closeButton className={"bg-primary text-white"}>
				{ t('policyVersion.activate.label') }
			</Modal.Header>

			<FormProvider {...useFormObject}>
				<Form onSubmit={useFormObject.handleSubmit( handleSubmit )}>

				<Modal.Body>
					<Row>
						<Col>
							<DynamicText htmlContent={ t('toBeActivated.modal.body.1', {policyVersionLabel: policyVersion.label})} />
							<Card>
								<Card.Header>{ t('toBeActivated.modal.application.label') }</Card.Header>
								<Card.Body>
									<FileUploader changesPropertyName={ 'fileChanges' }/>
								</Card.Body>
							</Card>
							<div className={"mt-3"}>
								<DynamicText htmlContent={ t('toBeActivated.modal.body.2')} />
							</div>
						</Col>
					</Row>
				</Modal.Body>

				<Modal.Footer>
					<SubmitButton isSubmitting={useFormObject.formState.isSubmitting} variant={ "primary" } type={"submit"}>{ t( 'default.ok' ) }</SubmitButton>
					<Button variant={ "secondary" } className={"ms-2"} onClick={ () => setShow(false) } >{ t( 'default.cancel' ) }</Button>
				</Modal.Footer>
				</Form>
			</FormProvider>
		</Modal>
	)
}

export default ToBeActivated