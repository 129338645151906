import React, {useState} from 'react';
import {FormProvider, useForm} from "react-hook-form";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import FormControl from "../_components/FormControl";
import {RestAsyncSelectControl} from "../_components/RestAsyncSelectControl";
import {useTranslation} from "react-i18next";
import {damageService} from "../_services/damage.service";
import SwitchButtonControl from "../_components/SwitchButtonControl";
import appService from "../_services";

function DamageRendement() {
    const {t} = useTranslation();
    const useFormObject = useForm();
    const queryParams = new URLSearchParams(window.location.search)
    const [defaultPolicies] = useState( () => {
        const policies = JSON.parse( queryParams.get("defaultPolicies") )
        return policies || []
    });

    appService.useTitle( t('reports.damageRendement.title') )

    const onSubmit = (data) => {
        damageService.printDamageRendement( {yearFrom: data.yearFrom, yearTo: data.yearTo, policies: JSON.stringify(data.policies), detailed: data.detailed } )
    }

	return 	(
        <>
            <h2>{t('reports.damageRendement.title')}</h2>

            <FormProvider {...useFormObject}>
            <Form onSubmit={useFormObject.handleSubmit(onSubmit)}>
                <Card>
                    <Card.Header>
                        {t('default.filter')}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Form.Group className="col-md-6" controlId="yearFrom">
                                <Form.Label>{t('damageRendement.yearFrom.label')}</Form.Label>
                                <FormControl
                                    name={"yearFrom"}
                                    type={"number"}
                                />
                            </Form.Group>
                            <Form.Group className="col-md-6" controlId="yearTo">
                                <Form.Label>{t('damageRendement.yearTo.label')}</Form.Label>
                                <FormControl
                                    name={"yearTo"}
                                    type={"number"}
                                />
                            </Form.Group>
                        </Row>
                        <Row className={"mt-3"}>
                            <Form.Group className="col-md-12" controlId="policyHolder">
                                <Form.Label>{t('damageRendement.policies.label')}</Form.Label>
                                <RestAsyncSelectControl
                                    value={defaultPolicies}
                                    name={"policies"}
                                    domainName={"policy"}
                                    sort={"[Long]id"}
                                    isMulti={true}
                                    rules={{
                                        required: true
                                    }}
                                />
                            </Form.Group>
                        </Row>
                        <Row className={"mt-3"}>
                            <Col md={2}>
                                <SwitchButtonControl
                                    offlabel={t("damageRendement.detailed.offlabel")}
                                    onlabel={t("damageRendement.detailed.onlabel")}
                                    name={"detailed"}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Row className={"mt-3"}>
                    <Col md={2}>
                        <Button type={"submit"}>{ t('default.createPdf') }</Button>
                    </Col>
                </Row>
            </Form>
        </FormProvider>
    </>
)
}

export default DamageRendement