import {createSlice} from "@reduxjs/toolkit";


const authSlice = createSlice({
    name: 'tabId',
    initialState: null,
    reducers: {
        setTabId: (state, action) => {
            return action.payload
        },
    }
})

export const { setTabId } = authSlice.actions

export default authSlice.reducer
