import React, {useCallback, useMemo, useRef, useState} from "react";
import {ConfiguratorTarget} from "./ConfiguratorTarget";
import {ConfiguratorTargetType, getEnumType} from "../_enum/enum";
import {Accordion} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import textService from "../_services/text.service";
import PropTypes from "prop-types";
import {messagePanelService} from "../_services/message.panel.service";
import ConfiguratorTargetMessage from "./ConfiguratorTargetMessage";

function ConfiguratorTargetList( { targets, onClose, validFrom, feeTotalChanged, getCorrespondingTargets, onConfiguratorTargetHeaderClick, activeConfiguratorTargetKey, onIndexedChanged, policyVersionBroker, brokerOrConsultant, configuratorTargetBrokerCorrespondsWithPolicyVersionBroker } ) {
	const {t} = useTranslation()
	const [configuratorTargetMessages, setConfiguratorTargetMessages] = useState({});
	const stateRef = useRef();

	stateRef.current = {targets: targets};

	const refreshFeeTotal = useCallback( () => {
		const feeTotal = targets.reduce( (sum, target) => ( !['delete'].includes(target.action) ? sum + target.total : sum ), 0 );
		feeTotalChanged( feeTotal );
	}, [targets, feeTotalChanged])

	const handleOnClose = useCallback( (key) => {
		onClose(key);
		refreshFeeTotal();
	}, [onClose, refreshFeeTotal])

	const handleOnFeeChanged = useCallback( (targetKey, total, tariff, isManualTariff) => {
		let target = targets.find( (t) => t.key === targetKey )
		target.total = total
		target.isManualTariff = isManualTariff
		target.tariff = tariff
		refreshFeeTotal();
	}, [refreshFeeTotal, targets])

	const removeConfiguratorTargetMessage = useCallback( (targetKey) => {
		const ctm = configuratorTargetMessages[targetKey];
		if (ctm) {
			const id = configuratorTargetMessages[targetKey].key
			delete configuratorTargetMessages[targetKey]
			setConfiguratorTargetMessages( {...configuratorTargetMessages} )
			messagePanelService.update( undefined, [id] );
		}
	}, [configuratorTargetMessages, setConfiguratorTargetMessages]);

	const addOrUpdateConfiguratorTargetMessage =  useCallback((message) => {
		let prevId = configuratorTargetMessages[message.key] && configuratorTargetMessages[message.key].id;
		const id = messagePanelService.update( [{ uuid: message.key, header: message.header, message: message.message, variant: 'info' }], [prevId] )[0];
		configuratorTargetMessages[message.key] = message;
		message.id = id;
		setConfiguratorTargetMessages( {...configuratorTargetMessages} );
	}, [configuratorTargetMessages, setConfiguratorTargetMessages]);

	const handleOnAmountChanged = useCallback( (targetKey, amount) => {
		const target = targets.find( (t) => t.key === targetKey )

		let correspondingTargets = []

		//when a base target amount is changed, then we try to update other base targets or dependent targets from same building
		if ( target.targetClassName === 'configuratorTargetBase' || target.targetClassName === 'configuratorTargetBaseBroker') {
			correspondingTargets = getCorrespondingTargets(targets, target, amount);
		}

		target.amount = amount;

		correspondingTargets.forEach( (correspondingTarget) => {
			const postConfiguratorTargetMessage = (amountDependencyFactor, roundAmountToMultiple) => {
				const key = correspondingTarget.key;
				const text = amountDependencyFactor !== 1 ?
					t('configuratorTargetList.dependentTariffSuggestionAfterAmountChanged', {whatChanged: target.pathAndNameAsText, changedAmount: textService.formatNumber(amount), suggestedAmount: textService.formatNumber(Math.round(amount*amountDependencyFactor / roundAmountToMultiple) * roundAmountToMultiple), suggestedChange:correspondingTarget.pathAndNameAsText})
					:
					t('configuratorTargetList.baseTariffSuggestionAfterAmountChanged', {whatChanged: target.pathAndNameAsText, changedAmount: textService.formatNumber(amount), suggestedChange:correspondingTarget.pathAndNameAsText});
				const buttons = [
					{
						variant:"success",
						text: t('default.yes'),
						onClick: () => {
							stateRef.current.targets.find( (t) => t.key === correspondingTarget.key ).amount = Math.round(amount*amountDependencyFactor / roundAmountToMultiple) * roundAmountToMultiple;
							removeConfiguratorTargetMessage(correspondingTarget.key);
						}
					},
					{
						variant:"danger",
						text:t('default.no'),
						onClick: () => {
							removeConfiguratorTargetMessage(correspondingTarget.key);
						}
					},
				];
				const configuratorTargetMessage = {
					id: undefined, //will be filled when message is created
					key: key,
					header: t('default.youWouldLikeProbably'),
					message: <ConfiguratorTargetMessage text={text} buttons={buttons}/>
				}

				addOrUpdateConfiguratorTargetMessage(configuratorTargetMessage)
			}

			switch(correspondingTarget.targetClassName) {
				case 'configuratorTargetBase':
				case 'configuratorTargetBaseBroker':
					postConfiguratorTargetMessage(1, 1);
					break;
				case 'configuratorTargetDependentProduct':
				case 'configuratorTargetDependentProductBroker':
					postConfiguratorTargetMessage(correspondingTarget.amountDependencyFactor, correspondingTarget.roundAmountToMultiple);
					break;
				default:
					//skip
			}
		})
	}, [t, targets, addOrUpdateConfiguratorTargetMessage, getCorrespondingTargets, removeConfiguratorTargetMessage]);

	const targetElements = useMemo( () => {
		let result = []

		targets.forEach( (target) => {
			if ( !['delete'].includes(target.action) ) {
				result.push( <ConfiguratorTarget
						key={ target.key }
						targetKey={ target.key }
						targetId={ target.targetId }
						sameBuildingTargets={ targets.filter( (t) => t.action !== 'delete' && ((target.building && t.building && target.building.id === t.building.id) || (!target.building && !t.building) ) ).map( (target) => {
							return {id: target.targetId, amount: target.amount, isManualTariff: target.isManualTariff, tariff: target.tariff }
						} ) }
						dependsOnTarget={ targets.find( (t) => t.action !== 'delete' && target.dependsOn && target.dependsOn.id === t.targetId )}
						target={ target.pathAndNameAsText || t('createPolicy.addProductConfigurator.manualTariff') }
						onConfiguratorTargetHeaderClick={onConfiguratorTargetHeaderClick}
						building={ target.building }
						configuratorValues={ target.configuratorValues }
						availableInsuranceAmount={ target.availableInsuranceAmount }
						configuratorTargetType={ getEnumType( "appvers.enums.ConfiguratorTargetType", target.type || ConfiguratorTargetType.VALUE ) }
						onClose={ handleOnClose }
						policyValidFrom={ validFrom }
						validFrom={ target.validFrom }
						validTo={ target.validTo }
						onFeeChanged={ handleOnFeeChanged }
						onAmountChanged={ handleOnAmountChanged }
						policyType={ target.policyType }
						avb={ target.avb }
						discountSurchargeNames={ target.discountSurchargeOfferInfo.discountSurchargeNames }
						discountSurchargeLabels={ target.discountSurchargeOfferInfo.discountSurchargeLabels }
						discountSurchargeValues= { target.discountSurchargeOfferInfo.discountSurchargeValues }
						defaultDiscountSurcharge = { target.discountSurchargeOfferInfo.discountSurcharge }
						defaultParticipation={ target.participation }
						defaultAmount={ target.amount }
						previousAmount={ target.previousAmount }
						previousNextInvoicePeriodFrom={ target.previousNextInvoicePeriodFrom }
						amountIndexedForIndexYearPlus1={ target.amountIndexedForIndexYearPlus1 }
						indexYear={ target.indexYear }
						defaultIndividualTexts={ target.individualTexts }
						defaultIndexed={ target.indexed }
						defaultPlaceOfInsuranceStreet={ target.placeOfInsuranceStreet }
						defaultPlaceOfInsuranceZip={ target.placeOfInsuranceZip }
						firstLossRisk={ target.firstLossRisk }
						defaultAdditionalDiscountSurchargePercent = { target.additionalDiscountSurchargePercent }
						defaultAdditionalDiscountSurchargeValue = { target.additionalDiscountSurchargeValue }
						defaultTariff = { target.tariff }
						defaultIsManualTariff = { target.isManualTariff }
						defaultUseStampTax={ target.useStampTax }
						defaultStampTaxPercent= { target.stampTaxPercent }
						defaultUseLoeschfuenfer={ target.useLoeschfuenfer }
						defaultLoeschfuenferRate={ target.loeschfuenferRate }
						indexable={ target.indexable }
						configuratorTargetIndividualTexts={target.configuratorTargetIndividualTexts}
						onIndexedChanged={onIndexedChanged}
						configuratorTargetBrokerCorrespondsWithPolicyVersionBroker={ configuratorTargetBrokerCorrespondsWithPolicyVersionBroker( target.broker, policyVersionBroker, brokerOrConsultant ) }
					/>
				)
			}
		});
		return result;
	}, [handleOnClose, validFrom, handleOnFeeChanged, handleOnAmountChanged, t, onConfiguratorTargetHeaderClick, onIndexedChanged, configuratorTargetBrokerCorrespondsWithPolicyVersionBroker, policyVersionBroker, brokerOrConsultant, targets] );

	return (
		<>
			<Accordion activeKey={activeConfiguratorTargetKey} >
				{ targetElements }
			</Accordion>
		</>
	)
}

ConfiguratorTargetList.propTypes = {
	targets: PropTypes.array,
	onClose: PropTypes.func,
	validFrom: PropTypes.any,
	feeTotalChanged: PropTypes.func,
	sameBuilding: PropTypes.func,
	activeConfiguratorTargetKey: PropTypes.number,
}

export { ConfiguratorTargetList };
