import React, {useState} from 'react';
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import restService from "../../_services/rest.service";

function AddConfiguratorTargetButton({onClick, id, building, optional}) {
    const {t} = useTranslation()
	const [disabled, setDisabled] = useState(false)

	return (
		<Button size={"sm"} variant={optional ? 'warning' : 'danger' } disabled={disabled} onClick={ () => {
			setDisabled(true)
			onClick(id, building)
				.then( () => setDisabled(false) )
				.catch( (error) => {
					restService.handleServerErrorsAxios( error )
					setDisabled(false)
				} )
		} }>{ t('default.add') }{ optional ? ' (' + t('default.optional') + ')' : ''}</Button>
	)
}

export default AddConfiguratorTargetButton
