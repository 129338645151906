import React, {useEffect, useState} from "react";
import restService from "./rest.service";
import i18n from "i18next";
import {messageBoxService} from "./messageBox.service";
import {MessageBoxButtons} from "../_components/MessageBox";
import {fileService} from "./file.service";
import FormElementsPolicyVersion from "../settlement/FormElementsPolicyVersion";
import FormElementsCommission from "../settlement/FormElementsCommission";
import FormElementsSettlement from "../settlement/FormElementsSettlement";
import FormElementsCreditNote from "../settlement/FormElementsCreditNote";
import FormElementsInvoice from "../settlement/FormElementsInvoice";
import FormElementsDamagePayment from "../settlement/FormElementsDamagePayment";
import FormElementsParticipation from "../settlement/FormElementsParticipation";
import FormElementsOtherLeadershipDiscount from "../settlement/FormElementsOtherLeadershipDiscount";
import FormElementsCreditNoteProfitParticipation from "../settlement/FormElementsCreditNoteProfitParticipation";
import TabContentProfitParticipationDetails from "../settlement/TabContentProfitParticipationDetails";
import LinkToDomain from "../LinkToDomain";
import FormElementsInvoiceProfitParticipation from "../settlement/FormElementsInvoiceProfitParticipation";
import TabPayments from "../settlement/TabPayments";
import PaymentStateBadge from "../payment/PaymentStateBadge";
import {apiClientPrivate} from "../api/apiClient";

export const invoiceService = {
	useSettlementBatchObjects,
	printSettlementBatch,
	bookSettlementBatch,
	bookSettlement,
	restartSettlementBatches,
	getPaymentLink,
	getSettlementFormElements,
	commissionCreditNoteToExcel,
	getDetailAsText,
	printSettlements,
	showInvoiceLabel,
	updateAdditionalInfo,
	checkPolicyVersionSettlementBatchRabattSelection,
}

function updateAdditionalInfo( params ) {
	return new Promise ((resolve, reject) => {
		apiClientPrivate.put( `/api/invoice/updateAdditionalInfo`, params, { } )
			.then( response => restService.handleServerResponseAxios( response ) )
			.then( resolve )
			.catch( reject )
	});
}

function printSettlements( searchText, searchOptions, namedCriteria, withLogo, ppstamp, selection ) {
	const domainName = 'settlement';
	return new Promise ((resolve, reject) => {
		if ( domainName ) {
			let params = restService.prepareSearchParams( domainName, searchText, searchOptions, namedCriteria );
			params.selection = JSON.stringify(selection)
			params.withLogo = withLogo
			params.ppstamp = ppstamp

			apiClientPrivate.get( `/api/invoice/printSettlements?` + new URLSearchParams( params ), {
				responseType: 'blob',
			})
				.then( (response) => {
					fileService.saveAttachmentWithContentDispositionFileName(response)
						.then(resolve)
				} )
				.catch( (error) => {
					restService.handleServerErrorsAxios( error );
					reject();
				} );
		}
	});
}

function bookSettlementBatch( id ) {
	messageBoxService.display( i18n.t( `settlementBatch.book.question` ), i18n.t( 'settlementBatch.book' ), [MessageBoxButtons.YES, MessageBoxButtons.NO] )
		.then( ( button ) => {
			if ( button === MessageBoxButtons.YES ) {
				const controller = new AbortController()
				apiClientPrivate( `/api/settlementBatch/book/${ id }`, {
					signal: controller.signal
				} )
					.then( response => restService.handleServerResponseAxios( response ) )
					.then( () => { /*do nothing*/ } )
					.catch( ( error ) => {
						restService.handleServerErrorsAxios( error, controller.signal );
					} );
			}
		})
}

function bookSettlement( id ) {
	messageBoxService.display(i18n.t('settlementBatch.book.question'), i18n.t('settlementBatch.book'), [MessageBoxButtons.YES, MessageBoxButtons.NO])
		.then( (button) => {
			if ( button === MessageBoxButtons.YES ) {
				const controller = new AbortController()
				apiClientPrivate.get( `/api/settlement/book/${ id }`, {
					signal: controller.signal
				} )
					.then( response => restService.handleServerResponseAxios( response ) )
					.then( () => { /*do nothing*/ } )
					.catch( ( error ) => {
						restService.handleServerErrorsAxios( error, controller.signal );
					} );
			}
		})
}

function printSettlementBatch( id, className, dateFrom, dateTo, withLogo = true, ppstamp = false ) {
	const controller = new AbortController()
	const params = {
		withLogo: withLogo,
		ppstamp: ppstamp,
	}
	apiClientPrivate.get( `/api/${className}/printSettlementBatch/${ id }?` + new URLSearchParams( params ), {
		responseType: 'blob',
		signal: controller.signal
	} )
		.then( fileService.saveAttachmentWithContentDispositionFileName )
		.catch( (error) => {
			restService.handleServerErrorsAxios( error, controller.signal );
		} );
}


function useSettlementBatchObjects(className, params) {
	const [ objects, setObjects ] = useState([]);

	useEffect(() => {
		const controller = new AbortController();

		if ( className ) {
			apiClientPrivate.get( `/api/${ className }/getObjects?` + new URLSearchParams( params ), {
				signal: controller.signal
			} )
				.then( response => restService.handleServerResponseAxios( response ) )
				.then( json => {
					setObjects(json.objects);
				} )
				.catch( error => {
					setObjects([]);
					restService.handleServerErrorsAxios( error, controller.signal );
				} );
			return function cleanup() {
				controller.abort();
			}
		}
	}, [className, params]);

	return [objects, setObjects];
}

function restartSettlementBatches() {
	const controller = new AbortController()
	apiClientPrivate.get( `/api/settlementBatch/restartSettlementBatches`, {
		signal: controller.signal
	} )
		.then( () => {
			//do nothing
		})
}

function getPaymentLink(className, id) {
	let result = ''

	if ( className ) {
		const regexp = /appvers.(BankPayment|ManualPayment)/g;
		const matches = [...className.matchAll( regexp )];

		if ( matches ) {
			switch ( matches[0][1] ) {
				case 'BankPayment':
					result = `/bankPayment/show/${id}`
					break
				case 'ManualPayment':
					result = `/manualPayment/show/${id}`
					break
				default:
					throw new Error(`Class '${className}' is neither BankPayment nor ManualPayment.`)
			}
		}
		else {
			throw new Error(`Class '${className}' is neither BankPayment nor ManualPayment.`)
		}
	}
	return result
}

function getSettlementFormElements(className) {
	let formElements = []
	let tabs = []

	if ( className ) {
		formElements.push(FormElementsSettlement)

		const regexp = /appvers.(Invoice|CreditNote)(.*)/g;
		const matches = [...className.matchAll( regexp )];

		const class1 = matches[0][1];
		const class2 = matches[0][2];

		switch ( class1 ) {
			case 'CreditNote':
				formElements.push( FormElementsCreditNote );
				break;
			case 'Invoice':
				formElements.push( FormElementsInvoice );
				tabs.push( {
					eventKey: 'payments',
					title: i18n.t('settlement.payments.label'),
					positionIdx: 1,
					content: TabPayments
				} )
				break;
			default:
				throw new Error( `Class '${ className }' is neither invoice nor creditNote.` );
		}

		switch ( class2 ) {
			case 'PolicyVersion':
				formElements.push( FormElementsPolicyVersion );
				break;
			case 'Commission':
				formElements.push( FormElementsCommission );
				break;
			case 'Participation':
				formElements.push( FormElementsParticipation );
				break;
			case 'DamagePaymentInvolvedInsurance':
			case 'DamagePaymentPolicyHolder':
			case 'DamagePaymentNotification':
				formElements.push( FormElementsDamagePayment );
				break;
			case 'OtherLeadershipDiscount':
				formElements.push( FormElementsOtherLeadershipDiscount )
				break;
			case 'ProfitParticipation':
				if ( class1 === 'CreditNote' ) {
					formElements.push( FormElementsCreditNoteProfitParticipation )
					tabs.push( {
						eventKey: 'test',
						title: i18n.t( 'tabContentProfitParticipationDetails.tab.label' ),
						positionIdx: 1,
						content: TabContentProfitParticipationDetails
					} )
				}
				else if ( class1 === 'Invoice' ) {
					formElements.push( FormElementsInvoiceProfitParticipation )
				}
				break;
			default:
				console.warn( `Class '${ className }' has no special elements?` )
		}
	}

	return {formElements: formElements, tabs: tabs}
}

function commissionCreditNoteToExcel(id) {
	const controller = new AbortController()
	apiClientPrivate.get( `/api/commissionSettlementBatch/commissionCreditNoteToExcel/${ id }`, {
		responseType: 'blob',
		signal: controller.signal
	} )
		.then( fileService.saveAttachmentWithContentDispositionFileName )
		.catch( (error) => {
			restService.handleServerErrorsAxios( error, controller.signal );
		} );
}

function getDetailAsText(detail) {
	let result = undefined;
	if ( detail ) {
		result = [];
		let idx = 0
		if ( detail.links ) {
			result = result.concat( detail.links.map( ( link ) => {
				return <LinkToDomain key={idx++} domain={ link.domain } text={ link.text }/>
			} ) );
		}
		if ( detail.infos ) {
			result = result.concat(detail.infos.map( ( info ) => {
				return <div key={idx++}>{ info }</div>
			} ));
		}
		if ( detail.paymentState ) {
			result = result.concat(
				<PaymentStateBadge paymentState={detail.paymentState}/>
			);
		}
	}
	return result
}

function showInvoiceLabel(invoice) {
	if ( !invoice.refNr ) {
		return (
			<span>
				{invoice.label}
			</span>
		)
	}
	else {
		return (
			<span>
			{invoice.refNr ? `${invoice.refNr}, ` : ''} {invoice.invoiceNr}, {invoice.paymentTotal}/{invoice.total}
				<PaymentStateBadge paymentState={invoice.paymentState}/>
		</span>
		)
	}
}

function checkPolicyVersionSettlementBatchRabattSelection( params ) {
	return new Promise ((resolve, reject) => {
		apiClientPrivate.put( `/api/settlementBatch/checkPolicyVersionSettlementBatchRabattSelection`, params, { } )
			.then( response => restService.handleServerResponseAxios( response ) )
			.then( json => {
				resolve(json)
			} )
			.catch( reject )
	});
}
