import React, {useEffect, useState} from 'react';
import {Alert as BootstrapAlert, Button, Card, Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import logoAppvers from "./images/logo_appvers_with_name.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faKey} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import DefaultSpinner from "./_components/DefaultSpinner";
import {useTranslation} from "react-i18next";
import {securityService} from "./_services/security.service";
import {Alert} from "./_components";
import FormControl from "./_components/FormControl";
import {FormProvider, useForm} from "react-hook-form";
import {alertService} from "./_services";
import Trans from "./_components/Trans";
import restService from "./_services/rest.service";
import i18n from "i18next";

function PasswordRequirements({passwordRequirements}) {

    function renderRules() {
        const rules = [];
        rules.push(i18n.t('password.rules.minPasswordLength', {
            minPasswordLength: passwordRequirements.minPasswordLength
        }));

        const pattern = passwordRequirements.validationRegex;

        // We'll look for segments that start with `(?=.*[`
        // and end with `])`. Each segment defines a requirement.
        let startIndex = 0;
        while ((startIndex = pattern.indexOf('(?=.*[', startIndex)) !== -1) {
            const endIndex = pattern.indexOf('])', startIndex);
            if (endIndex === -1) break; // Just a safety check

            // Extract the content inside the brackets
            // `(?=.*[` is 6 chars before the bracket content starts
            const charSet = pattern.substring(startIndex + 6, endIndex);

            // Check what type of requirement this character set represents
            if (charSet.includes('0-9')) {
                // Digits
                rules.push(i18n.t('password.rules.mustContainDigit'));
            } else if (charSet.includes('A-Z')) {
                // Uppercase letters
                rules.push(i18n.t('password.rules.mustContainUppercase'));
            } else {
                // Special characters
                // Remove backslashes for display
                const displayedChars = charSet.replace(/\\/g, '');
                rules.push(i18n.t('password.rules.mustContainSpecial', { specialCharacters: displayedChars }));
            }

            startIndex = endIndex;
        }

        return (
            <ul>
                {rules.map((rule, index) => (
                    <li key={index}>{rule}</li>
                ))}
            </ul>
        );
    }

    if ( passwordRequirements ) {
        return (
            <div>
                <Trans i18nKey="password.rules.header"/>
                {renderRules()}
            </div>
        )
    }
    else {
        return null
    }
}

function PasswordSetup({token, isPasswordReset = true} ) {
    const { t } = useTranslation()
    const [tokenVerified, setTokenVerified] = useState(false)
    const [tokenValid, setTokenValid] = useState(false)
    const [passwordSet, setPasswordSet] = useState(false)
    const useFormObject = useForm();
    const [passwordRequirements, setPasswordRequirements] = useState({})
    const password = useFormObject.watch('password');

    useEffect(() => {
        // Verify the token
        // If the token is valid, setTokenVerified to true
        securityService.checkToken(token).then( (result) => {
            setTokenVerified(true)
            setTokenValid(result.valid === true )
        }).catch( (error) => {
            restService.handleServerErrorsAxios(error)
        })
        securityService.getPasswordRequirements(token).then( (json) => {
            if ( json.valid ) {
                setPasswordRequirements(json)
            }
        })
    }, [token])

    const handleSubmit = (data) => {
        securityService.changePassword( token, data.password ).then( (json) => {
            if ( json && json.messageCode ) {
                alertService.error(t(json.messageCode))
            }
            else {
                setPasswordSet(true)
            }
        })
    }

    const renderNavigation = () => {
        return (
            <Row className={"mb-3 mt-3"}>
                {(isPasswordReset || passwordSet) && <Col>
                    <p className={"text-end"}>
                        <Link to={"/"} className={"float-end"}>{t('login.backToLogin.label')}</Link>
                    </p>
                </Col>}
            </Row>
        )
    }

    const renderSuccess = () => {
        return (
            <Container>
                <Row>
                    <Col>
                        <BootstrapAlert variant="success">
                            <center>{isPasswordReset?t('login.passwordReset.success'):t('login.passwordCreate.success')}</center>
                        </BootstrapAlert>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {renderNavigation()}
                    </Col>
                </Row>
            </Container>
        )
    }

    if ( tokenVerified ) {
        if ( tokenValid ) {
            if ( passwordSet ) {
                return renderSuccess()
            }
            else {
                return (
                    <Col sm="7" md="5" lg="4" xl="3">
                        <Card className="my-5">
                            <Card.Body>
                                <div className="text-center">
                                    <img src={logoAppvers} alt={"logo"} className="my-3"/>
                                </div>

                                <Alert/>
                                <BootstrapAlert variant="info">
                                    <Trans i18nKey={'login.passwordReset.info'}/>
                                    <PasswordRequirements passwordRequirements={passwordRequirements.passwordRules}/>
                                </BootstrapAlert>
                                <FormProvider {...useFormObject}>
                                    <Form className="login-form" onSubmit={useFormObject.handleSubmit(handleSubmit)}>
                                        <InputGroup className={"mb-3"}>
                                            <InputGroup.Text><FontAwesomeIcon
                                                icon={faKey}/></InputGroup.Text>
                                            <FormControl type='password' name='password'
                                                         rules={{
                                                             required: true
                                                         }}
                                                         placeholder={t('login.new.password.label')}
                                                         autoComplete="off"/>
                                        </InputGroup>
                                        <InputGroup>
                                            <InputGroup.Text><FontAwesomeIcon
                                                icon={faKey}/></InputGroup.Text>
                                            <FormControl type={'password'} name={'confirmPassword'}
                                                         validationMessages={{
                                                             passwordMatch: t('login.new.password.repeat.error')
                                                         }}
                                                         rules={{
                                                             validate: {
                                                                 passwordMatch: (v) => {
                                                                     return v === password
                                                                 }
                                                             },
                                                             required: true
                                                         }}
                                                         placeholder={t('login.new.password.repeat.label')}
                                                         autoComplete="off"/>
                                        </InputGroup>
                                        {renderNavigation()}
                                        <Button className="px-5 mb-4 w-100" variant="success"
                                                type="submit">{isPasswordReset?t('login.change.password.label'):t('login.create.password.label')}</Button>
                                    </Form>
                                </FormProvider>
                            </Card.Body>
                        </Card>
                    </Col>
                )
            }
        }
        else {
            return (
                <Container>
                    <Row>
                        <Col>
                            <BootstrapAlert variant="danger">
                                <center>{t('login.password.invalidToken')}</center>
                            </BootstrapAlert>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {renderNavigation()}
                        </Col>
                    </Row>
                </Container>
            )
        }
    }
    else {
        return (
            <DefaultSpinner loading={true}/>
        )
    }
}

export default PasswordSetup
