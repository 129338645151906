import React, {useMemo} from 'react';
import {Alert, Table} from "react-bootstrap";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

function SimpleTable( {columns, rows} ) {
    const {t} = useTranslation();

    const visibleColumns = useMemo( () => {
        return columns.filter( (column) => !column.hidden )
    }, [columns] )

    const getDisplayValue = ( column, object ) => {
        if ( column && column.formatter && column.formatter instanceof Function ) {
            return column.formatter( object )
        }
        else {
            return <span className={"bg-danger"} >{`Formatter missing for column '${column.key || column.id}'`}</span>
        }
    }

    const table = useMemo( () => {
        const getRows = () => {
            const getRow = ( object, key ) => {
                return (
                    <tr key={ key }>
                        { visibleColumns.map( column => {
                            return <td key={column.key || column.id} className={(column.className && column.className instanceof Function) ? column.className(object) : column.className }>{ getDisplayValue(column, object) }</td>
                        } ) }
                    </tr>
                )
            }

            if ( rows && rows.length > 0 ) {
                return rows.map( ( object, index ) => getRow( object, index ) );
            }
            else {
                return <tr><td colSpan={columns.length}><Alert variant={"light"}>{t('simpleTable.noData')}</Alert></td></tr>
            }
        }

        const getTable = () => {
            return (
                <Table bordered>
                    { visibleColumns.some( (column) => column.label ) && <thead>
                    <tr key={'0'}>
                        {visibleColumns.map(column => {
                            return <th key={column.key || column.id} className={column.className}>{column.label}</th>
                        })}
                    </tr>
                    </thead> }
                    <tbody>
                    { getRows() }
                    </tbody>
                </Table>
            )
        }


        return getTable();
    }, [rows, visibleColumns, t, columns.length] )

	return table
}

SimpleTable.propTypes = {
    columns: PropTypes.arrayOf( PropTypes.object ).isRequired,
    rows: PropTypes.arrayOf( PropTypes.object ).isRequired,
}

export default SimpleTable