import React, {useState} from 'react';
import {Alert, Button, Col, Form, OverlayTrigger, Popover, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock as faClockSolid, faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {faClock} from "@fortawesome/free-regular-svg-icons";
import {useTranslation} from "react-i18next";
import moment from "moment";
import textService from "../_services/text.service";

function Snooze( {notification, onUpdate, className, defaultValue} ) {
    const {t} = useTranslation();
    const [newSnooze, setNewSnooze] = useState( notification.snoozedUntil || defaultValue );
    const [showSnoozePopover, setShowSnoozePopover] = useState( false );

    const shiftSnooze = (amount, unit) => {
        const newDate = moment(newSnooze).add(amount, unit)
        setNewSnooze(newDate.format('YYYY-MM-DD'))
    }

    const renderSnoozeTooltip = (
        <Popover id="popover-basic" style={{maxWidth: "600px"}}>
            <Popover.Header as="h3">{ t('notification.snoozedUntil') }</Popover.Header>
            <Popover.Body>
                <Form>
                    { notification.snoozedUntil && <Row>
                        <Col>
                            <Alert variant={"info"}>{ t(`snooze.snoozedUntil.${notification.classSimpleName}`, {date: textService.formatDateTime( moment(notification.snoozedUntil), { year: "numeric", month: "numeric", day: "numeric", weekday: "long" } )}) }</Alert>
                        </Col>
                    </Row> }
                    <Row>
                        <Col>
                            <Button className={"float-start me-2"} onClick={() => shiftSnooze('-1', 'weeks') }><FontAwesomeIcon icon={faMinus}/> 1 {t('default.week', {count: 1})}</Button>
                            <Button className={"float-start"} onClick={() => shiftSnooze('-1', 'days') }><FontAwesomeIcon icon={faMinus}/> 1 {t('default.day', {count: 1})}</Button>
                            <Form.Control
                                className={"ms-2 me-2"}
                                style={{ width: 'auto', display: 'inline-block' }}
                                autoFocus
                                type="date"
                                value={ newSnooze }
                                onChange={ (event) => setNewSnooze( event.target.value ) }
                            />
                            <Button className={"float-end"} onClick={() => shiftSnooze('1', 'weeks') }><FontAwesomeIcon icon={faPlus}/> 1 {t('default.week', {count: 1})}</Button>
                            <Button className={"float-end me-2"} onClick={() => shiftSnooze('1', 'days') }><FontAwesomeIcon icon={faPlus}/> 1 {t('default.day', {count: 1})}</Button>
                        </Col>
                    </Row>
                    <div className={"mt-3"}>
                        <Button size={"sm"} variant={"primary"} type="submit" onClick={ (event) => {
                            event.preventDefault();
                            event.stopPropagation()
                            onUpdate(notification.id, {snoozedUntil: newSnooze, version: notification.version})
                            setShowSnoozePopover( false )
                        }}>{ t('snooze.snooze') }</Button>
                        <Button size={"sm"} variant={"secondary"} className={"ms-2"} onClick={ () => {
                            setShowSnoozePopover( false )
                        }}>{ t('default.cancel') }</Button>
                        <Button size={"sm"} variant={"danger"} className={"ms-2"} onClick={ (event) => {
                            event.preventDefault();
                            event.stopPropagation()
                            onUpdate(notification.id, {snoozedUntil: null, version: notification.version})
                            setShowSnoozePopover( false )
                        }}>{ t('snooze.dontSnooze') }</Button>
                    </div>
                </Form>
            </Popover.Body>
        </Popover>
    );


    return (
        <OverlayTrigger
            onToggle={ ( show ) => setShowSnoozePopover( true ) }
            trigger={"click"}
                show={showSnoozePopover}
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderSnoozeTooltip}
                onHide={ () => setShowSnoozePopover( false ) }
            >
                <span className={ `snooze-clock ${className} ${ notification.snoozedUntil ? 'text-danger' : '' }` }>
                    <FontAwesomeIcon icon={ notification.snoozedUntil ? faClockSolid : faClock} size={"lg"} className={"ms-3 cursor-pointer"} />
                </span>
        </OverlayTrigger>
	)
}

export default Snooze