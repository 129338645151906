import restService from "./rest.service";
import {apiClientPrivate} from "../api/apiClient";

export const policyTargetConditionService = {
	isFulfilled,
}

function isFulfilled( configuratorTargetIds, brokerId ) {
	return new Promise( (resolve, reject) => {
		let data = { }
		data['configuratorTargets'] = configuratorTargetIds
		data['brokerId'] = brokerId
		const params = { data: JSON.stringify( data ) }
		apiClientPrivate.post( `/api/policyTargetCondition/isPolicyTargetConditionFulfilled`, params )
			.then( r => restService.handleServerResponseAxios( r ) )
			.then( json => resolve(json) )
			.catch( reject );
	});
}
