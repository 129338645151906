import React, {useEffect, useState} from 'react';
import restService from "../_services/rest.service";
import PropTypes from "prop-types";
import AcesTable from "./AcesTable";
import {apiClientPrivate} from "../api/apiClient";

function AclMerge( { domainInstance1, domainInstance2, editable } ) {
    const [ aclInfo, setAclInfo ] = useState( );

    useEffect(() => {
        if ( domainInstance1 === undefined || !domainInstance1.id || domainInstance2 === undefined || !domainInstance2.id ) return;
        if ( domainInstance1.classSimpleName !== domainInstance2.classSimpleName ) {
            throw new Error(`AclMerge: Domain instances must be of same domain. Following domains found: '${domainInstance1.classSimpleName}' and '${domainInstance2.classSimpleName}'`)
        }
        const controller = new AbortController()
        const data = {
            id1: domainInstance1.id,
            id2: domainInstance2.id,
            className: domainInstance1.classSimpleName
        }
        apiClientPrivate.post( `/api/security/aclInfoMerge`, data, {
            signal: controller.signal,
        } )
            .then((response) => restService.handleServerResponseAxios(response))
            .then( data => {
                setAclInfo( data );
            } )
            .catch( error =>  restService.handleServerErrorsAxios( error, controller.signal ) );
        return function cleanup() {
            controller.abort();
        }
    }, [ domainInstance1, domainInstance2, setAclInfo ]);

    return (
        <AcesTable aclInfo={aclInfo} editable={editable} />
    )
}

export default AclMerge

AclMerge.propTypes = {
    domainInstance: PropTypes.object,
    editable: PropTypes.bool,
}

AclMerge.defaultProps = {
    editable: false,
}
