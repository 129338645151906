export class SimpleCache {
	#cache;

	constructor(){
		this.#cache = { }
	}

	get(key) {
		return this.#cache[key]
	}

	set(key, value) {
		this.#cache[key] = value
	}

	setAll(data) {
		for (const key in data) {
			this.set(key, data[key])
		}
	}

	keyExists(key) {
		return Object.keys(this.#cache).includes(key)
	}

	clear() {
		this.#cache = { }
	}
}