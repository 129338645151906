import React, {useCallback, useEffect, useState} from 'react';
import {Button, Card, Col, Row} from "react-bootstrap";
import ShowField from "../form/ShowField";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import restService from "../_services/rest.service";
import InvoiceAdditionalInfoEditor from "./InvoiceAdditionalInfoEditor";
import {invoiceService} from "../_services/invoice.service";

function InvoiceAdditionalInfo({clazzName, instance, updatingAdditionalInfo, additionalInfoButtonText, afterUpdate, selection, allSelected}) {
	const {t} = useTranslation();
	const [ rabatt, setRabatt ] = useState(undefined);
	const [ rabattText1, setRabattText1 ] = useState(undefined);
	const [ rabattText2, setRabattText2 ] = useState(undefined);
	const [ additionalInfo, setAdditionalInfo ] = useState(undefined);
	const [ showAdditionalInfoEditor, setShowAdditionalInfoEditor ] = useState(false);

	useEffect( () => {
		if ( undefined === rabatt && instance.id !== undefined ) {
			setRabatt( instance.rabatt );
			setRabattText1( instance.rabattText1 );
			setRabattText2( instance.rabattText2 );
			setAdditionalInfo( instance.additionalInfo );
		}
	}, [setRabatt, rabatt, setRabattText1, setRabattText2, setAdditionalInfo, instance.id, instance.rabatt, instance.rabattText1, instance.rabattText2, instance.additionalInfo])

	const onAdditionalInfoChanged = useCallback(() => {
		invoiceService.updateAdditionalInfo({className: clazzName, id: instance.id, version: instance.version, rabatt: rabatt, rabattText1: rabattText1, rabattText2: rabattText2, additionalInfo: additionalInfo, selection: selection, allSelected: allSelected})
			.then(() => {
				if ( afterUpdate ) {
					afterUpdate()
				}
			})
			.catch( (error, signal) => {
				restService.handleServerErrorsAxios(error, signal)
			});
	}, [instance.id, rabatt, additionalInfo, afterUpdate, clazzName, instance.version, selection, allSelected, rabattText1, rabattText2]);

	return (
		<>
			<InvoiceAdditionalInfoEditor
				show={showAdditionalInfoEditor}
				setShow={setShowAdditionalInfoEditor}
				rabatt={rabatt}
				setRabatt={setRabatt}
				rabattText1={rabattText1}
				setRabattText1={setRabattText1}
				rabattText2={rabattText2}
				setRabattText2={setRabattText2}
				additionalInfo={additionalInfo}
				setAdditionalInfo={setAdditionalInfo}
				onSubmit={onAdditionalInfoChanged}
			/>
			<Card>
				<Card.Header>
					{t('policyVersionSettlementBatch.additionalInfo.label')}
				</Card.Header>
				<Card.Body>
					<Row>
						<ShowField label={'policyVersionSettlementBatch.rabatt.label'} object={instance} property={'rabatt'} type={'text'} size={12} appendix={' %'}/>
					</Row>
					<Row>
						<ShowField label={'policyVersionSettlementBatch.rabattText1.label'} object={instance} property={'rabattText1'} type={'text'} size={12} />
					</Row>
					<Row>
						<ShowField label={'policyVersionSettlementBatch.rabattText2.label'} object={instance} property={'rabattText2'} type={'text'} size={12} />
					</Row>
					<Row>
						<ShowField label={'policyVersionSettlementBatch.additionalInfo.label'} object={instance} property={'additionalInfo'} type={'text'} size={12} />
					</Row>
					<Row>
						<Col>
							<Button
								className={"float-end"}
								variant={'primary'}
								disabled={updatingAdditionalInfo || !(instance && !instance.booked)}
								onClick={() => setShowAdditionalInfoEditor(true)}
							>
								{(instance && instance.booked) ? t('data.are.booked') : additionalInfoButtonText}
							</Button>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</>
	)
}

export default InvoiceAdditionalInfo

InvoiceAdditionalInfo.propTypes = {
	clazzName: PropTypes.string,
	instance: PropTypes.object,
	updatingAdditionalInfo: PropTypes.bool,
	additionalInfoButtonText: PropTypes.string,
	afterUpdate: PropTypes.func
}
