import React, {useEffect, useMemo, useState} from 'react';
import restService from "../_services/rest.service";
import {Card, Col, Form, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import PlainValue from "./PlainValue";
import EditableTableControl from "../form/EditableTableControl";
import SimpleTable from "./SimpleTable";
import bootstrapTableService from "./bootstrapTable.service";
import PropTypes from "prop-types";

function AcesTable( {aclInfo, editable}) {
    const { t } = useTranslation();
    const [ isEditable, setIsEditable ] = useState( false );
    const [ acesByPrincipal, setAcesByPrincipal ] = useState( );
    const [usersAndRoles] = restService.useUsersAndRoles()

    useEffect(() => {
        setIsEditable(editable)
    }, [editable])

    useEffect(() => {
        if ( aclInfo && aclInfo.aces ) {
            setAcesByPrincipal(
                aclInfo.aces.reduce( (acc, ace) => {
                    let sidName
                    if ( ace.sid.principal ) {
                        sidName = ace.sid.principal
                    }
                    else if ( ace.sid.grantedAuthority ) {
                        sidName = ace.sid.grantedAuthority
                    }
                    let exitingAcc = acc.find( accItem => accItem.userOrRole === sidName )
                    if ( !exitingAcc ) {
                        exitingAcc = {
                            userOrRole: sidName,
                            readPermission: false,
                            writePermission: false
                        }
                        acc.push( exitingAcc )
                    }
                    if ( ace.acl.permission === 1 ) {
                        exitingAcc.readPermission = true
                    }
                    else if ( ace.acl.permission === 2 ) {
                        exitingAcc.writePermission = true
                    }
                    return acc
                }, [])
            )
        }
    }, [aclInfo])

    //if readPermission is removed, writePermission must be removed too
    //if writePermission is added, readPermission must be added too
    async function handleAfterPermissionUpdate(rowId, data, originalRow) {
        let newData = data[rowId]
        if ( (originalRow && originalRow.readPermission)  && !newData.readPermission ) {
            newData.writePermission = false
        }
        else if ( (!originalRow || !originalRow.readPermission) && newData.writePermission ) {
            newData.readPermission = true
        }
        return data;
    }

    const columns = useMemo( () => {
        const usersAndRolesValidator = (( value, data, rowIndex ) => {
            if ( data[rowIndex].deleted ) return
            if ( !usersAndRoles.find( item => { return (value.label ? item.label === value.label : item === value) } ) ) {
                return t('acl.userOrRole.notFound.message')
            }
            //check uniqueness
            if ( data.find( (item, index) => {
                if ( index !== rowIndex && item.userOrRole === value && !item.deleted) {
                    return true
                }
                return false
            })) {
                return t('acl.userOrRole.unique.message')
            }
        })

        const permissionCommonValidator = (value, data, rowIndex) => {
            if ( data[rowIndex].deleted ) return
            if ( !data[rowIndex].writePermission && !data[rowIndex].readPermission)  {
                return t('acl.readPermission.or.writePermission.required.message')
            }
        }

        return [
            {
                id: "userOrRole",
                label: t("acl.userOrRole.label"),
                input: {tag: "typeahead", id: 'name', options: usersAndRoles, required: true},
                validator: usersAndRolesValidator,
                formatter: (value) => value.userOrRole
            },
            {
                id: "readPermission",
                label: t("acl.readPermission.label"),
                input: {tag: "check", type: "checkbox"},
                validator: (value, data, rowIndex) => {
                    let result = permissionCommonValidator(value, data, rowIndex)
                    if (result) {
                        return result
                    } else if (data[rowIndex].writePermission && !data[rowIndex].readPermission) {
                        return t('acl.readPermission.required.message')
                    }
                },
                afterUpdate: handleAfterPermissionUpdate,
                formatter: ( value ) => bootstrapTableService.formatterBoolean(value.readPermission)
            },
            {
                id: "writePermission",
                label: t("acl.writePermission.label"),
                input: {tag: "check", type: "checkbox"},
                validator: permissionCommonValidator,
                afterUpdate: handleAfterPermissionUpdate,
                formatter: ( value ) => bootstrapTableService.formatterBoolean(value.writePermission)
            },
        ]
    }, [t, usersAndRoles] )

    const renderAcesTable = () => {
        if ( !acesByPrincipal )  return
        //group aces by ace.sid.principal
        if ( isEditable ) {
            return <EditableTableControl name="aclData" columns={columns} data={acesByPrincipal} />
        }
        else {
            return <SimpleTable columns={ columns } rows={ acesByPrincipal }/>
        }
    }

    if ( !aclInfo || ( !aclInfo.canChangeOwner && !aclInfo.canChangeAcl) ) return null

    return (
        <>
            <Card className={"mb-2"}>
                <Card.Header>{t('aclInfo.panel.label')}</Card.Header>
                <Card.Body>
                    {aclInfo.canChangeOwner && <Row>
                        <Col><Form.Label>Owner</Form.Label><PlainValue value={aclInfo.owner.principal ? aclInfo.owner.principal : aclInfo.owner.grantedAuthority}/> </Col>
                    </Row>}
                    {aclInfo.canChangeAcl && <Row>
                        {renderAcesTable()}
                    </Row>}
                </Card.Body>
            </Card>
            {/*<div>*/}
            {/*    <pre>{JSON.stringify(aclInfo, null, '\t')}</pre>*/}
            {/*    <pre>{JSON.stringify(acesByPrincipal, null, '\t')}</pre>*/}
            {/*</div>*/}
        </>
    )
}

export default AcesTable

AcesTable.propTypes = {
    aclInfo: PropTypes.object,
    editable: PropTypes.bool,
}

AcesTable.defaultProps = {
    editable: false,
}