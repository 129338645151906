import React, {useMemo} from 'react';
import {useConfig} from "./_services/useConfig";
import textService from "./_services/text.service";
import {Badge, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {securityService} from "./_services/security.service";
import settingService from "./_services/setting.service";
import {useTranslation} from "react-i18next";

const Footer = ( factory, deps ) => {
    const { config: { versionInfo } } = useConfig()
    const hasAccessToYouTrackIssues = securityService.useGranted( `/api/application/getResolvedYouTrackIssues` )
    const {helpdeskEmail} = settingService.useSetting(['helpdeskEmail'])
    const { t } = useTranslation();

    const timestamp = useMemo( () => {
        if( versionInfo.timestamp ) {
            return textService.formatDateTime(Date.parse(versionInfo.timestamp));
        }
        else {
            return ''
        }
    }, [versionInfo.timestamp])

    const element = useMemo( () => {
        return <Badge className={"float-end"} bg="primary">Version { versionInfo.tag } vom: { timestamp }</Badge>
    }, [versionInfo.tag, timestamp])

    return (
        <Row>
            <Col>
                <a href={`mailto:${helpdeskEmail}`}>{t('default.contactUs')}</a>
            </Col>
            <Col>
                { (hasAccessToYouTrackIssues.ready && hasAccessToYouTrackIssues.value ) ?
                    <Link to={ "/changelog" }>{ element }</Link>
                        :
                    element }
            </Col>
        </Row>
    )

};

export default Footer;
