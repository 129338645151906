import React, {useMemo} from 'react';
import {ToastContainer} from "react-bootstrap";
import Ticket from "./Ticket";

function TicketsCell( {tickets, handleUpdateTicket}) {

    const ticketsElements = useMemo( () => {
        return tickets.map( ( ticket) => <Ticket key={ticket.id} ticket={ticket} onUpdate={handleUpdateTicket}/> )
    }, [tickets, handleUpdateTicket])

	return (
        <ToastContainer className="position-relative d-flex flex-wrap gap-3 w-100" style={{zIndex:1000}}> {/*zIndex set because of ToastContainer in MessagePanel.jsx*/}
            {ticketsElements}
        </ToastContainer>
	)
}

export default TicketsCell