import React, {useEffect, useMemo, useState} from 'react';
import {Button, ButtonGroup, Card, Col, Dropdown, Form, FormLabel, InputGroup, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import FormControl from "../_components/FormControl";
import {RestAsyncSelectControl} from "../_components/RestAsyncSelectControl";
import {FormElements as ZipFormElements} from "../zip/FormElements";
import {PartnerType} from "../_enum/enum";
import FormCheckControl from "../_components/FormCheckControl";
import EditableTableControl from "../form/EditableTableControl";
import {partnerService} from "../_services/partner.service";
import FileUploader from "../_components/FileUploader";
import {GoogleMapLink} from "../_components/GoogleMapLink";
import {isValid as isValidIban} from 'iban';
import {useFormContext} from "react-hook-form";
import {damageService} from "../_services/damage.service";
import DropdownItemWithOptions from "../_components/DropdownItemWithOptions";
import restService from "../_services/rest.service";
import textService from "../_services/text.service";
import moment from "moment";
import {AllowedForEnum} from "../form/form.service";
import {securityService} from "../_services/security.service";
import DisplayIfAnyChildPropertyPresent from "../form/DisplayIfAnyChildPropertyPresent";
import Allowed from "../_components/Allowed";
import InputGroupText from "../_components/InputGroupText";
import InputGroupCheckbox from "../_components/InputGroupCheckbox";
import Acl from "../_components/Acl";
import MergeHints from "../_components/MergeHints";
import MergeHint from "../_components/MergeHint";
import {ChonkyFileBrowser} from "../_components/ChonkyFileBrowser";
import AclMerge from "../_components/AclMerge";

function FormElements({domainInstance, defaultValues, mergeDomainInstance}) {
	const { t } = useTranslation();
	const [zipLabel, setZipLabel] = useState(domainInstance.zipLabel || '');
	const [streetName, setStreetName] = useState(domainInstance.streetName || '');
	const [streetNr, setStreetNr] = useState(domainInstance.streetNr || '');
	const [isAgent, setIsAgent] = useState(domainInstance.isAgent)
	const [isBroker, setIsBroker] = useState(domainInstance.isBroker || false)
	const [isBuildingManager, setIsBuildingManager] = useState(domainInstance.isBuildingManager || false)
	const [isInsuranceCompany, setIsInsuranceCompany] = useState(domainInstance.isInsuranceCompany)
	const brokerInterestRateNames = partnerService.useBrokerInterestRateNames()
	const [brokerInterestRatesData, setBrokerInterestRatesData] = useState( [] )
	const { getValues } = useFormContext();
	const [tags] = restService.useDomainInstancesList('partnerTag', 1, 10000, undefined, undefined, undefined, undefined, "select");
	const isDomainEditable = securityService.useAllowedFor( AllowedForEnum.EDIT )
	const hasAccessToAttachments = securityService.useAccessToProperty( 'partner.attachments' )
	const partnerType = useMemo( () => domainInstance.partnerType || (defaultValues && defaultValues.partnerType), [domainInstance, defaultValues])
	const [phonePrivate, setPhonePrivate] = useState(domainInstance.phonePrivate || (defaultValues && defaultValues.phonePrivate))
	const [emailPrivate, setEmailPrivate] = useState(domainInstance.emailPrivate || (defaultValues && defaultValues.emailPrivate))
	const [phoneMobile, setPhoneMobile] = useState(domainInstance.phoneMobile || (defaultValues && defaultValues.phoneMobile))
	const [phoneBusiness, setPhoneBusiness] = useState(domainInstance.phoneBusiness || (defaultValues && defaultValues.phoneBusiness))
	const [emailBusiness, setEmailBusiness] = useState(domainInstance.emailBusiness || (defaultValues && defaultValues.emailBusiness))
	const isMerging = mergeDomainInstance && Object.keys(mergeDomainInstance).length > 0

	useEffect( () => {
		//domainInstance is loaded later, thus 'undefined' is set in useState. Later, when domainInstance is loaded, we need to update the values here:
		setPhonePrivate( domainInstance.phonePrivate || (defaultValues && defaultValues.phonePrivate) )
		setEmailPrivate( domainInstance.emailPrivate || (defaultValues && defaultValues.emailPrivate) )
		setPhoneMobile( domainInstance.phoneMobile || (defaultValues && defaultValues.phoneMobile) )
		setPhoneBusiness( domainInstance.phoneBusiness || (defaultValues && defaultValues.phoneBusiness) )
		setEmailBusiness( domainInstance.emailBusiness || (defaultValues && defaultValues.emailBusiness) )
	}, [domainInstance, defaultValues] );

	const columns = [
		{id: "validFrom", label: t("brokerInterestRate.validFrom.label"), input: {tag: "input", type: "date", required: true}, formatter: (row) => textService.formatDateTime(moment(row.validFrom), {dateStyle: 'medium'})},
		{id: "interestRate", label: t("brokerInterestRate.interestRate.label"), input: {tag: "input", type: "text", required: true}, formatter: (row) => textService.formatNumber(row.interestRate) + '%'},
		{id: "name", label: t("brokerInterestRate.name.label"), input: {tag: "typeahead", id: "brokerInterestRate", type: "text", options: brokerInterestRateNames, required: true}, formatter: (row) => row.name }
	]

	useEffect( () => {
		const getBrokerInterestRatesData = () => {
			if ( domainInstance && domainInstance.brokerInterestRates ) {
				return domainInstance.brokerInterestRates.map((interestRateData) => {
					return {
						id: interestRateData.id,
						validFrom: interestRateData.validFrom,
						interestRate: interestRateData.interestRate,
						name: interestRateData.name,
					}
				})
			}
			else {
				return []
			}
		}
		setZipLabel(domainInstance.zipLabel || '')
		setStreetName(domainInstance.streetName || '');
		setStreetNr(domainInstance.streetNr || '');
		setIsAgent( domainInstance.isAgent );
		setIsBroker( domainInstance.isBroker || false );
		setIsBuildingManager( domainInstance.isBuildingManager || false );
		setIsInsuranceCompany( domainInstance.isInsuranceCompany );
		setBrokerInterestRatesData( getBrokerInterestRatesData() );
	}, [domainInstance])

	const renderPhonePrivate = () => {
		if ( partnerType === PartnerType.PERSON ) {
			return (
				<Allowed allowedFor={AllowedForEnum.SHOW} propertyName={"phonePrivate"} >
					<Form.Group as={Col} md="4" controlId="formGroupNamePhonePrivate">
						<Form.Label>{t('partner.phonePrivate')}<MergeHint name={"phonePrivate"}/></Form.Label>
						<FormControl
							name={"phonePrivate"}
							type={"text"}
							value= {domainInstance.phonePrivate || (defaultValues && defaultValues.phonePrivate)}
							onChange={(event) => { setPhonePrivate(event.target.value || '') }}
							rules={{
								required: !emailPrivate && !phoneMobile
							}}
							validationMessages={{
								required: t('partner.phonePrivate.or.emailPrivate.or.phoneMobile.required'),
							}}
						/>
					</Form.Group>
				</Allowed>
			)
		}
	}

	const renderBrokerTable = () => {
		if ( isBroker ) {
			return (
				<Allowed allowedFor={AllowedForEnum.SHOW} propertyName={"brokerInterestRates"} >
					<Row>
						<EditableTableControl name="brokerInterestRatesData" columns={columns} data={brokerInterestRatesData || []}/>
					</Row>
				</Allowed>
			)
		}
	}

	const renderAttachments = () => {
		if ( domainInstance && domainInstance.id ) {
			return (
				<Row className={ "mb-3" }>
					<ChonkyFileBrowser holderType={'partner'} holderId={domainInstance.id}/>
				</Row>
			)
		}
		else if ( isDomainEditable.ready && isDomainEditable.value && hasAccessToAttachments.ready && hasAccessToAttachments.write ) {
			return (
				<Row>
					<Form.Group as={ Col } md="12" controlId="groupFileChanges">
						<Form.Label>{ t( 'uploadFiles.label' ) }</Form.Label>
						<FileUploader changesPropertyName={ 'fileChanges' }/>
					</Form.Group>
				</Row>
			)
		}
	}

	const renderEmailPrivate = () => {
		if ( partnerType === PartnerType.PERSON ) {
			return (
				<Allowed allowedFor={AllowedForEnum.SHOW} propertyName={"emailPrivate"} >
					<Form.Group as={Col} md="4" controlId="formGroupEmailPrivate">
						<Form.Label>{t('partner.emailPrivate')}<MergeHint name={"emailPrivate"}/></Form.Label>
						<FormControl
							name={"emailPrivate"}
							type={"email"}
							value={domainInstance.emailPrivate || (defaultValues && defaultValues.emailPrivate)}
							onChange={(event) => { setEmailPrivate(event.target.value || '') }}
							rules={{
								required: !phonePrivate && !phoneMobile
							}}
							validationMessages={{
								required: t('partner.phonePrivate.or.emailPrivate.or.phoneMobile.required'),
							}}
						/>
					</Form.Group>
				</Allowed>
			)
		}
	}

	const renderName = () => {
		const renderName = (width) => {
			return (
				<Allowed allowedFor={AllowedForEnum.SHOW} propertyName={"name"} >
					<Form.Group as={Col} md={width} controlId="formGroupPartnerName">
						<Form.Label>{t('partner.name')}<MergeHint name={"name"}/></Form.Label>
						<FormControl
							name={"name"}
							type={"text"}
							value={domainInstance.name || (defaultValues && defaultValues.name)}
							rules={{
								required: true
							}}
							validationMessages={{
								required: t( 'partner.name.required' )
							}}
						/>
					</Form.Group>
				</Allowed>
			)
		}

		const renderFirstName = () => {
			return (
				<Allowed allowedFor={AllowedForEnum.SHOW} propertyName={"firstName"} >
					<Form.Group as={Col} md="5" controlId="formGroupPartnerFirstName">
						<Form.Label>{t('partner.firstName')}<MergeHint name={"firstName"}/></Form.Label>
						<FormControl
							name={"firstName"}
							type={"text"}
							value={domainInstance.firstName || (defaultValues && defaultValues.firstName)}
							rules={{
								required: true
							}}
						/>
					</Form.Group>
				</Allowed>
			)
		}

		if ( partnerType === PartnerType.PERSON ) {
			return (
				<>
					{renderName(5)}
					{renderFirstName()}
				</>
			)
		}
		else {
			return (
				renderName(10)
			)
		}
	}

	const renderAdditionalInfo = () => {
		if ( partnerType === PartnerType.PERSON ) {
			return (
				<Allowed allowedFor={AllowedForEnum.SHOW} propertyName={"birthDate"} >
					<Card className={"mb-2"}>
						<Card.Header>
							{t('partner.card.additional')}
						</Card.Header>
						<Card.Body>
							<Row>
								<Form.Group as={Col} md="3" controlId="formGroupBirthDate">
									<Form.Label>{t('partner.birthDate')}<MergeHint name={"birthDate"}/></Form.Label>
									<FormControl
										name={"birthDate"}
										type={"date"}
										value= {domainInstance.birthDate || (defaultValues && defaultValues.birthDate)}
										rules={{
											required: true
										}}
									/>
								</Form.Group>
							</Row>
						</Card.Body>
					</Card>
				</Allowed>
			)
		}
	}

	const getPrintButtons = () => {
		if ( domainInstance.id > 0 ) {
			return (
				<Dropdown as={ ButtonGroup } size="sm" className={ "float-end" }>
					<Button>
						<DropdownItemWithOptions
							label={t("reports.envelopeC5.label")}
							options={[{name:'additionalText', control: 'input', placeholder: t('partner.deployInstruction.placeholder')}]}
							onClick={ (values) => {
								partnerService.printEnvelopeC5( domainInstance.id, values.additionalText ).then();
							}}
						/>
					</Button>
					<Dropdown.Toggle split variant="primary" id="dropdown-split-basic"/>

					<Dropdown.Menu>
						<Dropdown.Item onClick={ () => {
							partnerService.printVoucherC5( domainInstance.id ).then();
						}}>{t('reports.voucherC5.label')}
						</Dropdown.Item>


						<Dropdown.Item onClick={ () => {
							damageService.printDamageRendement( {partnerId: domainInstance.id, detailed: false} ).then()
							}}>{t('reports.damageRendement.title')}
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			)
		}
	}

	const pkDebCred = useMemo( () => {
		let result = ""
		if ( domainInstance.pkDeb ) {
			result += ( result ? ', ' : '' ) + `PK Deb: ${ domainInstance.pkDeb }`
		}
		if ( domainInstance.pkCred) {
			result += ( result ? ', ' : '' ) + `PK Kred: ${domainInstance.pkCred}`
		}
		return result;
	}, [domainInstance])

	const tagsColumns = [
		{id: "partnerTag", label: '' , input: {tag: "typeahead", id: "partnerTag", options: tags, required: true }, formatter: (row) => row.partnerTag && row.partnerTag.label },
	]

	return (
		<>
			<MergeHints domain1={domainInstance} domain2={mergeDomainInstance}>
			{ !isMerging && <Row className={ "mb-2" }>
				<Col>
					{ getPrintButtons() }
				</Col>
			</Row> }
			<DisplayIfAnyChildPropertyPresent>
				<Card className={ "mb-2" }>
					<Card.Header>
						<div className={ "float-start" }>{ t( 'partner.card.identification' ) }</div>
						<div className={ "float-end" }>{ pkDebCred }</div>
					</Card.Header>
					<Card.Body>
						<Allowed allowedFor={ AllowedForEnum.SHOW } propertyName={ "salutation" }>
							<Row className={ "mb-3" }>
								<Form.Group as={ Col } md="2" controlId="formGroupSalutation">
									<Form.Label>{ t( 'partner.salutation' ) }<MergeHint name={"salutation"} labelProperty={"label"}/></Form.Label>
									<RestAsyncSelectControl
										domainName={ "salutation" }
										sort={ "description_sort" }
										value={ domainInstance.salutationId }
										label={ domainInstance.salutationLabel }
										name={ 'salutation' }
										rules={ {
											required: true
										} }
									/>
								</Form.Group>

								{ renderName() }
							</Row>
						</Allowed>
						<DisplayIfAnyChildPropertyPresent>
							<Row className={ "mb-3" }>
								<Allowed allowedFor={ AllowedForEnum.SHOW } propertyName={ "nameSuffix1" }>
									<Form.Group as={ Col } md="6" controlId="formGroupNameSuffix1" >
										<Form.Label>{ t( 'partner.nameSuffix1' ) }<MergeHint name={"nameSuffix1"}/></Form.Label>
										<FormControl
											name={ "nameSuffix1" }
											type={ "text" }
											value={ domainInstance.nameSuffix1 || ( defaultValues && defaultValues.nameSuffix1 ) }
										/>
									</Form.Group>
								</Allowed>
								<Allowed allowedFor={ AllowedForEnum.SHOW } propertyName={ "nameSuffix2" }>
									<Form.Group as={ Col } md="6" controlId="formGroupNameSuffix2">
										<Form.Label>{ t( 'partner.nameSuffix2' ) }<MergeHint name={"nameSuffix2"}/></Form.Label>
										<FormControl
											name={ "nameSuffix2" }
											type={ "text" }
											value={ domainInstance.nameSuffix2 || ( defaultValues && defaultValues.nameSuffix2 ) }
										/>
									</Form.Group>
								</Allowed>
							</Row>
						</DisplayIfAnyChildPropertyPresent>
					</Card.Body>
				</Card>
			</DisplayIfAnyChildPropertyPresent>
			<DisplayIfAnyChildPropertyPresent>
				<Card className={ "mb-2" }>
					<Card.Header>{ t( 'partner.card.contact' ) }</Card.Header>
					<Card.Body>
						<Row className={ "mb-3" }>
							<Allowed allowedFor={ AllowedForEnum.SHOW } propertyName={ "streetName" }>
								<Form.Group as={ Col } md="4" controlId="formGroupStreetName">
									<Form.Label>{ t( 'partner.streetName' ) }<MergeHint name={"streetName"}/></Form.Label>
									<FormControl
										name={ "streetName" }
										type={ "text" }
										value={ domainInstance.streetName || ( defaultValues && defaultValues.streetName ) }
										onChange={ ( event ) => setStreetName( event.target.value || '' ) }
										rules={ {
											required: true
										} }
									/>
								</Form.Group>
							</Allowed>

							<Allowed allowedFor={ AllowedForEnum.SHOW } propertyName={ "streetNr" }>
								<Form.Group as={ Col } md="2" controlId="formGroupStreetNr">
									<Form.Label>{ t( 'partner.streetNr' ) }<MergeHint name={"streetNr"}/></Form.Label>
									<FormControl
										name={ "streetNr" }
										type={ "text" }
										value={ domainInstance.streetNr || ( defaultValues && defaultValues.streetNr ) }
										onChange={ ( event ) => setStreetNr( event.target.value || '' ) }
										rules={ {
											required: true
										} }
									/>
								</Form.Group>
							</Allowed>

							<Allowed allowedFor={ AllowedForEnum.SHOW } propertyName={ "zipId" }>
								<Form.Group as={ Col } md="6" controlId="formGroupZip">
									<Form.Label>{ t( 'partner.zip' ) } <GoogleMapLink
										street={ `${ streetName } ${ streetNr }` } zipLabel={ zipLabel }/> <MergeHint name={"zip"} labelProperty={"label"}/> </Form.Label>
									<RestAsyncSelectControl domainName={ "zip" }
									                        sort={ "zip" }
									                        value={ domainInstance.zipId }
									                        label={ domainInstance.zipLabel }
									                        name={ 'zip' }
									                        noSelection={ { label: 'Nothing', id: "" } }
									                        rules={ { required: true } }
									                        createable={ {
										                        formElements: <ZipFormElements/>,
										                        target: 'place'
									                        } }
									                        onChange={ ( option ) => setZipLabel( option.label || '' ) }
									/>
								</Form.Group>
							</Allowed>
						</Row>
						<Row className={ "mb-3" }>
							{ renderPhonePrivate() }
							<Allowed allowedFor={ AllowedForEnum.SHOW } propertyName={ "phoneBusiness" }>
								<Form.Group as={ Col } md="4" controlId="formGroupNamePhoneBusiness">
									<Form.Label>{ t( 'partner.phoneBusiness' ) }<MergeHint name={"phoneBusiness"}/></Form.Label>
									<FormControl
										name={ "phoneBusiness" }
										type={ "text" }
										value={ domainInstance.phoneBusiness || ( defaultValues && defaultValues.phoneBusiness ) }
										onChange={ ( event ) => {
											setPhoneBusiness( event.target.value || '' )
										} }
										rules={ {
											required: partnerType === PartnerType.COMPANY && !emailBusiness && !phoneMobile
										} }
										validationMessages={ {
											required: t( 'partner.phoneBusiness.or.emailBusiness.or.phoneMobile.required' ),
										} }
									/>
								</Form.Group>
							</Allowed>
							<Allowed allowedFor={ AllowedForEnum.SHOW } propertyName={ "phoneMobile" }>
								<Form.Group as={ Col } md="4" controlId="formGroupNamePhoneMobile">
									<Form.Label>{ t( 'partner.phoneMobile' ) }<MergeHint name={"phoneMobile"}/></Form.Label>
									<FormControl
										name={ "phoneMobile" }
										type={ "text" }
										value={ domainInstance.phoneMobile || ( defaultValues && defaultValues.phoneMobile ) }
										onChange={ ( event ) => {
											setPhoneMobile( event.target.value || '' )
										} }
										rules={ {
											required: ( partnerType === PartnerType.PERSON && !phonePrivate && !emailPrivate ) || ( partnerType === PartnerType.COMPANY && !phoneBusiness && !emailBusiness )
										} }
										validationMessages={ {
											required: partnerType === PartnerType.PERSON ? t( 'partner.phonePrivate.or.emailPrivate.or.phoneMobile.required' ) : t( 'partner.phoneBusiness.or.emailBusiness.or.phoneMobile.required' ),
										} }

									/>
								</Form.Group>
							</Allowed>
						</Row>
						<Row className={ "mb-3" }>
							{ renderEmailPrivate() }
							<Allowed allowedFor={ AllowedForEnum.SHOW } propertyName={ "emailBusiness" }>
								<Form.Group as={ Col } md="4" controlId="formGroupEmailBusiness">
									<Form.Label>{ t( 'partner.emailBusiness' ) }<MergeHint name={"emailBusiness"}/></Form.Label>
									<FormControl
										name={ "emailBusiness" }
										type={ "email" }
										value={ domainInstance.emailBusiness || ( defaultValues && defaultValues.emailBusiness ) }
										onChange={ ( event ) => {
											setEmailBusiness( event.target.value || '' )
										} }
										rules={ {
											required: partnerType === PartnerType.COMPANY && !phoneBusiness && !phoneMobile
										} }
										validationMessages={ {
											required: t( 'partner.phoneBusiness.or.emailBusiness.or.phoneMobile.required' ),
										} }
									/>
								</Form.Group>
							</Allowed>
							<Allowed allowedFor={ AllowedForEnum.SHOW } propertyName={ "homepage" }>
								<Form.Group as={ Col } md="4" controlId="formGroupHomepage">
									<Form.Label>{ t( 'partner.homepage' ) }<MergeHint name={"homepage"}/></Form.Label>
									<FormControl
										name={ "homepage" }
										type={ "text" }
										value={ domainInstance.homepage || ( defaultValues && defaultValues.homepage ) }
									/>
								</Form.Group>
							</Allowed>
						</Row>
					</Card.Body>
				</Card>
			</DisplayIfAnyChildPropertyPresent>
			{ renderAdditionalInfo() }
			<DisplayIfAnyChildPropertyPresent>
				<Card className={ "mb-2" }>
					<Card.Header>{ t( 'partner.card.bank' ) }</Card.Header>
					<Card.Body>
						{ [1, 2].map( ( nr ) => {
							return ( <Row key={ nr } className={ nr > 1 ? "mt-3" : "" }>
								<Allowed allowedFor={ AllowedForEnum.SHOW } propertyName={ `iban${ nr }name` }>
									<Form.Group as={ Col } md="6" controlId={ `formGroupIban${ nr }Name` }>
										<Form.Label>{ t( "partner.ibanName" ) } { nr }<MergeHint name={`iban${ nr }name`}/></Form.Label>
										<FormControl
											name={ `iban${ nr }name` }
											type={ "text" }
											value={ domainInstance[`iban${ nr }name`] }
											required={ false }
											validationMessages={ {
												missingName: 'Name not entered',
											} }
											rules={ {
												validate: {
													missingName: ( v ) => {
														const ibanEntered = !!getValues( `iban${ nr }` );
														return !ibanEntered || !( ibanEntered && !v );
													}
												}
											} }
										/>
									</Form.Group>
								</Allowed>

								<Allowed allowedFor={ AllowedForEnum.SHOW } propertyName={ `iban${ nr }` }>
									<Form.Group as={ Col } md="6" controlId={ `formGroupIban${ nr }` }>
										<Form.Label>{ t( "partner.iban" ) } { nr }<MergeHint name={`iban${ nr }`}/></Form.Label>
										<FormControl
											name={ `iban${ nr }` }
											type={ "text" }
											value={ domainInstance[`iban${ nr }`] }
											validationMessages={ {
												iban: 'False iban',
												missingIban: 'Iban is not entered'
											} }
											rules={ {
												validate: {
													missingIban: ( v ) => {
														const ibanNameEntered = !!getValues( `iban${ nr }name` );
														return !( ibanNameEntered && !v );
													},
													iban: ( v ) => !v || isValidIban( v )
												}
											} }
											required={ false }
										/>
									</Form.Group>
								</Allowed>
							</Row> )
						} ) }
					</Card.Body>
				</Card>
			</DisplayIfAnyChildPropertyPresent>
			<DisplayIfAnyChildPropertyPresent>
				<Card className={ "mb-2" }>
					<Card.Header>{ t( 'partner.business.relationship' ) }</Card.Header>
					<Card.Body>
						<Row className={ "mb-3" }>
							<Allowed allowedFor={ AllowedForEnum.SHOW } propertyName={ 'isInsuranceCompany' }>
								<Form.Group as={ Col } md="6" controlId="formGroupInsuranceCompany">
									{/* I am afraid, that we need Control element for InputGroupCheckbox Control: InputGroupCheckboxControl */}
									<FormLabel>{ t( 'partner.isInsuranceCompany' ) }<MergeHint name={"isInsuranceCompany"}/> <MergeHint name={"insuranceCompanyNr"}/> </FormLabel>
									<InputGroup className={ "mb-3" }>
										<InputGroupCheckbox
											name={ "isInsuranceCompany" }
											type={ "checkbox" }
											value={ domainInstance.isInsuranceCompany }
											onChange={ ( e ) => {
												setIsInsuranceCompany( e.target.checked )
											} }
										/>
										<InputGroupText>{ t( 'partner.insurance.company.nr' ) }</InputGroupText>
										<FormControl
											name={ "insuranceCompanyNr" }
											type={ "text" }
											placeholder={ t( 'partner.insurance.company.nr' ) }
											value={ domainInstance.insuranceCompanyNr }
											disabled={ !isInsuranceCompany }
										/>
									</InputGroup>
								</Form.Group>
							</Allowed>
							<Allowed allowedFor={ AllowedForEnum.SHOW } propertyName={ 'isAgent' }>
								<Form.Group as={ Col } md="6" controlId="formGroupAgent">
									<FormLabel>{ t( 'partner.isAgent' ) }<MergeHint name={"isAgent"}/> <MergeHint name={"agentNr"}/></FormLabel>
									{/* I am afraid, that we need Control element for InputGroupCheckbox Control: InputGroupCheckboxControl */}
									<InputGroup className={ "mb-3" }>
										<InputGroupCheckbox
											name={ "isAgent" }
											type={ "checkbox" }
											value={ domainInstance.isAgent }
											onChange={ ( e ) => {
												setIsAgent( e.target.checked )
											} }
										/>
										<InputGroupText>{ t( 'partner.agent.nr' ) }</InputGroupText>
										<FormControl
											name={ "agentNr" }
											type={ "text" }
											placeholder={ t( 'partner.agent.nr' ) }
											value={ domainInstance.agentNr }
											className={ "last-input" }
											disabled={ !isAgent }
										/>
									</InputGroup>
								</Form.Group>
							</Allowed>
						</Row>
						<Allowed allowedFor={ AllowedForEnum.SHOW } propertyName={ 'isBuildingManager' }>
							<Row>
								<Form.Group as={ Col } md="4" controlId="isBuildingManager">
									<FormCheckControl
										name={ "isBuildingManager" }
										type={ "checkbox" }
										value={ isBuildingManager }
										onChange={ ( e ) => {
											setIsBuildingManager( e.target.checked )
										} }
										label={ <>{t( 'partner.isBuildingManager' )} <MergeHint name={"isBuildingManager"} domain1={domainInstance} domain2={mergeDomainInstance}/></> }
									/>
								</Form.Group>
							</Row>
						</Allowed>
						<Allowed allowedFor={ AllowedForEnum.SHOW } propertyName={ 'isBroker' }>
							<Row>
								<Form.Group as={ Col } md="4" controlId="isBroker">
									<FormCheckControl
										name={ "isBroker" }
										type={ "checkbox" }
										value={ isBroker }
										onChange={ ( e ) => {
											setIsBroker( e.target.checked )
										} }
										label={ <>{t( 'partner.isBroker' )} <MergeHint name={"isBroker"} domain1={domainInstance} domain2={mergeDomainInstance}/></> }
									/>
								</Form.Group>
							</Row>
						</Allowed>
						{ renderBrokerTable() }
					</Card.Body>
				</Card>
			</DisplayIfAnyChildPropertyPresent>
			<Allowed allowedFor={ AllowedForEnum.SHOW } propertyName={ 'tags' }>
				<Card className={ "mb-2" }>
					<Card.Header>{ t( 'partner.tags' ) }</Card.Header>
					<Card.Body>
						<Row>
							<EditableTableControl
								name={ "tags" }
								columns={ tagsColumns }
								data={ domainInstance.tags }
							/>
						</Row>
					</Card.Body>
				</Card>
			</Allowed>
			{ !isMerging && domainInstance && <Allowed allowedFor={ AllowedForEnum.SHOW } propertyName={ 'attachments' }>
				<Card className={ "mb-2" }>
					<Card.Header>{ t( 'partner.attachments' ) }</Card.Header>
					<Card.Body>
						{renderAttachments()}
					</Card.Body>
				</Card>
			</Allowed> }
			{ isMerging ?
				<AclMerge domainInstance1={ domainInstance } domainInstance2={ mergeDomainInstance } editable={ isDomainEditable.ready && isDomainEditable.value }/>
				:
				<Acl domainInstance={ domainInstance } editable={ isDomainEditable.ready && isDomainEditable.value }/>
			}
		</MergeHints>
		</>
	);
}

export {FormElements};
