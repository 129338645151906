import React from 'react';
import restService from "../_services/rest.service"
import appService from "../_services";
import {dropdownService} from "../_services/dropdown.service";

function Options(params) {
	//possible solution of the limit of returned row: https://www.npmjs.com/package/react-select-async-paginate
	const [options] = params.options ? [params.options] : restService.useDomainInstancesList(params.domainName, 1, 10000, undefined, undefined, undefined, params.namedCriteria, params.templateSuffix);

	const getDefaultOption = () => {
		return <option key={params.defaultValue} value={params.defaultValue}/>
	}

	const getOptions = () => {
		if ( params.sortBy ) {
			appService.sortArrayOfObjects( options, params.sortBy )
		}
		let result = options.map((option, index) => {
			return (<option className={ dropdownService.getOptionClassName(params.optionClassName, option) } key={option[params.keyPropName]} value={option[params.keyPropName]}>{option[params.valuePropName]}</option>)
		});

		if ( params.nullable ) {
			result = [(<option key={''}/>)].concat(result);
		}

		return result;
	}

	return (
		/**
		 * TODO
		 * In order to set defaultValue in <Form.Control type="select">, you have to use defaultValue={value}.
		 * But there is a problem, when the options are loaded lazily => the defaultValue is set only during first render of
		 * the control. Since the options are rendered later, the defaultValue is never set. So we create a dummy option
		 * with the default value => defaultValue is set correctly and then the options are rendered.
		 *
		 * Anyway, if there will be more than 100 options, we will never see them all because of max=100 limit, thus
		 * we will need some lazy loading select.
		 */
		<>
			{ options.length > 0
				? getOptions()
				: getDefaultOption()
			}
		</>
	)
}

Options.defaultProps = {
	nullable: false,
	sortBy: ['label']
};

export { Options };
