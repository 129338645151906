import React, {useCallback, useContext, useState} from 'react';
import {Alert, Button, Col, Row} from "react-bootstrap";
import ShowField from "../form/ShowField";
import {useTranslation} from "react-i18next";
import Trans from "../_components/Trans";
import {invoiceService} from "../_services/invoice.service";
import restService from "../_services/rest.service";
import InvoiceAdditionalInfoEditor from "../settlement/InvoiceAdditionalInfoEditor";
import {messageBoxService} from "../_services/messageBox.service";
import {MessageBoxButtons} from "../_components/MessageBox";
import DynamicText from "../_components/DynamicText";
import {SettlementsContext} from "../settlement/SettlementsContext";

function PolicyVersionSettlementBatchFormElements( { settlementBatch } ) {
    const {t} = useTranslation();
    const [updatingAdditionalInfo, setUpdatingAdditionalInfo] = useState(false);
    const [showAdditionalInfoEditor, setShowAdditionalInfoEditor] = useState(false);
    const { settlementsState, dispatchSettlementsState } = useContext( SettlementsContext );

    const [rabatt, setRabatt] = useState(undefined);
    const [rabattText1, setRabattText1] = useState(undefined);
    const [rabattText2, setRabattText2] = useState(undefined);
    const [additionalInfo, setAdditionalInfo] = useState(undefined);

    const onAdditionalInfoChanged = useCallback(() => {
        setUpdatingAdditionalInfo(true)
        invoiceService.updateAdditionalInfo({className: settlementBatch.className, id: settlementBatch.id, version: settlementBatch.version, rabatt: rabatt, rabattText1: rabattText1, rabattText2: rabattText2, additionalInfo: additionalInfo, selection: settlementsState.selection, allSelected: settlementsState.allSelected})
            .then((response) => {
                let content = Object.keys( response ).map( key => {
                    return <p key={key}>{t(`policyVersionSettlementBatch.rabatt.success.${key}`, {count: response[key]})}</p>
                })
                messageBoxService.display( content, t('policyVersionSettlementBatch.rabatt.success.title'), [MessageBoxButtons.OK])
                setUpdatingAdditionalInfo(false)
            })
            .catch( (error, signal) => {
                restService.handleServerErrorsAxios(error, signal)
                setUpdatingAdditionalInfo(false)
            });
    }, [rabatt, additionalInfo, settlementBatch.className, settlementBatch.id, settlementBatch.version, settlementsState.selection, settlementsState.allSelected, rabattText1, rabattText2, t]);

    const handleShowAdditionalInfoEditor = () => {
        if ( !settlementsState.allSelected && Object.keys(settlementsState.selection).length === 0 ) {
            messageBoxService.display( t('policyVersionSettlementBatch.rabatt.noSelection.content'), t('policyVersionSettlementBatch.rabatt.noSelection.title'), [MessageBoxButtons.OK])
            return
        }

        invoiceService.checkPolicyVersionSettlementBatchRabattSelection({settlementBatchId: settlementBatch.id, selection: settlementsState.selection, allSelected: settlementsState.allSelected})
            .then((invoicesFromYoungPolicies) => {
                if ( invoicesFromYoungPolicies.length > 0 ) {
                    messageBoxService.display(
                        <DynamicText htmlContent={t('policyVersionSettlementBatch.rabatt.content', {
                            count: invoicesFromYoungPolicies.length,
                            list: invoicesFromYoungPolicies.map( item => item.policyVersionNumber ).join(', ')
                        })}/>,
                        t('policyVersionSettlementBatch.rabatt.title'),
                        [MessageBoxButtons.YES, MessageBoxButtons.NO])
                        .then( (button) => {
                            if ( button === MessageBoxButtons.YES ) {
                                const newSelection = {...settlementsState.selection}
                                invoicesFromYoungPolicies.forEach( item => {
                                    newSelection[item.id] = false
                                })
                                dispatchSettlementsState( { type: 'selection', value: newSelection } )
                            }

                            setShowAdditionalInfoEditor( true )
                        })
                }
                else {
                    setShowAdditionalInfoEditor( true )
                }
            })
            .catch( (error) => {
                restService.handleServerErrorsAxios(error)
            })
    }

    return (
        <>
            <InvoiceAdditionalInfoEditor
                show={showAdditionalInfoEditor}
                setShow={setShowAdditionalInfoEditor}
                rabatt={rabatt}
                setRabatt={setRabatt}
                rabattText1={rabattText1}
                setRabattText1={setRabattText1}
                rabattText2={rabattText2}
                setRabattText2={setRabattText2}
                additionalInfo={additionalInfo}
                setAdditionalInfo={setAdditionalInfo}
                onSubmit={onAdditionalInfoChanged}
            />
            <Row>
                <ShowField label={'settlementBatch.dateFrom.label'} object={settlementBatch} property={'dateFrom'} type={'date'} size={4}/>
                <ShowField label={'settlementBatch.dateTo.label'} object={settlementBatch} property={'dateTo'} type={'date'} size={4}/>
            </Row>

            { settlementBatch.printWithSettlementBatchInvoicesCount > 0 && <Row className={"mt-3"}>
                <Col>
                    <Alert variant={"info"}>
                        <Trans
                            i18nKey={'policyVersionSettlementBatch.printWithSettlementBatch.tooltip'}
                            values={{count: settlementBatch.printWithSettlementBatchInvoicesCount}}
                        />
                    </Alert>
                </Col>
            </Row> }

            {(settlementBatch && !settlementBatch.booked) &&
                <Button
                    variant={'primary'}
                    disabled={updatingAdditionalInfo}
                    onClick={handleShowAdditionalInfoEditor}
                >
                    {t( 'policyVersionSettlementBatch.rabatt.label' )}
                </Button>
            }
        </>
    )
}

export default PolicyVersionSettlementBatchFormElements