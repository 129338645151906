import React, {useEffect, useMemo, useState} from 'react';
import {PolicyTargetConditionOperator} from "../_enum/enum";
import {Button, ButtonGroup, Card, Col, Dropdown, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import SwitchButtonControl from "../_components/SwitchButtonControl";
import FormControl from "../_components/FormControl";
import {useFormContext, useWatch} from "react-hook-form";
import {v4} from "uuid";
import {faPlus, faMinus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PolicyTargetConditionConfiguratorTarget from "./PolicyTargetConditionConfiguratorTarget";

function PolicyTargetCondition({policyTargetCondition, namePrefix = "", removeMe}) {
	const { t } = useTranslation();
	const { setValue } = useFormContext();
	useWatch({ name: `${namePrefix}operator` })
	const [operator, setOperator] = useState( policyTargetCondition.operator)

	const [policyTargetConditions, setPolicyTargetConditions] = useState( policyTargetCondition.policyTargetConditions, [] )
	const [policyTargetConditionConfiguratorTargets, setPolicyTargetConditionConfiguratorTargets] = useState( policyTargetCondition.policyTargetConditionConfiguratorTargets, [] )

	const getKey = ( objectWithIdOrKey ) => objectWithIdOrKey.id || objectWithIdOrKey.key

	useEffect( () => {
		setValue( `${ namePrefix }operator`, operator )
	}, [operator, namePrefix, setValue] );

	const policyTargetConditionsElement = useMemo( () => {
		//return map of PolicyTargetCondition collected from policyTargetConditions
		return policyTargetConditions.map( ( policyTargetCondition, index ) => {
			const childPrefix = namePrefix + `policyTargetConditions.${index}.`
			const key = getKey(policyTargetCondition)
			return <PolicyTargetCondition key={key}
			                              policyTargetCondition={ policyTargetCondition }
			                              namePrefix={ childPrefix }
			                              deleted={ policyTargetCondition.deleted }
			                              removeMe={ () => {
				                              const ptcToBeRemoved = policyTargetConditions.find( ptc => getKey(ptc) === key )
				                              ptcToBeRemoved.deleted = true
				                              setPolicyTargetConditions( [...policyTargetConditions] ) //ensures rerender
			                              } }
			/>
		} )
	}, [namePrefix, policyTargetConditions] )

	const policyTargetConditionConfiguratorTargetsElement = useMemo( () => {
		//return map of PolicyTargetConfiguratorTarget collected from configuratorTargets
		return policyTargetConditionConfiguratorTargets.map( ( policyTargetConditionConfiguratorTarget, index ) => {
			const childPrefix = namePrefix + `policyTargetConditionConfiguratorTargets.${index}.`
			const key = getKey(policyTargetConditionConfiguratorTarget)
			return <PolicyTargetConditionConfiguratorTarget key={ key }
			                                                policyTargetConditionConfiguratorTarget={ policyTargetConditionConfiguratorTarget }
			                                                namePrefix={childPrefix}
			                                                operator={operator}
			                                                removeMe={ () => {
				                                                const ptcctToBeRemoved = policyTargetConditionConfiguratorTargets.find( ptcct => getKey(ptcct) === key )
				                                                ptcctToBeRemoved.deleted = true
				                                                setPolicyTargetConditionConfiguratorTargets( [...policyTargetConditionConfiguratorTargets] ) //ensures rerender
			                                                } }
			/>

		} )
	}, [policyTargetConditionConfiguratorTargets, operator, namePrefix] )

	const handleAddPolicyTargetCondition = () => {
		setPolicyTargetConditions( [...policyTargetConditions, { id: null, key: v4(), name: "", operator: PolicyTargetConditionOperator.AND, policyTargetConditions: [], policyTargetConditionConfiguratorTargets: [] } ] )
	}

	const handleAddPolicyTargetConditionConfiguratorTargets = () => {
		setPolicyTargetConditionConfiguratorTargets( [...policyTargetConditionConfiguratorTargets, {id: null, key: v4(), configuratorTarget: null }] )
	}

	return (
		<>
			<FormControl type={ "text" } name={ `${ namePrefix }id` } hidden={ true }
			             value={ policyTargetCondition.id }/>
			<FormControl type={ "text" } name={ `${ namePrefix }operator` } hidden={ true }
			             value={ policyTargetCondition.operator }/>
			<FormControl type={ "text" } name={ `${ namePrefix }version` } hidden={ true }
			             value={ policyTargetCondition.version }/>
			<FormControl type={ "text" } name={ `${ namePrefix }deleted` } hidden={ true }
			             value={ policyTargetCondition.deleted }/>
			{ policyTargetCondition && !policyTargetCondition.deleted && <Card
				style={ { backgroundColor: 'hsla(0, 0%, 0%, 0.3)', borderWidth: '3px', borderStyle: operator === PolicyTargetConditionOperator.AND ? 'solid' : 'dashed', borderColor: operator === PolicyTargetConditionOperator.AND ? '' : 'black' } }
				className={ "m-3" }
				key={ policyTargetCondition.id }
			>
				<Card.Header>
					<Row>
						<Col md={ 12 }>
							<div style={ { display: "table", width: "100%" } }>
								<div style={ { display: "table-cell" } }>
									<FormControl
										placeholder={ t( "default.enterNameHere.placeholder" ) }
										className={ 'policy-target-condition-name w-100 mb-1' }
										name={ `${ namePrefix }name` }
										type={ "text" }
										value={ policyTargetCondition.name }
										rules={ {
											required: true
										} }
									/>
								</div>
								<div style={ {
									display: "table-cell",
									textAlign: "right",
									width: "50px",
									verticalAlign: "bottom",
									paddingBottom: "5px"
								} }>
									{ removeMe && <Button size={ "xs" } variant={ "danger" } className={ 'float-end' }
									                      onClick={ removeMe }><FontAwesomeIcon
										icon={ faMinus }/></Button> }
								</div>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<SwitchButtonControl
								value={ operator === PolicyTargetConditionOperator.AND }
								onlabel={ t( "policyTargetCondition.operator.AND.tooltip" ) }
								offlabel={ t( "policyTargetCondition.operator.OR.tooltip" ) }
								onstyle={ 'light' }
								offstyle={ 'light' }
								name={ `${ namePrefix }operator_switchbutton` }
								onChange={ ( value ) => {
									const op = ( value ? PolicyTargetConditionOperator.AND : PolicyTargetConditionOperator.OR )
									setOperator( op )
								} }
								size={ "xs" }
								className={ 'mb-2' }
							/>
						</Col>
					</Row>
				</Card.Header>
				<Card.Body>
					{ policyTargetConditionsElement }
					{ policyTargetConditionConfiguratorTargetsElement }
					<Dropdown as={ ButtonGroup } size="sm" className={ "float-end" }>
						<Button size={ "xs" } variant={ "primary" }
						        onClick={ handleAddPolicyTargetConditionConfiguratorTargets }><FontAwesomeIcon
							icon={ faPlus }/></Button>

						{ policyTargetConditionConfiguratorTargets.filter( ( ptcct ) => !ptcct.deleted ).length === 0 &&
							<>
								<Dropdown.Toggle split variant="primary" id="dropdown-split-basic"/>

								<Dropdown.Menu>
									<Dropdown.Item onClick = { handleAddPolicyTargetCondition }>add condition</Dropdown.Item>
								</Dropdown.Menu>
							</> }
					</Dropdown>
				</Card.Body>
			</Card>
			}
		</>
	)
}

export default PolicyTargetCondition