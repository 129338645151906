import React, {useMemo, useCallback, useState, useRef, useContext} from 'react';
import PropTypes from "prop-types";
import DomainTable from "../_components/DomainTable";
import bootstrapTableService from "../_components/bootstrapTable.service";
import {Button, ButtonGroup, Form, Dropdown as ReactDropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf, faEye, faFileExcel} from "@fortawesome/free-solid-svg-icons";
import {policyService} from "../_services/policy.service";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {SettlementCancelReason} from "../_enum/enum";
import restService from "../_services/rest.service";
import {invoiceService} from "../_services/invoice.service";
import SettlementPrintedMessageListener from "./SettlementPrintedMessageListener";
import {SettlementsContext} from "./SettlementsContext";

function Settlements( {namedCriteria, searchOptions, getData, hiddenFields, domainName = 'settlement', showSelection = false, cellClasses = undefined} ) {
	const {t} = useTranslation();
	const domainTableRef = useRef()
	// const [showCancelQuestion, setShowCancelQuestion] = useState(false);
	// const [settlementCancelReason, setSettlementCancelReason] = useState(undefined);
	// const [settlementCancelNote, setSettlementCancelNote] = useState(undefined);
	// const [settlementCancelRow, setSettlementCancelRow] = useState(undefined);
	const [searchText, setSearchText] = useState(''); // eslint-disable-line no-unused-vars
	const settlementsContext = useContext( SettlementsContext );
	const settlementsState = useMemo( () => settlementsContext && settlementsContext.settlementsState, [settlementsContext] )
	const dispatchSettlementsState = useMemo( () => settlementsContext && settlementsContext.dispatchSettlementsState, [settlementsContext] )

	const handlePrintSettlement = useCallback( (row, withLogo = true) => {
		policyService.printSettlement( row.id, withLogo )
			.then( () => { /*invoicePolicyVersion printed*/} )
	}, []);

	const selectSettlement = ( id, selected, settlementsState, dispatchSettlementsState ) => {
		const newSelection = { ...settlementsState.selection, [id]: selected }
		dispatchSettlementsState( { type: 'selection', value: newSelection } )
	}

	const selectAllSettlements = useCallback( ( selected ) => {
		dispatchSettlementsState( { type: 'selection', value: { } } )
		dispatchSettlementsState( { type: 'allSelected', value: selected } )
	}, [dispatchSettlementsState])

	const isSettlementSelected = useCallback( ( id, settlementState ) => {
		let selectionValue = settlementState.selection[id]
		if ( selectionValue === undefined ) {
			selectionValue = settlementState.allSelected
		}
		return selectionValue
	}, [])

	// const handleCancelSettlementQuestion = useCallback( (row) => {
	// 	setSettlementCancelRow(row)
	// 	setShowCancelQuestion(true);
	// }, [setSettlementCancelRow, setShowCancelQuestion])

	// const handleSubmitCancelSettlement = useCallback( (event) => {
	// 	event.preventDefault();
	// 	event.stopPropagation();
	// 	setShowCancelQuestion(false)
	// 	policyService.cancelSettlement( settlementCancelRow.id, settlementCancelReason, settlementCancelNote )
	// 		.then( (settlement) => {
	// 			domainTableRef.current.onRowChanged( settlement );
	// 		})
	// }, [domainTableRef, settlementCancelRow, settlementCancelReason, settlementCancelNote])

	const _cellClasses = useCallback( ( cell, row, rowIndex, colIndex) => {
		if ( undefined === cellClasses  ) {
			return ''
		}
		else if ( 'function' === typeof cellClasses ) {
			return cellClasses( cell, row, rowIndex, colIndex )
		}
		else if ( 'string' === typeof cellClasses ) {
			return cellClasses
		}
	}, [cellClasses])

	const columns = useMemo(() => {
		const _columns = [{
			dataField: 'id',
			text: t('default.id'),
			hidden: true,
		},
		{
			hidden: !showSelection,
			dataField: 'selected',
			text: t('default.selection'),
			sort: false,
			headerFormatter: () => {
				return <Button size={ 'sm' } onClick={ () => selectAllSettlements( settlementsState && !settlementsState.allSelected ) }>{ t( settlementsState && settlementsState.allSelected ? 'default.none' : 'default.all' ) }</Button>
			},
			headerStyle: (column, colIndex) => {
				return { width: '6%', textAlign: 'center' };
			},

			formatExtraData: {settlementsState: settlementsState, dispatchSettlementsState: dispatchSettlementsState},
			formatter: (cellContent, row, rowIndex, formatExtraData) => {
				return<>
					<Form.Check // prettier-ignore
						type="switch"
						id={`custom-switch-row-${row.id}`}
						checked={isSettlementSelected(row.id, formatExtraData.settlementsState)}
						onChange={(e) => selectSettlement( row.id, e.target.checked, formatExtraData.settlementsState, formatExtraData.dispatchSettlementsState )}
					/>
				</>
			},
			classes: (cell, row, rowIndex, colIndex) => `text-center ${_cellClasses(cell, row, rowIndex, colIndex)}`,
		},
		{
			dataField: 'number',
			text: t('settlement.number'),
			sort: true,
			headerStyle: (column, colIndex) => {
				return { width: '6%', textAlign: 'right' };
			},
			classes: (cell, row, rowIndex, colIndex) => `text-end ${_cellClasses(cell, row, rowIndex, colIndex)}`,
		},
		{
			dataField: 'createdOn',
			text: t('default.createdOn'),
			headerStyle: (column, colIndex) => {
				return { width: '6%' };
			},
			formatter: (cellContent, row) => {
				if ( cellContent ) return  bootstrapTableService.formatterDate(cellContent)
				else {
					return ''
				}
			},
			classes: (cell, row, rowIndex, colIndex) => _cellClasses(cell, row, rowIndex, colIndex),
			sort: true,
		},
		{
			dataField: 'detail',
			text: t('settlement.detail'),
			sort: false,
			headerStyle: (column, colIndex) => {
				return { width: '20%' };
			},
			formatter: (cellContent, row) => {
				return invoiceService.getDetailAsText(row.detail);
			},
			classes: (cell, row, rowIndex, colIndex) => _cellClasses(cell, row, rowIndex, colIndex),
		},
		{
			dataField: 'partner',
			text: t('settlement.partner'),
			sortField: 'partner.label_sort',
			formatter: (cellContent, row) => {
				if ( cellContent ) {
					return <Link to={ restService.getTo( cellContent, 'show' ) } target = "_blank" rel = "noopener noreferrer" >
						{ cellContent.label }
					</Link>
				}
				else {
					return ('')
				}
			},
			sort: true,
			classes: (cell, row, rowIndex, colIndex) => _cellClasses(cell, row, rowIndex, colIndex),
		},
		{
			dataField: 'type',
			text: t('settlement.type'),
			sortField: 'type_sort',
			sort: true,
			headerStyle: (column, colIndex) => {
				return { width: '15%' };
			},
			classes: (cell, row, rowIndex, colIndex) => _cellClasses(cell, row, rowIndex, colIndex),
		},
		// {
		// 	dataField: 'paymentState',
		// 	text: t('invoice.paymentState'),
		// 	// sort: true,
		// 	formatter: (cellContent, row) => {
		// 		return <PaymentStateBadge paymentState={cellContent}/>
		// 	},
		// },
		{
			dataField: 'total',
			text: t('settlement.total'),
			sort: true,
			formatter: (cellContent, row) => bootstrapTableService.formatterAmount(cellContent),
			headerStyle: (column, colIndex) => {
				return { width: '12%', textAlign: 'right' };
			},
			classes: (cell, row, rowIndex, colIndex) => `text-end ${_cellClasses(cell, row, rowIndex, colIndex)}`
		}, {
			dataField: '',
			text: '',
			sort: false,
			classes: (cell, row, rowIndex, colIndex) => `text-end ${_cellClasses(cell, row, rowIndex, colIndex)}`,
			formatter: (cellContent, row) => {
				return (
					<>
						<SettlementPrintedMessageListener settlementId={row.id}/>
						<ReactDropdown as={ButtonGroup} size="sm">
							<Button variant={"primary"} onClick={ () => handlePrintSettlement(row) }>
								<FontAwesomeIcon icon={faFilePdf}/>
							</Button>
							<ReactDropdown.Toggle split variant={"primary"}/>
							<ReactDropdown.Menu>
								<ReactDropdown.Item onClick={ ( event ) => {
									handlePrintSettlement(row, false);
								}}>{t('default.withoutLogo.asPdf.label')}</ReactDropdown.Item>
							</ReactDropdown.Menu>
						</ReactDropdown>

						{row.className === 'appvers.CreditNoteCommission' &&
							<Button
								className={"ms-1"}
								variant={ 'primary' }
								size={ 'sm' }
								onClick={ ( event ) => {
									event.preventDefault();
									event.stopPropagation();
									invoiceService.commissionCreditNoteToExcel(row.id);
								} }>
								<FontAwesomeIcon icon={ faFileExcel }/>
							</Button>
						}

						<Link className={`btn btn-sm btn-primary ms-1`} to={`/settlement/show/${row.id}`} target={"_blank"} rel={"noopener noreferrer"}>
							<FontAwesomeIcon icon={faEye}/>
						</Link>
						{/*{ row.cancelledWhy === SettlementCancelReason.NOT_CANCELLED &&*/}
						{/*	<Button className={ "ms-1" } variant={ 'danger' } size={ 'sm' }*/}
						{/*	        onClick={ () => handleCancelSettlementQuestion( row ) }>*/}
						{/*		<FontAwesomeIcon icon={ faFileCircleXmark }/>*/}
						{/*	</Button>*/}
						{/*}*/}
					</>
				)
			},
			headerStyle: (column, colIndex) => {
				return { width: '10%' };
			},
		}
		];

		return _columns.filter( (column) => !hiddenFields.includes( column.dataField ) );
	}, [t, showSelection, isSettlementSelected, handlePrintSettlement, hiddenFields, _cellClasses, selectAllSettlements, settlementsState, dispatchSettlementsState])

	const rowClasses = (row, rowIndex) => {
		if ( row.cancelledWhy === SettlementCancelReason.NOT_CANCELLED ) {
			return ''
		}
		else {
			return 'line-through'
		}
	}

	const handleTableStateChanged = ( state ) => {
		setSearchText( state.searchText )
	}

	return (
		<>
			<DomainTable
				ref={ domainTableRef }
				domainName={ domainName }
				columns={ columns }
				searchOptions={ searchOptions }
				namedCriteria={ namedCriteria }
				rowClasses={ rowClasses }
				sortField={ 'createdOn' }
				sortOrder={ 'desc' }
				templateSuffix={ 'brief' }
				getData={ getData }
				onTableStateChanged={ handleTableStateChanged }
			/>
			{/*<Modal*/}
			{/*	show={ showCancelQuestion }*/}
			{/*	onHide={ () => setShowCancelQuestion( false ) }*/}
			{/*>*/}
			{/*	<Modal.Header>*/}
			{/*		{ settlementCancelRow &&*/}
			{/*			<h4>{ t( `settlements.cancelQuestion.cancel`, { what: t( `${ settlementCancelRow.className }.label` ) } ) }</h4>*/}
			{/*		}*/}
			{/*	</Modal.Header>*/}
			{/*	<Modal.Body>*/}
			{/*		{ settlementCancelRow &&*/}
			{/*			<p>{ t( 'settlements.cancelQuestion.message', {*/}
			{/*				what: t( `${ settlementCancelRow.className }.label` ),*/}
			{/*				number: settlementCancelRow.number,*/}
			{/*				partner: settlementCancelRow.partner && settlementCancelRow.partner.label*/}
			{/*			} ) }</p>*/}
			{/*		}*/}
			{/*		<Form onSubmit={ handleSubmitCancelSettlement }>*/}
			{/*			<Row>*/}
			{/*				<Col>*/}
			{/*					<Form.Group as={ Row } className="mt-3" controlId="cancelledWhy">*/}
			{/*						<Form.Label column*/}
			{/*						            sm={ 4 }>{ t( 'settlements.cancelQuestion.cancelledWhy' ) }</Form.Label>*/}
			{/*						<Col sm={ 8 }>*/}
			{/*							<Dropdown*/}
			{/*								nullable={ true }*/}
			{/*								required={ true }*/}
			{/*								keyPropName={ 'key' }*/}
			{/*								valuePropName={ 'value' }*/}
			{/*								options={ Object.values( SettlementCancelReason ).filter( ( scr ) => scr !== SettlementCancelReason.NOT_CANCELLED ).map( ( scr ) => {*/}
			{/*									return {*/}
			{/*										key: scr,*/}
			{/*										value: t( 'appvers.enums.SettlementCancelReason.' + scr )*/}
			{/*									}*/}
			{/*								} ) }*/}
			{/*								onChange={ ( e ) => {*/}
			{/*									setSettlementCancelReason( e.target.value )*/}
			{/*								} }*/}
			{/*							/>*/}
			{/*						</Col>*/}
			{/*					</Form.Group>*/}
			{/*					<Form.Group as={ Row } className="mt-3" controlId="cancelledNote">*/}
			{/*						<Form.Label column*/}
			{/*						            sm={ 4 }>{ t( 'settlements.cancelQuestion.cancelledNote' ) }</Form.Label>*/}
			{/*						<Col sm={ 8 }>*/}
			{/*							<Form.Control*/}
			{/*								name="cancelledNote"*/}
			{/*								as="textarea"*/}
			{/*								onChange={ ( e ) => {*/}
			{/*									setSettlementCancelNote( e.target.value )*/}
			{/*								} }*/}
			{/*							/>*/}
			{/*						</Col>*/}
			{/*					</Form.Group>*/}
			{/*				</Col>*/}
			{/*			</Row>*/}
			{/*			<Row className={ "mt-3" }>*/}
			{/*				<Col className={ "text-end" }>*/}
			{/*					<Button className={ "me-2" } type={ 'submit' } variant="primary">*/}
			{/*						{ settlementCancelRow &&*/}
			{/*							t( `settlements.cancelQuestion.cancel`, { what: t( `${ settlementCancelRow.className }.label` ) } )*/}
			{/*						}*/}
			{/*					</Button>*/}
			{/*					<Button variant="secondary" onClick={ () => setShowCancelQuestion( false ) }>*/}
			{/*						{ t( 'default.cancel' ) }*/}
			{/*					</Button>*/}
			{/*				</Col>*/}
			{/*			</Row>*/}
			{/*		</Form>*/}
			{/*	</Modal.Body>*/}
			{/*</Modal>*/}
		</>
	)
}


Settlements.defaultProps = {
	hiddenFields: []
};

Settlements.propTypes = {
	namedCriteria: PropTypes.object,
	searchOptions: PropTypes.object,
	getData: PropTypes.func,
	hiddenFields: PropTypes.array
};

export { Settlements };
