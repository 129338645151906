import React from "react";
import useAuth from "./useAuth";

const AuthContext = React.createContext()

export const AuthProvider = ({children}) => {
    const authValues = useAuth()

    return (
        <AuthContext.Provider value={authValues}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuthContext = () => React.useContext(AuthContext)
