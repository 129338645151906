import React, {useReducer} from 'react';
import {SettlementsContext} from "./SettlementsContext";
import {settlementsReducer} from "./settlementsReducer";

function SettlementsContextProvider({ children }) {
    const [settlementsState, dispatch] = useReducer(
        settlementsReducer,
	    { selection: {}, allSelected: false }
    );

	return (
        <SettlementsContext.Provider value={{settlementsState: settlementsState, dispatchSettlementsState: dispatch}}>
            {children}
        </SettlementsContext.Provider>
	)
}

export default SettlementsContextProvider