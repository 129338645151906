import React, {useEffect, useState} from 'react';
import restService from "../_services/rest.service";
import PropTypes from "prop-types";
import AcesTable from "./AcesTable";
import {apiClientPrivate} from "../api/apiClient";

function Acl( {domainInstance, editable}) {
    const [ aclInfo, setAclInfo ] = useState( );

    useEffect(() => {
        if ( domainInstance === undefined || !domainInstance.id ) return;
        const controller = new AbortController()
        const data = {
            id: domainInstance.id,
            className: domainInstance.classSimpleName || domainInstance.className
        }
        apiClientPrivate.post( `/api/security/aclInfo`, data, {
            signal: controller.signal,
        } )
            .then((response) => restService.handleServerResponseAxios(response))
            .then( data => {
                setAclInfo( data );
            } )
            .catch( error =>  restService.handleServerErrorsAxios( error, controller.signal ) );
        return function cleanup() {
            controller.abort();
        }
    }, [domainInstance, setAclInfo]);

    return (
        <AcesTable aclInfo={aclInfo} editable={editable} />
    )
}

export default Acl

Acl.propTypes = {
    domainInstance: PropTypes.object,
    editable: PropTypes.bool,
}

Acl.defaultProps = {
    editable: false,
}
