import React from 'react';
import PreviewSettlementBatch from "../_components/_settlementBatch/PreviewSettlementBatch";
import PolicyVersionSettlementBatchFormElements from "./PolicyVersionSettlementBatchFormElements";
import PolicyVersionSettlementBatchAdditionalPrintDropdowns from "./PolicyVersionSettlementBatchAdditionalPrintDropdowns";
import SettlementsContextProvider from "../settlement/SettlementsContextProvider";

function PreviewPolicyVersionSettlementBatch( { id } ) {
	return (
		<SettlementsContextProvider>
			<PreviewSettlementBatch
				className={'policyVersionSettlementBatch'}
				id={ parseInt(id) }
				settlementBatchFormElements={<PolicyVersionSettlementBatchFormElements/>}
				additionalPrintDropdowns={<PolicyVersionSettlementBatchAdditionalPrintDropdowns />}
				cellClasses={ (cell, row, rowIndex, colIndex) => {
					return row.printWithSettlementBatch ? 'cell-print-with-settlement-batch' : ''
				}}
				showSelection={true}
			/>
		</SettlementsContextProvider>
	);
}

export default PreviewPolicyVersionSettlementBatch
