import React, {useEffect, useState} from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import FormControl from "../_components/FormControl";
import {RestAsyncSelectControl} from "../_components/RestAsyncSelectControl";
import {FormElements as PartnerFormElements} from "../partner/FormElements";
import {PartnerType} from "../_enum/enum";
import CreatePersonQuestionModal from "./CreatePersonQuestionModal";
import ChangeBankAccount from "../_components/ChangeBankAccount";
import {useFormContext} from "react-hook-form";
import restService from "../_services/rest.service";

function FormElements( {domainInstance, isAddMode, defaultValues} ) {
	const { t } = useTranslation();
	const [showCreatePersonModal, setShowCreatePersonModal] = useState(false);
	const [handleOnClose, setHandleOnClose] = useState(null);
	const { setValue } = useFormContext();
	const [partnerId, setPartnerId] = useState(null);
	const [partner] = restService.useDomainInstance('partner', partnerId)
	const [bankAccountNr, setBankAccountNr] = useState( domainInstance.bankAccountNr )

	const handleOnBankAccountChanged = (newBankAccount) => {
		setBankAccountNr( newBankAccount )
		setValue('bankAccountNr', newBankAccount)
	}

	const handleOnPartnerChange = (partner) => {
		setPartnerId(partner.id);
	}

	useEffect(() => {
		if ( partner.iban1 !== bankAccountNr && partner.iban2 !== bankAccountNr ) {
			if (partner.iban1) {
				setBankAccountNr(partner.iban1)
			} else if (partner.iban2) {
				setBankAccountNr(partner.iban2)
			}
		}
	}, [partner, bankAccountNr]);

	const _bankAccountNr = domainInstance && domainInstance.bankAccountNr
	useEffect(() => {
		setBankAccountNr(_bankAccountNr)
	}, [_bankAccountNr]);

	const _partnerId = domainInstance && domainInstance.partner  && domainInstance.partner.id
	useEffect(() => {
		if ( _partnerId ) {
			setPartnerId(_partnerId)
		}
	}, [_partnerId]);

	const beforeCreatePartner = (defaultValuesPartner) => {
		return new Promise( (resolve, reject) => {
			setShowCreatePersonModal(true);
			const onClose = (partnerType) => {
				if ( partnerType ) {
					defaultValuesPartner.partnerType = partnerType;
					if ( partnerType === PartnerType.PERSON ) {
						const names = defaultValuesPartner.name.split(' ');
						if ( names.length >= 2 ) {
							defaultValuesPartner.name = names[0]
							defaultValuesPartner.firstName = names.slice(1).join(' ')
						}
					}
					resolve( {defaultValues: defaultValuesPartner, domainName: partnerType === PartnerType.PERSON ? 'person' : 'company' } );
				} else {
					reject();
				}
				setShowCreatePersonModal(false);
			}

			setHandleOnClose( () => onClose );
		})
	}

	return (
		<>
			<CreatePersonQuestionModal show={showCreatePersonModal} onClose={handleOnClose}/>
			<Card className={"mb-2"}>
				<Card.Header>{t('damagePaymentInfo.card.info')}</Card.Header>
				<Card.Body>
					<Row className={"mb-3"}>
						<Form.Group as={Col} controlId="formGroupPartner">
							<Form.Label>{t('damagePaymentInfo.partner')}</Form.Label>
							<RestAsyncSelectControl
								domainName={"partner"}
								sort={"fullName_sort"}
								value={ domainInstance && domainInstance.partner && domainInstance.partner.id }
								label={ domainInstance && domainInstance.partner && domainInstance.partner.label }
								name={'partner'}
								rules={{
									required: true
								}}
								createable={{formElements: <PartnerFormElements/>, target:'name', beforeCreate: beforeCreatePartner}}
								onChange={ (partner) => handleOnPartnerChange(partner) }
							/>
						</Form.Group>
					</Row>
					<Row className={"mb-3"}>
						<Form.Group as={Col} controlId="formGroupDescription">
							<Form.Label>{t('damagePaymentInfo.description')}</Form.Label>
							<FormControl
								name={"description"}
								type={"text"}
								value={domainInstance.description || (defaultValues && defaultValues.name)}
								rules={{
									required: true
								}}
							/>
						</Form.Group>
					</Row>
					<Row className={"mb-3"}>
						<Form.Group as={Col} md="4" controlId="formGroupPaymentInfoDate">
							<Form.Label>{t('damagePaymentInfo.paymentInfoDate')}</Form.Label>
							<FormControl
								name={"paymentInfoDate"}
								type={"date"}
								value={domainInstance.paymentInfoDate}
								rules={{
									required: true
								}}
							/>
						</Form.Group>
					</Row>
                    <Row className={"mb-3"}>
						<Form.Label>{t('damagePaymentInfo.bankAccountNr')}</Form.Label>
                        <Col lg={ 4 }>{bankAccountNr}</Col>
                        <ChangeBankAccount onBankAccountChanged={handleOnBankAccountChanged} defaultBankAccountNr={domainInstance.bankAccountNr} partner={partner}/>
                    </Row>
				</Card.Body>
			</Card>
		</>
	);
}

export { FormElements };
