import React from 'react';
import {useTranslation} from "react-i18next";
import Index from "../form/Index";
import {FormElements} from "./FormElements";

function RoleGroup({ match }) {
	const { t } = useTranslation();

	const columns = [{
		dataField: 'id',
		text: t('roleGroup.id.label'),
		hidden: true
	}, {
		dataField: 'name',
		text: t('roleGroup.name'),
		sort: false
	}, {
		dataField: 'description',
		text: t('roleGroup.description'),
		sort: false,
	}];

	return (
		<Index
			path={match.path}
			domainName={'roleGroup'}
			columns={columns}
			toString={ (object) => ( object.username ) }
			formElements={<FormElements/>}
			sortField={'name'}
			allowEdits={true}
			allowDeletions={true}
		/>
	);
}

export { RoleGroup };
