import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Badge} from "react-bootstrap";

/**
 * FontAwesomeIcon with a small number in the bottom right corner. Used for example as a counter for unread messages.
 * @returns {JSX.Element}
 * @constructor
 */
function FontAwesomeIconWithNumber({ icon, number, bg }) {
    return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <FontAwesomeIcon icon={icon} />
            {number > 0 && (
                <Badge
                    pill
                    bg={bg || 'primary'}
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        transform: 'translate(50%, 50%)',
                        fontSize: '0.6rem',
                    }}
                >
                    {number}
                </Badge>
            )}
        </div>
    );
}

export default FontAwesomeIconWithNumber