import React, {useEffect, useMemo, useState} from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import FormControl from "../_components/FormControl";
import EditableTableControl from "../form/EditableTableControl";
import SwitchButtonControl from "../_components/SwitchButtonControl";
import textService from "../_services/text.service";
import moment from "moment";

function FormElements({domainInstance}) {
	const { t } = useTranslation();
	const [indexable, setIndexable] = useState(domainInstance.indexable)

	useEffect( () => {
		setIndexable( domainInstance.indexable );
	}, [domainInstance])

	const columnsEsAnsatzs = useMemo( () => [
		{
			id: "validFrom",
			label: t("esAnsatz.validFrom.label"),
			input: {tag: "input", type: "date"},
			validator: (value,data,rowIndex) => { return (value > data[rowIndex].validTo)?t('default.fromCantBeAfterTo.message'):undefined},
			formatter: (esAnsatz) => textService.format(moment(esAnsatz.validFrom), 'date')
		},
		{
			id: "rate",
			label: t("esAnsatz.rate.label"),
			input: {tag: "input", type: "double"},
			formatter: (esAnsatz) => textService.format(esAnsatz.rate, 'number')
		}
	], [t] )

	const columnsIndexValues = useMemo( () => [
		{
			id: "year",
			label: t("indexValue.year.label"),
			input: {tag: "input", type: "integer"},
			validator: (value,data,rowIndex) => { return (value < 1900 || value > 2100)?t('default.invalidYear.message'):undefined},
			formatter: (indexValue) => textService.formatNumber(indexValue.year, {useGrouping: false, minimumFractionDigits: 0})
		},
		{
			id: "indexValue",
			label: t("indexValue.indexValue.label"),
			input: {tag: "input", type: "double"},
			formatter: (indexValue) => textService.format(indexValue.indexValue, 'number')
		}
	], [t] )

	return (
		<>
			<Card className={"mb-2"}>
				<Card.Header>{t('product.card.common.header')}</Card.Header>
				<Card.Body>
					<Row>
						<Form.Group as={Col} md="12" controlId="formGroupName">
							<Form.Label>{t('product.name.label')}</Form.Label>
							<FormControl
								name={"name"}
								type={"text"}
								value= {domainInstance.name}
								rules={{
									required: true
								}}
							/>
						</Form.Group>
					</Row>
				</Card.Body>
			</Card>
			<Card className={"mb-2"}>
				<Card.Header>{t('product.card.esAnsatz.header')}</Card.Header>
				<Card.Body>
					<Row>
						<EditableTableControl name={"esAnsatzs"} columns={columnsEsAnsatzs} data={domainInstance.esAnsatzs || []}/>
					</Row>
				</Card.Body>
			</Card>
			<Card className={"mb-2"}>
				<Card.Header>{t('product.card.indexValue.header')}</Card.Header>
				<Card.Body>
					<Row>
						<Col md={2}>
							<SwitchButtonControl
								value={ domainInstance.indexable }
								offlabel={t("product.indexable.offlabel")} onlabel={t("product.indexable.onlabel")} name={"indexable"}
								onChange={ (value) => setIndexable(value) }
							/>
						</Col>
					</Row>
					{ indexable &&
						<Row>
							<EditableTableControl name={"indexValues"} columns={columnsIndexValues} data={domainInstance.indexValues || []}/>
						</Row>
					}
				</Card.Body>
			</Card>
		</>
	);
}

export { FormElements };
