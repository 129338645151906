import React, {useCallback, useEffect, useState} from 'react';
import restService from "../_services/rest.service";
import {Alert, Alert as BootstrapAlert, Button, Card, Col, Form, Row} from "react-bootstrap";
import FormControl from "../_components/FormControl";
import DefaultSpinner from "../_components/DefaultSpinner";
import {t} from "i18next";
import {FormProvider, useForm} from "react-hook-form";
import Trans from "../_components/Trans";
import {messageBoxService} from "../_services/messageBox.service";
import {MessageBoxButtons} from "../_components/MessageBox";
import FormCheckControl from "../_components/FormCheckControl";
import {securityService} from "../_services/security.service";
import {alertService} from "../_services";
import {useHistory} from "react-router-dom";
import DynamicText from "../_components/DynamicText";
import PlainValue from "../_components/PlainValue";
import TwoFactorRegistrationFormFields from "./TwoFactorRegistrationFormFields";

function UserProfile() {
    const history = useHistory()
    const [userProfile, _, isLoaded] = restService.useUserProfile() // eslint-disable-line no-unused-vars
    const methods = useForm()
    const [changePassword, setChangePassword] = useState(false)
    const newPassword = methods.watch('newPassword')
    const [twoFactorEnabled, setTwoFactorEnabled] = useState(false)
    const [changeCounter, setChangeCounter] = useState(0)
    const emailChanged = methods.watch('email') !== userProfile.email

    const handleFormSubmit = (data) => {
        securityService.updateProfile(data)
        .then((result) => {
            alertService.success( t( 'default.updated', { what: t( 'user.label' ) } ), { keepAfterRouteChange: true } );
            history.push('/')
        })
        .catch( (error) =>{
                restService.handleServerErrorsAxios(error)
            }
        )
    }

    useEffect(() => {
        if ( userProfile && userProfile.twoFactorEnabled ) {
            setTwoFactorEnabled(true)
        }
    }, [userProfile, setTwoFactorEnabled]);

    const showTwoFactorRegistration = !userProfile.twoFactorEnabled && twoFactorEnabled

    const onTwoFactorSetup = useCallback((e) => {
        if ( e.target.checked ) {
            if ( !userProfile.twoFactorEnabled ) {
                setChangeCounter((prev) => prev + 1)
            }
            else {
                setChangeCounter((prev) => prev - 1)
            }
            setTwoFactorEnabled(true)
        }
        else {
            if (  userProfile.twoFactorEnabled ) {
                messageBoxService.display(
                    <DynamicText htmlContent={t('twoFactorAuthentication.disable.content')}/>,
                    <strong>{t('twoFactorAuthentication.disable.title')}</strong>,
                    [MessageBoxButtons.YES, MessageBoxButtons.NO], {headerClassName: 'bg-danger text-white h5'})
                    .then( (button) => {
                        if ( button === MessageBoxButtons.YES ) {
                            setTwoFactorEnabled(false)
                            setChangeCounter((prev) => prev + 1)
                        }
                        else {
                            setTwoFactorEnabled(true)
                        }
                    })
            }
            else {
                setTwoFactorEnabled(false)
                setChangeCounter((prev) => prev - 1)
            }
        }
    }, [setTwoFactorEnabled, userProfile.twoFactorEnabled])

    const onPasswordChange = (e) => {
        setChangePassword(e.target.checked)
        if ( e.target.checked ) {
            setChangeCounter((prev) => prev + 1)
        }
        else {
            setChangeCounter((prev) => prev - 1)
        }
    }

    const renderConfirmationArea = () => {
        if ( changeCounter > 0 || emailChanged ) {
            return (
                <Card className={"mb-3"}>
                    <Card.Header>
                        {t('user.authenticationArea')}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Form.Group as={Col} md={3} controlId="formGroupPasswordConfirmation">
                                <Form.Label>{t('login.password.label')}</Form.Label>
                                <FormControl
                                    name={"passwordConfirmation"}
                                    type={"password"}
                                    rules={{
                                        required: true
                                    }}
                                    shouldUnregister={true}
                                    placeholder={t('login.password.label')}
                                    autoComplete={"off"}
                                />
                            </Form.Group>
                            {userProfile && userProfile.twoFactorEnabled && <Form.Group as={Col} md={3} controlId="formGroupTotpConfirmation">
                                <Form.Label>{t('login.totp.label')}</Form.Label>
                                <FormControl
                                    name={"totpConfirmation"}
                                    type={"text"}
                                    rules={{
                                        required: true
                                    }}
                                    shouldUnregister={true}
                                    placeholder={t('login.totp.label')}
                                    autoComplete={"off"}
                                />
                            </Form.Group>}
                        </Row>
                    </Card.Body>
                </Card>
            )
        }
    }

    const changePasswordArea = () => {
        if ( changePassword ) {
            return (
                <Col>
                    <BootstrapAlert variant="info">
                        <Trans i18nKey={'login.passwordReset.info'}/>
                    </BootstrapAlert>
                    <Row>
                        <Form.Group as={Col} md={6} controlId="formGroupNewPassword">
                            <Form.Label>{t('login.new.password.label')}</Form.Label>
                            <FormControl
                                name={"newPassword"}
                                type={"password"}
                                rules={{
                                    required: true
                                }}
                                shouldUnregister={true}
                                placeholder={t('login.new.password.label')}
                                autoComplete={"off"}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md={6} controlId="formGroupNewPasswordRepeat">
                            <Form.Label>{t('login.new.password.repeat.label')}</Form.Label>
                            <FormControl
                                name={"newPasswordRepeat"}
                                type={"password"}
                                validationMessages={{
                                    passwordMatch: t('login.new.password.repeat.error')
                                }}
                                shouldUnregister={true}
                                rules={{
                                    validate: {
                                        passwordMatch: (v) => {
                                            return v === newPassword
                                        }
                                    },
                                    required: true
                                }}
                                placeholder={t('login.new.password.repeat.label')}
                                autoComplete={"off"}
                            />
                        </Form.Group>
                    </Row>
                </Col>
            )
        }
    }

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(handleFormSubmit)}>
                <Card className={"mb-3"}>
                    <Card.Header>
                        {t('user.profile')}
                    </Card.Header>
                    <Card.Body>
                        {(isLoaded) ?
                            <>
                                <Row className={"mb-3"}>
                                    <Form.Group as={Col} md={6} controlId="formGroupUsername">
                                        <Form.Label>{t('user.username.label')}</Form.Label>
                                        <PlainValue className={ "pe-3" } value={ userProfile.username } />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} controlId="formGroupEmail">
                                        <Form.Label>{t('user.email.label')}</Form.Label>
                                        <FormControl
                                            name={"email"}
                                            type={"text"}
                                            value={userProfile.email}
                                            rules={{
                                                required: true
                                            }}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} md="12" controlId="formGroupChangePassword">
                                        <Form.Check
                                            type="switch"
                                            id="changePassword"
                                            label={t('userProfile.changePassword')}
                                            checked={changePassword}
                                            onChange={onPasswordChange}
                                        />
                                    </Form.Group>
                                    {changePasswordArea()}
                                </Row>
                            </>
                            :
                            <DefaultSpinner loading={true}/>
                        }
                    </Card.Body>
                </Card>
                <Card className={"mb-3"}>
                    <Card.Header>
                        <Form.Group as={Col} controlId="formGroupTwoFactor">
                            {!twoFactorEnabled && <Alert variant={"warning"}>{t('twoFactorAuthentication.recommendation')}</Alert> }
                            <FormCheckControl
                                type="switch"
                                name="twoFactorEnabled"
                                label={t('user.twoFactorAuthenticationGroup')}
                                value={twoFactorEnabled}
                                onChange={onTwoFactorSetup}
                            />
                        </Form.Group>
                    </Card.Header>
                    <Card.Body>
                        {showTwoFactorRegistration && <TwoFactorRegistrationFormFields/>}
                    </Card.Body>
                </Card>

                {renderConfirmationArea()}
                <Form.Group>
                    <Button type="submit" className="btn btn-primary">{t('default.save')}</Button>&nbsp;
                    <Button className="btn btn-secondary" onClick={()=>history.push('/')}>{t('default.cancel')}</Button>
                </Form.Group>
            </Form>
        </FormProvider>
    )
}

export default UserProfile
