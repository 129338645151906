import React, {useCallback, useEffect, useState} from 'react';
import settingService from "../_services/setting.service";
import {Alert, Button, Card, Col, Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function AnnouncementEdit() {
	const [announcement, setAnnouncement] = useState('')
	const [announcementHistory, setAnnouncementHistory] = useState([])
	const {t} = useTranslation();

	useEffect( () => {
		settingService.getSetting( ['announcement', 'announcementHistory'] )
			.then( (values) => {
				setAnnouncement( values.announcement )
				setAnnouncementHistory( !values.announcementHistory ? [] : values.announcementHistory )
			} )
	}, [] );

	const handleSave = useCallback( () => {
		settingService.setSetting('announcement', announcement)
			.then()

		let _announcementHistory = announcementHistory
		if ( !_announcementHistory.includes(announcement) && announcement ) {
			_announcementHistory.unshift( announcement );
			if ( _announcementHistory.length > 5 ) {
				_announcementHistory.pop();
			}
			settingService.setSetting('announcementHistory', _announcementHistory)
				.then()
		}
	}, [announcement, announcementHistory] )

	return (
		<>
			<Alert variant={"info"}>
				{t('announcement.edit.info')}
			</Alert>
			<Form.Group as={ Col } md="12" controlId="product">
				<Form.Label>{t("announcement.label")}</Form.Label>
				<Form.Control
					name="announcement"
					type="text"
					as="textarea"
					value={ announcement }
					onChange={ (e) => setAnnouncement(e.target.value) }
				/>
			</Form.Group>

			<Card className={"mt-3"}>
				<Card.Header>{t('announcement.history')}</Card.Header>
				<Card.Body>
					{ announcementHistory && announcementHistory.map( (announcement, idx) => {
						return <Alert key={idx} className={'small p-2'} variant={"light"}>
							<div>{announcement}</div>
							<div><Button variant={"success"} size={'sm'} className={'mt-2'} onClick={ () => setAnnouncement(announcement)}>{t('default.copy')}</Button></div>
						</Alert>
					} ) }
				</Card.Body>
			</Card>


			<Button className="btn btn-primary mt-3" onClick={handleSave}>{t('default.save')}</Button>
		</>
	)
}

export default AnnouncementEdit