import {SimpleCache} from "../_classes/SimpleCache";
import restService from "./rest.service";
import {apiClientPrivate} from "../api/apiClient";

export const cacheService = {
	cacheableFetch,
	clearCache,
	createSimpleCache,
}

const caches = []

/**
 * Clears all caches stored in 'caches' list.
 **/
function clearCache() {
	caches.forEach( cache => {
		cache.clear()
	} )
}

/**
 * Creates a new SimpleCache and stores is in a 'caches' list.
 * Caches list is used in clearCache() (for example during logout/login)
 * @returns {SimpleCache}
 */
function createSimpleCache() {
	const cache = new SimpleCache()
	caches.push( cache )
	return cache
}

/**
 * Fetches data from server and caches it.
 * It can happen, that this method is called with data ['a', 'b', 'c'], then it tries to fetch the result them.
 * Then it is called with data ['b', 'c', 'd']. The method recognizes, that 'b' and 'c' are already being fetched (but
 * not yet returned from server). It fetches only 'd' and waits for both promises, and then it resolves the result.
 *
 * @param url
 * @param data
 * @param paramsPropertyName
 * @param cache
 * @returns {Promise<unknown>}
 */
function cacheableFetch(url, data, paramsPropertyName, cache, signal) {
	// console.log( `cacheableFetch: ${ JSON.stringify(data) }` )

	return new Promise( ( resolve, reject ) => {
		let promises = new Set()
		let dataToBeLoaded = []

		/**
		 * Check if the data is already in the cache.
		 * - If it is, check if it is a promise or a value. If it is a promise, collect the promise in 'promises' Set.
		 * - If it is not, collect the data in 'dataToBeLoaded' array.
		 */
		if ( typeof ( data ) === "string" ) {
			if ( !cache.keyExists( data ) ) {
				// console.log( `cacheableFetch: ${ data } not in cache` )
				dataToBeLoaded.push( data )
			} else {
				if ( cache.get( data ) instanceof Promise ) {
					// console.log( `cacheableFetch: ${ data } promise in cache` )
					promises.add( cache.get( data ) )
				} else {
					// console.log( `cacheableFetch: ${ data } already cached` )
				}
			}
		} else {
			data.forEach( dataItem => {
				if ( !cache.keyExists( dataItem ) ) {
					// console.log( `cacheableFetch: ${ dataItem } not in cache` )
					dataToBeLoaded.push( dataItem )
				} else {
					if ( cache.get( dataItem ) instanceof Promise ) {
						// console.log( `cacheableFetch: ${ dataItem } promise in cache` )
						promises.add( cache.get( dataItem ) )
					} else {
						// console.log( `cacheableFetch: ${ dataItem } already cached` )
					}
				}
			} )
		}

		/**
		 * If there is dataToBeLoaded, fetch it from the server. The fetch is a promise that is
		 * also added to 'promises' Set.
		 */
		if ( dataToBeLoaded.length > 0 ) {
			const promise = new Promise( ( resolveFetch, rejectFetch ) => {
				// console.log( `cacheableFetch: fetching data ${ dataToBeLoaded } ` )
				const params = {
					[paramsPropertyName]: JSON.stringify( dataToBeLoaded ),
				}
				apiClientPrivate.get( `${ url }?` + new URLSearchParams( params ), {
					signal: signal,
				} )
					.then((response) => restService.handleServerResponseAxios(response))
					.then( result => {
						// console.log( 'cacheableFetch: fetched', result )
						cache.setAll( result )
						resolveFetch()
					} )
					.catch( ( error ) => {
						rejectFetch( error )
					} )
			} )

			dataToBeLoaded.forEach( dataItem => {
				if ( !cache.get( dataItem ) ) {
					cache.set( dataItem, promise )
				}
			} )
			promises.add( promise )
		}
		else {
			// console.log( `cacheableFetch: nothing to fetch for data ${ data } ` )
		}

		/**
		 * If all collected promises are resolved, resolve the cached values.
		 */
		Promise.all( promises )
			.then( () => {
				let result = {}
				if ( typeof ( data ) === "string" ) {
					result[data] = cache.get( data )
				} else {
					data.forEach( dataItem => {
						result[dataItem] = cache.get( dataItem )
					} )
				}
				// console.log( `cacheableFetch: ${ JSON.stringify(result) } resolved` )
				resolve( result )
			} )
			.catch( ( error ) => {
				reject( error )
			} )
	})
}
