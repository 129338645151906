import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Card, Form, Table, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import restService from "../_services/rest.service";
import notificationService from "../_services/notification.service";
import {useTranslation} from "react-i18next";
import appService from "../_services";
import {TicketPriority, TicketState} from "../_enum/enum";
import TicketsCell from "./TicketsCell";
import TicketEditor from "./TicketEditor";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import moment from "moment/moment";
import {useSelector} from "react-redux";

function Tickets() {
	const [tickets, setTickets] = useState( [] )
	const [closed, setClosed] = useState( false )
	const [snoozed, setSnoozed] = useState( false )
	const [searchText, setSearchText] = useState( '' )
	const [showEditForm, setShowEditForm] = useState( false )
	const [newTicket, setNewTicket] = useState( undefined )
	const username = useSelector(state => state.auth.username)

	const { t } = useTranslation();

	document.title = t( 'tickets.header' )

	const ticketsCriteria = useMemo( () => {
		return {
			queryName: "tickets",
			params: {closed: closed, snoozed: snoozed, searchText: searchText}
		}
	}, [closed, snoozed, searchText])

	const reloadTickets = useCallback( ( signal, ticketsCriteria ) => {
		restService.getDomainInstancesList( "ticket", 1, 200, 'createdOn desc', '', undefined, ticketsCriteria, undefined, signal )
			.then( ( result ) => {
				// setTotalSize( result.totalSize );
				setTickets( result.data );
			} )
			.catch( ( error ) => {
				restService.handleServerErrorsAxios( error, signal )
			} );
	}, [] )

	useEffect( () => {
		const controller = new AbortController();

		reloadTickets( controller.signal, ticketsCriteria )

		return function cleanup() {
			controller.abort()
		}
	}, [ticketsCriteria, reloadTickets] )

	const handleTicketChanged = useCallback( ( changedTickets ) => {
		if ( 0 <= changedTickets.length ) {

			let _tickets = [...tickets]

			for ( const changedTicket of changedTickets ) {
				const index = _tickets.findIndex( ( m ) => m.id === changedTicket.notification.id )
				if ( index !== -1 ) {
					_tickets[index] = changedTicket.notification
				} else {
					if ( changedTicket.isNew || changedTicket.isUnsnoozed ) {
						_tickets = [changedTicket.notification, ..._tickets]
					}
				}

				if ( changedTicket.isNew || changedTicket.isUnsnoozed ) {
					const notificationCommandsHrefs = changedTicket.notification.commands && changedTicket.notification.commands.map( ( command ) => notificationService.getNotificationCommandHref( command ) ).filter( ( href ) => !!href )
					const href = notificationCommandsHrefs && notificationCommandsHrefs.length > 0 ? notificationCommandsHrefs[0] : undefined;

					const hrefWithServer = `${ window.location.origin }${ href }`

					appService.notify( changedTicket.notification.title, changedTicket.notification.text, hrefWithServer )
				}
			}

			setTickets( _tickets )
		}
	}, [tickets, setTickets] )

	notificationService.usePlainNotificationsChanged( 'ticket', handleTicketChanged );

	const handleCancelEditForm = useCallback( () => {
		setShowEditForm( false )
	}, [] )

	const handleSubmitEditForm = useCallback( ( data ) => {
		restService.saveDomainInstance( 'ticket', data )
			.then( () => setShowEditForm( false ) )
			.catch( ( error ) => {
				restService.handleServerErrorsAxios( error )
			} )
	}, [] )

	const handleNewTicket = useCallback( () => {
		setNewTicket( { title: '', description: '', priority: TicketPriority.NORMAL, state: TicketState.OPEN, from: username } )
		setShowEditForm( true )
	}, [username] )

	const header = useMemo( () => {
		return (
			<>
				<span className={ "w-100" }>
					<span className={ 'float-start' }>{ t( 'tickets.header' ) }</span>
					<div className={ 'float-end' }>
						<div className={ 'd-inline-block ms-2' }>
							<Form.Control
								type="text"
								placeholder={ t( 'default.search' ) }
								value={ searchText }
								onChange={ ( e ) => setSearchText( e.target.value ) }
								size={ "sm" }
							/>
						</div>
						<ToggleButtonGroup type="checkbox" size={ "sm" } className={ 'd-inline-block ms-2' }
						                   name={ "test" }>
							<ToggleButton
								id="toggle-tickets-closed"
								type="checkbox"
								variant="outline-primary"
								checked={ closed }
								value="1"
								onChange={ ( e ) => setClosed( e.currentTarget.checked ) }
							>
								{ t( 'messages.closed' ) }
							</ToggleButton>
							<ToggleButton
								id="toggle-tickets-snoozedOnly"
								type="checkbox"
								variant="outline-primary"
								checked={ snoozed }
								value="2"
								onChange={ ( e ) => setSnoozed( e.currentTarget.checked ) }
							>
								{ t( 'messages.snoozed' ) }
							</ToggleButton>
						</ToggleButtonGroup>
					</div>
				</span>
			</>
		)
	}, [t, closed, snoozed, searchText] )

	const handleUpdateTicket = useCallback( ( id, values ) => {
		const replaceTicket = ( ticket ) => {
			const index = tickets.findIndex( ( m ) => m.id === id )
			tickets.splice( index, 1, ticket ); //replace the ticket
			setTickets( [...tickets] )
		}

		const index = tickets.findIndex( ( m ) => m.id === id )
		if ( index !== -1 ) {
			const ticket = { ...tickets[index], values }
			replaceTicket( ticket )

			restService.updateDomainInstance( 'ticket', ticket.id, values )
				.then( ( ticket ) => {
					replaceTicket( ticket )
				} )
		}

	}, [tickets, setTickets] )

	const byPriority = useMemo( () => {
		const priorities = Object.keys( appService.groupBy(tickets, (ticket) => ticket.priority ) )
		return {
			//.filter( priority => priorities.includes(priority) ) is used because of maintaining the order of priorities
			labels: Object.keys( TicketPriority ).filter( priority => priorities.includes(priority) ).map( ( key ) => {
				return { value: key, label: t( `appvers.enums.TicketPriority.${ key }` ) }
			} ),
			getter: ( ticket ) => {
				return ticket.priority
			}
		}
	}, [t, tickets] )

	const byState = useMemo( () => {
		const states = Object.keys( appService.groupBy(tickets, (ticket) => ticket.state ) )
		return {
			//.states.includes(state) is used because of maintaining the order of states
			labels: Object.keys( TicketState ).filter( ( state ) => states.includes(state) && ( closed || state !== TicketState.CLOSED ) ).map( ( key ) => {
				return { value: key, label: t( `appvers.enums.TicketState.${ key }` ) }
			} ),
			getter: ( ticket ) => {
				return ticket.state
			}
		}
	}, [t, tickets, closed] )

	const columns = useMemo( () => byState, [byState] )
	const rows = useMemo( () => byPriority, [byPriority] )

	const board = useMemo( () => {
		return (
			<>
				<Table bordered hover>
					<tr>
						{ columns.labels.map( ( columnLabel ) => {
							return (
								<td key={ columnLabel.value }
								    className={ "p-4 text-center border h6" }>{ columnLabel.label }</td>
							)
						} ) }
					</tr>
					{ rows.labels.map( ( rowLabel ) => {
						return (
							<>
								<tr key={ rowLabel.value + 'header' } className={ "border h6" }>
									<td colSpan={ columns.labels.length }
									    className={ "p-2 bg-light" }>{ rowLabel.label }</td>
								</tr>
								<tr key={ rowLabel.value } className={ "border" }>
									{
										columns.labels.map( ( columnLabel ) => {
											const filteredTickets = tickets.filter( ( ticket ) => {
												return columns.getter( ticket ) === columnLabel.value && rows.getter( ticket ) === rowLabel.value
											} )

											return (
												<td key={ rowLabel.value + '-' + columnLabel.value } className={ "p-4 border align-top" }>
													<TicketsCell tickets={ filteredTickets }
													             handleUpdateTicket={ handleUpdateTicket }/>
												</td>
											)
										} )
									}
								</tr>
							</>
						)
					} ) }
				</Table>
			</>
		)
	}, [tickets, columns, rows, handleUpdateTicket] )

	return (
		<>
			<TicketEditor ticket={ newTicket } showForm={ showEditForm } onCancel={ handleCancelEditForm }
			              onSubmit={ handleSubmitEditForm }/>
			<Card>
				<Card.Header>
					{ header }
				</Card.Header>
				<Card.Body>
					<span>
						<Button variant="primary" size="sm" className={ 'mb-2' }
						        onClick={ handleNewTicket }> <FontAwesomeIcon icon={ faPlus }/> </Button>
					</span>
					{ board }
				</Card.Body>
			</Card>
		</>
	)
}

export default Tickets