import React from 'react';
import restService from "../_services/rest.service";
import ShowField from "../form/ShowField";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {accountingService} from "../_services/accounting.service";

function ShowAccountingExport(props) {
	const {t} = useTranslation();
	const [accountingExport] = restService.useDomainInstance( 'accountingExport', props.id );

	const handleDownloadAccountingExportFileSage50 = () => {
		accountingService.downloadAccountingExportFile( props.id, 'sage50' )
	}

	const handleDownloadAccountingExportFileAbacus = () => {
		accountingService.downloadAccountingExportFile( props.id, 'abacus' )
	}

	return (
		<>
			<div>
				<ShowField label={'accountingExport.id.label'} object={accountingExport} property={'id'} type={'number'}/>
				<ShowField label={'accountingExport.createdOn.label'} object={accountingExport} property={'createdOn'} type={'datetime'}/>
				<ShowField label={'accountingExport.createdBy.label'} object={accountingExport} property={'createdBy'} type={'text'}/>
			</div>
			<div>
				<Button type={"button"} onClick={handleDownloadAccountingExportFileSage50}>{t('default.download')} Sage50</Button>
				<Button type={"button"} onClick={handleDownloadAccountingExportFileAbacus} className={"ms-2"}>{t('default.download')} Abacus</Button>
			</div>
		</>
	)
}

export default ShowAccountingExport