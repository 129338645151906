import React, {useMemo} from 'react';
import {securityService} from "../_services/security.service";

function Secured( { granted, type, children } ) {
	const hasAccess = securityService.useGranted( granted, type )

	const elements = useMemo( () => {
		return hasAccess.ready && hasAccess.value ?
			children
			:
			<></>
	}, [hasAccess.ready, hasAccess.value, children])

	return (
		<>
		{/*<p>{JSON.stringify(hasAccess)}</p>*/}
		{elements}
		</>
	)
}

export default Secured