import React, {useMemo} from 'react';
import RoleNode from "./RoleNode";
import Spinner from "../Spinner";

function RoleNodeTreeLevel( {nodes} ) {
    return (
        useMemo( () => {
            if ( nodes ) {
                return nodes.map( ( node ) => <RoleNode key={ node.authority } roleNode={ node }/> )
            }
            else  {
                return <Spinner/>
            }
        }, [nodes] )
    )
}

export default RoleNodeTreeLevel
