import axios from "axios";

import {SERVER_URL} from "../config";
import qs from "qs";
import {hideLoading, showLoading} from "../_services/loadingManager";
import store from "../store";
import {setAuth, clearAuth} from "../security/authSlice";
import i18n from "i18next";

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    console.log('processing request\'s queue of: ', failedQueue.length);
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

export const apiClient = axios.create({
    baseURL: SERVER_URL,
    headers: {
        'Accept-Language': i18n.language,
    }
});

export const apiClientPrivate = axios.create({
    baseURL: SERVER_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
    },
    withCredentials: true
});

apiClientPrivate.interceptors.request.use(
    config => {
        if ( config.onUploadProgress === undefined ) {
            showLoading();  // Increment the counter when request starts
        }
        let auth = store.getState().auth;
        const accessToken = auth.access_token;
        if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
    },
    error => {
        if ( error.config.onUploadProgress === undefined ) {
            hideLoading()
        }
        return Promise.reject(error)
    }
);

apiClientPrivate.interceptors.response.use(
    (response) => {
        if ( response.config.onUploadProgress === undefined ) {
            hideLoading(); // Decrement the counter when response is received
        }
        return response
    },
    async (error) => {
        if ( error.config === undefined || error.config.onUploadProgress === undefined ) {
            hideLoading();  // Decrement the counter on response error
        }
        const originalRequest = error.config;
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({resolve, reject});
                }).then(token => {
                    originalRequest.headers['Authorization'] = `Bearer ${token}`;
                    return apiClient(originalRequest);
                }).catch(err => {
                    return Promise.reject(err);
                });
            }

            isRefreshing = true;

            try {
                const response = await apiClient.post('/oauth/access_token',
                    qs.stringify({
                        grant_type: 'refresh_token',
                    }),
                    {
                        withCredentials: true,
                    }
                );
                store.dispatch(setAuth(response.data));
                apiClientPrivate.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;
                processQueue(null, response.data.access_token);
                originalRequest.headers['Authorization'] = `Bearer ${response.data.access_token}`;
                return apiClientPrivate(originalRequest);
            }
            catch (refreshError) {
                processQueue(refreshError, null);
                store.dispatch(clearAuth());
                window.location.href = '/';
                return Promise.reject(refreshError);
            }
            finally {
                isRefreshing = false;
            }
        }
        return Promise.reject(error);
    }
);

