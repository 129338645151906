import React from 'react';
import DefaultSpinner from "./DefaultSpinner";
import {useLoading} from "../_services/loadingContext";

function Spinner() {
	const {isLoading} = useLoading()

	return (
		<DefaultSpinner loading={isLoading} css={"position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);"} size={10}/>
	);
}

export default Spinner
