import {ButtonGroup, Form, ToggleButton} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";
import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import reactHookFormService from "./reactHookForm.service";

function ToggleButtonGroupControl( { name, options, value, rules, validationMessages, className, placeholder, disabled, onChange }) {
	const { setValue, control } = useFormContext();
	const defVal = useMemo( () => value === undefined || value === null ? '' : value, [value] )
	const [currentValue, setCurrentValue] = useState(defVal);

	useEffect(() => {
		const newValue = defVal;
		setCurrentValue(newValue);
		setValue(name, newValue);
	}, [setValue, name, defVal]);

	return (
		<>
			<Controller
				control={ control }
				name={ name }
				rules={ rules }
				defaultValue={ currentValue }
				render={ ( { field, fieldState } ) =>
					<>
						<ButtonGroup>
							{ options.map( (option, idx) => {
								return (
									<ToggleButton
										key={idx}
										type="radio"
										onClick={ ( ) => {
											setCurrentValue( option.value );
											field.onChange( option.value );
											if ( onChange ) {
												onChange( option.value );
											}
										} }
										onBlur={ field.onBlur }
										variant={option.value === currentValue ? 'primary' : 'secondary'}
										value={option.value}
										checked={option.value === currentValue}
										name={option.value}
										className={ (className ? `${className} ` : '') + (!!fieldState.error ? 'is-invalid' : '') }
										placeholder={ placeholder }
										disabled={ disabled }
									>
										{option.label}
									</ToggleButton>
								)
							})}
						</ButtonGroup>
						<Form.Control.Feedback type="invalid">
							{ reactHookFormService.getValidationMessage(fieldState, validationMessages) }
						</Form.Control.Feedback>
					</>
				}
			/>
		</>
	)
}

ToggleButtonGroupControl.propTypes = {
	name: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired,
	defaultValue: PropTypes.any,
	onChange: PropTypes.func
};

export default ToggleButtonGroupControl;
