import React, {useCallback, useMemo, useState} from 'react'
import {Accordion, Card, Col, Row, Button, Table, Badge, Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {ConfiguratorTargetHeader} from "./ConfiguratorTargetHeader";
import ShowField from "../form/ShowField";
import { NumericFormat } from "react-number-format";
import DiscountSurchargeFeeElementTr from "./DiscountSurchargeFeeElementTr";
import CustomPanelToggle from "../_components/CustomPanelToggle";
import {Link} from "react-router-dom";
import restService from "../_services/rest.service";
import DiscountSurcharge from "./DiscountSurcharge";
import {RestAsyncSelectControl} from "../_components/RestAsyncSelectControl";
import {FormElements as BuildingFormElements} from "../building/FormElements";
import Address from "./Address";
import AllowedContextClassSimpleName from "../_components/AllowedContextClassSimpleName";
import textService from "../_services/text.service";
import moment from "moment/moment";
import {AllowedForEnum} from "../form/form.service";
import Allowed from "../_components/Allowed";
import EditableInAllowedContext from "../_components/EditableInAllowedContext";

function PolicyTarget({policyVersion, target, onChanged}) {
    const {t} = useTranslation()
    const [activeKey, setActiveKey] = useState(0);

    const getTariffs = () => {
        if ( !target.tariff ) return;
        let remainingAmount = target.amount
        let output = []
        if ( target.tariff.length === 1 ) {
            output.push(
                <NumericFormat key={0} value={ target.tariff[0].tariff * 1000 }
                               displayType={ 'text' }
                               thousandSeparator={ t('thousandSeparator') }/>
            )
        }
        else {
            for (let i = 0; i < target.tariff.length; i++) {
                let trf = target.tariff[i];
                output.push(
                    <span key={i}>{output.length>0?' & ':''}{trf.tariff * 1000} (&times; <NumericFormat value={trf.value}
                                                                                                        displayType={'text'}
                                                                                                        thousandSeparator={t('thousandSeparator')}/>)</span>
                )
                remainingAmount -= trf.value;
                if (remainingAmount <= 0) break
            }
        }
        return output
    }

    const getFee = () => {
        return (
            <>
                <div><NumericFormat value={ target.indexedFeeBruttoCurrentYear } displayType={ 'text' } thousandSeparator={ t('thousandSeparator') }/> CHF</div>
                <div className={"indexed-wrapper"}>{target.indexed ? t("configuratorTarget.indexed.onlabel"): t("configuratorTarget.indexed.offlabel")}</div>
            </>
        )
    }

    const getFeeCard = () => {
        return (
            <Card className={"mb-2" }>
                <Card.Header>
                    { t( "configuratorTarget.card.header" ) } <NumericFormat value={ target.indexedFeeTotalCurrentYear } displayType={ 'text' } thousandSeparator={ t('thousandSeparator') }/> CHF
                </Card.Header>
                <Card.Body>
                    <Table size="sm">
                        <tbody>
                        <tr>
                            <td>{ t( 'configuratorTarget.tariff.label') }</td>
                            <td className="text-right">{getTariffs()}</td>
                        </tr>
                        <tr>
                            <td>{ t( 'configuratorTarget.feeBrutto.label') }</td>
                            <td className="text-right">{ getFee() }</td>
                        </tr>
                        {getDiscountSurchargeFee()}
                        {getAdditionalDiscountOrSurcharge()}
                        {getStampTaxElement()}
                        {getLoeschfuenferElement()}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        )
    }

    const getDiscountSurchargeFee = () => {
        let discountSurcharge = target.discountSurcharge
        if ( discountSurcharge ) {
            return Object.keys(discountSurcharge).filter((idx) => discountSurcharge[idx].rate.rate !== 0).map((idx) => {
                const ds = discountSurcharge[idx];
                return <DiscountSurchargeFeeElementTr key={idx} name={ds.name} listBoxLabel={ds.listBoxLabel}
                                                      rate={ds.rate.rate} unit={ds.rate.unit} value={ds.value}/>
            })
        }
    }

    const getAdditionalDiscountOrSurcharge = () => {
        if ( target.additionalDiscountSurchargePercent ) {
            return (
                <tr>
                    <td>{target.additionalDiscountSurchargeValue < 0 ? t('default.Discount') : t('default.Surcharge')} {target.additionalDiscountSurchargePercent} %</td>
                    <td className="text-right"><NumericFormat value={ target.additionalDiscountSurchargeValue } displayType={ 'text' } thousandSeparator={ t('thousandSeparator') }/> CHF</td>
                </tr>
            )
        }
    }

    const getStampTaxElement = () => {
        return (target.useStampTax &&
            <tr>
                <td>{ t( 'configuratorTarget.stampTaxPercent.label', {percent: target.stampTaxPercent}) }</td>
                <td className="text-right">
                    <NumericFormat value={ target.indexedStampTaxValueCurrentYear } displayType={ 'text' }
                                   thousandSeparator={ t('thousandSeparator') }/> CHF
                </td>
            </tr>
        )
    }

    const getLoeschfuenferElement = () => {
        return (target.useLoeschfuenfer &&
                <tr>
                    <td>{t('configuratorTarget.loeschfuenfer.label')}</td>
                    <td className="text-right">
                        <NumericFormat value={target.indexedLoeschfuenferValueCurrentYear} displayType={'text'}
                                       thousandSeparator={t('thousandSeparator')}/> CHF
                    </td>
                </tr>
            )
    }

    const getIndividualTextsCard = () => {
        if ( !target.individualTexts || target.individualTexts.length === 0 ) return;
        return (
            <Accordion>
                <Card className={"mb-2"} style={{overflow: "inherit"}}>
                    <Card.Header>
                        <CustomPanelToggle variant="link" eventKey="0">
                            {t('configuratorTarget.individualTexts.card.header')}
                        </CustomPanelToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>{t('configuratorTarget.individualTexts.text.label')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {target.individualTexts.map( (individualText, index) => {
                                        return <tr key={index}>
                                            <td>{(individualText.individualText && individualText.individualText.constructor === Object) ? individualText.individualText.label : individualText.individualText}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        )
    }

    const getParticipationCard = () => {
        return (
            <Allowed allowedFor={AllowedForEnum.SHOW} propertyName={"policyParticipants"}>
                <Accordion defaultActiveKey={ target.participation.length > 1 ? "0" : "" }>
                    <Card className={"mb-2"}>
                        <Card.Header>
                            <CustomPanelToggle as={Button} variant="link" eventKey="0">
                                {t('configuratorTarget.participation.card.header')}
                            </CustomPanelToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>{t('configuratorTarget.participation.partner.label')}</th>
                                            <th>{t('configuratorTarget.participation.percent.label')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {target.participation.map( (participation, index) => {
                                        return <tr key={index}>
                                            <td><Link to={ restService.getTo( participation.partner, 'show' ) } target = "_blank" rel = "noopener noreferrer" >
                                                {participation.partner.label}
                                            </Link></td>
                                            <td>{participation.percent}</td>
                                        </tr>
                                    })}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </Allowed>
        )
    }

    const chflElement = useMemo( () => {
        return <Badge bg={target.countryCode === 'FL'? 'primary' : 'danger'}>{target.countryCode}</Badge>
    }, [target.countryCode])

    const addressOrBuildingElement = useMemo( () => {
        if ( target.building && target.building.label ) {
            return (
                <div className={'font-weight-light'}>
                    <EditableInAllowedContext
                        onSave={ (formData) => {
                            return new Promise( (resolve, reject) => {
                                restService.updateDomainInstance('policyTarget', target.id, { version: target.version, ...formData })
                                    .then( (domain) => {
                                        onChanged()
                                        resolve();
                                    })
                                    .catch( (error) => reject(error) )
                            })
                        }}
                        editFormElement={<RestAsyncSelectControl
                            noSelection={true}
                            domainName={"building"}
                            sort={"street_sort"}
                            name={'building'}
                            value={target.building && target.building.id}
                            label={target.building && target.building.label}
                            createable={{formElements: <BuildingFormElements/>, target:'street'}}
                        />}
                        allowedContextPropertyName={'building'}
                    >
                        <> <em>{target.building.label}</em> { chflElement } </>
                    </EditableInAllowedContext>
                </div>
            )
        }
        else if ( target.placeOfInsuranceStreet && target.placeOfInsuranceZip ) {
            return (
                <div className={'font-weight-light'}>
                    <EditableInAllowedContext
                        onSave={ (formData) => {
                            return new Promise( (resolve, reject) => {
                                restService.updateDomainInstance('policyTarget', target.id, { version: target.version, ...formData })
                                    .then( (domain) => {
                                        onChanged()
                                        resolve();
                                    })
                                    .catch( (error) => reject(error) )
                            })
                        }}
                        editFormElement={<Address namePrefix={'placeOfInsurance'} defaultStreet={target.placeOfInsuranceStreet} defaultZip={target.placeOfInsuranceZip}/>}
                        allowedContextPropertyName={'placeOfInsuranceStreet'}
                    >
                        <> <em>{target.placeOfInsuranceStreet}, {target.placeOfInsuranceZip.label}</em> { chflElement } </>
                    </EditableInAllowedContext>
                </div>
            )
        }
        else {
            return undefined;
        }
    }, [target.building, target.placeOfInsuranceStreet, target.placeOfInsuranceZip, onChanged, target.id, chflElement, target.version])

    const handleConfiguratorTargetHeaderClick = useCallback( () => {
        if ( activeKey > 0 ) {
            setActiveKey(0);
        }
        else {
            setActiveKey(target.key);
        }
    }, [target.key, activeKey])

    const discountSurchargeHeader = useMemo( () => {
        let result = []
        if ( target && target.discountSurcharge ) {
            for ( const dsKey in target.discountSurcharge ) {
                const dsItem = target.discountSurcharge[dsKey];
                result.push( <span className={"ms-2"} key={dsItem.name}>{ dsItem.name } ({ dsItem.listBoxLabel })</span> )
            }
        }
        return <span className={"float-end small"}>{result}</span>
    }, [target])

    return (
        //there is a separate accordion for each target in order to let them open all
        <AllowedContextClassSimpleName classSimpleName={"policyTarget"}>
            <Accordion activeKey={activeKey}>
               <Card>
                   <Form.Control name={"policy-target-id"} type={"number"} hidden={true} readOnly={true} value={target.id}/>
                   <ConfiguratorTargetHeader eventKey={target.key} onClick={handleConfiguratorTargetHeaderClick}>
                       <Row>
                           <Col md={12}>
                               <span className={'fw-bold'}>{target.pathAndNameAsText}</span><span> (<NumericFormat value={ target.amount } displayType={ 'text' } thousandSeparator={ t('thousandSeparator') }/>)</span>
                               <span>{discountSurchargeHeader}</span>
                           </Col>
                       </Row>
                       <Row>
                           <Col md={12}>
                               { addressOrBuildingElement ? addressOrBuildingElement : chflElement }
                           </Col>
                       </Row>
                       <Row>
                           <Col md={12}>
                               <span><NumericFormat value={ target.indexedFeeTotalCurrentYear } displayType={ 'text' } thousandSeparator={ t('thousandSeparator') }/> CHF</span>
                           </Col>
                       </Row>
                   </ConfiguratorTargetHeader>
                   <Accordion.Collapse eventKey={target.key}>
                       <Card.Body>
                           <Row className={"mb-3"}>
                               <Col md={12}>
                                   <span className={"small"}>{target.fullPathAndNameAsText}</span>
                               </Col>
                           </Row>
                           <Row>
                               <ShowField label={'createPolicy.detail.products.header.amount'} object={target}
                                          property={'amount'} type={'number'} size={6}
                                          appendix={ target.indexed ? ' (' + t('createPolicy.detail.products.header.amount.appendix', {year: policyVersion.validFrom && moment(policyVersion.validFrom).year(), index: textService.formatNumber(target.indexStart)} ) + ')' : '' }
                                          appendixClass={'fw-normal fst-italic'}
                               />
	                           <ShowField label={'createPolicy.detail.products.header.indexedAmount'} object={target}
                                          property={'indexedAmountCurrentYear'} type={'number'} size={6}
                                          appendix={ target.indexed ? ' (' + t('createPolicy.detail.products.header.indexedAmount.appendix', {year: moment().year(), index: textService.formatNumber(target.indexCurrentYear)} ) + ')' : '' }
                                          appendixClass={'fw-normal fst-italic'}
	                           />
                           </Row>
                           <Row>
                               <ShowField label={'createPolicy.detail.policyType.label'} object={target}
                                          property={'policyType'} type={'text'} size={12}/>
                           </Row>
                           <Row>
                               <ShowField label={'createPolicy.detail.avb.label'} object={target} property={'avb.label'}
                                          type={'text'} size={12}/>
                           </Row>
                           <Row>
                               <ShowField label={'createPolicy.detail.firstLossRisk.label'} object={target}
                                          property={'firstLossRisk'} type={'boolean'} size={4}/>
                           </Row>

                           <DiscountSurcharge policyTarget={target}/>
                           {getFeeCard()}
                           {getIndividualTextsCard()}
                           {getParticipationCard()}
                       </Card.Body>
                   </Accordion.Collapse>
               </Card>
            </Accordion>
        </AllowedContextClassSimpleName>
    )
}

export {PolicyTarget}
