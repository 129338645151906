import React, {useContext} from 'react';
import {AllowedContext} from "../form/form.service";

/**
 * Overrides classSimpleName in allowedContext.
 * The classSimpleName is located normally in context.data.classSimpleName. In a case, when you
 * need to edit more complex structure (like policy version), then it is useful to override
 * the classSimpleName for some elements.
 * @param classSimpleName
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
function AllowedContextClassSimpleName({classSimpleName, children}) {
	const allowedContext = useContext( AllowedContext )

	return (
        <AllowedContext.Provider value={ { ...allowedContext, classSimpleName: classSimpleName } }>
            { children }
        </AllowedContext.Provider>
	)
}

export default AllowedContextClassSimpleName