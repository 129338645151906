import React, {useEffect, useRef} from 'react';
import {Form, FormControl, Button, Card, InputGroup, Row, Col, Alert} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import logoAppvers from './images/logo_appvers_with_name.svg';
import {faKey} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, useHistory} from "react-router-dom";
import {Alert as MessageAlert}  from "./_components";
import {useAuthContext} from "./security/AuthContext";
import {securityService} from "./_services/security.service";
import settingService from "./_services/setting.service";

const TwoFactorAuthentication = () => {
	const {twoFactorRequired, login, logout} = useAuthContext()
	const { "_twofactor.trusted.device.valid.days": daysToValid } = settingService.useSetting(['_twofactor.trusted.device.valid.days'])
	const { t } = useTranslation()
	const tokenInput = useRef()
	const history = useHistory();


	function handleSubmit(event) {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;
		if ( form.checkValidity() === true ) {
			const formData = new FormData(event.target)
			const data = Object.fromEntries(formData.entries())
			securityService.validateOtp(data).then( auth => {
				login(auth, true)
			})
		}
	}

	useEffect(() => {
		if ( !twoFactorRequired ) {
			history.push('/')
		}
	}, [twoFactorRequired, history]);

	useEffect(() => {
		tokenInput.current.focus()
	}, []);

	const logoutHandler = () => {
		logout()
	};

	return (
		<Col sm="7" md="5" lg="4" xl="3">
			<Card className="my-5">
				<Card.Body>
					<div className="text-center">
						<img src={logoAppvers} alt={"logo"} className="my-3"/>
					</div>
					<center>
						<h3>{t('login.twoFactor.authentication.label')}</h3>
						<Alert variant="info">
							{t('login.twoFactor.authentication.message')}
						</Alert>
					</center>
					<MessageAlert />
					<Form className="2fa-form" onSubmit={handleSubmit}>
					<InputGroup className={"mb-3"}>
						<InputGroup.Text><FontAwesomeIcon icon={faKey}/></InputGroup.Text>
						<FormControl type='text' name='token' required ref={tokenInput} placeholder={t('login.token.label')} autoComplete="off"/>
					</InputGroup>
						<Form.Check
							name={ "trustDevice" }
							type={ "checkbox" }
							id={ "trustDevice" }
							label={ t('login.trust.this.device', {count: daysToValid}) }
						/>
					<Row className={"mb-3"}>
						<Col>
							<p className={"text-end"}>
								<Link to={"#"} onClick={logoutHandler} className={"float-end"}>{t('default.cancel')}</Link>
							</p>
						</Col>
					</Row>


						<Button className="px-5 mb-4 w-100" variant="success" type="submit">{t('login.authenticate.label')}</Button>
				</Form>
				</Card.Body>
			</Card>
		</Col>
	);
};

export default TwoFactorAuthentication;

