import React, {useEffect, useReducer} from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import FormControl from "../_components/FormControl";
import appService from "../_services";
import {roleNodeTreeReducer} from "../_components/RoleNodeTree/roleNodeTreeReducer";
import {RoleNodeTreeDispatchContext} from "../_components/RoleNodeTree/RoleNodeTreeContext";
import RoleNodeTree from "../_components/RoleNodeTree/RoleNodeTree";
import DefaultSpinner from "../_components/DefaultSpinner";
import ConnectedUsers from "./ConnectedUsers";


function FormElements({domainInstance, defaultValues}) {
	const { t } = useTranslation();
	const initialRoleNodeTree = appService.useRoleNodeTree(domainInstance.roleGroupRoles)
	const [roleNodeTree, dispatch] = useReducer(
		roleNodeTreeReducer,
		initialRoleNodeTree
	);

	useEffect( () => {
		dispatch({type: 'init', roleNodeTree: initialRoleNodeTree})
	}, [initialRoleNodeTree])

	return (
		<>
			<Card className={"mb-2"}>
				<Card.Header>{t('roleGroup.card.info')}</Card.Header>
				<Card.Body>
					<Row className={"mb-3"}>
						<Form.Group as={Col} md="4" controlId="formGroupName">
							<Form.Label>{t('roleGroup.name')}</Form.Label>
							<FormControl
								name={"name"}
								type={"text"}
								value={domainInstance.name || (defaultValues && defaultValues.description)}
								rules={ {
									required: true,
								} }
							/>
						</Form.Group>
						<Form.Group as={Col} md="8" controlId="formGroupDescription">
							<Form.Label>{t('roleGroup.description')}</Form.Label>
							<FormControl
								name={"description"}
								type={"text"}
								value={domainInstance.description || (defaultValues && defaultValues.description)}
							/>
						</Form.Group>
					</Row>
				</Card.Body>
			</Card>
			<ConnectedUsers domainInstance={domainInstance} />
			<Card className={"mt-3"}>
				<Card.Header>
					{t('user.roleNodeTree')}
				</Card.Header>
				<Card.Body>
					{ ( roleNodeTree.roots && roleNodeTree.roots.length > 0) ?
						<>
							<FormControl type={"text"} name={'roleNodeTree'} hidden={true} value={ roleNodeTree }/>
							<RoleNodeTreeDispatchContext.Provider value={dispatch}>
								<RoleNodeTree nodes={roleNodeTree.roots}/>
							</RoleNodeTreeDispatchContext.Provider>
						</>
						:
						<DefaultSpinner loading={true}/>
					}
				</Card.Body>
			</Card>
		</>
	);
}

export { FormElements };
