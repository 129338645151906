import {Form} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";
import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import reactHookFormService from "./reactHookForm.service";
import {Dropdown} from "./Dropdown";
import {securityService} from "../_services/security.service";
import PlainValue from "./PlainValue";

function DropdownControl( { name, rules, validationMessages, value, placeholder, onChange, readOnly, className, optionsDomainName, keyPropName, valuePropName, nullable, options, size, disabled, sortBy}) {
	const { control, setValue } = useFormContext();
	const defVal = useMemo( () => value === undefined || value === null ? '' : value, [value] )
	const [currentValue, setCurrentValue] = useState(defVal);
	const accessToProperty = securityService.useAccessToPropertyOfAllowedContext()

	useEffect(() => {
		const newValue = defVal;
		setCurrentValue(newValue);
		setValue(name, newValue);
	}, [name, defVal, setValue]);

	const getControl = () => {
		return (
			<>
				{/*<p>TypeaheadControl: {(defaultValue === undefined || defaultValue === null) ? '' : defaultValue}</p>*/ }
				<Controller
					control={ control }
					name={ name }
					rules={ rules }
					value={ currentValue }
					render={ ( { field, fieldState } ) => {
						return ( <>
							{/*<p>field.value: {field.value===undefined?'undefined':(field.value===null?'null':field.value)}</p>*/ }
							<Dropdown
								onChange={ ( e ) => {
									setCurrentValue( e.target.value );
									field.onChange( e );
									if ( onChange ) {
										onChange( e );
									}
								} }
								onBlur={ field.onBlur }
								value={ field.value }
								name={ field.name }
								dropdownRef={ field.ref }
								className={ ( className ? `${ className } ` : '' ) + ( !!fieldState.error ? 'is-invalid' : '' ) }
								placeholder={ placeholder }
								readOnly={ readOnly }
								optionsDomainName={ optionsDomainName }
								keyPropName={ keyPropName }
								valuePropName={ valuePropName }
								nullable={ nullable }
								options={ options }
								size={ size }
								disabled={ disabled }
								sortBy={ sortBy }
							/>
							<Form.Control.Feedback type="invalid">
								{ reactHookFormService.getValidationMessage( fieldState, validationMessages ) }
							</Form.Control.Feedback>
						</> )
					}
					}
				/>
			</>
		)
	}

	if ( accessToProperty.ready ) {
		if ( accessToProperty.write ) {
			return getControl()
		} else if ( accessToProperty.read ){
			let displayValue = ''
			if ( options ) {
				const option = options.find( ( option ) => option[keyPropName] == value ) // eslint-disable-line
				if ( option ) {
					displayValue = option[valuePropName]
				}
			}
			return <PlainValue className={ "pe-3" } value={ displayValue }/>
		}
	}

	return null
}

DropdownControl.propTypes = {
	name: PropTypes.string.isRequired,
	rules: PropTypes.object,
	validationMessages: PropTypes.object,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	optionsDomainName: PropTypes.string,
	keyPropName: PropTypes.string,
	valuePropName: PropTypes.string,
	nullable: PropTypes.bool,
	options: PropTypes.array,
	size: PropTypes.string
};

export default DropdownControl;
