import React, {useEffect, useState} from 'react';
import {Button, Form, Modal} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import { withRouter } from 'react-router-dom';
import restService from "../_services/rest.service";
import {FormProvider, useForm} from "react-hook-form";
import PropTypes from "prop-types";
import {formService} from "./form.service";
import {Alert} from "../_components";

function AddEditPopupForm( props ) {
	const { t } = useTranslation();
	const [domainInstance] = restService.useDomainInstance(props.domainName, props.id);
	const useFormObject = useForm();
	const isAddMode = !props.id;
	const [showForm, setShowForm] = useState( props.show );

	useEffect(() => {
		setShowForm( props.show );
	}, [props.show]);


	const handleSubmit = useFormObject.handleSubmit(data => {
		let flattenData = formService.flattenSubmitData(data);

		if ( isAddMode ) {
			create( flattenData );
		} else {
			update( props.id, flattenData );
		}
	})

	const handleCancel = () => {
		props.onCancel();
	}

	function create(data) {
		//todo předělat saveDomainInstance a upateDomainInstance na Promise
		restService.saveDomainInstance( props.domainName, data )
			.then( json => {
				props.onUpdate(json);
			})
			.catch( (error, signal) => {
				restService.handleServerErrorsAxios(error, signal, {id: "addEditPopupFormAlert", autoClose: false})
			});
	}

	function update(id, data) {
		restService.updateDomainInstance( props.domainName, id, data )
			.then( (result) => {
					props.onUpdate(result);
				})
			.catch( ( error, signal ) => {
				restService.handleServerErrorsAxios( error, signal, {id: "addEditPopupFormAlert", autoClose: false} );
			});
	}

	const childrenProps = {
		domainInstance: domainInstance,
		defaultValues: props.defaultValues
	}

	return (
		<Modal
			show={showForm}
			size="xl"
			animation={false}
			onHide={handleCancel}
			centered
			backdrop="static"
		>
			<Modal.Header>
				<h5>{ t( 'default.header.' + (isAddMode?'create':'update'), { what: t(props.domainName + '.label') } ) }</h5>
			</Modal.Header>
			<Modal.Body>
				<Alert id={'addEditPopupFormAlert'} />
				<FormProvider {...useFormObject}>
					<Form>
						<Form.Control
							hidden
							name="id"
							defaultValue={props.id}
						/>
						{React.cloneElement(props.children, {...childrenProps})}
					</Form>
				</FormProvider>
			</Modal.Body>
			<Modal.Footer>
				<Form.Group>
					<Button type="button" variant={"primary"} onClick={handleSubmit}>{t('default.save')}</Button>
					<Button type="button" className={"ms-2"} variant={"secondary"} onClick={handleCancel}>{t('default.cancel')}</Button>
				</Form.Group>
			</Modal.Footer>
		</Modal>
	);
}

AddEditPopupForm.propTypes = {
	id: PropTypes.string,
	domainName: PropTypes.string.isRequired,
	children: PropTypes.any.isRequired,
	show: PropTypes.bool,
	onUpdate: PropTypes.func,
	onCancel: PropTypes.func
};


export default withRouter(AddEditPopupForm);
