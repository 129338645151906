import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {FormProvider, useForm} from "react-hook-form";
import {Navigation} from "./Navigation";
import {useTranslation} from "react-i18next";
import EditableTableControl from "../../form/EditableTableControl";
import restService from "../../_services/rest.service";
import {Card, Form} from "react-bootstrap";
import {alertService} from "../../_services";
import {useHistory} from "react-router-dom";
import DiscountSurcharge from "../../policy/DiscountSurcharge";
import {apiClientPrivate} from "../../api/apiClient";
import {messageBoxService} from "../../_services/messageBox.service";
import {MessageBoxButtons} from "../../_components/MessageBox";
import {damageService} from "../../_services/damage.service";

function Details({damagePaymentInfoData, dispatch, ...props}) {
    const {t} = useTranslation();
    const history = useHistory();
    const methods = useForm();
    const [isSubmitting, setIsSubmitting] = useState(false)

    const namedCriteria = useMemo( () => ( {queryName: "damage", params: {id: damagePaymentInfoData.baseData.damage.id}} ), [damagePaymentInfoData.baseData.damage.id] );

    const loadMe = useCallback(() => {
        return damagePaymentInfoData.baseData.damage.id !== undefined
    }, [damagePaymentInfoData.baseData.damage.id]);

    const [damageDetails] = restService.useDomainInstancesList('damageDetail', undefined, undefined, undefined, undefined, undefined, namedCriteria, "select", loadMe)

    const handleFormSubmit = (data) => {
        const detailsInfo = damageService.getDamagePaymentInfoDetailsInfo( damagePaymentInfoData.details )

        if ( detailsInfo.total < 0 && detailsInfo.someParticipationExists && detailsInfo.someNonParticipationExists ) {
            messageBoxService.display( t('damagePaymentInfoDetail.totalNegativeAndParticipationExists.content'), t('damagePaymentInfoDetail.totalNegativeAndParticipationExists.title'), [MessageBoxButtons.OK] )
                .then()
            return
        }

        setIsSubmitting(true);
        dispatch({type: 'DETAILS_CHANGE', data: data.damageDetails});
    }

    const redirectToShow = useCallback( (id) => {
        history.push('/damagePaymentInfo/show/'+id)
        alertService.success( t( 'default.updated', { what: t( 'damagePaymentInfo.label' ) } ), { keepAfterRouteChange: true } );
    }, [history, t])

    const save = useCallback( () => {
        return new Promise( (resolve, reject) => {
            let data = damagePaymentInfoData.baseData;
            data['details'] = damagePaymentInfoData.details;
            const params = { data: JSON.stringify( data ) }
            apiClientPrivate.post( `/api/damagePaymentInfo/saveOrUpdateDamage`, params )
                .then( r => restService.handleServerResponseAxios( r ) )
                .then( json => resolve(json.damagePaymentInfoId) )
                .catch( reject );
        });
    }, [damagePaymentInfoData.baseData, damagePaymentInfoData.details])

    useEffect(() => {
        if ( isSubmitting ) {
            save()
                .then((id) => {
                    redirectToShow(id)
                })
                .catch(error => {
                    restService.handleServerErrorsAxios( error )
                })
            setIsSubmitting(false);  //this line was before in 'then' and 'catch' blocks, but it caused the form to be submitted twice
        }
    }, [isSubmitting, redirectToShow, save])

    const handleParticipationUpdate = (changedColumn, rowId, data) => {
        if ( changedColumn === 'participation' ) {
            //set + or - to amount accordingly
            if (
                ( data[rowId].participation === true && data[rowId].amount > 0 ) ||
                ( data[rowId].participation === false && data[rowId].amount < 0 ) ) {
                data[rowId].amount = -data[rowId].amount
            }
        }
        else {
            //set participation checkbox
            if ( data[rowId].amount < 0 ) {
                data[rowId].participation = true
            }
            else {
                data[rowId].participation = false
            }
        }
        return data;
    }

    const columns = [
        {id: "damageDetail", label: t("damagePaymentInfoDetail.damageDetail"), input: {tag: "select", values: damageDetails, required: true, nullable: true}},
        {id: "discountSurcharges", label: t("damageDetail.discountSurcharge"), input: {tag: "label"}, readonly:true,
            formatter: (cellContent, row) => {
                if ( row && row.damageDetail ) {
                    const damageDetail = damageDetails.find( (dd) => dd.id === parseInt(row.damageDetail.id) )
                    if ( damageDetail ) {
                        if (damageDetail.participationType) {
                            return damageDetail.participationType.label
                        } else if (damageDetail.policyTarget) {
                            const policyTarget = damageDetail.policyTarget
                            return <DiscountSurcharge policyTarget={policyTarget}/>
                        }
                    }
                }
            }
        },
        {id: "amount", label: t("damagePaymentInfoDetail.amount"), input: {tag: "numericFormat", required: true, decimalScale: 2}, afterUpdate: async (rowId, data, originalValues) => {
            return handleParticipationUpdate('amount', rowId, data, originalValues);
        }},
        {id: "participation", label: t("damagePaymentInfoDetail.participation"), input: {tag: "check"}, afterUpdate: async (rowId, data, originalValues) => {
            return handleParticipationUpdate('participation', rowId, data, originalValues);
        }},
    ]

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(handleFormSubmit)}>
                <Card className={"mb-2"}>
                    <Card.Header>{t('damageDetail.card.info')}</Card.Header>
                    <Card.Body>
                        <EditableTableControl
                            name="damageDetails"
                            columns={columns}
                            data={damagePaymentInfoData.details}
                        />
                        <Navigation isSubmitting={isSubmitting} damagePaymentInfoData={damagePaymentInfoData} {...props} />
                    </Card.Body>
                </Card>
            </Form>
        </FormProvider>
    )
}

export {Details}
