import React, {useMemo} from 'react';
import {Trans, useTranslation} from "react-i18next";
import restService from "../_services/rest.service";
import {FormProvider, useForm} from "react-hook-form";
import {formService} from "../form/form.service";
import {Alert, Button, Form} from "react-bootstrap";
import FormControl from "../_components/FormControl";
import SubmitButton from "../_components/SubmitButton";
import {withRouter} from "react-router-dom";
import AllowedContextWrapper from "../_components/AllowedContextWrapper";
import {FormElements as PartnerFormElements} from "../partner/FormElements";
import {FormElements as BuildingFormElements} from "../building/FormElements";
import {FormElements as ZipFormElements} from "../zip/FormElements";
import Domains from "./Domains";

function MergeDomains( { history } ) {
    const { t } = useTranslation();
    const useFormObject = useForm();
    const queryParams = new URLSearchParams(window.location.search)
    const classSimpleName = queryParams.get('classSimpleName')
    const idSource = queryParams.get('idSource')
    const idTarget = queryParams.get('idTarget')
    const template = useMemo( () => ( classSimpleName === 'person' || classSimpleName === 'company' ) ? 'merge' : undefined, [classSimpleName] );
    const [domainSource] = restService.useDomainInstance( classSimpleName, idSource, undefined, template );
    const [domainTarget] = restService.useDomainInstance( classSimpleName, idTarget, undefined, template );

    const formElements = useMemo( () => {
        switch( classSimpleName ) {
            case 'person':
            case 'company':
                return <PartnerFormElements />
            case 'building':
                return <BuildingFormElements />
            case 'zip':
                return <ZipFormElements />
            default:
                throw new Error(`Unsupported ${classSimpleName} for merge.`);
        }
    }, [classSimpleName])

    function goBack() {
        if ( history.length === 1 ) {
            window.close();
        }
        else {
            history.goBack();
        }
    }

    function onSubmit(data) {
        const flattenData = formService.flattenSubmitData(data);

        return new Promise( (resolve, reject) => {
            restService.mergeDomainInstances( flattenData, idSource )
                .then( () => {
                    goBack();
                    resolve();
                })
                .catch( (error, signal ) => {
                    resolve(); //if reject() is called, then isSubmitting is not set back to false
                    console.log(error);
                    restService.handleServerErrorsAxios( error, signal )
                } );
        })
    }

    const childrenProps = useMemo( () => {
        return {
            domainInstance: domainTarget,
            mergeDomainInstance: domainSource,
            isAddMode: false,
        }
    }, [domainSource, domainTarget])

    const handleCancel = () => {
        goBack();
    }

    return (
        <>
            { Object.keys( domainSource ).length > 0 && Object.keys( domainTarget ).length > 0 &&
                <AllowedContextWrapper domainName={ classSimpleName } id={ idTarget } allowShow={ true }
                                       allowEdits={ true } allowDeletions={ false }>
                    <FormProvider { ...useFormObject }>
                        <Form onSubmit={ useFormObject.handleSubmit( onSubmit ) }>
                            <FormControl
                                hidden={ true }
                                name="id"
                                type={ "number" }
                                value={ domainTarget && domainTarget.id }
                            />
                            <FormControl
                                hidden={ true }
                                name="classSimpleName"
                                type={ "text" }
                                value={ domainTarget && domainTarget.classSimpleName }
                            />
                            <FormControl
                                hidden={ true }
                                name="version"
                                type={ "number" }
                                value={ domainTarget && domainTarget.version }
                            />
                            <Alert variant="info">
                                <Trans
                                    i18nKey="mergeDomains.info"
                                    components={ { Domains: <Domains domainSource={domainSource} domainTarget={domainTarget}/> } }
                                />
                            </Alert>
                            { React.cloneElement( formElements, { ...childrenProps } ) }
                            <Form.Group>
                                <SubmitButton isSubmitting={ useFormObject.formState.isSubmitting } type="submit"
                                              className="btn btn-primary">{ t( 'default.merge' ) }</SubmitButton>
                                <Button className="btn btn-secondary ms-2"
                                        onClick={ handleCancel }>{ t( 'default.cancel' ) }</Button>
                            </Form.Group>
                        </Form>
                    </FormProvider>
                </AllowedContextWrapper>
            }
        </>
    );
}

export default withRouter(MergeDomains);
