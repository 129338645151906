import React, {useCallback, useEffect, useRef, useState} from 'react';
import {fileService} from "../_services/file.service";
import {Button, ProgressBar} from "react-bootstrap";
import {faPlay, faTrash, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import restService from "../_services/rest.service";
import {useTranslation} from "react-i18next";
import {UploadStatus} from "../_enum/enum";

function UploadWithProgressComponent({file, fileId, onUploadStatusChange, alertOptions}) {
    const [progressInfo, setProgressInfo] = useState({percentage: 0});
    const [uploadStatus, setUploadStatus] = useState(UploadStatus.WAITING)
    const [responseData, setResponseData] = useState()
    const abortControllerRef = useRef(null)
    const isMounted = useRef(true)
    const {t} = useTranslation()

    useEffect(() => {
        return () => {
            isMounted.current = false
            if ( abortControllerRef.current ) {
                if ( !abortControllerRef.current.signal.aborted ) {
                    abortControllerRef.current.abort()
                }
            }
        }
    }, []);

    const uploadFile = useCallback( ( file ) => {
        abortControllerRef.current = new AbortController()
        setUploadStatus( UploadStatus.WAITING )
        fileService.uploadAttachment(file, (event) => {
            const percentCompleted = Math.round((event.loaded * 100) / event.total)
            setProgressInfo(
    {
                    percentage: percentCompleted,
                    file: file,
                }
            )
            setUploadStatus(UploadStatus.UPLOADING)
        }, abortControllerRef.current)
        .then( (response) => {
            setResponseData( response.data )
            setUploadStatus(UploadStatus.DONE)
        })
        .catch( (error) => {
            restService.handleServerErrorsAxios(error, abortControllerRef.current.signal, alertOptions)
            if ( isMounted.current ) {
                setProgressInfo((prevProgressInfo) => {
                    return {
                        ...prevProgressInfo,
                        percentage: 0,
                    }
                })
                setUploadStatus((prevUploadStatus) => {
                    if ( prevUploadStatus !== UploadStatus.CANCELED ) {
                        return UploadStatus.ERROR
                    }
                    return prevUploadStatus
                })
            }
        })
    }, [alertOptions])

    useEffect(() => {
        if (file) {
            uploadFile(file)
        }
    }, [file, uploadFile]);

    useEffect(() => {
        if ( uploadStatus ) {
            if ( uploadStatus === UploadStatus.DONE ) {
                onUploadStatusChange(fileId, uploadStatus, responseData)
            }
            else {
                onUploadStatusChange(fileId, uploadStatus)
            }
        }
    }, [uploadStatus, onUploadStatusChange, responseData, fileId])

    const sizeInMB = (size) => {
        return (size / 1024 / 1024).toFixed(2) + " MB";
    }

    const deleteUpload = useCallback((e) => {
        e.stopPropagation()
        setUploadStatus(UploadStatus.REMOVED)
    }, [setUploadStatus])

    const cancelUpload = useCallback((e) => {
        e.stopPropagation()
        setUploadStatus(UploadStatus.CANCELED)
        if (abortControllerRef.current && !abortControllerRef.current.signal.aborted) {
            abortControllerRef.current.abort()
        }
    }, [])

    const renderUploadStatus = () => {
        if ( uploadStatus === UploadStatus.UPLOADING || uploadStatus ===  UploadStatus.DONE ) {
            return <ProgressBar animated={false} now={progressInfo.percentage} label={`${progressInfo.percentage}%`} />
        }
        else {
            return t('appvers.enums.UploadStatus.' + uploadStatus)
        }
    }

    const renderButtons = () => {
        let result = []
        if (uploadStatus === UploadStatus.UPLOADING) {
            result.push(
                <Button type={"button"} key={"btn-cancel"} onClick={cancelUpload} size={"xs"} className={"ms-2"}
                        variant={"danger"}><FontAwesomeIcon icon={faXmark}/></Button>
            )
        }
        else {
            if ( uploadStatus !== UploadStatus.DONE && uploadStatus !== UploadStatus.WAITING ) {
                //renew upload
                result.push(
                    <Button type={"button"} key={"btn-upload"} onClick={(e) => {e.stopPropagation(); uploadFile(file)}} size={"xs"} className={"ms-2"}
                            variant={"info"}><FontAwesomeIcon icon={faPlay}/></Button>
                )
            }
            result.push(
                <Button type={"button"} key={"btn-delete"} onClick={deleteUpload}  size={"xs"} className={"ms-2"} variant={"danger"}><FontAwesomeIcon icon={faTrash}/></Button>
            )
        }
        return result
    }

    return (
        <>
                <div  className="d-flex align-items-center" style={{width: "100%", color: "black"}} key={fileId}>
                    <div className={"flex-grow-1"}>{file.name}, {sizeInMB(file.size)}</div>
                    <div className={"flex-grow-0 ms-3"} style={{width: "150px"}}>
                        {renderUploadStatus()}
                    </div>
                    <div className={"flex-grow-0 ms-3"}>
                        <div style={{minWidth: "70px"}}>
                            {renderButtons()}
                        </div>
                    </div>
                </div>
        </>
    )
}

export const UploadWithProgress = React.memo(UploadWithProgressComponent)
