import restService from "./rest.service";
import {fileService} from "./file.service";
import {apiClientPrivate} from "../api/apiClient";

const reportService = {
	debtorsList,
	printDamagePayments,
	printActivePartner,
};

function debtorsList(criteria) {
	return new Promise( (resolve, reject) => {
		const controller = new AbortController();
		const params = { criteria: JSON.stringify( criteria ) }
		apiClientPrivate.post( `/api/report/debtorsList`, params, {
			responseType: 'blob',
			signal: controller.signal,
		} )
			.then( fileService.saveAttachmentWithContentDispositionFileName )
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
				reject();
			} );
	});
}

function printDamagePayments( dateFrom, dateTo, groupData ) {
	return new Promise( (resolve, reject) => {
		const params = {
			dateFrom: dateFrom,
			dateTo: dateTo,
			groupData: groupData
		}
		const controller = new AbortController()
		apiClientPrivate.post( `/api/report/printDamagePayments`, params, {
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( fileService.saveAttachmentWithContentDispositionFileName )
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
				reject();
			} );
	});
}

function printActivePartner( ) {
	return new Promise( (resolve, reject) => {
		const controller = new AbortController()
		apiClientPrivate.get( `/api/report/printActivePartner`, {
			responseType: 'blob',
			signal: controller.signal
		} )
			.then( fileService.saveAttachmentWithContentDispositionFileName )
			.catch( (error) => {
				restService.handleServerErrorsAxios( error, controller.signal );
				reject();
			} );
	});
}

export default reportService;
