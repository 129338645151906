import React, {useCallback, useEffect, useMemo, useState} from 'react';
import websocketService from "../_services/websocket.service";
import settingService from "../_services/setting.service";
import {Alert} from "react-bootstrap";
import {Trans} from "react-i18next";

function Announcement() {
    const [announcement, setAnnouncement] = useState( '' );

    useEffect( () => {
        settingService.getSetting( ['announcement'] )
            .then( (values) => {
                setAnnouncement( values.announcement )
            })
    }, [setAnnouncement] )


    const afterUpdateAnnouncementCallback = useCallback( ( changes ) => {
		settingService.getSetting( ['announcement'] )
			.then( (values) => {
				setAnnouncement( values.announcement )
			})
    }, [setAnnouncement]);

    const ids = useMemo( () => ['announcement'], [] );
    websocketService.useAfterUpdateSubscription('setting', ids, afterUpdateAnnouncementCallback);

	return (
		announcement
			?
		    <Alert variant={"warning"}>
			    <Trans
				    components={ { p: <p/>, b: <b/>, i: <i/> } }
			    >
					{announcement}
			    </Trans>
			</Alert>
			:
			<></>
	)
}

export default Announcement