import React from 'react';
import DomainPartner from "./DomainPartner";
import DomainDefault from "./DomainDefault";

function Domains( { domainSource, domainTarget } ) {

	const getElement = (domain) => {
		switch ( domain.classSimpleName ) {
			case 'person':
			case 'company':
				return <DomainPartner domain={domain} />
			default:
				return <DomainDefault domain={domain} />
		}
	}

	return (
		<ul>{
			[domainSource, domainTarget].map( (domain, index) => {
				return <li key={index}>{getElement(domain)}</li>
			})
		}</ul>
	)
}

export default Domains